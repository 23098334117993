import * as angular from 'angular';

angular.module('app').directive('assetDetailsMetadata', assetDetailsMetadata);

function assetDetailsMetadata() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: require('../../views/templates/assetDetailsMetadata.html'),
		scope: {
			asset: '=',
			hasDetails: '=',
			showDetails: '=',
			isPastRevision: '=',
			currentUser: '=',
			bucket: '=',
			groupPermissions: '=',
			readonly: '=?',
			noLinks: '=?',
		},
	};
}
