import * as angular from 'angular';
import * as moment from 'moment';

angular.module('app').filter('duration', duration);

function duration() {
	return function (seconds) {
		const dur = moment.duration(seconds, 's');

		// moment currently lacks a duration.format() method. See: https://github.com/moment/moment/issues/1048
		const hours = Math.floor(dur.asHours());
		const asMoment = moment.utc(dur.asMilliseconds());
		return hours > 0 ? hours + asMoment.format(':mm:ss') : asMoment.format('m:ss');
	};
}
