import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('ratingsControl', ratingsControl);

function ratingsControl() {
	return {
		restrict: 'E',
		templateUrl: require('../../views/templates/ratingsControl.html'),
		scope: {
			max: '=?',
			average: '=?',
			detailsClass: '=?',
			rating: '=',
			isReadonly: '=',
		},
		replace: true,
		link(scope, elem) {
			let starWidth;

			scope.maxValue = scope.max || 5;
			scope.values = _.range(scope.maxValue);

			scope.submitRating = function (value) {
				const newRating = value + 1;
				scope.rating = scope.rating === newRating ? null : newRating;
			};

			scope.getBorderWidth = function (value) {
				if (!starWidth) {
					starWidth = elem.find('button').outerWidth();
				}

				return Math.round(starWidth * (1 - Math.max(0, Math.min(1, (scope.average || 0) - value))));
			};
		},
	};
}
