import * as angular from 'angular';
import * as _ from 'lodash';
import { IGeometryUtility, IMimeTypeUtility, IThinAsset } from '../helpers';

interface IAssetThumbnailModel {
	thumbnailKind: 'default' | 'image' | 'multi-image' | 'audio' | 'video' | 'text';
	title?: string;
	tileSize: number;
	fixedWidth?: number;
	fixedHeight?: number;
	fileKind?: string;
	fileName?: string;
	imgSrc?: string;
	textSrc?: string;
	textType?: string;
	imgWidth?: number;
	imgHeight?: number;
	disableFullscreen?: boolean;
	enableAutoplay?: boolean;
	mediaSources: Array<{ src: string; type: string } | string>;
	playing?: boolean;
	multiModel: {
		imgSrcs: Array<{ src: string; width: number; height: number }>;
		isTruncated: boolean;
		isInteractive: boolean;
		isPlaying: boolean;
		isBookLayout: boolean;
	};
}

angular.module('app').directive('assetThumbnail', assetThumbnail);

function assetThumbnail(
	$timeout: angular.ITimeoutService,
	$window: angular.IWindowService,
	geometryUtility: IGeometryUtility,
	mimeTypeUtility: IMimeTypeUtility
): angular.IDirective {
	const defaultPageLimit = 5;
	const allowedFixedDimensions = ['none', 'width', 'height'];

	return {
		restrict: 'AE',
		scope: {
			asset: '=',
			maxWidth: '=?',
			maxHeight: '=?',
			tileSize: '=?',
			fixedDimension: '@', // { none (default) | width | height }
			showMediaControls: '=?',
			showMultiImageControls: '=?',
			showMuteButton: '<?', // undefined | boolean | 'force'
			autoplay: '=?',
			pageLimit: '=?',
			shareToken: '=?',
			thumbnailUrls: '=?', // optional dictionary of { fileId -> thumbnailUrl }; used to resolve S3 urls directly, rather than the redirect url from the files service,
			hasPreview: '=?', // write property to inform the consumer of whether or not a preview could be displayed
			fillContainer: '=?',
			makeFocus: '=?',
			fillWidth: '=?',
			forceLargeThumbnail: '=?',
			isBookLayout: '=?',
			getThumbnailKind: '=?',
			useCustomStyles: '=?',
			disableFullscreen: '=?',
			enableAutoplay: '=?',
			isMultiImageControlBarVisible: '=?',
			isPastRevision: '<?',
			renderOnResize: '=?',
			renderTextToHtml: '=?',
			isWorking: '=?',
			lazyLoad: '=?',
		},
		templateUrl: require('../../views/templates/assetThumbnail.html'),
		replace: true,
		link(scope, elem) {
			function getFileUrlFromCache(fileId: string) {
				return scope.thumbnailUrls && scope.thumbnailUrls[fileId];
			}

			function digestRender() {
				renderThumbnail();
				scope.$digest();
			}

			function renderThumbnail() {
				const asset: IThinAsset | null = scope.asset;
				const fixedDimension = _.includes(allowedFixedDimensions, scope.fixedDimension)
					? scope.fixedDimension
					: 'none';
				const minimalPageCount = scope.pageLimit || defaultPageLimit;

				let maxWidth: number;
				let maxHeight: number;
				if (scope.fillContainer) {
					maxWidth = elem.parent().width();
					maxHeight = elem.parent().height();
				} else if (!isNaN(scope.maxWidth) && !isNaN(scope.maxHeight)) {
					maxWidth = scope.maxWidth;
					maxHeight = scope.maxHeight;
				} else {
					throw new Error(
						'asset-thumbnail requires fillContainer = true or maxWidth and maxHeight attributes'
					);
				}

				const model: IAssetThumbnailModel = {
					thumbnailKind: 'default',
					tileSize:
						typeof scope.tileSize !== 'undefined' ? scope.tileSize : Math.min(maxWidth, maxHeight),
					disableFullscreen: scope.disableFullscreen,
					enableAutoplay: scope.enableAutoplay,
					mediaSources: [],
					multiModel: {
						imgSrcs: [],
						isTruncated: false,
						isInteractive: false,
						isPlaying: scope.autoplay === true,
						isBookLayout: scope.isBookLayout,
					},
				};

				if (fixedDimension === 'width') {
					model.fixedWidth = maxWidth;
				}
				if (fixedDimension === 'height') {
					model.fixedHeight = maxHeight;
				}

				if (asset) {
					model.title = asset.title;
					model.fileKind =
						(asset.file && mimeTypeUtility.getFileKind(asset.file.mediaType)) ||
						(asset.source && mimeTypeUtility.getFileKind(asset.source.mediaType));
					model.fileName = (asset.file && asset.file.name) || (asset.source && asset.source.name);

					const largerDimension = scope.forceLargeThumbnail
						? Number.MAX_SAFE_INTEGER
						: Math.max(maxWidth, maxHeight);

					const thumbnailInfo = asset.getBestThumbnailForSize(largerDimension);
					if (thumbnailInfo && thumbnailInfo.url) {
						model.thumbnailKind = 'image';
						model.imgSrc = getFileUrlFromCache(thumbnailInfo.fileId) || thumbnailInfo.url;
						let bounds;
						if (scope.fillWidth) {
							bounds = geometryUtility.getBestFitForConstraint(
								maxWidth,
								undefined,
								thumbnailInfo.width,
								thumbnailInfo.height
							);
						} else {
							bounds = geometryUtility.getBestFitForConstraint(
								maxWidth,
								maxHeight,
								thumbnailInfo.width,
								thumbnailInfo.height
							);
						}
						model.imgWidth = Math.round(bounds.width);
						model.imgHeight = Math.round(bounds.height);
					} else if (asset.kind === 'video') {
						model.imgWidth = maxWidth;
						model.imgHeight = maxHeight;
						model.imgSrc = '/images/icon-video-file.svg';
					}

					const format = asset.getBestFormatForSize(largerDimension);
					if (format && (asset.kind === 'pdf' || (format.files && format.files.length > 1))) {
						model.thumbnailKind = 'multi-image';
						model.multiModel.isInteractive = !!scope.showMultiImageControls;

						if (fixedDimension === 'height') {
							model.fixedWidth = model.imgWidth;
						}

						let files = _.compact(format.files || [format.file]);

						if (!scope.showMultiImageControls && files.length > minimalPageCount + 1) {
							files = _.take(files, minimalPageCount);
							model.multiModel.isTruncated = true;
						}

						model.multiModel.imgSrcs = _.map(files, f => ({
							src: getFileUrlFromCache(f.id) || f.url,
							width: f.primaryMetadata.image.width,
							height: f.primaryMetadata.image.height,
						}));

						if (scope.autoplay === 'hover') {
							elem.parent().hover(
								() => {
									model.multiModel.isPlaying = true;
								},
								() => {
									model.multiModel.isPlaying = false;
								}
							);
						}
					}

					// Special case for A/V/Text, which have divergent kinds/thumbnail kinds.
					if (model.fileKind === 'audio' && scope.showMediaControls) {
						model.thumbnailKind = 'audio';
						model.mediaSources = asset.getAudioSources(scope.shareToken);
					} else if (model.fileKind === 'video' && (scope.autoplay || scope.showMediaControls)) {
						model.thumbnailKind = 'video';
						model.mediaSources = asset.getVideoSourcesForSize(
							scope.forceLargeThumbnail ? Number.MAX_SAFE_INTEGER : maxHeight,
							scope.shareToken
						);

						if (scope.autoplay === 'hover') {
							playOnHover();
						} else if (scope.autoplay) {
							model.playing = true;
						}
					} else if (model.fileKind === 'video' && scope.showMuteButton === 'force') {
						// if we don't autoplay the video but we still want a mute button, e.g. tinygrid view
						playOnHover();
					} else if (
						model.fileKind === 'image' &&
						asset.file &&
						asset.file.metadata &&
						asset.file.metadata.duration &&
						asset.file.url
					) {
						model.mediaSources = [asset.file.url];

						if (model.enableAutoplay || scope.autoplay === true) {
							model.playing = true;
						} else if (scope.autoplay === 'hover') {
							playOnHover();
						}
					} else if (model.fileKind === 'text' && scope.renderTextToHtml) {
						model.thumbnailKind = 'text';
						const bestTextFormat = asset.getBestTextFormat(scope.shareToken);
						if (bestTextFormat) {
							model.textSrc = bestTextFormat.src;
							model.textType = bestTextFormat.type;
						}
					}

					// allow link to preview page for image and multi-image thumbnails
					if (model.thumbnailKind === 'image' || model.thumbnailKind === 'multi-image') {
						scope.hasPreview = true;
					}
				}

				scope.model = model;
				if (scope.getThumbnailKind) {
					scope.getThumbnailKind.value = model.thumbnailKind;
				}

				function playOnHover() {
					elem
						.parent()
						.on('mouseover', () => {
							scope.$evalAsync(() => {
								scope.model.playing = true;
							});
						})
						.on('mouseout', () => {
							scope.$evalAsync(() => {
								scope.model.playing = false;
							});
						});
				}
			}

			if (!angular.isDefined(scope.showMuteButton)) {
				scope.showMuteButton = !scope.showMediaControls;
			}

			if (scope.renderOnResize) {
				let resizeWindow: angular.IPromise<void>;

				const doneResize = function () {
					renderThumbnail();
				};

				const startResize = function () {
					$timeout.cancel(resizeWindow);
					resizeWindow = $timeout(doneResize, 200);
				};

				angular.element($window).on('resize', startResize);

				scope.$on('$destroy', () => {
					angular.element($window).off('resize', startResize);
				});
			}

			/* eslint-disable angular/document-service */
			document.addEventListener('fullscreenchange', digestRender);
			document.addEventListener('webkitfullscreenchange', digestRender);
			document.addEventListener('mozfullscreenchange', digestRender);
			document.addEventListener('MSFullscreenChange', digestRender);
			/* eslint-enable */

			scope.$watch(
				'{ assetId: asset.id, assetRevision: asset.revision.id, maxWidth: maxWidth, maxHeight: maxHeight, fixedDimension: fixedDimension, showMediaControls: showMediaControls, showMultiImageControls: showMultiImageControls, autoplay: autoplay, pageLimit: pageLimit, forceLargeThumbnail: forceLargeThumbnail}',
				renderThumbnail,
				true
			);
		},
	};
}
