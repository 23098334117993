import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as SparkMD5 from 'spark-md5';
import { AssetFile, IAsset, IDateTimeUtility, IFileMapper, IUriUtility } from '../helpers';
import { CancellationToken, IAppSettings, IAssetService, IJobService } from '.';
import { Job } from './jobService';

export interface AssetFileDto {
	id: string;
	name: string;
	byteCount?: number;
	/** An ISO8601-formatted date. */
	lastModified?: string;
	mediaType: string;
	metadata: any;
	link: AssetFileLinkDto;
}

export interface AssetFileLinkDto {
	uri: string;
}

export interface CreateDirectUploadResponseDto {
	uploadId: string;
	uploadUri: string;
	protocol: DirectUploadProtocolKind;
}

export enum DirectUploadProtocolKind {
	Gcs = 'gcs',
}

export interface GcsResource {
	id: string;
	name: string;
	size: string;
	md5Hash: string;
}

export interface Progress {
	readonly lengthComputable: boolean;
	readonly loaded: number;
	readonly total: number;
}

angular.module('app').factory('fileUploadService', fileUploadService);

function fileUploadService(
	$http: angular.IHttpService,
	$log: angular.ILogService,
	$q: angular.IQService,
	$rootScope: angular.IRootScopeService,
	$timeout: angular.ITimeoutService,
	$window: angular.IWindowService,
	appSettings: IAppSettings,
	dateTimeUtility: IDateTimeUtility,
	fileMapper: IFileMapper,
	assetService: IAssetService,
	jobService: IJobService,
	uriUtility: IUriUtility
) {
	function createAssetWithContentAsync(
		file: File,
		cancellationToken: CancellationToken
	): angular.IPromise<IAsset> {
		const deferred = $q.defer<IAsset>();
		const updateProgressSoon = _.throttle((e: Progress) => {
			if (e.lengthComputable) {
				deferred.notify({ lengthComputable: true, loaded: e.loaded, total: e.total });
			}
		}, 50);

		const jqXhr = assetService.createAssetWithContentAsync(
			deferred,
			updateProgressSoon,
			file
		) as any;

		if (cancellationToken) {
			cancellationToken.whenCanceled(() => {
				$timeout(
					() => {
						if (jqXhr) {
							jqXhr.abort();
						}
						deferred.reject('canceled');
					},
					0,
					false
				);
			});
		}

		return deferred.promise;
	}

	function uploadFileAsync(
		file: File,
		cancellationToken: CancellationToken
	): angular.IPromise<AssetFile> {
		const deferred = $q.defer<AssetFile>();
		let jqXhr;
		const updateProgressSoon = _.throttle((e: Progress) => {
			if (e.lengthComputable) {
				deferred.notify({ lengthComputable: true, loaded: e.loaded, total: e.total });
			}
		}, 50);

		// use direct upload only if the file is larger than X MB
		const minDirectUploadFileSize = appSettings.directUploadThresholdMiB * 1024 * 1024;
		if (file.size > minDirectUploadFileSize) {
			doDirectUpload(deferred, updateProgressSoon, file, cancellationToken).catch(deferred.reject);
		} else {
			jqXhr = doFormDataUpload(deferred, updateProgressSoon, file);
		}

		if (cancellationToken) {
			cancellationToken.whenCanceled(() => {
				$timeout(
					() => {
						if (jqXhr) {
							jqXhr.abort();
						}
						deferred.reject('canceled');
					},
					0,
					false
				);
			});
		}

		return deferred.promise;
	}

	function doFormDataUpload(
		deferred: angular.IDeferred<AssetFile>,
		updateProgressSoon: (e: Progress) => void,
		file: File
	) {
		const form = new FormData();
		form.append('file', file);
		form.append('lastModified', dateTimeUtility.toIso8601(file.lastModified));

		// Angular's $http service makes it difficult to use FormData and progress events
		return $.ajax({
			url: `${appSettings.assetServiceBaseUri}files`,
			type: 'POST',
			processData: false,
			contentType: false,
			data: form,
			xhr() {
				const xhr = $.ajaxSettings.xhr();
				if (xhr.upload) {
					xhr.upload.addEventListener('progress', updateProgressSoon, false);
				}

				return xhr;
			},
		})
			.done(result => {
				deferred.resolve(fileMapper.map(result));
			})
			.fail((jqXhr, status, error) => {
				deferred.reject(error);
			})
			.always(() => {
				$rootScope.$digest();
			});
	}

	async function doDirectUpload(
		deferred: angular.IDeferred<AssetFile>,
		updateProgressSoon: (e: Progress) => void,
		inputFile: File,
		cancellationToken: CancellationToken
	) {
		interface GcsResponse {
			resource?: GcsResource;
			resumePosition?: number;
			status: UploadStatus;
		}

		enum UploadStatus {
			/** Requires resource to be set */
			Created,
			/** Requires resumePosition be set */
			Incomplete,
			Interrupted,
			Failed,
		}

		const { data: uploadInfo } = await $http.post<CreateDirectUploadResponseDto>(
			`${appSettings.assetServiceBaseUri}files/uploads/direct`,
			{
				supportedProtocols: ['gcs'],
			},
			{
				headers: {
					'X-Origin': $window.origin,
				},
			}
		);
		if (!uploadInfo) {
			throw new Error('Failed to get direct upload URI from server.');
		}
		if (uploadInfo.protocol !== DirectUploadProtocolKind.Gcs) {
			throw new Error('Server requested direct upload using unknown protocol');
		}
		const uploadId = uploadInfo.uploadId;

		let md5Hash;
		try {
			[md5Hash] = await Promise.all([
				getMD5FileHash(inputFile),
				executeUpload(uploadInfo.uploadUri, inputFile, cancellationToken, updateProgressSoon),
			]);
		} catch (err) {
			$log.error(err);
			deferred.reject('Direct upload failed');
			return;
		}

		$http
			.post(
				uriUtility.fromPattern(
					`${appSettings.assetServiceBaseUri}files/uploads/{uploadId}/finish`,
					{ uploadId }
				),
				{
					id: uploadId,
					mediaType: inputFile.type || 'application/octet-stream',
					fileName: inputFile.name,
					size: inputFile.size,
					md5Hash,
				}
			)
			.then(response => {
				const data = response.data;
				// if status is 202 ACCEPTED, a job is returned; if 200 OK, a file
				if (response.status === 202) {
					const job = data as Job;
					jobService
						.awaitJobsCompletionAsync([job.id], {
							cancellationToken,
							fields: 'items.response',
						})
						.then(jobsResponse => {
							const completedJob = jobsResponse.items[0];
							const jobResponse = completedJob && completedJob.response;
							if (!jobResponse) {
								throw new Error('No response object in completed job.');
							}
							const assetFile = fileMapper.map(jobResponse.content);
							deferred.resolve(assetFile);
						})
						.catch(err => {
							$log.error(err);
							deferred.reject('Upload job failed.');
						});
				} else if (response.status === 200) {
					const assetFile = fileMapper.map(data as AssetFileDto);
					deferred.resolve(assetFile);
				} else {
					const resp = JSON.stringify(response);
					throw new Error(`"Finish" request gave illegal response: ${resp}`);
				}
			})
			.catch(err => {
				$log.error(err);
				deferred.reject('Upload failed.');
			});

		async function executeUpload(
			url: string,
			file: File,
			token: CancellationToken,
			updateProgress: (e: Progress) => void
		): Promise<GcsResource> {
			let attempts = 0;

			const progressHandler = {
				total: 0,
				loaded: 0,
				get update() {
					const alreadyLoaded = this.loaded;
					const remaining = this.total - this.loaded;
					return (e: ProgressEvent) => {
						if (e.lengthComputable) {
							// total should only increase on the very first update
							if (e.total > this.total) {
								this.total = e.total;
							}
							// if this is the first run, this will return e.loaded (0 + x / y * y)
							this.loaded = alreadyLoaded + (e.loaded / e.total) * (remaining || e.total);
						}
						updateProgress({
							lengthComputable: e.lengthComputable,
							total: this.total,
							loaded: this.loaded,
						});
					};
				},
			};

			function uploadFromStart() {
				return uploadSlice(url, file, token, progressHandler.update);
			}

			async function handleResponse(response: GcsResponse): Promise<GcsResource> {
				if (token.isCancelled) {
					throw new Error('Upload cancelled');
				}

				if (
					response.status === UploadStatus.Failed ||
					response.status === UploadStatus.Interrupted
				) {
					if (attempts++ >= 50) {
						throw new Error(`Could not upload file; tried ${attempts} times`);
					}
					// truncated exponential backoff
					// y = { 1.7^x for y < 60, 60 for y >= 60 }
					await $timeout(Math.min(1000 * Math.pow(1.7, attempts), 60000));
				}

				switch (response.status) {
					case UploadStatus.Failed:
						$log.warn('Failed to complete direct upload; trying again...');
						return await handleResponse(await uploadFromStart());

					case UploadStatus.Interrupted: {
						$log.warn('Upload incomplete; attempting to get resume position.');
						const newResp = await getResumePosition(url, file, token);
						return await handleResponse(newResp);
					}

					case UploadStatus.Incomplete: {
						const resume = response.resumePosition!;
						$log.warn(`Found resume position; resuming upload starting at byte: ${resume}`);
						const newResp = await uploadSlice(url, file, token, progressHandler.update, [resume]);

						return await handleResponse(newResp);
					}

					case UploadStatus.Created: {
						if (response.resource) {
							return response.resource;
						}
						throw new Error('No resource attached to "created" response');
					}

					default:
						throw new Error(`Encountered unexpected response: ${response.status}`);
				}
			}

			const firstTry = await uploadFromStart();
			return await handleResponse(firstTry);
		}

		async function getResumePosition(
			url: string,
			file: File,
			token: CancellationToken
		): Promise<GcsResponse> {
			for (let i = 0; i < 2; i++) {
				let response: angular.IHttpPromiseCallbackArg<GcsResource>;
				try {
					response = await $http.put<GcsResource>(url, null, {
						headers: {
							'Content-Range': `bytes */${file.size}`,
						},
						timeout: token,
					});
				} catch (err) {
					if (err.status > 0) {
						// angular throws when status code > 299, but we want to handle it ourselves
						response = err;
					} else {
						// however, if there's an actual network error, status code will be < 0
						$log.warn('Getting resume position encountered network error; trying again');
						return {
							status: UploadStatus.Interrupted,
						};
					}
				}

				if (response.status === 308 && response.headers) {
					/*
					This is our expected condition for this request: 308 Resume Incomplete.

					When we get a 308, the response's Range header indicates what the server
					has successfully received. This tells us where to resume the upload.

					However, GCS might sometimes not include the Range header; in which case, we need to
					wait a few seconds and try again. If the second try doesn't work, we start over.

					https://cloud.google.com/storage/docs/json_api/v1/how-tos/resumable-upload#resume-upload
					*/
					if (!response.headers('Range')) {
						await $timeout(4000);
						continue;
					}
					// 'bytes=12-34' -> '12-34'
					const rangeStr = response.headers('Range').replace('bytes=', '');
					const range = rangeStr.split('-').map(str => parseInt(str, 10));
					return {
						status: UploadStatus.Incomplete,
						resumePosition: range[1],
					};
				}
				return getReturnValue(response);
			}
			// if we try twice and there is still no Range header
			$log.warn('Failed to resume upload; did not receive a Range header back');
			return {
				status: UploadStatus.Failed,
			};
		}

		/**
		 * @param url
		 * @param file
		 * @param token cancellation token
		 * @param updateProgress function to handle progress notifications
		 * @param range bytes to send, zero-indexed: [start (inclusive), end (**exclusive**)].
		 * <br> Will default to `[0, file.size]` -- i.e. the entire file
		 */
		async function uploadSlice(
			url: string,
			file: File,
			token: CancellationToken,
			updateProgress: (e: ProgressEvent) => void,
			range?: [number, number?]
		): Promise<GcsResponse> {
			if (!range) {
				range = [0];
			}
			if (!range[1]) {
				range[1] = file.size;
			}
			const data = file.slice(range[0], range[1]);

			let response: angular.IHttpPromiseCallbackArg<GcsResource>;
			try {
				const httpConfig: angular.IRequestConfig = {
					method: 'PUT',
					url,
					data,
					headers: {
						// our range is [x, y) but Content-Range is [x, y]
						'Content-Range': `bytes ${range[0]}-${range[1] - 1}/${file.size}`,
						'Content-Type': file.type,
					},
					uploadEventHandlers: {
						progress: <EventListener>updateProgress,
					},
					timeout: token,
				};

				response = await $http<GcsResource>(httpConfig);
			} catch (err) {
				$log.warn(`Upload encountered connection error; trying again`);
				return {
					status: UploadStatus.Interrupted,
				};
			}

			return getReturnValue(response);
		}

		function getReturnValue(response: angular.IHttpPromiseCallbackArg<GcsResource>): GcsResponse {
			if (!response.status) {
				return {
					status: UploadStatus.Interrupted,
				};
			} else if ([200, 201].includes(response.status) && response.data) {
				return {
					status: UploadStatus.Created,
					resource: response.data,
				};
			} else if (response.status === 429 || (response.status > 499 && response.status < 600)) {
				return {
					status: UploadStatus.Interrupted,
				};
			} else if (response.status > 399 && response.status < 500) {
				return {
					status: UploadStatus.Failed,
				};
			}
			throw new Error(`Direct upload encountered unrecoverable error: ${response.status}`);
		}
	}

	async function getUploadBatchIdAsync(): Promise<string> {
		return $http
			.get<any>(`${appSettings.assetServiceBaseUri}newids?count=1`)
			.then(result => result.data.ids[0]);
	}

	/**
	 * Get Base64 MD5 hash of file.
	 */
	function getMD5FileHash(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const spark = new SparkMD5.ArrayBuffer();
			const reader = new FileReader();
			const chunkSize = 2 * 1024 * 1024;
			let start = 0;

			reader.onload = () => {
				const buf = <ArrayBuffer>reader.result!;
				spark.append(buf);
				start += buf.byteLength;

				if (start < file.size) {
					loadNext();
				} else {
					resolve(btoa(spark.end(true)));
				}
			};

			reader.onerror = () => reject(new Error('Failed to get MD5 hash.'));

			function loadNext() {
				reader.readAsArrayBuffer(file.slice(start, Math.min(start + chunkSize, file.size)));
			}

			loadNext();
		});
	}

	const subscribeScope = $rootScope.$new(true);
	type AngularEventListener = (event: angular.IAngularEvent, ...args: any[]) => any;
	function subscribe(
		scope: angular.IScope,
		args: {
			useFullProgressMeter: AngularEventListener;
		}
	) {
		const useFullProgressMeterDeregister = subscribeScope.$on(
			'useFullProgressMeter',
			args.useFullProgressMeter
		);

		scope.$on('$destroy', () => {
			useFullProgressMeterDeregister();
		});
	}

	return Object.freeze({
		uploadFileAsync,
		createAssetWithContentAsync,
		getUploadBatchIdAsync,
		subscribe,
	});
}

export type IFileUploadService = ReturnType<typeof fileUploadService>;
