import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import {
	IAssetService,
	ICancellationService,
	IErrorService,
	ISearchResultsNavigationModel,
} from '../services';
import { IUser } from '../helpers';

angular.module('app').controller('AssetPreviewController', assetPreview);

function assetPreview(
	$scope: angular.IScope,
	$transition$: uirouter.Transition,
	assetService: IAssetService,
	cancellationService: ICancellationService,
	currentUser: IUser,
	searchResultsNavigationModel: ISearchResultsNavigationModel,
	errorService: IErrorService
) {
	$scope.model = {
		asset: null,
	};
	$scope.currentUser = currentUser;

	const $stateParams = $transition$.params();
	$scope.assetId = $stateParams.assetId;
	$scope.shareToken = $stateParams.shareToken;
	$scope.assetRevision = $stateParams.revisionId || undefined;
	const cs = cancellationService.createCancellationSource();

	async function getAsset() {
		const assetId =
			$scope.assetId ||
			($scope.shareToken && (await assetService.getShareAssetIdAsync($scope.shareToken, cs.token)));
		if (!assetId) {
			throw 'notfound';
		}
		$scope.assetId = assetId;

		const asset = await assetService.getAssetAsync(
			assetId,
			{ revision: $scope.assetRevision, shareToken: $scope.shareToken },
			cs.token
		);
		searchResultsNavigationModel.setCurrentAsset(asset.id);
		$scope.$apply(scope => {
			scope.model.asset = asset;
			scope.pagingContext = searchResultsNavigationModel.getCurrentContext();
		});
		$scope.$emit('pageTitleChange', asset.title);
	}

	getAsset()
		.catch(error => {
			if (error === 'abort') {
				return;
			} else if (error === 'notfound') {
				errorService.showNotFound();
			} else {
				errorService.showError();
			}
		})
		.finally(cancellationService.cancelWhenDestroyed(cs, $scope));
}
