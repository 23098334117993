import * as angular from 'angular';

angular.module('app').factory('errorReportingService', errorReportingService);

function errorReportingService() {
	let errorCount = 0;

	function reportError() {
		errorCount++;
	}

	function getErrorCount() {
		return errorCount;
	}

	return {
		reportError,
		getErrorCount,
	};
}

export type IErrorReportingService = ReturnType<typeof errorReportingService>;
