import * as angular from 'angular';

angular.module('app').factory('searchHelpService', searchHelpService);

function searchHelpService($http, appSettings) {
	function getSearchFieldsAsync() {
		return $http
			.get(`${appSettings.assetServiceBaseUri}assets/search/fields`)
			.then(response => response && response.data && response.data.fields);
	}

	return {
		getSearchFieldsAsync,
	};
}
