import * as angular from 'angular';

angular.module('app').directive('fillMeter', fillMeter);

function fillMeter() {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		scope: {
			fillFactor: '=',
			meterCount: '=?',
		},
		templateUrl: require('../../views/templates/fillMeter.html'),
		link(scope, element) {
			element[0].querySelector('.fill-meter-bar').style.width = `${scope.fillFactor * 100}%`;
		},
	};
}
