import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import * as _ from 'lodash';
import {
	IAppSettings,
	IAssetPermissionService,
	IAssetService,
	IBucketService,
	ICancellationService,
	IEmbedService,
	IErrorService,
	IFacetQueryService,
	IFileUploadService,
	IGroupService,
	IJobService,
	AssetOperation,
	Job,
	ISearchTextService,
	Bucket,
	ISearchResultsNavigationModel,
} from '../services';
import { IConstants } from '../infrastructure';
import { IUser, IAsset } from '../helpers';

interface IAssetDetailsModel {
	addFormatModel: any;
	asset: IAsset | null;
	hasDetails: boolean;
	hasFilters: boolean;
	hasPreview: boolean;
	isEditable: boolean;
	isPastRevision: boolean;
	isSubmitting: boolean;
	originalFileUpload: { progress: number } | null;
	revisionLimit: number;
	showDetails: boolean;
	sourceFileUpload: { progress: number } | null;
	supportsFormats: boolean;
}

interface IAssetDetailsScope extends angular.IScope {
	assetListState: 'assets' | 'assetsEmbed';
	bucket: Bucket;
	currentUser: IUser;
	enableFaithlifeComments: boolean;
	hideSearchText: boolean;
	model: IAssetDetailsModel;
	showSharePanel: boolean;
	thumbnailStatus: 'processing' | 'error' | undefined;
}

angular.module('app').controller('AssetDetailsController', assetDetails);

function assetDetails(
	$log: angular.ILogService,
	$rootScope: angular.IRootScopeService,
	$scope: IAssetDetailsScope,
	$state: uirouter.StateService,
	$transition$: uirouter.Transition,
	$window: angular.IWindowService,
	appSettings: IAppSettings,
	assetDetailsAddFormatModel,
	assetPermissionsService: IAssetPermissionService,
	assetService: IAssetService,
	bucketService: IBucketService,
	cancellationService: ICancellationService,
	constants: IConstants,
	currentUser: IUser,
	embedService: IEmbedService,
	errorService: IErrorService,
	facetQueryService: IFacetQueryService,
	fileUploadService: IFileUploadService,
	groupService: IGroupService,
	jobService: IJobService,
	searchResultsNavigationModel: ISearchResultsNavigationModel,
	searchTextService: ISearchTextService
) {
	const model: IAssetDetailsModel = {
		asset: null,
		sourceFileUpload: null,
		originalFileUpload: null,
		addFormatModel: null,
		isSubmitting: false,
		isPastRevision: false,
		isEditable: false,
		hasDetails: false,
		showDetails: false,
		revisionLimit: 3,
		supportsFormats: false,
		hasPreview: false,
		hasFilters: !!facetQueryService.filters.length || !!searchTextService.searchText,
	};

	const $stateParams = $transition$.params();
	const assetId = $stateParams.assetId;
	const assetRevision = $stateParams.revisionId || undefined;
	let hasPermissionToEditAsset = false;
	$scope.assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';

	searchResultsNavigationModel.setCurrentAsset(assetId);

	$scope.hideSearchText = true;
	$scope.enableFaithlifeComments = appSettings.enableFaithlifeComments;
	$scope.currentUser = currentUser;
	bucketService.getCurrentBucketAsync().then(currentBucket => {
		$scope.$apply(scope => (scope.bucket = currentBucket));
	});
	jobService.addStatusChangedHandler({ limit: 25 }, checkJobs);
	checkJobs(_.compact(_.values(jobService.myJobs.running)));

	function hasOperationsForThumbnailUpdates(operations: ReadonlyArray<AssetOperation> | undefined) {
		if (operations) {
			for (const operation of operations) {
				if (operation.op === 'createFormats' && operation.ops) {
					return hasOperationsForThumbnailUpdates(operation.ops);
				} else if (
					operation.op === 'createFormat' &&
					operation.name.startsWith(constants.previewFormatPrefix)
				) {
					return true;
				} else if (
					operation.op === 'createStandardFormats' ||
					operation.op === 'createStandardPreviews'
				) {
					return true;
				}
			}
		}
		return false;
	}

	function checkJobs(jobs: ReadonlyArray<Job>) {
		for (const job of jobs) {
			$log.debug('checking job', job); // eslint-disable-line
			const requestContent = job.request && job.request.content;
			if (requestContent) {
				const hasOurAsset =
					requestContent.assetId === assetId ||
					(Array.isArray(requestContent.assetIds) && requestContent.assetIds.includes(assetId));
				if (hasOurAsset && hasOperationsForThumbnailUpdates(requestContent.ops)) {
					if (job.status === 'running' || job.status === 'pending') {
						$scope.thumbnailStatus = 'processing';
					} else if (job.status === 'completed') {
						assetService.getAssetAsync(assetId).then(updatedAsset => {
							reloadWithAsset(updatedAsset);
							$scope.thumbnailStatus = undefined;
							$scope.$apply();
						});
					}
				}
			}
		}
	}

	function incrementShareCount() {
		$scope.model.asset!.activeShareCount!++;
	}

	function toggleSharePanel() {
		$scope.showSharePanel = !$scope.showSharePanel;
	}

	function updateAssetProperties(
		updatedAsset: Partial<IAsset>,
		propertyNames: ReadonlyArray<keyof IAsset>
	) {
		propertyNames.forEach(prop => {
			(model.asset![prop] as any) = updatedAsset[prop];
		});
		model.asset!.revision = updatedAsset.revision!;
	}

	async function refreshAsset() {
		if (currentUser && !currentUser.isAnonymous) {
			try {
				const asset = await assetService.getAssetAsync(assetId, { revision: assetRevision });
				if (
					(asset.bucket && asset.bucket !== (await bucketService.getCurrentBucketAsync()).id) ||
					!$scope.groupPermissions
				) {
					const bucket = await bucketService.setCurrentBucketAsync({ id: asset.bucket }, true);
					$scope.groupPermissions = await bucketService.getBucketGroupPermissionsAsync(bucket);
				}
				$scope.bucket = await bucketService.getCurrentBucketAsync();
				$rootScope.hasCreatePermission = $scope.bucket.createPermission === 'full';
				const permissions = await assetPermissionsService.getAssetsPermissionsAsync([asset]);
				hasPermissionToEditAsset = permissions.canEdit;
				reloadWithAsset(asset);
				assetService.reportAssetView(assetId);
				$scope.$emit('pageTitleChange', asset.title);
				groupService.currentAccountToken = $scope.bucket.group && $scope.bucket.group.token;
			} catch (error) {
				if (error === 'notfound') {
					errorService.showNotFound();
				} else {
					errorService.showError();
				}
			}
		}
	}

	function reloadWithAsset(asset: IAsset) {
		model.asset = asset;
		model.isPastRevision = !_.isUndefined(assetRevision);
		model.isEditable =
			hasPermissionToEditAsset && !model.isPastRevision && asset && !asset.isDeleted;
		model.addFormatModel = assetDetailsAddFormatModel.createAddFormatModel(asset);
	}

	function revertTo(revisionId: string) {
		if (model.isPastRevision || revisionId === model.asset!.revision.id) {
			return;
		}

		assetService
			.revertAssetToRevisionAsync(assetId, revisionId)
			.then(() => assetService.getAssetAsync(assetId))
			.then(reloadWithAsset);
	}

	function revertToCurrent() {
		if (!model.isPastRevision) {
			return;
		}

		assetService.revertAssetToRevisionAsync(assetId, model.asset!.revision.id!).then(() => {
			$state.go('details', { assetId });
		});
	}

	function updateOriginalFile(files: FileList) {
		if (!files.length) {
			return;
		}

		const isFirstOriginal = model.asset && !model.asset.file && !!model.asset.source;

		model.originalFileUpload = {
			progress: 0.0,
		};

		const cancellationSource = cancellationService.createCancellationSource();

		fileUploadService
			.uploadFileAsync(files[0], cancellationSource.token)
			.then(
				uploaded => uploaded.id,
				() => {
					// TODO: handle failure
				},
				progress => {
					model.originalFileUpload!.progress = (progress.loaded / progress.total) * 0.9;
				}
			)
			.then(uploadedId =>
				assetService.updateAssetAsync(
					assetId,
					edit => {
						edit.updateOriginal(uploadedId);
						if (isFirstOriginal) {
							edit.initializeAsset();
						}
					},
					cancellationSource.token
				)
			)
			.then(() => assetService.getAssetAsync(assetId, undefined, cancellationSource.token))
			.then(asset => {
				updateAssetProperties(asset, ['file', 'formats']);
				model.originalFileUpload = null;
			})
			.finally(cancellationService.cancelWhenDestroyed(cancellationSource, $scope));
	}

	function deleteAsset() {
		model.isSubmitting = true;

		assetService
			.deleteAssetsAsync([assetId])
			.then(() => {
				$state.go($scope.assetListState);
			})
			.finally(() => {
				model.isSubmitting = false;
			});
	}

	function undeleteAsset() {
		model.isSubmitting = true;

		assetService
			.undeleteAssetsAsync([assetId])
			.then(() => assetService.getAssetAsync(assetId))
			.then(reloadWithAsset)
			.finally(() => {
				model.isSubmitting = false;
			});
	}

	function uploadSourceFile(files) {
		if (!files.length) {
			return;
		}

		model.sourceFileUpload = {
			progress: 0.0,
		};

		const cancellationSource = cancellationService.createCancellationSource();

		fileUploadService
			.uploadFileAsync(files[0], cancellationSource.token)
			.then(
				uploadedFile => uploadedFile.id,
				() => {
					// TODO: handle failure
				},
				progress => {
					model.sourceFileUpload!.progress = (progress.loaded / progress.total) * 0.9;
				}
			)
			.then(uploadedId =>
				assetService.updateAssetAsync(
					assetId,
					edit => {
						edit.updateSource(uploadedId);
					},
					cancellationSource.token
				)
			)
			.then(() => assetService.getAssetAsync(assetId, undefined, cancellationSource.token))
			.then(updatedAsset => {
				updateAssetProperties(updatedAsset, ['source']);
				model.sourceFileUpload = null;
			})
			.finally(cancellationService.cancelWhenDestroyed(cancellationSource, $scope));
	}

	function deleteSourceFile() {
		const cancellationSource = cancellationService.createCancellationSource();

		assetService
			.updateAssetAsync(
				assetId,
				edit => {
					edit.updateSource(undefined);
				},
				cancellationSource.token
			)
			.then(() => assetService.getAssetAsync(assetId, undefined, cancellationSource.token))
			.then(updatedAsset => {
				updateAssetProperties(updatedAsset, ['source']);
			})
			.finally(cancellationService.cancelWhenDestroyed(cancellationSource, $scope));
	}

	function showAddFormatDialog() {
		model.addFormatModel.showFormatDialog = true;
	}

	function closeAddFormatDialog() {
		if (model.addFormatModel) {
			model.addFormatModel.closeDialog();
		}
	}

	function createFormat() {
		if (!model.addFormatModel) {
			return;
		}

		const cancellationSource = cancellationService.createCancellationSource();
		model.addFormatModel
			.createFormat(assetService, cancellationSource.token)
			.finally(cancellationService.cancelWhenDestroyed(cancellationSource, $scope));
	}

	function removeFormat(format) {
		format.isRemoved = true;
		const updatedFormatList = _.without(model.asset!.formats, format);

		const cancellationSource = cancellationService.createCancellationSource();
		assetService
			.updateAssetAsync(
				assetId,
				edit => {
					edit.removeFormats([format.file.id]);
				},
				cancellationSource.token
			)
			.then(() => assetService.getAssetAsync(assetId, undefined, cancellationSource.token))
			.then(updatedAsset => {
				const updated = {
					revision: updatedAsset.revision,
					formats: updatedFormatList,
				};

				updateAssetProperties(updated, ['formats']);
			});
	}

	$scope.model = model;
	$scope.revertTo = revertTo;
	$scope.revertToCurrent = revertToCurrent;
	$scope.updateOriginalFile = updateOriginalFile;
	$scope.incrementShareCount = incrementShareCount;
	$scope.toggleSharePanel = toggleSharePanel;
	$scope.deleteAsset = deleteAsset;
	$scope.undeleteAsset = undeleteAsset;
	$scope.uploadSourceFile = uploadSourceFile;
	$scope.deleteSourceFile = deleteSourceFile;
	$scope.showAddFormatDialog = showAddFormatDialog;
	$scope.closeAddFormatDialog = closeAddFormatDialog;
	$scope.createFormat = createFormat;
	$scope.removeFormat = removeFormat;
	$scope.getAssetRawUri = assetService.getAssetUri;
	$scope.getAssetRevisionsRawUri = assetService.getAssetRevisionsUri;
	$scope.getPreviewPath = function () {
		return model.hasPreview
			? $state.href('preview', { assetId: model.asset!.id, revisionId: model.asset!.revision.id })
			: undefined;
	};
	$scope.getStatsPath = function () {
		return $state.href('assetStats', { assetId: model.asset!.id });
	};

	$scope.$on('asset-video-loaded', (event, data) => {
		$scope.videoCurrentApi = data.videoPlayerApi;
	});

	$scope.setVideoThumbnailToCurrentFrame = function () {
		if (
			!$scope.videoCurrentApi ||
			$scope.thumbnailStatus === 'processing' ||
			$scope.thumbnailStatus === 'error'
		) {
			return;
		}

		const cancellationSource = cancellationService.createCancellationSource();
		const options = { asset: model.asset, offset: $scope.videoCurrentApi.getCurrentTime() };
		$scope.thumbnailStatus = 'processing';

		try {
			Array.from(
				$window.document.getElementsByClassName('asset-details-thumbnail-preview-canvas')
			).forEach($scope.videoCurrentApi.paintCurrentFrameOnCanvas);
			$scope.thumbnailPreviewCanvasHasData = true;
		} catch (error) {
			$log.error(error);
		}

		model.addFormatModel
			.replaceStandardPreviewsAsync(assetService, options, cancellationSource.token)
			.catch(error => {
				$scope.thumbnailStatus = 'error';
				throw error;
			});
	};
	refreshAsset();

	$scope.onTitleChange = function () {
		$scope.$emit('pageTitleChange', $scope.model.asset!.title);
	};
}
