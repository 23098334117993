// cache all templates ahead of time
require('../views/about.html');
require('../views/master-simple.html');
require('../views/searchHelp.html');
require('../views/webOptimizedHelp.html');
require('../views/embed.html');
require('../views/admin/admin.html');
require('../views/asset/details.html');
require('../views/asset/exif.html');
require('../views/asset/list.html');
require('../views/asset/preview.html');
require('../views/asset/shares.html');
require('../views/asset/stats.html');
require('../views/boardStats.html');
require('../views/error/error.html');
require('../views/error/forbidden.html');
require('../views/error/notfound.html');
require('../views/mobile/mobileSiteHeader.html');
require('../views/templates/assetBoardsEditor.html');
require('../views/templates/assetDetailsMetadata.html');
require('../views/templates/assetDetailsThumbnailPreview.html');
require('../views/templates/assetFormatOverlay.html');
require('../views/templates/assetFormats.html');
require('../views/templates/assetMetadata.html');
require('../views/templates/assetMetadataEditor.html');
require('../views/templates/assetMetadataField.html');
require('../views/templates/assetMetadataPropertyEditor.html');
require('../views/templates/assetPreviewHeader.html');
require('../views/templates/assetPreviewImages.html');
require('../views/templates/assetPreviewModal.html');
require('../views/templates/assetPreviewSidebar.html');
require('../views/templates/assetPrevNext.html');
require('../views/templates/assetRevisions.html');
require('../views/templates/assetTextPreview.html');
require('../views/templates/assetThumbnail.html');
require('../views/templates/assetTooltip.html');
require('../views/templates/promptButton.html');
require('../views/templates/assetVideoPlayer.html');
require('../views/templates/boardsList.html');
require('../views/templates/brandBar.html');
require('../views/templates/bucketPicker.html');
require('../views/templates/confirmationButton.html');
require('../views/templates/copyrightInput.html');
require('../views/templates/creatorInfo.html');
require('../views/templates/customFieldNameInput.html');
require('../views/templates/dropdownMenu.html');
require('../views/templates/facetList.html');
require('../views/templates/favoriteButton.html');
require('../views/templates/fileSelect.html');
require('../views/templates/fillMeter.html');
require('../views/templates/filterBreadcrumbBar.html');
require('../views/templates/filtersMenu.html');
require('../views/templates/groupBucketQuota.html');
require('../views/templates/hamburgerMenu.html');
require('../views/templates/icon-favorite.html');
require('../views/templates/icon-globe.html');
require('../views/templates/icon-grid.html');
require('../views/templates/icon-group.html');
require('../views/templates/icon-lock.html');
require('../views/templates/icon-person.html');
require('../views/templates/icon-previewpane.html');
require('../views/templates/icon-sidebar.html');
require('../views/templates/icon-table.html');
require('../views/templates/icon-tinygrid.html');
require('../views/templates/locationEditor.html');
require('../views/templates/modal.html');
require('../views/templates/multiImageThumbnail.html');
require('../views/templates/permissionInput.html');
require('../views/templates/ratingAverage.html');
require('../views/templates/ratingsControl.html');
require('../views/templates/rightsInput.html');
require('../views/templates/ringMeter.html');
require('../views/templates/scrollingSelect.html');
require('../views/templates/selectWithCustomOption.html');
require('../views/templates/serverRendered.html');
require('../views/templates/shareMenu.html');
require('../views/templates/signinModal.html');
require('../views/templates/siteHeader.html');
require('../views/templates/smartMediaEditorModal.html');
require('../views/templates/smartMediaEditor.html');
require('../views/templates/suggestionTooltip.html');
require('../views/templates/tableView.html');
require('../views/templates/tagTooltip.html');
require('../views/templates/uploadMenu.html');
require('../views/templates/uploadModal.html');
require('../views/templates/userJobStatus.html');
require('../views/templates/userJobStatusItem.html');
