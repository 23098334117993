import * as angular from 'angular';
import * as _ from 'lodash';
import * as amber from '@faithlife/amber-api';
import {
	AssetFile,
	AssetFileFormat,
	IAgent,
	IAgentMapperService,
	IAssetRevision,
	IAssetUtility,
	IFileMapper,
	IFormatMapper,
	IRevisionMapper,
	IThumbnailInfo,
	IUriUtility,
	IUtility,
} from '.';
import { MembershipKind } from '../services';

export interface IAssetShare {
	id?: string;
	token?: string;
	date?: string;
	expires?: string;
	agent?: IAgent;
}

export interface ILocation {
	name?: string;
	address?: string;
	city?: string;
	state?: string;
	country?: string;
}

export interface IAssetTag {
	text: string;
	score?: number;
	freebase?: { mid: string };
}

export interface IThinAsset {
	id: string;
	kind?: string;
	title?: string;
	description?: string;
	readRequires?: MembershipKind;
	tags?: { text: string }[];
	formats?: AssetFileFormat[];
	file?: AssetFile;
	source?: { name?: string; mediaType?: string };
	baseUrl: string;
	fileUrl?: string;
	revision: { id?: string };
	dateCreated?: string;
	isDeleted: boolean;
	getBestThumbnailForSize(size: number): IThumbnailInfo | undefined;
	getBestFormatForSize(size: number): AssetFileFormat | undefined;
	getVideoSourcesForSize(size: number, shareToken?: string): IAssetFormatInfo[];
	getAudioSources(shareToken?: string): IAssetFormatInfo[];
	getBestTextFormat(shareToken?: string): IAssetFormatInfo | undefined;
	getDownloadPathForFile(file: AssetFile): string;
}

export interface IAsset extends IThinAsset {
	bucket: string;
	date?: string;
	language?: string;
	authors?: { name?: string; id?: string }[];
	headline?: string;
	note?: string;
	status?: string;
	editRequires?: MembershipKind;
	tags?: IAssetTag[];
	categories?: any[];
	copyright?: string;
	publisher?: string;
	location?: ILocation;
	unsplashCredit?: string;
	webStatement?: string;
	licenseTerms?: string;
	rights?: string;
	favorite?: boolean;
	uploadId?: string;
	other?: any;
	families: { name: string; data?: any }[];
	shares?: IAssetShare[];
	ratings?: { [rating: string]: number };
	rating?: number;
	views?: number;
	downloads?: number;
	activeShareCount?: number;
	globalRating: number;
	globalRatingCount: number;
	globalFavoriteCount: number;
	sourceUrl?: string;
	webOptimizedUrl?: string;
	webOptimizedDownloadUrl?: string;
	creator?: IAgent;
	source?: AssetFile;
	revision: IAssetRevision;
	boardIds?: string[];
	originalMetadata?: any;
	originalDto: amber.IAsset;
	getFullEmbedHtml(shareToken?: string): string | undefined;
}

export interface IAssetFormatInfo {
	src: string;
	type: string;
}

angular.module('app').factory('assetMapper', assetMapper);

const DEFAULT_PRIMARY_FILE = {
	name: '(No file)',
};

function assetMapper(
	assetUtility: IAssetUtility,
	uriUtility: IUriUtility,
	formatMapper: IFormatMapper,
	agentMapper: IAgentMapperService,
	fileMapper: IFileMapper,
	revisionMapper: IRevisionMapper,
	utility: IUtility
) {
	function getDownloadPathForFile(baseUrl: string, fileId: string, revId?: string) {
		return uriUtility.fromPattern(`${baseUrl}/files/{fileId}/content`, { fileId, rev: revId });
	}

	function mapAsset(source: undefined, assetServiceBaseUri: string): undefined;
	function mapAsset(source: any, assetServiceBaseUri: string): IAsset;
	function mapAsset(source: any[], assetServiceBaseUri: string): IAsset[];
	function mapAsset(source: any, assetServiceBaseUri: string): IAsset | IAsset[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map(x => mapAsset(x, assetServiceBaseUri));
		}

		if (!source.file && !source.source) {
			source.file = DEFAULT_PRIMARY_FILE;
		}

		const metadata = source.metadata || {};
		const permissions = source.permissions || {};
		const license = metadata.license || {};
		const creation = source.creation || {};
		const appMetadata = (source.appData && source.appData.metadata) || {};
		const userMetadata = (source.userData && source.userData.metadata) || {};
		const shares = source.stats && source.stats.shares.items;
		const now = new Date().getTime();

		const formats =
			source.formats &&
			source.formats.map(format => formatMapper.map(format, source.id, source.revision.id));

		const families = _.map(metadata.families || [], (familyName: string) => ({
			name: familyName,
			data: _.cloneDeep(metadata[familyName] || {}),
		}));

		return <IAsset>{
			id: source.id,
			bucket: source.bucket,
			kind: source.kind,
			title: metadata.title,
			description: metadata.description,
			date: metadata.date,
			language: metadata.language,
			authors: metadata.authors,
			headline: metadata.headline,
			note: metadata.note,
			status: metadata.status,
			readRequires: permissions.readRequires,
			editRequires: permissions.editRequires,
			tags: metadata.tags,
			categories: metadata.categories,
			copyright: metadata.copyright,
			publisher: metadata.publisher,
			location: metadata.location,
			unsplashCredit: metadata.unsplashCredit,
			webStatement: license.webStatement,
			licenseTerms: license.usageTerms,
			rights: license.rights,
			favorite: userMetadata.favorite,
			dateCreated: creation.date,
			uploadId: metadata.uploadId,
			other: metadata.other,
			isDeleted: !!source.isDeleted,
			formats,
			families,
			rating: userMetadata.rating,
			views: source.stats && source.stats.views.count,
			downloads:
				source.stats &&
				source.stats.downloads &&
				_.reduce(source.stats.downloads, (sum, x: any) => sum + x.counter.count, 0),
			shares,
			activeShareCount: _.reduce(
				shares,
				(sum, share: any) =>
					sum + (!share.expires || new Date(share.expires).getTime() > now ? 1 : 0),
				0
			),

			get globalRating() {
				return (
					appMetadata.ratingTotal &&
					appMetadata.ratingCount &&
					appMetadata.ratingTotal / appMetadata.ratingCount
				);
			},

			globalRatingCount: appMetadata.ratingCount || 0,
			globalFavoriteCount: appMetadata.favoriteCount || 0,
			ratings:
				source.stats &&
				_.reduce(
					source.stats.ratings,
					(result, item: any) => {
						result[item.rating] = parseInt(item.count, 10);
						return result;
					},
					{}
				),

			get baseUrl() {
				return uriUtility.fromPattern(`${assetServiceBaseUri}assets/{id}`, { id: this.id });
			},
			get fileUrl() {
				return this.file && getDownloadPathForFile(this.baseUrl, this.file.id, this.revision.id);
			},
			get sourceUrl() {
				return (
					this.source && getDownloadPathForFile(this.baseUrl, this.source.id, this.revision.id)
				);
			},
			get webOptimizedUrl() {
				const baseUrlMatch = this.file && this.file.url && this.file.url.match(/(.*?\/v1\/)/);
				return (this.kind === 'image' || this.kind === 'video') && baseUrlMatch
					? uriUtility.fromPattern(`${baseUrlMatch[0]}assets/{id}/optimized`, { id: this.id })
					: undefined;
			},
			get webOptimizedDownloadUrl() {
				const baseUrl = this.webOptimizedUrl;
				return baseUrl && `${baseUrl}?download=true`;
			},

			getFullEmbedHtml(shareToken?: string) {
				if (this.kind !== 'image' || !this.webOptimizedUrl) {
					return undefined;
				}

				const originalWidth = this.file?.dimensions?.width;
				if (!originalWidth) {
					return undefined;
				}

				let currWidth = originalWidth;
				let prevWidth = currWidth;
				let srcset: string = '';

				do {
					const url = new URL(this.webOptimizedUrl);
					if (shareToken) {
						url.searchParams.set('share', shareToken);
					}

					if (currWidth !== originalWidth) {
						url.searchParams.set('w', currWidth.toString());
					}
					srcset += `${url.toString()} ${currWidth}w,`;

					prevWidth = currWidth;
					// Next width is half of previous width rounded up to the nearest hundred (so smallest size will be 100px)
					currWidth = Math.ceil(currWidth / 2 / 100) * 100;
				} while (prevWidth > 100);

				return `<img alt="${utility.encodeHtmlEntities(this.title)}" srcset="${srcset}" src="${
					this.webOptimizedUrl
				}" />`;
			},

			getBestThumbnailForSize(size) {
				return assetUtility.getBestThumbnailForSize(this, size);
			},
			getBestFormatForSize(size) {
				return assetUtility.getBestFormatForSize(this, size);
			},
			getVideoSourcesForSize(size, shareToken) {
				return assetUtility.getVideoSourcesForSize(this, size, assetServiceBaseUri, shareToken);
			},
			getAudioSources(shareToken) {
				return assetUtility.getAudioSources(this, assetServiceBaseUri, shareToken);
			},
			getBestTextFormat(shareToken) {
				return assetUtility.getBestTextFormat(this, assetServiceBaseUri, shareToken);
			},
			getDownloadPathForFile(file) {
				return getDownloadPathForFile(this.baseUrl, file.id, this.revision.id);
			},

			creator: agentMapper.map(creation.agent),
			file: fileMapper.map(source.file, source.id, source.revision?.id),
			source: fileMapper.map(source.source, source.id, source.revision?.id),
			revision: revisionMapper.map(source.revision),
			boardIds: appMetadata.boardIds,
			originalMetadata: metadata,
			originalDto: source,
		};
	}

	function isFullAsset(asset: IThinAsset | IAsset | undefined): asset is IAsset {
		return asset?.['bucket'] !== undefined;
	}

	return {
		map: mapAsset,
		isFullAsset,
	};
}

export type IAssetMapper = ReturnType<typeof assetMapper>;
