import * as angular from 'angular';

angular.module('app').filter('permissionLabel', permissionLabel);

function permissionLabel($filter) {
	return function (membershipRequired) {
		switch (membershipRequired) {
			case 'admin':
				return $filter('translate')('permissionInput.admins');
			case 'moderator':
				return $filter('translate')('permissionInput.moderators');
			case 'member':
				return $filter('translate')('permissionInput.members');
			case 'follower':
				return $filter('translate')('permissionInput.followers');
			case 'none':
				return $filter('translate')('permissionInput.public');
			default:
				return null;
		}
	};
}
