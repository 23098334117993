import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import * as _ from 'lodash';
import {
	CancellationSource,
	FileProgress,
	FileProgressStatus,
	ICancellationService,
	IEmbedService,
	IFacetQueryService,
	IFileUploadManager,
	IFileUploadService,
	IFilterUtility,
	IJobService,
	ProgressReport,
} from '../services';
import { IConstants } from '../infrastructure';

angular.module('app').directive('uploadModal', uploadModal);

function uploadModal(): angular.IDirective {
	return {
		restrict: 'E',
		scope: {},
		templateUrl: require('../../views/templates/uploadModal.html'),
		replace: true,
		controller(
			$log: angular.ILogService,
			$rootScope: angular.IRootScopeService,
			$scope: angular.IScope,
			$state: uirouter.StateService,
			$filter: angular.IFilterService,
			cancellationService: ICancellationService,
			constants: IConstants,
			embedService: IEmbedService,
			facetQueryService: IFacetQueryService,
			fileUploadManager: IFileUploadManager,
			fileUploadService: IFileUploadService,
			filterUtility: IFilterUtility,
			jobService: IJobService
		) {
			let cancellationSource: CancellationSource | undefined;

			function setStatus(status: FileProgressStatus | 'processing' | undefined) {
				const wasWorking = $scope.status === 'uploading' || $scope.status === 'preparing';
				const isWorking = status === 'uploading' || status === 'preparing';

				$scope.status = status;
				$scope.isDone = status === 'complete' || status === 'error' || status === 'processing';

				if (embedService.isEmbedded && wasWorking !== isWorking) {
					embedService.postMessage({
						type: 'working',
						value: isWorking,
					});
				}
			}

			function reset() {
				if (cancellationSource) {
					cancellationSource.cancel();
				}

				setStatus(undefined);
				$scope.jobs = null;

				$scope.fileCount = 0;
				$scope.progress = 0;
				$scope.failedUploads = null;
				$scope.uploadBatchId = null;
				progressModifier = 0.9;
			}

			let progressModifier = 0.9;

			function getFileProgress(file: FileProgress) {
				let progress = file.uploadedSize / file.fileSize || 0; /* NaN protection */
				if (file.status === 'uploading') {
					progress *= progressModifier;
				} // reserve room in the progress meter for server processing time

				return progress;
			}

			function calculateProgress(progress: ProgressReport) {
				const totalSize = _.reduce(progress.files, (sum, file) => sum + file.fileSize, 0);
				return progress.files.reduce(
					(sum, file) =>
						sum + getFileProgress(file) * (file.fileSize / totalSize || 0) /* NaN protection */,
					0
				);
			}

			function upload(fileList: ReadonlyArray<File | string>) {
				if (!fileList.length) {
					$log.warn('No valid files.');
					return;
				}

				// cancel existing uploads
				if (cancellationSource) {
					cancellationSource.cancel();
				}

				cancellationSource = cancellationService.createCancellationSource();
				const cancellationToken = cancellationSource.token;

				setStatus('preparing');

				$scope.fileCount = fileList.length;
				$scope.progress = 0;
				$scope.errors = [];

				const filterString = embedService.presetFilters || '';

				const filters = filterUtility.parseFilters(filterString);

				fileList = fileList.filter(file => {
					if (typeof file === 'string') {
						return true;
					}

					const matchesFilters = filterUtility.matchesFilters(file, filters);
					if (!matchesFilters) {
						setStatus('error');
						$scope.errors.push({
							file,
							message: $filter('translate')('uploads.errorMatchingFilters'),
						});
					}
					return matchesFilters;
				});

				if (!fileList.length) {
					$log.warn('No matching files');
					return;
				}
				$scope.fileCount = fileList.length;

				function logError(error: any) {
					$log.error('Asset creation or file upload errors', error);
				}

				function updateProgress(progress: ProgressReport) {
					$scope.progress = calculateProgress(progress);

					// Don't set the status to complete when files are complete,
					// status will transition to processing.
					if (progress.status !== 'complete') {
						setStatus(progress.status);
					} else {
						setStatus('processing');
					}
				}

				fileUploadManager
					.uploadFilesAndCreateAssetsAsync(fileList, updateProgress, cancellationToken)
					.then(batchResult => {
						$scope.progress = 1.0;
						$scope.uploadBatchId = batchResult.uploadBatchId;

						$scope.errors = batchResult.uploadResults.filter(
							x => x.error || (x.job && x.job.status === 'failed')
						);

						setStatus(undefined);

						$scope.continue();

						const jobIds = _.compact(batchResult.uploadResults.map(x => x.job && x.job.id));

						$scope.jobs = {
							total: batchResult.uploadResults.length,
							pending: jobIds.length,
							success: 0,
						};

						if (!jobIds.length) {
							return Promise.reject('All uploads failed');
						}

						return jobService
							.awaitJobsCompletionAsync(jobIds, {
								fields: 'items.(id,status)',
								cancellationToken,
							})
							.then(reset, undefined, progress => {
								progress.updatedJobs.forEach(job => {
									if (job.status === 'completed') {
										$scope.jobs.pending--;
										$scope.jobs.success++;
									} else if (job.status === 'failed') {
										$scope.jobs.pending--;
										$scope.jobs.error++;

										$scope.errors.push(
											batchResult.uploadResults.find(x => x.job && x.job.id === job.id)
										);
									}
								});
								if ($scope.jobs.success) {
									$rootScope.$broadcast(constants.fileUploadComplete);
								}
							})
							.catch(reason => {
								if (reason !== 'abort') {
									setStatus('complete');
								}
							});
					})
					.catch(reason => {
						if (reason !== 'abort') {
							setStatus('error');
							$scope.progress = 1.0;
							logError(reason);
						}
					});
			}

			reset();

			$scope.continue = function () {
				const uploadBatchId = $scope.uploadBatchId;
				const assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';

				$state.go(
					assetListState,
					uploadBatchId
						? {
								q: `upload:${uploadBatchId}`,
								filter: null,
								select: 'auto',
						  }
						: {}
				);
			};

			$scope.abort = function () {
				reset();
			};

			const handleUpload = (e: angular.IAngularEvent, data: { files: FileList; url?: string }) => {
				if ($rootScope.disableUploads) {
					return;
				}
				if (e.name === constants.uploadFromUrlEventName && data.url) {
					upload([data.url]);
				} else if (data.files) {
					upload(Array.from(data.files));
				}
			};

			fileUploadService.subscribe($scope, {
				useFullProgressMeter: () => {
					progressModifier = 0.99;
				},
			});

			$scope.$on(constants.dropEventName, handleUpload);
			$scope.$on(constants.filesSelectedEventName, handleUpload);
			$scope.$on(constants.uploadFromUrlEventName, handleUpload);
		},
	};
}
