import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import { pick } from 'lodash';
import { IFacetFilter } from './facetQueryService';
import { IAsset, IAssetFile, IAssetFileLink, IAssetFormat } from '@faithlife/amber-api';

export interface IAssetCompat extends IAsset {
	file?: IAssetFileCompat;
	formats?: IAssetFormatCompat[];
	webOptimizedUrl?: string;
}

export interface IAssetFileCompat extends IAssetFile {
	linkUri?: string;
	url?: string;
	dimensions?: { width: number; height: number };
}

export interface IAssetFormatCompat extends IAssetFormat {
	file?: IAssetFileCompat;
	files?: IAssetFileCompat[];
	firstFile?: IAssetFileCompat;
}

type EmbedMessage =
	| {
			type: 'assets' | 'filter';
			canceled: true;
	  }
	| {
			type: 'assets';
			assets: IAssetCompat[] | IAssetFileLink[];
	  }
	| {
			type: 'filter';
			filterData: {
				filters: IFacetFilter[];
				query: string;
				bucketId: string;
				fileCount: number;
			};
	  }
	| {
			kind: 'status';
			status: string;
	  }
	| {
			type: 'signedin';
	  }
	| {
			type: 'create';
			openInExternalEditor: boolean;
	  }
	| {
			type: 'working';
			value: boolean;
	  };

angular.module('app').factory('embedService', embedService);

function embedService(
	$rootScope: angular.IRootScopeService,
	$urlMatcherFactory: uirouter.UrlMatcherFactory,
	$state: uirouter.StateService,
	$location: uirouter.LocationServices,
	$log: angular.ILogService
) {
	let isEmbedded: boolean = false;
	let assetPickerFields: string;
	let disableSidebarLeft: boolean;
	let footerText: string;
	let pickerMode: string;
	let externalEditorKinds: string[] | undefined;
	let smartMediaCreateKind: string | undefined;
	let presetFilters: string | undefined;
	let returnUrl: string;
	let defaultPermissions: object | undefined;
	let multiSelect: boolean;
	let sidebarRightPreview: boolean | undefined;
	let sidebarRightDetails: boolean | undefined;
	let enableBucketPicker: boolean | undefined;
	let forceCreateShare: boolean;
	let createFilter: boolean | undefined;
	let createTokenUri: string;

	function setViewMode() {
		const params = $location.search();
		const embedRoute = $urlMatcherFactory.compile($state.get('assetsEmbed').url!);
		const embedMatch = embedRoute.exec($location.path(), params);

		isEmbedded =
			isEmbedded ||
			!!embedMatch ||
			(params.embedded && params.embedded !== 'false') ||
			!!params.returnUrl;

		if (isEmbedded) {
			assetPickerFields = assetPickerFields || params.assetPickerFields;
			disableSidebarLeft = disableSidebarLeft || params.disableSidebarLeft;
			footerText = footerText || params.footerText;
			pickerMode = pickerMode || params.pickerMode;
			externalEditorKinds =
				externalEditorKinds || parseExternalEditorKinds(params.externalEditorKinds);
			presetFilters =
				typeof presetFilters === 'undefined' ? params.filter || undefined : presetFilters;
			returnUrl = returnUrl || params.returnUrl;
			defaultPermissions = defaultPermissions || parsePermissions(params.defaultPermissions);
			multiSelect = multiSelect || params.multiSelect;
			sidebarRightPreview =
				sidebarRightPreview || parseSidebarRightSetting(params.sidebarRightPreview);
			sidebarRightDetails =
				sidebarRightDetails || parseSidebarRightSetting(params.sidebarRightDetails);
			enableBucketPicker = parseEnableBucketPicker(params.enableBucketPicker);
			forceCreateShare = forceCreateShare || params.forceCreateShare;
			createFilter = createFilter || params.createFilter;
			createTokenUri = createTokenUri || params.createTokenUri;
		}

		if (pickerMode) {
			$rootScope.viewMode = 'picker';
		} else if (isEmbedded && !pickerMode) {
			$rootScope.viewMode = 'vault-embed';
		} else {
			$rootScope.viewMode = 'full-app';
		}
	}

	function parseExternalEditorKinds(text: string) {
		if (!text) {
			return [];
		}
		try {
			const parsedAssetKinds = JSON.parse(text);
			if (
				Array.isArray(parsedAssetKinds) &&
				parsedAssetKinds.every(element => typeof element === 'string')
			) {
				return parsedAssetKinds;
			}
			$log.warn(`Invalid externalEditorKinds option: ${text}`);
		} catch (err) {
			$log.warn(`Invalid externalEditorKinds option: ${text}`, err);
		}
		return [];
	}

	function parsePermissions(text) {
		if (!text) {
			return undefined;
		}
		try {
			const permissions = JSON.parse(text);
			if (
				typeof permissions === 'object' &&
				(typeof permissions.readRequires === 'string' ||
					typeof permissions.editRequires === 'string')
			) {
				return pick(permissions, ['readRequires', 'editRequires']);
			}
			$log.warn(`Invalid permissions: ${text}`);
		} catch (err) {
			$log.warn(`Invalid permissions: ${text}`, err);
		}
		return undefined;
	}

	const parseSidebarRightSetting = setting =>
		setting === 'show' || setting === 'hide' ? setting === 'show' : undefined;

	const parseEnableBucketPicker = (text: string) => {
		if (text === 'true') {
			return true;
		}
		if (text === 'false') {
			return false;
		}
		return undefined;
	};

	function postMessage(message: EmbedMessage) {
		parent.postMessage(JSON.stringify(message), '*');
	}

	return {
		set isEmbedded(value) {
			isEmbedded = value;
		},
		get isEmbedded() {
			return isEmbedded;
		},
		set pickerMode(value) {
			pickerMode = value;
		},
		get pickerMode() {
			return pickerMode;
		},
		set externalEditorKinds(value) {
			externalEditorKinds = value;
		},
		get externalEditorKinds() {
			return externalEditorKinds;
		},
		set smartMediaCreateKind(value) {
			smartMediaCreateKind = value;
		},
		get smartMediaCreateKind() {
			return smartMediaCreateKind;
		},
		set assetPickerFields(value) {
			assetPickerFields = value;
		},
		get assetPickerFields() {
			return assetPickerFields;
		},
		set disableSidebarLeft(value) {
			disableSidebarLeft = value;
		},
		get disableSidebarLeft() {
			return disableSidebarLeft;
		},
		set footerText(value) {
			footerText = value;
		},
		get footerText() {
			return footerText;
		},
		set presetFilters(value) {
			presetFilters = value;
		},
		get presetFilters() {
			return presetFilters;
		},
		set returnUrl(value) {
			returnUrl = value;
		},
		get returnUrl() {
			return returnUrl;
		},
		set defaultPermissions(value) {
			defaultPermissions = value;
		},
		get defaultPermissions() {
			return defaultPermissions;
		},
		set multiSelect(value) {
			multiSelect = value;
		},
		get multiSelect() {
			return multiSelect;
		},
		set sidebarRightPreview(value) {
			sidebarRightPreview = value;
		},
		get sidebarRightPreview() {
			return sidebarRightPreview;
		},
		set sidebarRightDetails(value) {
			sidebarRightDetails = value;
		},
		get sidebarRightDetails() {
			return sidebarRightDetails;
		},
		get enableBucketPicker() {
			return enableBucketPicker;
		},
		set forceCreateShare(value) {
			forceCreateShare = value;
		},
		get forceCreateShare() {
			return forceCreateShare;
		},
		set createFilter(value) {
			createFilter = value;
		},
		get createFilter() {
			return createFilter;
		},
		get createTokenUri() {
			return createTokenUri;
		},
		setViewMode,
		postMessage,
	};
}

export type IEmbedService = ReturnType<typeof embedService>;
