import * as angular from 'angular';
import * as _ from 'lodash';
import { IUriUtility, IUtility } from '../helpers';

angular.module('app').directive('assetFormats', assetFormats);

function assetFormats(uriUtility: IUriUtility, utility: IUtility) {
	return {
		replace: true,
		restrict: 'E',
		templateUrl: require('../../views/templates/assetFormats.html'),
		scope: {
			isEditable: '=',
			asset: '=',
			originalFileUpload: '=',
			shareToken: '=?',
			isReadonly: '=?',
			removeFormat: '&?',
			updateOriginalFile: '&?',
			showSystemFormats: '&?',
			groupPermissions: '<?',
			isPastRevision: '<?',
		},
		link(scope: angular.IScope) {
			scope.isUrl = link => /^https?:\/\//i.test(link);

			function isViewableAsset() {
				return (
					scope.shareToken ||
					scope.asset.readRequires === 'none' ||
					(scope.asset.readRequires == null &&
						scope.groupPermissions &&
						scope.groupPermissions.readRequires === 'none')
				);
			}

			function updateWebOptimizedUrl() {
				if (isViewableAsset() && scope.asset.webOptimizedUrl) {
					scope.webOptimizedUrl = uriUtility.fromPattern(scope.asset.webOptimizedUrl, {
						rev: scope.isPastRevision ? scope.asset.revision.id : undefined,
					});
					scope.webOptimizedDownloadUrl = uriUtility.fromPattern(scope.webOptimizedUrl, {
						download: true,
					});
				} else {
					scope.webOptimizedUrl = null;
					scope.webOptimizedDownloadUrl = null;
				}
			}

			function updateFullEmbed() {
				scope.fullEmbedHtml = isViewableAsset()
					? scope.asset.getFullEmbedHtml(scope.shareToken) ?? null
					: null;
			}

			updateWebOptimizedUrl();
			updateFullEmbed();
			scope.$watchGroup(
				[
					'asset',
					'asset.readRequires',
					'asset.revision.id',
					'asset.webOptimizedUrl',
					'groupPermissions',
					'groupPermissions.readRequires',
					'isPastRevision',
					'shareToken',
				],
				() => {
					updateWebOptimizedUrl();
					updateFullEmbed();
				}
			);

			scope.hasSystemFormats = () =>
				_.filter(scope.asset.formats, format => format.isSystemFormat).length;

			scope.getEmbed = (file, url) => {
				if (!file || !file.mediaType?.startsWith('image/')) {
					return false;
				}
				const height = file.dimensions?.height;
				const width = file.dimensions?.width;
				return `<img src="${url || file.url}" ${width && `width="${width}"`} ${
					height && `height="${height}"`
				} alt="${utility.encodeHtmlEntities(scope.asset.title)}" />`;
			};
		},
	};
}
