import * as angular from 'angular';

angular.module('app').directive('autoFocus', autoFocus);

function autoFocus($timeout) {
	return {
		link(scope, element, attrs) {
			scope.$watch(attrs.autoFocus, value => {
				if (value === true) {
					$timeout(() => {
						const el = element[0];
						const tagName = el.tagName;

						if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
							el.select();
						}

						el.focus();
						scope[attrs.autoFocus] = false;
					});
				}
			});
		},
	};
}
