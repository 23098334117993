import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import { Bucket, IBucketService, IEmbedService, IGroupService } from '../services';
import { IFilteredDataSource } from './';

angular.module('app').component('bucketPicker', {
	templateUrl: require('../../views/templates/bucketPicker.html'),
	controller: BucketPicker,
});

interface BucketInfo {
	id: string;
	text: string;
	model: Bucket;
	avatarUri: string;
	view?: string;
}

interface IBucketPicker extends angular.IController {
	selectedBucketId?: string;
	selectedBucket?: BucketInfo;
	isReadonly?: boolean;
	returnUrl?: string;
	selectBucket: (id: string) => void;
	filteredDataSource: IFilteredDataSource<BucketInfo>;
	onSelect: (id: string) => void;
}

function BucketPicker(
	this: IBucketPicker,
	$document: angular.IDocumentService,
	$log: angular.ILogService,
	$scope: angular.IScope,
	$state: uirouter.StateService,
	$sanitize: (html: string) => string,
	bucketService: IBucketService,
	groupService: IGroupService,
	embedService: IEmbedService
) {
	const ctrl = this;
	const bucketLookup: { [key: string]: BucketInfo | undefined } = {};

	ctrl.onSelect = async function (id: string) {
		ctrl.selectedBucketId = id;
		if (
			ctrl.selectedBucketId &&
			ctrl.selectedBucketId !== (await bucketService.getCurrentBucketAsync()).id
		) {
			const saveSetting = !embedService.isEmbedded;
			const selectedBucket = bucketLookup[ctrl.selectedBucketId];
			if (!selectedBucket) {
				return;
			}
			ctrl.selectedBucket = selectedBucket;
			const selectedAccountToken =
				(ctrl.selectedBucket.model.group && ctrl.selectedBucket.model.group.token) || undefined;
			groupService.currentAccountToken = selectedAccountToken;
			await bucketService.setCurrentBucketAsync(ctrl.selectedBucket.model, saveSetting);

			const assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
			$state.go(
				assetListState,
				{
					accountToken: selectedAccountToken,
					bucket: selectedAccountToken ? null : ctrl.selectedBucketId,
				},
				{ inherit: false }
			);
		}
	};

	ctrl.filteredDataSource = {
		async getSliceAsync(index: number, count: number, query?: string): Promise<BucketInfo[]> {
			try {
				// ui-scroll will sometimes request slices stretching out of bounds, e.g. starting below 0
				const start = Math.max(index, 0);
				const end = Math.max(index + count, 0);

				const buckets = query
					? bucketService.searchBuckets(query).slice(start, end)
					: await bucketService.getBucketRangeAsync(start, end);

				return buckets.map(mapBucket);
			} catch (e) {
				$log.error('Could not get bucket list for picker: ', e);
				return [];
			}
		},
		async indexOfAsync(item: BucketInfo, query?: string): Promise<number> {
			return query
				? bucketService.searchBuckets(query).findIndex(bucket => bucket.id === item.id)
				: bucketService.getBucketIndex(item.id);
		},
	};

	function mapBucket(source: Bucket): BucketInfo {
		const bucket: BucketInfo = {
			id: source.id,
			text: (source.group && source.group.name) || source.name || source.id,
			model: source,
			avatarUri: (source.group && source.group.avatarUri) || '/images/defaultGroupAvatar.png',
		};

		bucketLookup[bucket.id] = bucket;
		return bucket;
	}

	ctrl.$onInit = function () {
		const { enableBucketPicker, isEmbedded, pickerMode, returnUrl } = embedService;

		bucketService.getCurrentBucketAsync().then(currentBucket => {
			ctrl.selectedBucket = mapBucket(currentBucket);
			$scope.$apply();
		});
		ctrl.isReadonly =
			typeof enableBucketPicker !== 'undefined' ? !enableBucketPicker : isEmbedded || !!pickerMode;
		ctrl.returnUrl = returnUrl;
		ctrl.selectedBucketId = ctrl.selectedBucket?.id;
		// todo: ensure current bucket is in the list of buckets
	};

	$scope.$watch(
		() => bucketService.getCurrentBucket(),
		bucket => {
			if (bucket) {
				ctrl.selectedBucket = mapBucket(bucket);
				ctrl.selectedBucketId = ctrl.selectedBucket.id;
			}
		}
	);
}
