import * as angular from 'angular';
import { IAssetService } from './assetService';
import { CancellationToken } from './cancellationService';

interface Asset {
	id: string;
	isDeleted?: boolean;
}

export interface AssetPermission {
	canEdit: boolean;
	canDelete: boolean;
}

angular.module('app').factory('assetPermissionsService', assetPermissionsService);

function assetPermissionsService(assetService: IAssetService) {
	function getAssetsPermissionsAsync(
		assets: ReadonlyArray<Asset>,
		cancellationToken?: CancellationToken
	): Promise<AssetPermission> {
		if (!assets.length) {
			return Promise.resolve({
				canEdit: false,
				canDelete: false,
			});
		}

		return assetService
			.getAssetsAccessAsync(
				assets.map(x => x.id),
				cancellationToken
			)
			.then(({ accessDecisions }) => {
				const canEdit =
					assets.length === accessDecisions.length && accessDecisions.every(x => x?.canEdit);
				return {
					canEdit,
					canDelete: canEdit && assets.some(x => !x.isDeleted),
				};
			});
	}

	return {
		getAssetsPermissionsAsync,
	};
}

export type IAssetPermissionService = ReturnType<typeof assetPermissionsService>;
