import * as angular from 'angular';
import { isSmartMedia, isSmartMediaLockup, isRoomLayout } from '../helpers/smartMediaAssetHelper';
import { IEmbedService } from '../services';

angular
	.module('app')
	.controller(
		'editorToggleController',
		(
			$scope: angular.IScope,
			$filter: angular.IFilterService,
			$rootScope: angular.IRootScopeService,
			embedService: IEmbedService
		) => {
			$scope.showEditor = false;
			$scope.toggleEditor = () => {
				$scope.showEditor = !$scope.showEditor;
				$rootScope.disableUploads = $scope.showEditor;
			};
			$scope.createInExternalEditor = function () {
				embedService.postMessage({
					type: 'create',
					openInExternalEditor: true,
				});
			};
			$scope.getSmartMediaCreateEditText = asset => {
				if (isSmartMediaLockup(asset)) {
					return $filter('translate')('smartMedia.editLockup');
				}
				if (isRoomLayout(asset)) {
					return $filter('translate')('smartMedia.editRoomLayout');
				}
				if (isSmartMedia(asset)) {
					return $filter('translate')('smartMedia.edit');
				}
				return $filter('translate')('smartMedia.create');
			};
		}
	);
