import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import * as _ from 'lodash';
import {
	IBoardService,
	IBoard,
	IErrorService,
	ICancellationService,
	IBoardShare,
} from '../services';
import { IDateTimeUtility } from '../helpers';

interface IBoardModel extends IBoard {
	shares: IBoardShareModel[];
}
interface IBoardStatsModel {
	board: IBoardModel | null;
	sharedViews: number | 0;
}

interface IBoardShareModel extends IBoardShare {
	isUrl?: boolean;
	isDeactivated?: boolean;
}

angular.module('app').controller('BoardStatsController', boardStats);

const urlRegex = /^https?:\/\//i;

function boardStats(
	$location: angular.ILocationService,
	$scope: angular.IScope,
	$transition$: uirouter.Transition,
	boardService: IBoardService,
	cancellationService: ICancellationService,
	dateTimeUtility: IDateTimeUtility,
	errorService: IErrorService
) {
	const model: IBoardStatsModel = {
		board: null,
		sharedViews: 0,
	};

	const $stateParams = $transition$.params();

	const cancellationSource = cancellationService.createCancellationSource();

	async function refreshBoardAsync() {
		try {
			const board = await boardService.getBoardAsync(
				$stateParams.boardId,
				cancellationSource.token
			);
			model.board = board;
			if (board.shares) {
				const now = new Date().getTime();
				for (let i = 0; i < board.shares.length; i++) {
					const share: IBoardShareModel = board.shares[i];
					model.sharedViews += share.views || 0;
					share.isDeactivated = share.expires ? new Date(share.expires).getTime() <= now : false;
					share.isUrl = share.token ? urlRegex.test(share.token) : false;
				}
			}
			$scope.$emit('pageTitleChange', board.name);
			$scope.$apply();
		} catch (error) {
			if (error === 'notfound') {
				errorService.showNotFound();
			} else {
				errorService.showError();
			}
		}
	}

	$scope.deactivateShare = async function (shareId) {
		const expires = dateTimeUtility.toIso8601();
		await boardService.editShareAsync(shareId, expires);
		const share = model.board?.shares.find(boardShare => boardShare.id === shareId);
		if (share) {
			share.expires = expires;
			share.isDeactivated = true;
			$scope.$apply();
		}
	};

	$scope.shareBaseUrl = `${$location.protocol()}://${$location.host()}/shares/boards/`;

	$scope.model = _.extend(model, {
		board: null,
	});

	refreshBoardAsync();
}
