import * as angular from 'angular';
import { IAgent, IAgentMapperService } from './agentMapper';

type Operation = any;

export interface IAssetRevision {
	id?: string;
	date?: string;
	changes?: Operation[];
	creator?: IAgent;
}

angular.module('app').factory('revisionMapper', revisionMapper);

function revisionMapper(agentMapper: IAgentMapperService) {
	function mapRevision(source: undefined): undefined;
	function mapRevision(source: any): IAssetRevision;
	function mapRevision(source: any[]): IAssetRevision[];
	function mapRevision(source: any): IAssetRevision | IAssetRevision[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<IAssetRevision>(mapRevision);
		}

		return {
			id: source.id,
			date: source.date,
			changes: source.changes && source.changes.ops,
			creator: agentMapper.map(source.agent),
		};
	}

	return {
		map: mapRevision,
	};
}

export type IRevisionMapper = ReturnType<typeof revisionMapper>;
