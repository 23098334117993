import * as angular from 'angular';
import { IAppSettingsProvider } from '../appSettings';

angular.module('app').provider('logBuffer', logBufferProvider);

function logBufferProvider(appSettingsProvider: IAppSettingsProvider) {
	return {
		$get() {
			const bufferSize = appSettingsProvider.unprocessedAppSettings.logBufferSize;
			const logs: any[] = [];

			return {
				getRecentLogs() {
					return logs;
				},
				addLog(level: string, data: any) {
					if (level === 'debug') {
						return;
					}

					const obj = {};
					obj[level] = Array.isArray(data) ? data[0] : data;

					logs.push(obj);

					if (logs.length > bufferSize) {
						logs.splice(-bufferSize, logs.length - bufferSize);
					}
				},
			};
		},
	};
}

export type ILogBufferProvider = ReturnType<typeof logBufferProvider>;
export type ILogBuffer = ReturnType<ILogBufferProvider['$get']>;
