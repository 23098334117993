import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('userJobStatus', userJobStatus);

function controller($scope, jobService, fileService, cancellationService, promiseUtilityService) {
	const vm = this;

	jobService.addStatusChangedHandler({ limit: 25 }, updateJobs);

	vm.completedJobs = {};
	vm.activeJobs = {};
	vm.isDropDownOpen = false;
	vm.activeJobsCount = 0;
	vm.completedJobsCount = 0;

	vm.toggleDropDown = function (e) {
		vm.isDropDownOpen = !vm.isDropDownOpen;

		if (!vm.isDropDownOpen) {
			vm.completedJobs = {};
			vm.completedJobsCount = 0;
		} else {
			updateFileInfoForJobs();
		}

		e.stopPropagation();
	};

	function updateJobs(jobs) {
		$scope.$apply(() => {
			_.each(jobs, job => {
				const existingJob = vm.activeJobs && vm.activeJobs[job.id];
				if (
					existingJob &&
					existingJob.response &&
					existingJob.response.content &&
					(!job.response || !job.response.content)
				) {
					if (!job.response) {
						job.response = {};
					}
					job.response.content = existingJob.response.content;
				}

				if (job.status === 'completed' || job.status === 'canceled' || job.status === 'failed') {
					try {
						if (vm.isDropDownOpen) {
							vm.completedJobs[job.id] = job;
							vm.completedJobsCount++;
						}

						if (vm.activeJobs[job.id]) {
							delete vm.activeJobs[job.id];
						}
					} finally {
						vm.activeJobsCount--;
					}
				} else {
					if (!vm.activeJobs[job.id]) {
						vm.activeJobsCount++;
					}
					vm.activeJobs[job.id] = job;
				}
			});
			if (vm.isDropDownOpen) {
				updateFileInfoForJobs();
			}
		});
	}

	// get file info for jobs
	function updateFileInfoForJobs() {
		const cs = cancellationService.createCancellationSource();
		const jobsToUpdate = [];
		const allJobs = _.union(_.values(vm.completedJobs), _.values(vm.activeJobs));

		Object.keys(allJobs).forEach(jobId => {
			const job = allJobs[jobId];
			if (!job.response) {
				const fileOp = _.find(job.request.content.ops, { op: 'setFile' });
				if (fileOp && fileOp.fileId) {
					job.response = {};
					job.response.content = {};
					jobsToUpdate.push({ fileId: fileOp.fileId, job });
				}
			}
		});

		promiseUtilityService
			.parallelForEach(
				jobsToUpdate,
				{ cancellationToken: cs, maxDegreeOfParallelism: 5 },
				jobInfo =>
					fileService.getFileAsync(jobInfo.fileId, cs.token).then(file => {
						jobInfo.job.response.content.file = file;
					})
			)
			.finally(cancellationService.cancelWhenDestroyed(cs, $scope));
	}

	$scope.$on('$destroy', () => {
		jobService.removeStatusChangedHandler(updateJobs);
	});
}

function userJobStatus($document) {
	const body = $document.find('body');

	return {
		restrict: 'E',
		replace: true,
		scope: {},
		controller,
		controllerAs: 'vm',
		templateUrl: require('../../views/templates/userJobStatus.html'),
		link(scope, elem) {
			body.click(onBodyClick);

			scope.$on('$destroy', () => {
				body.off('click', onBodyClick);
			});

			function onBodyClick(e) {
				if (e.target !== elem[0] && scope.vm.isDropDownOpen) {
					scope.$apply(() => {
						scope.vm.isDropDownOpen = false;
						scope.vm.completedJobs = {};
						scope.vm.completedJobsCount = 0;
					});
				}
			}
		},
	};
}
