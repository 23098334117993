import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('utility', utility);

function utility() {
	function defineLazyProperty(obj: any, propertyName: string, getValue: (any) => any) {
		let value;
		let isLoaded = false;

		Object.defineProperty(obj, propertyName, {
			get() {
				if (!isLoaded) {
					value = getValue.call(obj);
					isLoaded = true;
				}

				return value;
			},
			enumerable: true,
			configurable: true,
		});
	}

	function isEmptyValue(value: any) {
		return (
			_.isUndefined(value) ||
			_.isNull(value) ||
			_.isNaN(value) ||
			value === '' ||
			_.isEqual(value, {}) ||
			_.isEqual(value, [])
		);
	}

	function stripEmptyProperties(obj: any) {
		if (!_.isPlainObject(obj)) {
			return;
		}

		Object.keys(obj).forEach(prop => {
			if (isEmptyValue(obj[prop])) {
				delete obj[prop];
			} else {
				stripEmptyProperties(obj[prop]);
			}
		});
	}

	function capitalize(str: string) {
		return str.charAt(0).toUpperCase() + str.substr(1);
	}

	function startsWith(str: string, start: string) {
		return start === str.substr(0, start.length);
	}

	function endsWith(str: string, end: string) {
		return end === str.substr(-end.length);
	}

	// Adapted from http://ejohn.org/blog/title-capitalization-in-javascript/
	function toTitleCase(str: string) {
		const small = '(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|v[.]?|via|vs[.]?)';
		const punct = '([!"#$%&\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-]*)';

		function lower(word) {
			return word.toLowerCase();
		}

		function upper(word) {
			return word.substr(0, 1).toUpperCase() + word.substr(1);
		}

		return (function (title) {
			const parts: string[] = [];
			const split = /[:.;?!] |(?: |^)["Ò]/g;
			let index = 0;

			for (;;) {
				const m = split.exec(title);

				parts.push(
					title
						.substring(index, m ? m.index : title.length)
						.replace(/\b([A-Za-z][a-z.'Õ]*)\b/g, all =>
							/[A-Za-z]\.[A-Za-z]/.test(all) ? all : upper(all)
						)
						.replace(new RegExp(`\\b${small}\\b`, 'ig'), lower)
						.replace(new RegExp(`^${punct}${small}\\b`, 'ig'), (all, word) => punct + upper(word))
						.replace(new RegExp(`\\b${small}${punct}$`, 'ig'), upper)
				);

				index = split.lastIndex;

				if (m) {
					parts.push(m[0]);
				} else {
					break;
				}
			}

			return parts
				.join('')
				.replace(/ V(s?)\. /gi, ' v$1. ')
				.replace(/(['Õ])S\b/gi, '$1s')
				.replace(/\b(AT&T|Q&A)\b/gi, all => all.toUpperCase());
		})(str);
	}

	function addRange(collection, range) {
		Array.prototype.splice.apply(collection, [collection.length, 0].concat(range));
	}

	function findReplace<T>(array: T[], predicate: (item: T) => boolean, newItem: T): T | null {
		const index = array.findIndex(predicate);
		if (index === -1) {
			return null;
		}
		return array.splice(index, 1, newItem)[0];
	}

	function breakCamelCaseWords(str: string) {
		if (!str) {
			return str;
		}

		return str.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
	}

	function formatByteCount(byteCount, digits) {
		byteCount = parseInt(byteCount, 10);

		if (isNaN(byteCount) || !isFinite(byteCount)) {
			return '-';
		}

		if (byteCount === 0) {
			return '0B';
		}

		const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const number = Math.floor(Math.log(byteCount) / Math.log(1024));

		return (
			(byteCount / Math.pow(1024, number)).toFixed(number && _.isNumber(digits) ? digits : 2) +
			(units[number] || '')
		);
	}

	function isNotNullish<T>(value: T | undefined | null): value is T {
		return value != null;
	}

	function encodeHtmlEntities(str) {
		return String(str)
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;');
	}

	return {
		defineLazyProperty,
		isEmptyValue,
		stripEmptyProperties,
		capitalize,
		startsWith,
		endsWith,
		toTitleCase,
		breakCamelCaseWords,
		addRange,
		findReplace,
		formatByteCount,
		isNotNullish,
		encodeHtmlEntities,
	};
}

export type IUtility = ReturnType<typeof utility>;
