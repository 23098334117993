import * as angular from 'angular';

declare global {
	interface Document {
		hasStorageAccess?(): Promise<boolean>;
		requestStorageAccess?(): Promise<void>;
	}
}

angular.module('app').service('browserService', browserService);

function browserService($cookies, $log: angular.ILogService, $window: angular.IWindowService) {
	function addSignInEventListener(popupWindow: Window | null) {
		$window.addEventListener(
			'message',
			event => {
				if (event.origin !== $window.location.origin) {
					return;
				}
				if (event.data && event.data.type === 'signedin') {
					$log.info('Received message from child window.', event);
					if (popupWindow) {
						popupWindow.close();
					}
					$window.location.reload();
				}
			},
			false
		);
	}

	function areCookiesDisabled() {
		$cookies.put('foo', 'bar');
		const disabled = $cookies.get('foo') !== 'bar';
		$cookies.remove('foo');
		return disabled;
	}

	/* eslint-disable angular/document-service */
	async function hasStorageAccessAsync() {
		let hasAccess: boolean | undefined;
		if (typeof document.hasStorageAccess === 'function') {
			try {
				hasAccess = await document.hasStorageAccess();
				$log.debug(`Has storage access: ${hasAccess}`);
			} catch (reason) {
				$log.warn('Storage access check failed.', reason);
			}
		}
		return hasAccess;
	}

	function requestStorageAccessAsync() {
		// don't use async/await to ensure this is run immediately when called from an event handler
		if (typeof document.requestStorageAccess === 'function') {
			return document
				.requestStorageAccess()
				.then(() => {
					$log.debug('Request for storage access succeeded.');
				})
				.catch(reason => {
					$log.warn('Request for storage access failed.', reason);
				});
		}
		$log.debug('Browser does not support storage access API.');
		return Promise.resolve();
	}
	/* eslint-enable angular/document-service */

	return {
		addSignInEventListener,
		areCookiesDisabled,
		hasStorageAccessAsync,
		requestStorageAccessAsync,
	};
}

export type IBrowserService = ReturnType<typeof browserService>;
