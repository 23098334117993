import * as angular from 'angular';

angular.module('app').filter('limitToWordSplit', limitToWordSplit);

function limitToWordSplit() {
	return function (text, characters) {
		if (!text || !text.length || text.length <= characters) {
			return text;
		}

		const lastSpace = text.lastIndexOf(' ', characters);
		let tail = '';
		let length = characters;
		if (lastSpace > 0) {
			length = lastSpace;
			tail = ' ...';
		}

		return text.substr(0, length).trim() + tail;
	};
}
