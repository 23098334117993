import * as angular from 'angular';

angular.module('app').directive('mobileSiteHeader', mobileSiteHeader);

function mobileSiteHeader() {
	return {
		restrict: 'E',
		transclude: true,
		templateUrl: require('../../views/mobile/mobileSiteHeader.html'),
		scope: {
			noLinks: '=',
			currentUser: '=',
			sortOptions: '=?',
			sortValue: '=?',
			view: '=',
			isPickerMode: '=',
		},
		controller,
		controllerAs: 'vm',
	};

	function controller($rootScope, $scope, errorReportingService) {
		const vm = this;

		vm.getShouldBindToSearchText = function () {
			return !$scope.hideSearchText;
		};

		vm.getAppErrorCount = errorReportingService.getErrorCount;

		$scope.viewMode = $rootScope.viewMode;

		$scope.hasCreatePermission = $rootScope.hasCreatePermission;
	}
}
