import * as angular from 'angular';

angular.module('app').directive('sortOption', sortOption);

function sortOption() {
	return {
		restrict: 'AE',
		require: '^menuGroup',
		scope: {
			sortOption: '=',
			sortValue: '=',
		},
		link(scope, elem, attrs, groupController) {
			scope.sortOption.isActive = scope.sortOption.key === scope.sortValue;

			function setIsActive(isActive) {
				scope.sortOption.isActive = isActive;
			}

			const option = {
				id: scope.sortOption.key,
				isActive: scope.sortOption.isActive,
				setIsActive,
			};

			scope.$watch(
				() => scope.sortValue,
				newValue => {
					scope.sortValue = newValue;
				}
			);

			scope.model = {
				sortValue: scope.sortValue,
			};

			groupController.registerOption(option);

			elem.on({
				click() {
					groupController.selectOption(option);
					scope.sortValue = scope.sortOption.key;
				},
			});
		},
	};
}
