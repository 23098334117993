import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';

angular.module('app').factory('errorService', errorService);

function errorService($log: angular.ILogService, $state: uirouter.StateService) {
	function showErrorState(state: string) {
		$log.debug(`transitioning to error state ${state}`);
		if ($state.transition) {
			if ($state.transition.to().name === state) {
				return $state.transition.promise;
			}
			$state.transition.abort();
		}
		return $state.go(state, {}, { location: false });
	}

	function showNotFound() {
		return showErrorState('notFound');
	}

	function showError() {
		return showErrorState('error');
	}

	function showForbidden() {
		return showErrorState('forbidden');
	}

	return {
		showError,
		showForbidden,
		showNotFound,
	};
}

export type IErrorService = ReturnType<typeof errorService>;
