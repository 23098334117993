import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('tagsInput', tagsInput);

function tagsInput(autoCompleteProviderService) {
	const bibleReferenceProvider = autoCompleteProviderService.providers.bibleReference.createProvider();
	const freebaseProvider = autoCompleteProviderService.providers.freebase.createProvider();
	const uavProvider = autoCompleteProviderService.providers.uav.createProvider();

	function map(tag) {
		tag = _.isString(tag) ? { text: tag } : tag;

		if (tag.freebase) {
			return freebaseProvider.map(tag);
		} else if (tag.uav) {
			return uavProvider.map(tag);
		} else if (tag.reference) {
			return bibleReferenceProvider.map(tag);
		}

		return {
			id: `free:${tag.text}`,
			text: tag.text,
			data: tag,
		};
	}

	function unmap(select2Item) {
		return select2Item.data || select2Item.text;
	}

	return {
		restrict: 'AE',
		scope: {
			source: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
			noLinks: '=',
			fieldName: '=',
		},
		template:
			'<auto-complete filter-creator="filterCreator" providers="providers" mapping="{ map: map, unmap: unmap }" source="source" is-readonly="readonly" disable-sortable="disableSortable"></auto-complete>',
		link: {
			pre(scope) {
				scope.providers = [
					{ name: 'completion', args: [scope.fieldName || 'tags.plain'], label: '' },
					{ name: 'bibleReference', label: 'Reference' },
					{ name: 'uav', label: 'Logos' },
					{ name: 'freebase', label: 'Freebase' },
				];

				scope.map = map;
				scope.unmap = unmap;

				scope.$watch('readonly', readonly => {
					scope.filterCreator = readonly
						? null
						: function (model) {
								return { facet: 'tags.text', term: model.text };
						  };
				});
			},
		},
	};
}
