import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('copyrightInput', copyrightInput);

function copyrightInput($filter) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			source: '=',
			assets: '=',
			isReadonly: '=',
		},
		templateUrl: require('../../views/templates/copyrightInput.html'),
		link(scope) {
			function validateCopyrightText(newValues) {
				const copyrightText = newValues[0];
				// _.map requires a collection
				const assetRightsList = _.isArray(newValues[1]) ? newValues[1] : [newValues[1]];
				const rightsValues = _.map(assetRightsList, 'rights');

				if (rightsValues.length > 1) {
					scope.rights = _.uniq(rightsValues).length !== 1 ? '' : rightsValues[0];
				} else {
					scope.rights = rightsValues[0];
				}

				if (!scope.rights) {
					return true;
				} else if (!copyrightText) {
					return false;
				} else if (scope.rights === 'Public Domain') {
					return (
						copyrightText.search(
							new RegExp(`^${$filter('translate')('copyright.publicDomain')}$`, 'i')
						) > -1
					);
				} else if (scope.rights === 'Copyrighted') {
					// Copyright <year> <author(s)>
					return (
						copyrightText.search(
							new RegExp(
								`^${$filter('translate')(
									'copyright.copyright'
								)} \\d{4}( (?=[a-z]+)[a-z]+(,(?= [a-z]+)( [a-z]+)+)*)*$`,
								'i'
							)
						) > -1
					);
				} else if (scope.rights === 'Logos Free') {
					// Copyright <year> <author(s)> Logos Free
					return (
						copyrightText.search(
							new RegExp(
								`^${$filter('translate')(
									'copyright.copyright'
								)} \\d{4}( (?=[a-z]+)[a-z]+(,(?= [a-z]+)( [a-z]+)+)*)* / Logos Free$`,
								'i'
							)
						) > -1
					);
				} else if (scope.rights.indexOf('Attribution') > -1) {
					// Copyright <year> <author(s)> <right>
					return (
						copyrightText.search(
							new RegExp(
								`^${$filter('translate')(
									'copyright.copyright'
								)} \\d{4}( (?=[a-z]+)[a-z]+(,(?= [a-z]+)( [a-z]+)+)*)* / ${scope.rights}$`,
								'i'
							)
						) > -1
					);
				}

				return false;
			}

			scope.$watch(
				'[source, assets]',
				newValues => {
					scope.isValid = validateCopyrightText(newValues);
				},
				true
			);
		},
	};
}
