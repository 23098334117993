import * as uirouter from '@uirouter/angularjs';
import * as angular from 'angular';
import { tmh } from 'angular-dynamic-locale';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import * as hotkeys from 'angular-hotkeys';
import ResizeObserver from 'resize-observer-polyfill';
import 'angular-ui-scroll';
import '@iamadamjowett/angular-click-outside';
import * as rg4js from 'raygun4js';
import {
	IAppSettings,
	IBucketService,
	IErrorService,
	IGroupService,
	IPageTitleService,
	UserModel,
} from './services';
import { IAppSettingsProvider } from './appSettings';
import * as moment from 'moment';
import { IConstants, IDigestUtility } from './infrastructure';

export interface IAmberAppModule extends angular.IModule {
	compileProvider?: angular.ICompileProvider;
}

// eslint-disable-next-line angular/module-setter
const app: IAmberAppModule = angular
	.module('app', [
		'ui.router',
		'ngAnimate',
		'ngCookies',
		'ngSanitize',
		'ui.select2',
		'ui.scroll',
		'angular-click-outside',
		'angulartics',
		'angulartics.google.analytics',
		'cfp.hotkeys',
		'pascalprecht.translate',
		'tmh.dynamicLocale',
	])
	.factory(
		'missingTranslationHandler',
		() =>
			function (translationID) {
				return translationID.split('.').pop();
			}
	);
// eslint-disable-next-line angular/module-getter
app
	.config(
		(
			$compileProvider: angular.ICompileProvider,
			$logProvider: angular.ILogProvider,
			$provide: angular.auto.IProvideService,
			$sceProvider: angular.ISCEProvider,
			appSettingsProvider: IAppSettingsProvider,
			hotkeysProvider: hotkeys.HotkeysProvider,
			$translateProvider: angular.translate.ITranslateProvider,
			tmhDynamicLocaleProvider: tmh.IDynamicLocaleProvider,
			buildInfo
		) => {
			$logProvider.debugEnabled(!!appSettingsProvider.unprocessedAppSettings.isDebugEnabled);

			$sceProvider.enabled(false);
			hotkeysProvider.includeCheatSheet = false;

			// this is needed to make ui.select2 work correctly
			$provide.decorator('uiSelect2Directive', $delegate => {
				const directive = $delegate[0];
				directive.priority = 2;
				return $delegate;
			});

			$translateProvider.useSanitizeValueStrategy('escape');

			const supportedLocales = ['en', 'es', 'de', 'fr', 'ko', 'pt', 'zh-Hans', 'zh-Hant'];
			$translateProvider.registerAvailableLanguageKeys(supportedLocales, {
				'en-*': 'en',
				'es-*': 'es',
				'de-*': 'de',
				'fr-*': 'fr',
				'ko-*': 'ko',
				'pt-*': 'pt',
				'zh-HK': 'zh-Hant', // Hong Kong is mostly traditional
				'zh-CN': 'zh-Hans',
				'zh-TW': 'zh-Hant',
				'zh-hans': 'zh-Hans',
				'zh-hant': 'zh-Hant',
				'zh-hans-cn': 'zh-Hans',
				'zh-hans-hk': 'zh-Hans',
				'zh-hans-mo': 'zh-Hans',
				'zh-hans-sg': 'zh-Hans',
				'zh-hant-hk': 'zh-Hant',
				'zh-hant-mo': 'zh-Hant',
				'zh-hant-tw': 'zh-Hant',
				zh: 'zh-Hans', // Default Chinese without region = simplified
			});

			$translateProvider.uniformLanguageTag('bcp47');
			$translateProvider.fallbackLanguage('en');
			$translateProvider.preferredLanguage('en');

			const cacheBust = buildInfo.version ? `?v=${buildInfo.version}` : '';
			$translateProvider.useStaticFilesLoader({
				prefix: '/i18n/locale-',
				suffix: `.json${cacheBust}`,
			});

			$translateProvider.useMissingTranslationHandler('missingTranslationHandler');

			tmhDynamicLocaleProvider.localeLocationPattern('/i18n/angular-locale_{{locale}}.js');

			// Save the original $compileProvider to use it for dynamic registering
			app.compileProvider = $compileProvider;
		}
	)
	.run(
		(
			$document: angular.IDocumentService,
			$log: angular.ILogService,
			$rootScope: angular.IRootScopeService,
			$state: uirouter.StateService,
			$transitions: uirouter.TransitionService,
			$window: angular.IWindowService,
			$translate: angular.translate.ITranslateService,
			$location: angular.ILocationService,
			tmhDynamicLocale: tmh.IDynamicLocale,
			appSettings: IAppSettings,
			bucketService: IBucketService,
			buildInfo,
			constants: IConstants,
			digestUtility: IDigestUtility,
			errorService: IErrorService,
			groupService: IGroupService,
			pageTitleService: IPageTitleService,
			userModel: UserModel
		) => {
			$log.debug(buildInfo);

			let locales = $location.search().locale;
			const preferredLocale = $translate.resolveClientLocale();
			let validQueryLocale = false;
			if (locales) {
				if (angular.isString(locales)) {
					locales = [locales];
				}
				for (const locale of locales) {
					const negotiatedLocale = $translate.negotiateLocale(locale);
					if (negotiatedLocale) {
						$translate.preferredLanguage(negotiatedLocale);
						$translate.use(negotiatedLocale);

						// if negotiatedLocale is valid, we want to use the original locale from the query param for dates and numbers (eg. for ?locale=en-GB negotiatedLocale will be 'en', which we want for strings, but we can use the region-specific code for everything else)
						// set locale for angularjs date/number/currency
						tmhDynamicLocale.set(locale);

						// set locale for momentjs (only supports zh-cn, zh-hk, and zh-tw for Chinese)
						if (negotiatedLocale === 'zh-Hans') {
							moment.locale([locale, 'zh-cn']);
						} else if (negotiatedLocale === 'zh-Hant') {
							moment.locale([locale, 'zh-tw']);
						} else {
							moment.locale([locale, negotiatedLocale]);
						}

						validQueryLocale = true;
						break;
					}
				}
			}

			// attempt to set language based on browser preference
			if (!validQueryLocale && preferredLocale) {
				const stringsLocale = $translate.negotiateLocale(preferredLocale);
				if (stringsLocale) {
					$translate.preferredLanguage(stringsLocale);
					$translate.use(stringsLocale);

					// set locale for angularjs date/number/currency
					tmhDynamicLocale.set(preferredLocale);

					// set locale for momentjs (only supports zh-cn, zh-hk, and zh-tw for Chinese)
					if (stringsLocale === 'zh-Hans') {
						moment.locale([preferredLocale, 'zh-cn']);
					} else if (stringsLocale === 'zh-Hant') {
						moment.locale([preferredLocale, 'zh-tw']);
					} else {
						moment.locale([preferredLocale, stringsLocale]);
					}
				}
			}

			// see also variables.less @small-viewport-width
			const largeViewportMinWidth = 940;
			$rootScope.isSmallViewport = $window.innerWidth < largeViewportMinWidth;

			$rootScope.toggleForceDesktopView = function () {
				$rootScope.forceDesktopView = !$rootScope.forceDesktopView;
			};

			// for use in templates
			$rootScope.faithlifeUrl = `${appSettings.faithlifeUrl}/`;

			$rootScope.$state = $state;
			$rootScope.isState = function (state) {
				return $state.is(state);
			};

			userModel.getCurrentUserAsync().then(currentUser => {
				if (currentUser && currentUser.email) {
					(<any>rg4js)('setUser', currentUser.email);
				}
			});

			const onBeforeDeregister = $transitions.onBefore({}, trans => {
				const toParams = trans.params();

				groupService.nextAccountToken = toParams.accountToken || toParams.groupId;
				groupService.nextBucket = toParams.bucket;
			});

			groupService.setCurrentAccountFromLocationAsync();
			const onStartDeregister = $transitions.onStart({}, async () => {
				await groupService.setCurrentAccountFromLocationAsync();
			});

			const onStartRedirectDeregister = $transitions.onStart(
				{ to: x => !!x && ['assets'].includes(x.name) },
				async trans => {
					if (bucketService.hasBuckets()) {
						const newParams = await addAccountTokenOrBucketToParams({ ...trans.params() });

						if (newParams) {
							const toStateName = trans.to().name;
							const reload =
								trans.from().name !== toStateName &&
								(!$rootScope.assetListInitialized || trans.from().name === 'boardSharedDetails');

							return trans.router.stateService.target(toStateName!, newParams, { reload });
						}
					}
					return true;
				}
			);

			const onSuccessDeregister = $transitions.onSuccess({}, async trans => {
				const toState = trans.to();
				const toStateName = toState.name;
				const toParams = { ...trans.params() };

				$rootScope.pageTitle = await pageTitleService.setPageTitleFromCurrentState(
					(<any>toState).title
				);

				if (toStateName && ['assets', 'boardSharedDetails'].includes(toStateName)) {
					const newParams = await addAccountTokenOrBucketToParams(toParams);

					if (newParams) {
						await $state.go(
							toStateName,
							{ ...newParams, reload: null },
							{ location: newParams.reload || 'replace' }
						);
					} else if (toParams.reload) {
						// The reload param is necessary to reload toState
						// when the toParams are equal to our current state in $transitions.onStart.
						// Clear the parameter on transition success.
						toParams.reload = null;
						// Any previously set transition options are lost here. Notably, `location` will default to true.
						await $state.go(toStateName, toParams).then(x => x);
					}
				}
				return true;
			});

			const onErrorDeregister = $transitions.onError({}, trans => {
				if (trans.error().detail === 'notfound') {
					errorService.showNotFound();
				} else if (!trans.ignored()) {
					errorService.showError();
				}
			});

			/**
			 * Add a bucket identifier to the URL if there isn't already one, or if it doesn't match.
			 *
			 * Prefer to use params.accountToken, unless:
			 *
			 * 1. The URL is already using a groupId, in which case we just replace it with the right groupId
			 * 2. The current bucket doesn't have a group, in which case we just use a bucketId
			 *
			 * @return (boolean | any) false if we can't or shouldn't attach a bucket identifier to the URL
			 *
			 */
			const addAccountTokenOrBucketToParams = async params => {
				const currentUser = await userModel.getCurrentUserAsync();
				if (currentUser.isAnonymous) {
					return false;
				}

				const currentBucket = await bucketService.getCurrentBucketAsync();

				const paramsAccount = params.accountToken || params.groupId;
				const groupToken = currentBucket.group && currentBucket.group.token;
				const groupId = currentBucket.group && currentBucket.group.id;

				const accountAligned =
					paramsAccount && (paramsAccount === groupToken || paramsAccount === groupId);
				const bucketAligned = params.bucket && params.bucket === currentBucket.id;

				if (accountAligned || bucketAligned) {
					return false;
				}

				if (groupToken) {
					params.bucket = undefined;
					if (params.groupId) {
						params.groupId = groupId;
					} else {
						params.accountToken = groupToken;
					}
				} else {
					params.bucket = currentBucket.id;
				}
				return params;
			};

			const onPageTitleChangeDeregister = $rootScope.$on('pageTitleChange', (_, pageTitle) => {
				$rootScope.pageTitle = pageTitleService.appendBucketToPageTitle(pageTitle);
			});

			$rootScope.$on('$destroy', () => {
				onErrorDeregister();
				onBeforeDeregister();
				onStartDeregister();
				onStartRedirectDeregister();
				onSuccessDeregister();
				onPageTitleChangeDeregister();
			});

			if (appSettings.enableDigestDebugMessages) {
				digestUtility.enableDigestLogging();
			}

			$rootScope.updateWidth = function () {
				$rootScope.isSmallViewport = $window.innerWidth < largeViewportMinWidth;
			};

			const body = $document.find('body');
			body.on('paste', e => {
				const clipboardData = (e.originalEvent as ClipboardEvent).clipboardData;
				const files = clipboardData && clipboardData.files;

				if (files && files.length) {
					e.stopPropagation();
					e.preventDefault();

					$rootScope.$broadcast(constants.dropEventName, { files });
				}
			});

			$window.onresize = function () {
				$rootScope.updateWidth();
				$rootScope.$apply();
			};

			$window.ResizeObserver = $window.ResizeObserver || ResizeObserver;
		}
	);
