import * as angular from 'angular';
import * as _ from 'lodash';

angular
	.module('app')
	.filter('metadataValue', metadataValue)
	.filter('bibleReferenceFilter', bibleReferenceFilter);

function metadataValue($filter) {
	return function (model, type) {
		if (!_.isArray(model)) {
			model = [model];
		}

		return model.map(m => render(m, type)).join('; ');
	};

	function getFilter(name) {
		try {
			return $filter(name);
		} catch (e) {
			return null;
		}
	}

	function render(model, type) {
		const filter = type && getFilter(type);
		return filter ? filter(model) : model;
	}
}

function bibleReferenceFilter() {
	return function (reference) {
		return reference && reference.text;
	};
}
