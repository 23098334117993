import * as angular from 'angular';

angular.module('app').filter('dateQuery', dateQuery);

function dateQuery() {
	function getQuotedDate(date, relativeOffset) {
		return ['"', date, relativeOffset, '"'].join('');
	}

	return function (query, relativeOffset) {
		return [
			query.field,
			':(',
			getQuotedDate(query.date, `-${relativeOffset}`),
			' TO ',
			getQuotedDate(query.date, `+${relativeOffset}`),
			')',
		].join('');
	};
}
