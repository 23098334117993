import * as angular from 'angular';

angular.module('app').directive('ratingAverage', ratingAverage);

function ratingAverage() {
	return {
		restrict: 'E',
		templateUrl: require('../../views/templates/ratingAverage.html'),
		scope: {
			ratingCount: '=',
			average: '=',
		},
		replace: true,
	};
}
