import * as angular from 'angular';

export interface IUser {
	id?: string;
	token?: string;
	name?: string;
	avatarUri?: string;
	isAnonymous: boolean;
}

export interface IUserDetails extends IUser {
	email?: string;
	about?: string;
	organization?: string;
	title?: string;
	isEmployee?: boolean;
}

angular.module('app').factory('userMapper', userMapper);

function userMapper() {
	function mapUser(source: undefined): undefined;
	function mapUser(source: any): IUser;
	function mapUser(source: any[]): IUser[];
	function mapUser(source: any): IUser | IUser[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<IUser>(mapUser);
		}

		return {
			id: source.id,
			token: source.token,
			name: source.name,
			avatarUri: source.avatarUri,
			isAnonymous: source.id === -1,
		};
	}

	function mapUserDetails(source: undefined): undefined;
	function mapUserDetails(source: any): IUserDetails;
	function mapUserDetails(source: any): IUserDetails | undefined {
		if (!source) {
			return undefined;
		}

		return {
			id: String(source.id),
			token: source.profileToken && source.profileToken.value,
			email: source.email && source.email.value,
			name: (source.alias && source.alias.value) || (source.name && source.name.value),
			avatarUri: source.avatarUrls && source.avatarUrls.value.avatarUrl,
			about: source.about && source.about.value,
			organization: source.organization && source.organization.value,
			title: source.title && source.title.value,
			isAnonymous: source.id === -1,
			isEmployee:
				source.roleNames && source.roleNames.value && source.roleNames.value.includes('Employee'),
		};
	}

	return {
		map: mapUser,
		mapDetails: mapUserDetails,
	};
}

export type IUserMapperService = ReturnType<typeof userMapper>;
