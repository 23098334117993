import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';

angular
	.module('app')
	.directive('multiValueTextInput', multiValueTextInput)
	.directive('multiValueDateInput', multiValueDateInput)
	.directive('authorsInput', authorsInput)
	.directive('fieldValueAutoComplete', fieldValueAutoComplete)
	.directive('referenceInput', referenceInput);

function multiValueTextInput() {
	return {
		restrict: 'A',
		require: '?ngModel',
		link(scope, element, attrs, ngModel) {
			if (!ngModel || !scope.$eval(attrs.multi)) {
				return;
			}

			const separator = scope.$eval(attrs.separator);

			ngModel.$parsers.push(v => unmap(v, separator));

			ngModel.$formatters.push(model => {
				model = model || [];
				return _.isArray(model) ? map(model, separator) : model;
			});
		},
	};
}

function multiValueDateInput() {
	return {
		restrict: 'A',
		require: '?ngModel',
		link(scope, element, attrs, ngModel) {
			if (!ngModel || !scope.$eval(attrs.multi)) {
				return;
			}

			ngModel.$parsers.push(unmap);
			ngModel.$parsers.push(dates => {
				if (!dates) {
					return dates;
				}

				let isValid = true;

				const parsedDates = dates.map(date => {
					const parsed = moment(date);
					if (!parsed.isValid()) {
						isValid = false;
						return null;
					}

					return parsed.format('YYYY-MM-DD');
				});

				ngModel.$setValidity('multiValueDateInput', isValid);

				return isValid ? parsedDates : null;
			});

			ngModel.$formatters.push(model => {
				model = model || [];
				return _.isArray(model) ? map(model) : model;
			});
		},
	};
}

function authorsInput() {
	return {
		restrict: 'AE',
		scope: {
			source: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
		},
		template:
			'<auto-complete is-readonly="readonly" disable-sortable="disableSortable" filter-creator="filterCreator" providers="providers" mapping="{ map: map, unmap: unmap }" source="source"></auto-complete>',
		link: {
			pre(scope) {
				scope.providers = [{ name: 'authors' }];

				scope.map = function (model) {
					return {
						id: model.userId || model.name,
						text: model.name,
						data: model,
					};
				};

				scope.unmap = function (select2) {
					if (_.isString(select2) || !select2.data) {
						return { name: select2.text || select2 };
					}

					return _.pick(select2.data, ['name', 'userId']);
				};

				if (!scope.readonly) {
					scope.filterCreator = function (model) {
						return { facet: 'author', term: model.text };
					};
				}
			},
		},
	};
}

function fieldValueAutoComplete() {
	return {
		restrict: 'AE',
		scope: {
			source: '=',
			fieldName: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
			isSingle: '=',
			focusInput: '@',
			completionType: '<',
		},
		template:
			'<auto-complete is-readonly="readonly" disable-sortable="disableSortable" is-single="isSingle" filter-creator="filterCreator" providers="providers" mapping="{ map: map, unmap: unmap }" source="source" focus-input="focusInput"></auto-complete>',
		link: {
			pre(scope) {
				scope.providers = [
					{
						name: 'completion',
						args: [scope.fieldName, scope.completionType],
						completionType: scope.completionType,
					},
				];

				scope.map = function (model) {
					return { id: model, text: model };
				};

				scope.unmap = function (select2) {
					const val = _.isString(select2) ? select2 : select2.text;
					return val && val.trim();
				};

				if (!scope.readonly && !scope.isSingle) {
					scope.filterCreator = function (model) {
						return { facet: scope.fieldName, term: model.text };
					};
				}
			},
		},
	};
}

function referenceInput() {
	return {
		restrict: 'AE',
		scope: {
			source: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
			multiple: '=',
			focusInput: '@',
		},
		template:
			'<auto-complete is-single="!multiple" is-readonly="readonly" disable-sortable="disableSortable" restrict-selection="true" providers="providers" mapping="{ map: map, unmap: unmap }" source="source" focus-input="focusInput"></auto-complete>',
		link: {
			pre(scope) {
				scope.providers = [{ name: 'bibleReference' }];

				scope.map = function (model) {
					return {
						id: model.value,
						text: model.text,
						data: model,
					};
				};

				scope.unmap = function (select2) {
					const source = select2.data.reference || select2.data;
					return {
						text: select2.text,
						value: source.value,
					};
				};
			},
		},
	};
}

function map(value, separator) {
	return Array.isArray(value) ? value.join(separator || '; ') : value;
}

function unmap(value, separator) {
	const unmapped = (value || '')
		.split(separator || ';')
		.map(s => s.trim())
		.filter(s => s.length);

	return unmapped.length ? unmapped : null;
}
