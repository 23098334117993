import * as angular from 'angular';

angular.module('app').component('assetCompletionTag', {
	templateUrl: require('../../views/templates/assetCompletionTag.html'),
	controller: assetCompletionTag,
	bindings: {
		asset: '<',
	},
});

function assetCompletionTag($scope, $element) {
	const ctrl = this;
	const maxWidth = $element.parent().width();
	const maxHeight = $element.parent().height();

	ctrl.$onInit = renderThumbnail;

	$scope.asset = ctrl.asset;

	function renderThumbnail() {
		if (ctrl.asset.getBestThumbnailForSize) {
			const thumbnail = ctrl.asset.getBestThumbnailForSize(Math.max(maxWidth, maxHeight));

			if (thumbnail) {
				ctrl.previewUrl = thumbnail.url;
			}
		}
	}
}
