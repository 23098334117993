import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').controller('AssetSharesController', assetShares);

function assetShares(
	$location,
	$scope,
	$state,
	$transition$,
	appSettings,
	assetDetailsAddFormatModel,
	assetService,
	bucketService,
	cancellationService,
	currentUser,
	errorService,
	uriUtility
) {
	const model = {};

	$scope.currentUser = currentUser;

	$scope.loginUrl = uriUtility.fromPattern(appSettings.signInUri, {
		returnUrl: $location.absUrl(),
	});

	const shareToken = $transition$.params().shareToken;

	$scope.model = _.extend(model, {
		asset: null,
		boardShareAssetId: $transition$.params().assetId,
		hasDetails: false,
		shareToken,
		showDetails: false,
	});

	function showAddFormatDialog() {
		model.addFormatModel.showFormatDialog = true;
	}

	function closeAddFormatDialog() {
		if (model.addFormatModel) {
			model.addFormatModel.closeDialog();
		}
	}

	function createFormat() {
		if (!model.addFormatModel) {
			return;
		}

		const cancellationSource = cancellationService.createCancellationSource();
		model.addFormatModel
			.createFormat(assetService, cancellationSource.token)
			.finally(cancellationService.cancelWhenDestroyed(cancellationSource, $scope));
	}

	async function loadAsset() {
		try {
			const assetId =
				$scope.model.boardShareAssetId ?? (await assetService.getShareAssetIdAsync(shareToken));
			const asset = await assetService.getAssetAsync(assetId, { shareToken });
			const [{ accessDecisions }, bucket] = await Promise.all([
				assetService.getAssetsAccessAsync([asset.id]),
				bucketService.setCurrentBucketAsync({ id: asset.bucket }),
			]);
			$scope.bucket = bucket || { id: asset.bucket };
			model.asset = asset;
			model.addFormatModel = assetDetailsAddFormatModel.createAddFormatModel(asset, shareToken);
			model.canEdit = accessDecisions.length && accessDecisions[0] && accessDecisions[0].canEdit;
			assetService.reportAssetView(asset.id, shareToken);
			$scope.$emit('pageTitleChange', asset.title);

			$scope.groupPermissions = await bucketService.getBucketGroupPermissionsAsync(bucket);
		} catch (error) {
			if (error.status === 'notfound') {
				errorService.showNotFound();
			} else {
				errorService.showError();
			}
		}
	}

	loadAsset();

	$scope.createFormat = createFormat;
	$scope.showAddFormatDialog = showAddFormatDialog;
	$scope.closeAddFormatDialog = closeAddFormatDialog;

	$scope.getSharedPreviewPath = function () {
		return model.hasPreview
			? $scope.model.boardShareAssetId
				? $state.href('boardSharedAssetPreview', {
						shareToken: model.shareToken,
						assetId: $scope.model.boardShareAssetId,
				  })
				: $state.href('sharedPreview', { shareToken: model.shareToken })
			: undefined;
	};
}
