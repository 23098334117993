import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('assetPreviewImages', assetPreviewImages);

function assetPreviewImages() {
	return {
		restrict: 'E',
		scope: {
			assetCollection: '=',
			assetIndex: '=',
			numberOfImagesToPreload: '=?',
			getCurrentThumbnailKind: '=?',
		},
		link(scope) {
			scope.preloadedAssets = [];
			scope.preloadedAssets.push({
				index: scope.assetIndex,
				asset: scope.assetCollection[scope.assetIndex],
			});
			scope.thumbnailKind = { value: 'default' };

			scope.addToPreload = function (direction) {
				const wrapperIndex = _.findIndex(
					scope.preloadedAssets,
					wrapper => wrapper.index === scope.assetIndex
				);
				const preloadIndex = scope.assetIndex + (direction ? 1 : -1);
				if (wrapperIndex === 0 || wrapperIndex === scope.preloadedAssets.length - 1) {
					scope.preloadedAssets.splice(direction ? scope.preloadedAssets.length : 0, 0, {
						index: preloadIndex,
						asset: scope.assetCollection[preloadIndex],
					});
					if (scope.preloadedAssets.length > scope.numberOfImagesToPreload) {
						scope.preloadedAssets.splice(direction ? 0 : scope.preloadedAssets.length - 1, 1);
					}
				}
			};

			if (scope.assetIndex > 0) {
				scope.addToPreload(false);
			}
			if (scope.assetIndex < scope.assetCollection.length - 1) {
				scope.addToPreload(true);
			}

			scope.$on('load-sequential', (evt, args) => {
				if (
					!(
						(scope.assetIndex === 0 && !args.direction) ||
						(scope.assetIndex === scope.assetCollection.length - 1 && args.direction)
					)
				) {
					scope.assetIndex += args.direction ? 1 : -1;
					scope.addToPreload(args.direction);
				}
			});
		},
		templateUrl: require('../../views/templates/assetPreviewImages.html'),
	};
}
