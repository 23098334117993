import * as angular from 'angular';
import * as _ from 'lodash';
import { IConstants } from '../infrastructure';
import { IMimeTypeUtility } from '../helpers';

angular
	.module('app')
	.directive('dragDropMonitor', dragDropMonitor)
	.directive('dragDropDropTarget', dragDropDropTarget);

const dragStatusEventName = 'dragdrop.window.dragging';

interface IDragDropMonitorController extends angular.IController {
	setIsDragOverDropTarget(targetElement: JQuery, value: boolean): void;
	setDragOver(targetElement: JQuery, over: any): void;
	shouldShowDragDropOutline(show: boolean): void;
}

function dragDropMonitor($timeout: angular.ITimeoutService): angular.IDirective {
	return {
		restrict: 'A',
		controller(this: IDragDropMonitorController, $scope: angular.IScope) {
			const vm = this;
			let currentDragOverTarget;

			$scope.dragOver = false;
			$scope.showDragDropOutline = false;

			vm.shouldShowDragDropOutline = function (show: boolean) {
				$scope.showDragDropOutline = show;
			};

			vm.setDragOver = function (targetElement: JQuery, over: any) {
				if (over) {
					currentDragOverTarget = targetElement;
					$scope.dragOver = over;
				} else if (currentDragOverTarget === targetElement) {
					$scope.dragOver = false;
				} else {
					currentDragOverTarget = null;
				}
			};
			vm.setIsDragOverDropTarget = function (targetElement: JQuery, value: boolean) {
				if (value) {
					currentDragOverTarget = targetElement;
					$scope.isDragOverDropTarget = true;
				} else if (currentDragOverTarget === targetElement) {
					$scope.isDragOverDropTarget = false;
				} else {
					currentDragOverTarget = null;
				}
			};
		},
		link(scope, elem, attrs) {
			let timer: angular.IPromise<void>;
			let isDragging = false;

			function broadcastStatus() {
				scope.$broadcast(dragStatusEventName, { isDragging });
			}

			elem.on('dragover', e => {
				if (!scope.$eval(attrs.dragEnabled)) {
					return;
				}

				e.stopPropagation();
				e.preventDefault();

				if (!scope.isDragOverDropTarget) {
					(e.originalEvent as DragEvent).dataTransfer!.dropEffect = 'none';
				}

				if (isDragging) {
					$timeout.cancel(timer);
				} else {
					scope.$apply(() => {
						isDragging = true;
						scope.showDragDropOutline = true;
						broadcastStatus();
					});
				}

				timer = $timeout(() => {
					isDragging = false;
					scope.showDragDropOutline = false;
					broadcastStatus();
				}, 100);
			});
		},
	};
}

function dragDropDropTarget(
	$log: angular.ILogService,
	$parse: angular.IParseService,
	$rootScope: angular.IRootScopeService,
	$timeout: angular.ITimeoutService,
	constants: IConstants,
	mimeTypeUtility: IMimeTypeUtility
): angular.IDirective<IDragDropMonitorController> {
	const dropEventName = constants.dropEventName;

	function areValidFileTypes(
		fileTypes: ReadonlyArray<string>,
		acceptedFileTypes: ReadonlyArray<string> | null
	) {
		if (!acceptedFileTypes || acceptedFileTypes.length === 0) {
			return true;
		}

		const acceptedTypes = _.compact(acceptedFileTypes.map(mimeTypeUtility.splitMimeType));

		return _.every(fileTypes, fileType => {
			const fileTypeResult = mimeTypeUtility.splitMimeType(fileType);
			return (
				fileTypeResult &&
				_.every(
					acceptedTypes,
					acceptedType =>
						fileTypeResult.type === acceptedType.type &&
						(acceptedType.subtype === '*' || acceptedType.subtype === fileTypeResult.subtype)
				)
			);
		});
	}

	return {
		restrict: 'A',
		require: '^dragDropMonitor',
		link(scope, elem, attrs, controller) {
			const dataTransferEffect = attrs.datatransferEffect;
			let dragOutTimer: angular.IPromise<void>;

			const callback = attrs.dropAction && $parse(attrs.dropAction);

			elem.on({
				'dragover dragenter': function (e) {
					if ($rootScope.disableUploads) {
						return;
					}
					const dataTransfer = (e.originalEvent as DragEvent).dataTransfer!;
					const isAllowed =
						dataTransfer.types.includes('Files') &&
						areValidFileTypes(
							dataTransfer.types,
							attrs.acceptedFiles ? attrs.acceptedFiles.split(',') : null
						);

					dataTransfer.dropEffect = isAllowed ? dataTransferEffect : 'none';

					if (scope.isDragOver) {
						$timeout.cancel(dragOutTimer);
					} else {
						scope.$apply(() => {
							const over = attrs.over;
							if (over) {
								controller.setDragOver(elem, over);
								controller.shouldShowDragDropOutline(false);
							}
							controller.setIsDragOverDropTarget(elem, true);
							scope.isDragOver = true;
						});
					}

					dragOutTimer = $timeout(() => {
						scope.isDragOver = false;
						controller.setIsDragOverDropTarget(elem, false);
					}, 100);
				},
				dragleave() {
					scope.$apply(() => {
						if (attrs.over) {
							controller.setDragOver(elem, false);
							controller.shouldShowDragDropOutline(true);
						}

						scope.isDragOver = false;
						controller.setIsDragOverDropTarget(elem, false);
					});
				},
				drop(e) {
					$log.debug('drop', e);

					e.stopPropagation();
					e.preventDefault();

					const files = (e.originalEvent as DragEvent).dataTransfer!.files;

					scope.$apply(() => {
						if (!callback) {
							$rootScope.$broadcast(dropEventName, { files });
						} else {
							callback(scope, { files });
						}
					});
				},
			});

			scope.$on(dragStatusEventName, (event, status) => {
				scope.isDragging = status.isDragging;
			});
		},
	};
}
