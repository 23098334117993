import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('favoriteButton', favoriteButton);

function favoriteButton(assetService) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			assets: '=',
			showText: '=',
		},
		templateUrl: require('../../views/templates/favoriteButton.html'),
		link(scope) {
			function getNonFavoritedAssets() {
				return _.filter(scope.assets, asset => !asset.favorite);
			}

			function getFavoritedAssets() {
				return _.filter(scope.assets, asset => asset.favorite);
			}

			scope.updateFavorite = function () {
				const nonFavoritedAssets = getNonFavoritedAssets();
				const favoritedAssets = getFavoritedAssets();
				const assetsToModify = _.isEmpty(nonFavoritedAssets) ? favoritedAssets : nonFavoritedAssets;
				const assetIds = _.map(assetsToModify, 'id');
				const editFavorites = _.isEmpty(nonFavoritedAssets)
					? assetService.removeFromFavoritesAsync(assetIds)
					: assetService.addToFavoritesAsync(assetIds);
				editFavorites.then(() => {
					_.forEach(scope.assets, asset => {
						asset.favorite = !_.isEmpty(nonFavoritedAssets);
					});
					scope.allFavorited = !scope.allFavorited;
				});
			};

			scope.$watchCollection('assets', () => {
				scope.allFavorited = _.isEmpty(getNonFavoritedAssets());
			});
		},
	};
}
