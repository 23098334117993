import * as angular from 'angular';

const app = angular.module('app');

// eslint-disable-next-line angular/module-getter
app.directive('smartMediaEditor', smartMediaEditor);

function smartMediaEditor(mimeTypeUtility, bucketService) {
	const loadComponent = new Promise(resolve => {
		require.ensure(['../components/faithlifeSmartMediaEditor'], () => {
			const { FaithlifeSmartMediaEditor } = require('../components/faithlifeSmartMediaEditor');

			app.compileProvider.component('faithlifeSmartMediaEditor', FaithlifeSmartMediaEditor);

			resolve(FaithlifeSmartMediaEditor);
		});
	});

	const loadSmartMediaHelper = new Promise(resolve => {
		require.ensure(['../helpers/smartMediaHelper'], require =>
			resolve(require('../helpers/smartMediaHelper'))
		);
	});

	return {
		replace: true,
		scope: {
			asset: '=',
			editorConfiguration: '=',
		},
		templateUrl: require('../../views/templates/smartMediaEditor.html'),
		controller(userModel) {
			const vm = this;

			vm.fetchFontFamilies = async function () {
				const smartMediaHelper = await loadSmartMediaHelper;
				const user = await userModel.getCurrentUserAsync();
				return await smartMediaHelper.fetchFontFamilies(user.isEmployee);
			};
		},
		async link(scope, elem, attrs, controller) {
			const smartMediaHelper = await loadSmartMediaHelper;
			const initialModel = smartMediaHelper.getModel(null, mimeTypeUtility);
			scope.initialModel = initialModel;
			scope.handleOnBackgroundSelected =
				!scope.asset && (asset => scope.$apply(s => (s.backgroundTitle = asset.title)));
			scope.handleFetchFontFamilies = controller.fetchFontFamilies;
			scope.bucket = bucketService.getCurrentBucket().id;
			scope.ref = smartMediaHelper.createRef();

			scope.$on('saveRequested', async () => {
				if (scope.readOnly) {
					return;
				}

				const lockupData = await scope.ref.current.getLockupData();
				const smartMediaMetadata = smartMediaHelper.toAmberSmartMediaFamily(
					scope.ref.current.getModel(),
					(scope.editorConfiguration === 'roomLayout' && 'RoomLayout') || undefined
				);
				scope.$emit('saved', {
					smartMediaMetadata,
					backgroundTitle: scope.backgroundTitle,
					lockupData,
				});
			});

			scope.$watch('asset', async () => {
				const editor = await loadComponent;
				scope.smartMediaEditorComponentLoaded = !!editor;
				scope.showSmartMediaEditor = true;
				const model = smartMediaHelper.getModel(scope.asset, mimeTypeUtility);

				if (model) {
					scope.initialModel = model;
					scope.showSmartMediaEditor = true;
				}
				scope.$apply();
			});

			scope.$apply();
		},
	};
}
