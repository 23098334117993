import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('filterBreadcrumbBar', filterBreadcrumbBar);

function filterBreadcrumbBar(
	$state,
	searchTextService,
	facetQueryService,
	boardService,
	boardsModel,
	filterService,
	groupService,
	embedService
) {
	return {
		restrict: 'AE',
		replace: true,
		scope: {
			hitsCount: '=?',
			customTail: '=?',
			allowRemove: '=',
			isAnonymous: '=',
		},
		controller($scope) {
			$scope.currentAccountToken = groupService.currentAccountToken || null;
			$scope.assetListState = embedService.isEmbedded
				? 'assetsEmbed'
				: $state.is('boardSharedDetails')
				? 'boardSharedDetails'
				: 'assets';
			$scope.shareToken = $state.params.shareToken;
			$scope.isAssetListState = () => $state.current.name === $scope.assetListState || null;

			const presetFilters = facetQueryService.parseFilterParam(embedService.presetFilters);
			$scope.isReadOnlyFilter = function (facetTerm) {
				if (presetFilters) {
					return presetFilters.some(filter => filter.term === facetTerm);
				}
				return false;
			};

			$scope.$watch(
				() => boardsModel.currentBoardId,
				boardId => {
					$scope.currentBoardId = boardId;
					if (boardId) {
						const loadedBoard = _.find(boardsModel.boards || [], { id: boardId });
						(
							(loadedBoard && Promise.resolve(loadedBoard)) ||
							boardService.getBoardAsync(boardId, null, $state.params.shareToken)
						).then(board => {
							$scope.currentBoardName = board.name;
						});
					}
				}
			);

			$scope.currentSearch = searchTextService.searchText;
			$scope.$watch(
				() => searchTextService.searchText,
				() => {
					$scope.currentSearch = searchTextService.searchText;
				}
			);

			$scope.$watch(
				() => facetQueryService.filters,
				() => {
					$scope.filters = facetQueryService.filters;
				}
			);

			function updateSavedFilter() {
				$scope.currentSavedFilter = filterService.selectedSavedFilter;
				$scope.currentSavedFilterName = filterService.selectedSavedFilterName;
			}

			updateSavedFilter();
			$scope.$watch(() => filterService.selectedSavedFilter, updateSavedFilter);
			$scope.$watch(() => filterService.selectedSavedFilterName, updateSavedFilter);

			$scope.clearSearch = function () {
				searchTextService.clear();
			};

			$scope.clearSavedFilter = function () {
				filterService.clearSelectedSavedFilter();
				filterService.clearSelectedSavedFilterName();
			};

			$scope.remove = function (filterToRemove) {
				return function (filter) {
					return filterToRemove !== filter;
				};
			};

			$scope.removeAfter = function (filterToRemoveAfter) {
				return function (filter) {
					return $scope.filters.indexOf(filter) <= $scope.filters.indexOf(filterToRemoveAfter);
				};
			};

			$scope.getTailHref = function () {
				return $state.href($scope.customTail.state, $scope.customTail.stateParams);
			};
		},
		templateUrl: require('../../views/templates/filterBreadcrumbBar.html'),
	};
}
