import * as angular from 'angular';

angular.module('app').directive('assetPreviewModal', assetPreviewModal);

function assetPreviewModal(assetService, hotkeys, $timeout) {
	return {
		restrict: 'E',
		scope: {
			asset: '=',
			bucket: '=',
			groupPermissions: '=',
			display: '=',
			isEmbedded: '=',
			isSmallViewport: '=',
			loadSequential: '=',
			assetCollection: '=',
			assetIndex: '=',
			currentUser: '=',
			shareToken: '=?',
		},
		link(scope, elem) {
			scope.display.showSharePanel = false;
			scope.display.showSidebar = false;
			scope.display.zoom = false;
			scope.display.loading = false;

			scope.toggleSidebar = function () {
				const sections = elem.find('.asset-preview-section-content');
				angular.element.each(sections, (i, section) => {
					section.setAttribute('data-max-height', section.clientHeight);
					const sectionName = section.getAttribute('data-section-name');
					if (scope.display.sidebarSections[sectionName]) {
						angular
							.element(section)
							.css('max-height', `${angular.element(section).attr('data-max-height')}px`);
					}
				});
				scope.display.showSidebar = !scope.display.showSidebar;
				adjustForScrollbar();
			};

			scope.thumbnailKind = {
				value: 'default',
			};

			function adjustForScrollbar() {
				if (scope.isSmallViewport) {
					return;
				}
				function adjustCore() {
					const sidebarWidth = scope.display.showSidebar
						? elem.find('.asset-preview-sidebar').outerWidth()
						: 0;
					const container = elem.find('.preview-container');
					const width = container.width();
					const clientWidth = container.get(0).clientWidth;
					setScrollbarOffset(sidebarWidth + width - clientWidth);
				}
				$timeout(adjustCore, 100);
				// previews sometimes take a couple seconds to render
				$timeout(adjustCore, 2500);
			}

			function setScrollbarOffset(width) {
				const overlay = elem.find('.preview-overlay');
				const rightButton = elem.find('.paging-button-right');
				const scrollbarBorder = elem.find('.scrollbar-border');
				[overlay, rightButton, scrollbarBorder].forEach(el => el.css('right', `${width}px`));
			}

			adjustForScrollbar();

			scope.hidePreview = function () {
				scope.display.showPreviewModal = false;
				scope.display.zoom = false;
				scope.display.showSidebar = false;
			};

			scope.favoriteAsset = function () {
				scope.asset.favorite = !scope.asset.favorite;
				if (scope.asset.favorite) {
					assetService.addToFavoritesAsync([scope.asset.id]);
				} else {
					assetService.removeFromFavoritesAsync([scope.asset.id]);
				}
			};

			scope.loadSequentialEvent = function (direction) {
				scope.$broadcast('load-sequential', { direction }); // listener in assetPreviewImages.js
				scope.loadSequential(direction);
				const previewContainer = elem.find('.preview-container');
				if (previewContainer) {
					previewContainer[0].scrollTop = 0;
				}
			};

			scope.toggleSharePanel = function () {
				scope.display.showSharePanel = !scope.display.showSharePanel;
			};

			hotkeys
				.bindTo(scope)
				.add({
					combo: 'esc',
					description: 'Close preview',
					callback: scope.hidePreview,
				})
				.add({
					combo: 'left',
					description: 'Cycle to previous asset',
					callback() {
						scope.loadSequentialEvent(false);
						const buttonLeft = elem.find('.paging-button-left');
						buttonLeft.addClass('active');
						scope.timer = $timeout(() => {
							buttonLeft.removeClass('active');
						}, 500);
					},
				})
				.add({
					combo: 'right',
					description: 'Cycle to next asset',
					callback() {
						scope.loadSequentialEvent(true);
						const buttonRight = elem.find('.paging-button-right');
						buttonRight.addClass('active');
						scope.timer = $timeout(() => {
							buttonRight.removeClass('active');
						}, 500);
					},
				});

			scope.$on('$destroy', () => {
				if (scope.timer) {
					$timeout.cancel(scope.timer);
				}
			});
		},
		templateUrl: require('../../views/templates/assetPreviewModal.html'),
	};
}
