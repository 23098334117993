import * as angular from 'angular';

angular.module('app').directive('menuGroup', menuGroup);

function menuGroup() {
	return {
		restrict: 'AE',
		controller() {
			const vm = this;
			const options = [];
			let selectedOptionIndex = null;

			vm.registerOption = function (option) {
				options.push(option);
				if (option.isActive) {
					selectedOptionIndex = options.length - 1;
				}
			};

			vm.selectOption = function (option) {
				const index = options.findIndex(x => x.id === option.id);
				options[index].setIsActive(true);
				options[selectedOptionIndex].setIsActive(false);
				selectedOptionIndex = index;
			};
		},
	};
}
