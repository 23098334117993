import * as angular from 'angular';

angular.module('app').controller('ShareEmbedController', shareEmbed);

function shareEmbed($scope, $rootScope, $transition$, assetService, errorService, $location) {
	$rootScope.viewMode = 'share-embed';
	const $stateParams = $transition$.params();
	const shareToken = $stateParams.shareToken;

	$scope.shareUrl = `${$location.protocol()}://${$location.host()}/shares/${shareToken}`;
	$scope.disableFullscreen = !$stateParams.fs;
	$scope.enableAutoplay = !!$stateParams.autoplay;
	$scope.disableDownload = !$stateParams.dl;
	$scope.disableDetails = $stateParams.disableDetails;
	$scope.disableShare = $stateParams.disableShare;
	$scope.bookLayout = $stateParams.bookLayout;
	$scope.shareToken = shareToken;

	assetService
		.getShareAssetIdAsync(shareToken)
		.then(assetId => assetService.getAssetAsync(assetId, { shareToken }))
		.then(asset => {
			$scope.asset = asset;
			assetService.reportAssetView(asset.id, shareToken);
			$scope.$emit('pageTitleChange', asset.title);
		})
		.catch(error => {
			if (error.status === 'notfound') {
				errorService.showNotFound();
			} else {
				errorService.showError();
			}
		});
}
