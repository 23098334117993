import * as angular from 'angular';
import * as amber from '@faithlife/amber-api';

export interface ISearchFacet {
	name?: string;
	terms?: ISearchFacetTerm[];
}

export interface ISearchFacetTerm {
	value: any;
	count?: number;
}

angular.module('app').factory('facetMapper', facetMapper);

function facetMapper() {
	function mapFacet(source: undefined): undefined;
	function mapFacet(source: amber.ISearchFacet): ISearchFacet;
	function mapFacet(source: amber.ISearchFacet[]): ISearchFacet[];
	function mapFacet(source: any): ISearchFacet;
	function mapFacet(source: any[]): ISearchFacet[];
	function mapFacet(source: any): ISearchFacet | ISearchFacet[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<ISearchFacet>(mapFacet);
		}

		return {
			name: source.field,
			terms: source.terms,
		};
	}

	return {
		map: mapFacet,
	};
}

export type IFacetMapper = ReturnType<typeof facetMapper>;
