import * as angular from 'angular';

angular.module('app').filter('familyName', familyName);

function familyName(familyService, utility) {
	return function (value) {
		const family = familyService.getFamily(value);
		return (family && family.displayName) || utility.capitalize(utility.breakCamelCaseWords(value));
	};
}
