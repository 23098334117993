import * as angular from 'angular';
import { ILocalSettingsService } from './localSettingsService';

angular.module('app').factory('viewStyleService', viewStyleService);

function viewStyleService(
	$location: angular.ILocationService,
	localSettingsService: ILocalSettingsService
) {
	const viewStyleQuery: string = $location.search().viewStyle;
	let currentViewStyle: string | null | undefined = null;

	function createViewStyleService(isSmallViewport: boolean) {
		function localSettingsApi() {
			return localSettingsService.createSettingAccessor(
				'viewStyle',
				isSmallViewport ? 'table' : 'grid'
			);
		}

		return {
			getStyle() {
				if (!currentViewStyle) {
					currentViewStyle = viewStyleQuery || localSettingsApi().get();
				}

				return currentViewStyle;
			},

			setStyle(style: string) {
				if (currentViewStyle !== style) {
					currentViewStyle = style;

					if (!viewStyleQuery) {
						// Persist the style setting if there is no viewStyle query parameter in use
						localSettingsApi().set(style);
					}
				}
			},
		};
	}

	return {
		createViewStyleService,
	};
}

export type IViewStyleService = ReturnType<typeof viewStyleService>;
