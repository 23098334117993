import * as angular from 'angular';

angular.module('app').directive('assetPreviewSidebar', assetPreviewSidebar);

function assetPreviewSidebar() {
	return {
		restrict: 'E',
		scope: true,
		link(scope, elem) {
			scope.display.sidebarSections = {
				details: true,
				formats: true,
				history: true,
				comments: true,
				all: true,
			};

			scope.toggleSectionEvent = function (event) {
				scope.toggleSection(angular.element(event.currentTarget).next());
			};

			scope.toggleAllSections = function () {
				const sections = elem.find('.asset-preview-section-content');
				angular.element.each(sections, (i, section) => {
					const sectionName = section.getAttribute('data-section-name');
					if (scope.display.sidebarSections[sectionName] === scope.display.sidebarSections.all) {
						scope.toggleSection(angular.element(section));
					}
				});
				scope.display.sidebarSections.all = !scope.display.sidebarSections.all;
			};

			scope.toggleSection = function (sectionContent) {
				const sectionName = sectionContent.attr('data-section-name');
				if (scope.display.sidebarSections[sectionName]) {
					sectionContent.removeAttr('style');
				} else {
					sectionContent.css('max-height', `${sectionContent.attr('data-max-height')}px`);
				}
				scope.display.sidebarSections[sectionName] = !scope.display.sidebarSections[sectionName];
			};
		},
		templateUrl: require('../../views/templates/assetPreviewSidebar.html'),
	};
}
