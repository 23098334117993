import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('mimeTypeUtility', mimeTypeUtility);

function mimeTypeUtility() {
	const mediaKinds = {
		doc: [
			'msword',
			'wordprocessing',
			'opendocument[.]text',
			'powerpoint',
			'presentation',
			'opendocument[.]presentation',
		],
		xls: ['excel', 'spreadsheet'],
		pdf: ['pdf'],
		zip: ['zip', 'x-rar-compressed', 'x-tar'],
		psd: [],
		ai: [],
		fw: [],
		indd: [],
		application: [],
		image: [],
		video: [],
		audio: [],
		text: [],
	};

	function getFileKind(mediaType: string | null | undefined) {
		let kind =
			mediaType &&
			_.findKey(mediaKinds, patterns =>
				_.some(patterns, pattern => new RegExp(pattern, 'gi').test(mediaType))
			);

		if (!kind) {
			const mimeType = splitMimeType(mediaType);
			if (mimeType) {
				kind = mimeType.type;
			}
		}

		return kind || 'unknown';
	}

	function splitMimeType(mimeType: string | undefined | null) {
		if (!mimeType) {
			return null;
		}

		const parts = mimeType.split('/');
		if (parts.length !== 2) {
			return null;
		}

		return {
			type: parts[0],
			subtype: parts[1],
		};
	}

	return {
		getFileKind,
		splitMimeType,
	};
}

export type IMimeTypeUtility = ReturnType<typeof mimeTypeUtility>;
