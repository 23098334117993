import * as angular from 'angular';

angular.module('app').directive('confirmationButton', confirmationButton);

function confirmationButton(): angular.IDirective {
	return {
		restrict: 'E',
		replace: true,
		transclude: {
			confirmationButtonLabel: '?confirmationButtonLabel',
			title: '?modalTitle',
			subtitle: '?modalSubtitle',
			actionButtonLabel: '?modalActionButtonLabel',
		},
		scope: {
			confirmationButtonCallback: '&',
			confirmationBtnClass: '@',
			modalActionBtnClass: '@',
			showModal: '=?',
		},
		templateUrl: require('../../views/templates/confirmationButton.html'),
		link(scope, elem, attr, ctrl, transclude) {
			scope.hasConfirmationButtonLabel = transclude!.isSlotFilled('confirmationButtonLabel');

			scope.closeModal = function () {
				scope.showModal = false;
			};

			scope.confirmAction = function () {
				scope.confirmationButtonCallback();
			};
		},
	};
}
