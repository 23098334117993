import * as angular from 'angular';
import { isSmartMedia, isSmartMediaLockup, isRoomLayout } from '../helpers/smartMediaAssetHelper';

angular.module('app').directive('smartMediaEditorModal', smartMediaEditorModal);

function smartMediaEditorModal() {
	return {
		replace: true,
		scope: {
			asset: '=',
			showEditor: '=',
			kind: '=',
		},
		controller,
		controllerAs: 'vm',
		templateUrl: require('../../views/templates/smartMediaEditorModal.html'),
	};
}

function controller(
	$scope,
	$location,
	$q,
	$timeout,
	$filter,
	assetService,
	boardsModel,
	jobService,
	cancellationService,
	userModel,
	$rootScope
) {
	const vm = this;
	vm.toggleEditor = toggleEditor;
	vm.saveAsNew = saveAsNew;
	vm.overwriteExisting = overwriteExisting;
	vm.isSmartMedia = false;
	vm.isSmartMediaLockup = false;
	vm.isRoomLayout = false;
	vm.editorConfiguration = null;
	vm.updateExistingOnCommit = false;

	userModel
		.getCurrentUserAsync()
		.then(user => $scope.$apply(s => (s.showCreateLockup = user.isEmployee)));

	$scope.$on('saved', (e, data) => {
		if (!data) {
			$scope.$apply(() => {
				$scope.isWorking = false;
				$scope.errorMessage = $filter('translate')('smartMedia.errorMessage');
			});
			return;
		}

		if (!$scope.saveAsLockup) {
			delete data.lockupData;
		}

		data.metadata = {
			...data.smartMediaMetadata.model.metadata,
			tags: (data.smartMediaMetadata.model.metadata?.tags || []).map(x => x.original || x),
		};
		if (!data.metadata.title) {
			data.metadata.title = getFallbackTitle(data);
		}

		data.smartMediaMetadata.model = data.smartMediaMetadata.model.withoutMetadata();

		if (vm.updateExistingOnCommit) {
			editAsync(data);
		} else {
			createAsync(data);
		}
	});

	function getFallbackTitle(data) {
		if ($scope.saveAsLockup) {
			return `${data.title} ${$filter('translate')('smartMedia.lockup')}`;
		} else if ($scope.asset && $scope.asset.title) {
			return $scope.asset.title;
		} else if (data.backgroundTitle) {
			return `${data.backgroundTitle} ${$filter('translate')('smartMedia.label')}`;
		} else if (
			data.lockupData &&
			data.lockupData.objects &&
			data.lockupData.objects.some(o => o.text)
		) {
			const text = data.lockupData.objects.find(o => o.text).text;
			return `${text} ${$filter('translate')('smartMedia.label')}`;
		}
		return $filter('translate')('smartMedia.new');
	}

	function toggleEditor() {
		$scope.showEditor = !$scope.showEditor;
		$rootScope.disableUploads = $scope.showEditor;
	}

	function saveAsNew(saveAsLockup = false) {
		$timeout(() => {
			$scope.isWorking = true;
			$scope.errorMessage = null;
			$scope.saveAsLockup = saveAsLockup;
			vm.updateExistingOnCommit = false;
		}, 0);

		$scope.$broadcast('saveRequested');
	}

	function overwriteExisting(saveAsLockup = false) {
		$scope.isWorking = true;
		$scope.errorMessage = null;
		$scope.saveAsLockup = saveAsLockup;
		vm.updateExistingOnCommit = true;
		$scope.$broadcast('saveRequested');
	}

	function createAsync({ smartMediaMetadata, metadata, lockupData }) {
		const smartMedia = {
			styles: [smartMediaMetadata],
		};
		const { token: cancellationToken } = cancellationService.createCancellationSource();
		const assetMetadata = {
			...(($scope.asset && $scope.asset.originalMetadata) || {}),
			...metadata,
		};

		if (assetMetadata.other) {
			assetMetadata.other = assetMetadata.other.filter(v => v.name !== 'smartMediaLockup');
		}
		if (assetMetadata.tags) {
			assetMetadata.tags = assetMetadata.tags.filter(v => v.text !== 'smartMediaLockup');
		}

		const copyAsset = $scope.asset
			? assetService.copyAssetFileAsync(
					$scope.asset.id,
					($scope.asset.source || $scope.asset.file).id,
					null,
					cancellationToken
			  )
			: undefined;

		return $q
			.resolve(copyAsset)
			.then(sourceFile => {
				const operations = [
					...Object.entries(assetMetadata).map(([key, value]) => ({
						op: 'setMetadata',
						path: key,
						value,
					})),
					{ op: 'addToMetadataArray', path: 'families', value: 'smartMedia' },
					{ op: 'setMetadata', path: 'smartMedia', value: smartMedia },
					{ op: 'setFileFromSmartMedia', update: true },
				];
				if (sourceFile) {
					operations.push({ op: 'setSource', fileId: sourceFile.id });
				}
				if (lockupData) {
					operations.push(
						{
							op: 'addToMetadataArray',
							path: 'other',
							value: { name: 'smartMediaLockup', value: JSON.stringify(lockupData) },
						},
						{ op: 'addToMetadataArray', path: 'tags', value: { text: 'smartMediaLockup' } }
					);
				}
				if (
					boardsModel.currentBoardId &&
					boardsModel.boards.find(
						board => board.id === boardsModel.currentBoardId && !board.isDefault
					)
				) {
					operations.push({
						op: 'addToBoard',
						boardId: boardsModel.currentBoardId,
					});
				}

				return assetService.createAssetAsync(operations, {
					cancellationToken,
				});
			})
			.then(job =>
				jobService.awaitJobsCompletionAsync([job.id], {
					cancellationToken,
				})
			)
			.then(response => jobService.getJobsAsync([response.items[0].id]))
			.then(response => response.items[0].response.content)
			.then(asset => {
				toggleEditor();
				$location.path(`/assets/${asset.id}`);
				$scope.isWorking = false;
				return asset.id;
			})
			.then(assetId => {
				const operations = [
					{ op: 'updateFile' },
					{ op: 'adoptFileMetadata' },
					{ op: 'createStandardFormats' },
				];
				return assetService.editAssetsAsync([assetId], operations, {
					fireAndForget: true,
				});
			})
			.catch(() => {
				$scope.isWorking = false;
				$scope.errorMessage = $filter('translate')('smartMedia.errorMessage');
			});
	}

	function editAsync({ smartMediaMetadata, lockupData, metadata }) {
		const update = { styles: [smartMediaMetadata] };
		const { token: cancellationToken } = cancellationService.createCancellationSource();

		let updatedOtherMetadata;
		if (lockupData) {
			updatedOtherMetadata = [
				...($scope.asset.other || []).filter(v => v.name !== 'smartMediaLockup'),
				{ name: 'smartMediaLockup', value: JSON.stringify(lockupData) },
			];
		}

		return ($scope.asset.source
			? Promise.resolve({})
			: assetService.copyAssetFileAsync(
					$scope.asset.id,
					$scope.asset.file.id,
					null,
					cancellationToken
			  )
		)
			.then(({ id: sourceFileCopyId }) =>
				assetService.updateAssetAsync(
					$scope.asset.id,
					edit => {
						if (sourceFileCopyId) {
							edit.updateSource(sourceFileCopyId);
						}

						edit.updateMetadata('smartMedia', update);
						edit.updateMetadata('title', metadata.title);
						edit.updateMetadata('tags', metadata.tags);
						edit.updateMetadata('description', metadata.description);
						if (updatedOtherMetadata) {
							edit.updateMetadata('other', updatedOtherMetadata);
						}
						edit.updateOriginalFromSmartMedia();
					},
					cancellationToken
				)
			)
			.then(() => assetService.getAssetAsync($scope.asset.id, { cancellationToken }))
			.then(assetFromServer => {
				$scope.asset = assetFromServer;
				toggleEditor();
				$scope.isWorking = false;
			})
			.catch(() => {
				$scope.isWorking = false;
				$scope.errorMessage = $filter('translate')('smartMedia.errorMessage');
			});
	}

	function refreshView() {
		$scope.saveAsNewOptions = [
			{ text: $filter('translate')('smartMedia.label'), callback: () => saveAsNew(false) },
			{ text: $filter('translate')('smartMedia.lockup'), callback: () => saveAsNew(true) },
		];
		if (vm.isSmartMedia) {
			$scope.editorActionButtonDisplayText = $filter('translate')('smartMedia.saveAsNew');
			$scope.showOverwriteExisting = true;
			$scope.modalTitle =
				(vm.isSmartMediaLockup && $filter('translate')('smartMedia.editLockup')) ||
				(vm.isRoomLayout && $filter('translate')('smartMedia.editRoomLayout')) ||
				$filter('translate')('smartMedia.edit');
		} else {
			$scope.editorActionButtonDisplayText = $filter('translate')('generic.actions.save');
			$scope.showOverwriteExisting = false;
			$scope.modalTitle = $filter('translate')('smartMedia.create');
		}
	}

	$scope.$watch('asset', asset => {
		if (asset) {
			vm.asset = asset;
			vm.isSmartMedia = isSmartMedia(asset);
			vm.isSmartMediaLockup = isSmartMediaLockup(asset);
			vm.isRoomLayout = isRoomLayout(asset);
			vm.editorConfiguration =
				(vm.isSmartMediaLockup && 'lockup') || (vm.isRoomLayout && 'roomLayout') || 'smartMedia';

			refreshView();
		}
	});

	$scope.$watch('kind', kind => {
		if (kind) {
			vm.editorConfiguration = kind;

			refreshView();
		}
	});

	refreshView();
}
