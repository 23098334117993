import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').filter('simpleNumber', simpleNumber);

// `simpleNumber` filter is similar to angular's built-in `number` filter, except that it does not preserve insignificant zeros in the decimal part.
// Defaults to displaying one decimal place.
function simpleNumber($filter) {
	return function (number, decimalPlaces) {
		if (number && _.isNumber(number)) {
			const desiredSignificantPlaces = Math.pow(10, decimalPlaces || 1);
			const adjustedNumber =
				Math.round(number * desiredSignificantPlaces) / desiredSignificantPlaces;
			return $filter('number')(adjustedNumber);
		}
		return Number.NaN;
	};
}
