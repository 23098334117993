import * as angular from 'angular';
import * as _ from 'lodash';
import { IThinAsset, IUriUtility, IUtility } from '.';
import { IConstants } from '../infrastructure';

export interface IThumbnailInfo {
	fileId: string;
	width?: number;
	height?: number;
	url: string;
}

angular.module('app').factory('assetUtility', assetUtility);

function assetUtility(constants: IConstants, uriUtility: IUriUtility, utility: IUtility) {
	function getBestFormatsForSize(asset: IThinAsset, desiredSize: number, formatPrefix: string) {
		let closestSize: number | null = null;
		const bestFormats = _.groupBy(
			(asset.formats || [])
				.filter(f => {
					const firstFile = f.firstFile;
					return (
						f.name &&
						utility.startsWith(f.name, constants.previewFormatPrefix) &&
						firstFile &&
						firstFile.mediaType &&
						utility.startsWith(firstFile.mediaType, `${formatPrefix}/`)
					);
				})
				.map(f => {
					const previewSize = parseInt(
						f.name!.substring(constants.previewFormatPrefix.length).trim(),
						10
					);

					return previewSize
						? {
								format: f,
								size: previewSize,
						  }
						: undefined;
				})
				.filter(x => !!x)
				.map(x => x!),
			f => (f.size >= desiredSize ? 'formatsLargerThanDesired' : 'formatsSmallerThanDesired')
		);
		return _.takeWhile(
			_.sortBy(
				bestFormats.formatsLargerThanDesired || bestFormats.formatsSmallerThanDesired || [],
				f => Math.abs(desiredSize - f.size)
			),
			f => {
				if (closestSize === null) {
					closestSize = f.size;
				}

				return f.size === closestSize;
			}
		);
	}

	function verifyNotNull<T>(value: T | null | undefined): T {
		if (value == null) {
			throw new Error('Expected truthy value');
		}
		return value;
	}

	return {
		getBestFormatForSize(asset: IThinAsset, size: number) {
			return getBestFormatsForSize(asset, size, 'image')
				.map(x => x.format)
				.shift();
		},
		getBestThumbnailForSize(asset: IThinAsset, size: number): IThumbnailInfo | undefined {
			const bestFormat = getBestFormatsForSize(asset, size, 'image')
				.map(x => x.format)
				.shift();

			const previewFile =
				bestFormat && (bestFormat.file || (bestFormat.files && bestFormat.files[0]));

			return (
				previewFile?.metadata?.image && {
					fileId: previewFile.id,
					width: previewFile.metadata.image.width,
					height: previewFile.metadata.image.height,
					url: verifyNotNull(previewFile.url),
				}
			);
		},
		getVideoSourcesForSize(
			asset: IThinAsset,
			size: number,
			assetServiceBaseUri: string,
			shareToken: string | undefined
		) {
			if (!asset.formats) {
				return [];
			}

			const streamingFormatSources = asset.formats
				.filter(
					f =>
						!!(
							f.name === 'Amber Streaming HLS' &&
							f.firstFile &&
							f.firstFile.url &&
							f.firstFile.mediaType
						)
				)
				.map(f => ({
					src: f.firstFile!.url!,
					type: f.firstFile!.mediaType!,
				}));
			const otherFormatSources = getBestFormatsForSize(asset, size, 'video')
				.filter(f => !!(f.format.file && f.format.file.mediaType))
				.map(f => ({
					src: uriUtility.fromPattern(
						`${assetServiceBaseUri}assets/{assetId}/files/{fileId}/content`,
						{
							assetId: asset.id,
							fileId: f.format.file!.id,
							rev: asset.revision.id,
							share: shareToken,
						}
					),
					type: f.format.file!.mediaType!,
				}))
				.sort((a, b) =>
					// Video playback is flaky in Chrome when webm isn't first in the list
					// eslint-disable-next-line
					a.type.indexOf('webm') >= 0 ? -1 : b.type.indexOf('webm') >= 0 ? 1 : 0
				);
			const sources = [...streamingFormatSources, ...otherFormatSources];
			if (
				asset.file &&
				(asset.file.mediaType === 'video/mp4' || asset.file.mediaType === 'video/webm')
			) {
				sources.push({
					src: uriUtility.fromPattern(
						`${assetServiceBaseUri}assets/{assetId}/files/{fileId}/content`,
						{
							assetId: asset.id,
							fileId: asset.file!.id,
							rev: asset.revision.id,
							share: shareToken,
						}
					),
					type: asset.file!.mediaType!,
				});
			}
			return sources;
		},
		getAudioSources(
			asset: IThinAsset,
			assetServiceBaseUri: string,
			shareToken: string | undefined
		) {
			const formats = asset.formats || [];
			const files = [asset.file].concat(formats.map(format => format.file));

			return files
				.filter(file => !!(file && file.mediaType && utility.startsWith(file.mediaType, 'audio/')))
				.map(file => ({
					src: uriUtility.fromPattern(
						`${assetServiceBaseUri}assets/{assetId}/files/{fileId}/content`,
						{ assetId: asset.id, fileId: file!.id, rev: asset.revision.id, share: shareToken }
					),
					type: file!.mediaType!,
				}));
		},
		getBestTextFormat(
			asset: IThinAsset,
			assetServiceBaseUri: string,
			shareToken: string | undefined
		) {
			const formats = asset.formats || [];
			const files = [asset.file].concat(formats.map(format => format.file));
			const textTypePriority = ['text/markdown', 'text/html', 'text/plain'];

			return files
				.filter(file => !!(file && file.mediaType && utility.startsWith(file.mediaType, 'text/')))
				.map(file => ({
					src: uriUtility.fromPattern(
						`${assetServiceBaseUri}assets/{assetId}/files/{fileId}/content`,
						{ assetId: asset.id, fileId: file!.id, rev: asset.revision.id, share: shareToken }
					),
					type: file!.mediaType!,
				}))
				.sort((a, b) => textTypePriority.indexOf(a.type) - textTypePriority.indexOf(b.type))
				.shift();
		},
	};
}

export type IAssetUtility = ReturnType<typeof assetUtility>;
