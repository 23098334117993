import * as angular from 'angular';
import * as _ from 'lodash';
import { IAsset } from '../helpers';
import { IAssetService, IFormatOptions } from './assetService';
import { CancellationToken } from './cancellationService';

interface IDimensions {
	width?: number;
	height?: number;
}

interface IFormat {
	type: string;
	name: string;
	kind?: string;
}

angular.module('app').factory('assetDetailsAddFormatModel', assetDetailsAddFormatModel);

function assetDetailsAddFormatModel() {
	const flattenFormats = (expandedFormats: { [kind: string]: IFormat[] }) =>
		Object.entries(expandedFormats).flatMap(([kind, formats]) =>
			formats.map(format => ({ kind, ...format }))
		);

	const expandFormats = (flatFormats: IFormat[]) =>
		_.mapValues(_.groupBy(flatFormats, 'kind'), outer => outer.map(inner => _.omit(inner, 'kind')));

	function createAddFormatModel(asset: IAsset, shareToken: string | undefined) {
		const assetFile = asset?.file;
		if (
			!asset ||
			(asset.kind !== 'image' && asset.kind !== 'audio' && asset.kind !== 'video') ||
			!assetFile
		) {
			return null;
		}

		const initial: IDimensions = assetFile.dimensions ?? {};
		const hasAspectRatio = (initial && initial.height && initial.width) != null;
		let editable: IDimensions = {};

		// get formats that can be made from this asset
		function getAvailableFormats() {
			const formats = flattenFormats(allFormats);

			const filteredFormats = formats.filter(({ kind }) => {
				if (kind === asset.kind) {
					return true;
				}

				switch (kind) {
					case 'audio':
						return asset.kind === 'video';
					case 'image':
						return asset.kind === 'vector';
					case 'vector':
						return [
							'application/postscript',
							'application/vnd.adobe.illustrator',
							'image/eps',
							'image/svg+xml',
						].includes(assetFile!.mediaType ?? '');
					case 'video':
						return !!(assetFile!.mediaType?.startsWith('image/') && assetFile!.metadata?.duration);
					default:
						return false;
				}
			});

			return expandFormats(filteredFormats);
		}

		function getComputedWidthOrHeight(widthOrHeight: 'width' | 'height') {
			const otherDimension = widthOrHeight === 'width' ? 'height' : 'width';

			if (editable[widthOrHeight]) {
				return editable[widthOrHeight];
			} else if (hasAspectRatio && editable[otherDimension]) {
				return Math.round(
					(editable[otherDimension]! * initial[widthOrHeight]!) / initial[otherDimension]!
				);
			}
			return initial && initial[widthOrHeight];
		}

		function isOutsideBounds(property, max) {
			return property !== null && (property <= 0 || property > max);
		}

		const availableFormats = getAvailableFormats();
		const mediaType = assetFile.mediaType ?? 'application/octet-stream';
		let fullMediaType = mediaType;
		if (fullMediaType?.startsWith('video/')) {
			let videoCodec = assetFile.metadata?.video?.codec;
			if (videoCodec === 'h264' || videoCodec === 'hevc') {
				videoCodec = assetFile.metadata?.video?.codecTag;
				if (videoCodec === 'hev1') {
					videoCodec = 'hvc1';
				}
			}
			const audioCodec = assetFile.metadata?.audio?.codec;
			fullMediaType += `; codecs="${videoCodec}, ${audioCodec}"`;
		}
		const availableFormatTypes = flattenFormats(availableFormats).map(x => x.type);
		const format =
			availableFormatTypes.find(x => x === fullMediaType) ??
			availableFormatTypes.find(x => x.startsWith(mediaType)) ??
			mediaType;

		return {
			maxBitrate: 1000000,
			maxDimension: 16384,
			showFormatDialog: false,
			name: '',
			bitrate: undefined,
			format,
			autoQuality: true,
			quality: 100,
			optimizationKind: 'lossless',
			availableFormats,

			get invalidDimensions() {
				return (
					isOutsideBounds(this.computedWidth, this.maxDimension) ||
					isOutsideBounds(this.computedHeight, this.maxDimension)
				);
			},

			get invalidBitrate() {
				return isOutsideBounds(this.bitrate, this.maxBitrate);
			},

			get canSubmit() {
				if (this.invalidDimensions || this.invalidBitrate) {
					return false;
				}
				return (
					editable.width ||
					editable.height ||
					this.format !== assetFile!.mediaType ||
					this.bitrate ||
					this.optimizationKind !== 'none'
				);
			},

			get computedWidth() {
				return getComputedWidthOrHeight('width');
			},

			get computedHeight() {
				return getComputedWidthOrHeight('height');
			},

			get width() {
				return editable.width;
			},
			set width(value) {
				if (hasAspectRatio) {
					editable.height = undefined;
				}
				editable.width = value;
			},

			get height() {
				return editable.height;
			},
			set height(value) {
				if (hasAspectRatio) {
					editable.width = undefined;
				}
				editable.height = value;
			},

			get qualityStr() {
				return this.quality.toString(10);
			},
			set qualityStr(value) {
				this.quality = parseInt(value, 10);
			},

			closeDialog() {
				editable = {};
				this.name = '';
				this.bitrate = undefined;
				this.format = format;
				this.autoQuality = true;
				this.quality = 100;
				this.optimizationKind = 'lossless';
				this.showFormatDialog = false;
			},

			replaceStandardPreviewsAsync(
				assetService: IAssetService,
				options,
				cancellationToken: CancellationToken
			) {
				// use the current frame of the video
				function addChanges(changeOptions) {
					changeOptions.replaceStandardPreviews({ offset: options.offset, asset: options.asset });
				}

				return assetService
					.updateAssetAsync(options.asset.id, addChanges, cancellationToken)
					.then(() => assetService.getAssetAsync(asset.id, undefined, cancellationToken))
					.then(assetFromServer => assetFromServer);
			},

			createFormat(assetService: IAssetService, cancellationToken: CancellationToken) {
				let baseName = assetFile.name ?? '';
				const extensionSeparatorIndex = baseName.lastIndexOf('.');
				if (extensionSeparatorIndex > 0) {
					baseName = baseName.substring(0, extensionSeparatorIndex);
				}
				let fileName = baseName;
				let resize: IDimensions | undefined;
				let bitrate: number | undefined;
				let quality: number | undefined;
				let optimizationKind: string | undefined;

				if (asset.kind !== 'audio') {
					const computedWidth = this.computedWidth;
					const computedHeight = this.computedHeight;
					if (initial && (computedWidth !== initial.width || computedHeight !== initial.height)) {
						fileName += `_${computedWidth}x${computedHeight}`;
					}
					resize = {
						width: this.width,
						height: this.height,
					};
					if (!this.autoQuality) {
						quality = this.quality;
					}
				}

				if (asset.kind !== 'image' && this.bitrate && this.bitrate! > 0) {
					fileName += `_${this.bitrate}kbps`;
					bitrate = this.bitrate! * 1000;
				}

				if (asset.kind === 'image') {
					optimizationKind = this.optimizationKind;
				}

				const formatOptions: IFormatOptions = {
					asset,
					name: this.name,
					format: this.format,
					resize,
					fileName,
					bitrate,
					quality,
					optimizationKind,
				};

				asset.formats = asset.formats || [];
				asset.formats.push({
					name: this.name,
					isPlaceholder: true,
				});
				this.closeDialog();

				return assetService
					.updateAssetAsync(
						asset.id,
						edit => {
							edit.createFormat(formatOptions);
						},
						cancellationToken,
						shareToken
					)
					.then(() => assetService.getAssetAsync(asset.id, undefined, cancellationToken))
					.then(assetFromServer => {
						asset.formats = assetFromServer.formats;
					});
			},
		};
	}

	const allFormats = {
		vector: [
			{
				type: 'image/svg+xml',
				name: 'SVG',
			},
			{
				type: 'application/pdf',
				name: 'PDF',
			},
			{
				type: 'image/eps',
				name: 'EPS',
			},
		],
		image: [
			{
				type: 'image/png',
				name: 'PNG',
			},
			{
				type: 'image/jpeg',
				name: 'JPEG',
			},
			{
				type: 'image/jp2',
				name: 'JPEG 2000',
			},
			{
				type: 'image/gif',
				name: 'GIF',
			},
			{
				type: 'image/webp',
				name: 'WebP',
			},
		],
		audio: [
			{
				type: 'audio/mpeg',
				name: 'MP3',
			},
			{
				type: 'audio/ogg',
				name: 'OGG Vorbis',
			},
		],
		video: [
			{
				type: 'video/mp4; codecs="avc1, aac"',
				name: 'MP4 (H.264, AAC)',
			},
			{
				type: 'video/mp4; codecs="hvc1, aac"',
				name: 'MP4 (H.265, AAC)',
			},
			{
				type: 'video/webm; codecs="vp8, vorbis"',
				name: 'WebM (VP8, Vorbis)',
			},
			{
				type: 'video/webm; codecs="vp9, opus"',
				name: 'WebM (VP9, Opus)',
			},
		],
	};

	return {
		createAddFormatModel,
	};
}
