import * as angular from 'angular';

angular.module('app').factory('searchTextService', searchTextService);

function searchTextService() {
	let inputTextValue;

	const service: {
		searchText?: string;
		inputText?: string;
		inputModifiedTime: number;
		clear: () => void;
	} = {
		/** Always represents the current search filter */
		searchText: undefined,
		/** Always represents the text in the search field */
		get inputText() {
			return inputTextValue;
		},
		set inputText(value) {
			service.inputModifiedTime = Date.now();
			inputTextValue = value;
		},
		inputModifiedTime: Date.now(),
		clear() {
			service.searchText = undefined;
			service.inputText = undefined;
		},
	};

	return service;
}

export type ISearchTextService = ReturnType<typeof searchTextService>;
