import * as angular from 'angular';
import { IAppSettings } from './appSettings';
import { CancellationToken } from './cancellationService';

angular.module('app').factory('locationService', locationService);

function locationService(
	$q: angular.IQService,
	$window: angular.IWindowService,
	appSettings: IAppSettings,
	lazyLoader
) {
	let loadPromise: Promise<any> | undefined;

	return {
		geocodeAddressAsync(address: string, cancellationToken: CancellationToken) {
			const deferred = $q.defer();

			if (cancellationToken) {
				cancellationToken.whenCanceled(() => {
					deferred.reject('abort');
				});
			}

			if (!loadPromise) {
				loadPromise = new Promise(resolve => {
					$window.initializeGoogleMaps = function () {
						delete $window.initializeGoogleMaps;
						resolve($window.google.maps);
					};

					lazyLoader.loadServiceAsync(
						`https://maps.googleapis.com/maps/api/js?key=${appSettings.mapsApiKey}&callback=initializeGoogleMaps`
					);
				});
			}

			loadPromise.then(gmaps => {
				const geocoder = new gmaps.Geocoder();

				geocoder.geocode({ address }, (results, status) => {
					if (status === 'OK' || status === 'ZERO_RESULTS') {
						deferred.resolve({
							results,
						});
					} else {
						deferred.reject(status);
					}
				});
			});

			return deferred.promise;
		},
	};
}
