import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').filter('fileInfo', fileInfo);

function fileInfo($filter) {
	return function (file, spec, fieldSeparator) {
		if (!file) {
			return null;
		}

		fieldSeparator = fieldSeparator || ' &thinsp;&bull;&thinsp; ';

		const render = getRenderer(file);

		const result = _.join(
			spec
				.split(' ')
				.map(render)
				.filter(x => !!x),
			fieldSeparator
		);

		return result;
	};

	function getRenderer(file) {
		function renderMimeType(part) {
			return $filter('mimeType')(file.mediaType, part);
		}

		const renderer = {
			mimeType: renderMimeType,

			'mimeType:subtype': () => {
				const subtype = _.partial(renderMimeType, 'subtype')();
				return [subtype, file.metadata?.video?.codec, file.metadata?.audio?.codec]
					.filter(x => !!x)
					.map(x => String(x).toUpperCase())
					.join(' ');
			},

			duration() {
				const duration = file.metadata && file.metadata.duration;
				return duration && $filter('duration')(duration);
			},

			dimensions() {
				const dimensions = file.dimensions;
				return (
					dimensions && `${dimensions.width.toString()} &times; ${dimensions.height.toString()}`
				);
			},

			size() {
				const size = file.byteCount;
				return size && `${$filter('number')(size / 1000, 0)} KB`;
			},

			lastModified() {
				const lastModified = file.lastModified || (file.metadata && file.metadata.lastModified);
				return lastModified && $filter('relativeDate')(lastModified);
			},

			pageCount() {
				const pageCount = file.metadata && file.metadata.pageCount;
				return _.isNumber(pageCount) && pageCount + (pageCount === 1 ? ' page' : ' pages');
			},
		};

		return function (component) {
			const f = renderer[component];
			return f && f();
		};
	}
}
