import * as angular from 'angular';
import { IAppSettings } from './appSettings';
import { IUriUtility, IAgentMapperService } from '../helpers';

angular.module('app').factory('accountService', accountService);

function accountService(
	$http: angular.IHttpService,
	appSettings: IAppSettings,
	agentMapper: IAgentMapperService,
	uriUtility: IUriUtility
) {
	async function getAccountDetailsAsync(id: string) {
		return $http
			.get<any>(
				uriUtility.fromPattern(`${appSettings.accountServicesBaseUri}accounts/{id}`, { id })
			)
			.then(response => agentMapper.mapDetails(response.data));
	}

	return {
		getAccountDetailsAsync,
	};
}

export type IAccountService = ReturnType<typeof accountService>;
