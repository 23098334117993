import * as angular from 'angular';
import { IUtility } from '../helpers';

angular.module('app').filter('formattedMetadataKey', formattedMetadataKey);

function formattedMetadataKey(utility: IUtility) {
	return function (key: string | undefined, separator?: string) {
		if (!key) {
			return key;
		}

		const splitKey = key.split(separator || ':');
		return utility.capitalize(utility.breakCamelCaseWords(splitKey[splitKey.length - 1]));
	};
}

export type IFormattedMetadataKeyFilter = ReturnType<typeof formattedMetadataKey>;
