import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('familyService', familyService);

function familyService($filter) {
	const families = [
		{
			name: 'album',
			displayName: $filter('translate')('assetMetadata.album'),
			properties: [{ name: 'title', type: 'string' }],
		},
		{
			name: 'logosMedia',
			displayName: $filter('translate')('assetMetadata.logosMedia'),
			properties: [
				{
					name: 'collection',
					type: 'completion',
					typeData: { fieldName: 'logosMedia.collection' },
					multiple: true,
				},
				{ name: 'layout', type: 'completion', typeData: { fieldName: 'logosMedia.layout' } },
				{ name: 'purpose', type: 'completion', typeData: { fieldName: 'logosMedia.purpose' } },
				{ name: 'resourceIds', type: 'string', multiple: true },
				{ name: 'resourceLocations', type: 'string', multiple: true, separator: ' ' },
				{ name: 'authorTokens', type: 'string', multiple: true, separator: ' ' },
				{
					name: 'guidebookHeadings',
					type: 'guidebookHeadings',
					multiple: true,
					typeData: { fieldName: 'logosMedia.guidebookHeadings' },
				},
				{
					name: 'notable',
					type: 'tag',
					multiple: true,
					typeData: { fieldName: 'logosMedia.notable.plain' },
				},
				{ name: 'set', type: 'completion', typeData: { fieldName: 'logosMedia.set' } },
				{ name: 'series', type: 'completion', typeData: { fieldName: 'logosMedia.series' } },
				{ name: 'primary', type: 'boolean' },
			],
		},
		{
			name: 'verseOfTheDay',
			displayName: $filter('translate')('assetMetadata.verseOfTheDay'),
			properties: [
				{ name: 'reference', type: 'bibleReference' },
				{ name: 'bibleVersion', type: 'string' },
				{ name: 'text', type: 'string' },
				{ name: 'language', type: 'string' },
				{ name: 'dates', type: 'date', multiple: true },
				{ name: 'holidays', type: 'string', multiple: true },
				{ name: 'layout', type: 'string' },
				{ name: 'branding', type: 'string' },
				{ name: 'id', type: 'string' },
			],
		},
		{
			name: 'proclaimMedia',
			displayName: $filter('translate')('assetMetadata.proclaimMedia'),
			properties: [
				{ name: 'kind', type: 'completion', typeData: { fieldName: 'proclaimMedia.kind' } },
				{ name: 'thumbnailOffset', type: 'number' },
			],
		},
		{
			name: 'faithlifeTVSimple',
			displayName: $filter('translate')('assetMetadata.faithlifeTVSimple'),
		},
		{
			name: 'faithlifeTV',
			displayName: $filter('translate')('assetMetadata.faithlifeTV'),
			properties: [
				{
					name: 'overrideTitle',
					type: 'string',
				},
				{
					name: 'overrideDescription',
					type: 'multilinestring',
				},
				{
					name: 'category',
					type: 'completion',
					typeData: { fieldName: 'faithlifeTV.category' },
					multiple: true,
				},
				{ name: 'kind', type: 'completion', typeData: { fieldName: 'faithlifeTV.kind' } },
				{
					name: 'covers',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
					multiple: true,
					separator: ' ',
				},
				{
					name: 'overrideCovers',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
					multiple: true,
					separator: ' ',
				},
				{
					name: 'subItems',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
					multiple: true,
					separator: ' ',
				},
				{ name: 'licenseId', type: 'completion', typeData: { fieldName: 'faithlifeTV.licenseId' } },
				{
					name: 'distributor',
					type: 'completion',
					typeData: { fieldName: 'faithlifeTV.distributor' },
				},
				{ name: 'sectionTitle', type: 'string' },
				{ name: 'distributorId', type: 'string' },
				{ name: 'groupId', type: 'string' },
				{ name: 'airDates', type: 'date', multiple: true, separator: ';' },
				{
					name: 'trailers',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
					multiple: true,
					separator: ' ',
				},
				{
					name: 'captions',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
					multiple: true,
					separator: ' ',
				},
				{
					name: 'alternate',
					type: 'completion',
					typeData: { fieldName: 'id', completionType: 'asset' },
				},
				{ name: 'productUri', type: 'string' },
				{ name: 'startTime', type: 'number' },
				{ name: 'formats', type: 'string' },
				{ name: 'categoryWeight', type: 'number' },
			],
		},
		{
			name: 'mediaServicesUserOwnedContent',
			displayName: $filter('translate')('assetMetadata.mediaServicesUserOwnedContent'),
			properties: [
				{ name: 'uploadPlatform', type: 'string' },
				{ name: 'ownerId', type: 'number' },
			],
		},
		{
			name: 'webImage',
			displayName: $filter('translate')('assetMetadata.webImage'),
			properties: [
				{
					name: 'collection',
					type: 'completion',
					typeData: { fieldName: 'webImage.collection' },
					multiple: true,
				},
				{ name: 'set', type: 'string' },
				{ name: 'mediaKind', type: 'string' },
				{ name: 'pageUrl', type: 'string' },
				{ name: 'url', type: 'string' },
				{ name: 'width', type: 'number' },
				{ name: 'height', type: 'number' },
				{ name: 'thumbnailName', type: 'string' },
				{ name: 'dateFetched', type: 'date' },
				{ name: 'recallReason', type: 'string' },
			],
		},
		{
			name: 'series',
			displayName: $filter('translate')('assetMetadata.mediaSeries'),
			properties: [
				{ name: 'collection', type: 'completion', typeData: { fieldName: 'series.collection' } },
				{ name: 'episode', type: 'number' },
			],
		},
		{
			name: 'logosMediaResource',
			displayName: $filter('translate')('assetMetadata.logosMediaResource'),
			properties: [
				{ name: 'resourceId', type: 'string' },
				{ name: 'resourceVersion', type: 'string' },
				{ name: 'articleName', type: 'string' },
			],
		},
	];

	return {
		findFamilies(q) {
			return _.cloneDeep(
				_.filter(
					families,
					family => !q || family.displayName.toLowerCase().indexOf(q.toLowerCase()) !== -1
				)
			);
		},
		getFamily(name) {
			return _.cloneDeep(_.find(families, family => family.name === name));
		},
		getFamilies(names) {
			return _.cloneDeep(_.filter(families, family => !names || _.includes(names, family.name)));
		},
	};
}
