import * as angular from 'angular';

angular.module('app').directive('assetPreviewHeader', assetPreviewHeader);

function assetPreviewHeader() {
	return {
		restrict: 'E',
		scope: true,
		templateUrl: require('../../views/templates/assetPreviewHeader.html'),
	};
}
