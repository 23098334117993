import * as angular from 'angular';

angular.module('app').directive('checkBox', checkBox);

function checkBox(): angular.IDirective {
	return {
		restrict: 'E',
		replace: true,
		template:
			'<button type="button" class="checkbox-container" ng-disabled="disabled" ng-click="ngModel = !ngModel" role="checkbox"><div class="checkbox" ng-class="{ disabled: disabled }"><div class="checked-indicator" ng-class="{ checked: ngModel }"></div></div><div class="checkbox-label" ng-class="{ disabled: disabled }">{{label}}</div></button>',
		scope: {
			label: '@',
			ngModel: '=',
			disabled: '<',
		},
	};
}
