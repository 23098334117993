import * as angular from 'angular';

angular.module('app').factory('geometryUtility', geometryUtility);

function geometryUtility() {
	function getBestFitForConstraint(
		constraintWidth: number | undefined,
		constraintHeight: number | undefined,
		width: number | undefined,
		height: number | undefined
	) {
		if (!constraintHeight && !constraintWidth) {
			return { width, height };
		}

		let bounds: { width: number; height: number };
		const constraintAspectRatio =
			constraintWidth && constraintHeight ? constraintWidth / constraintHeight : undefined;
		const aspectRatio = width && height ? width / height : undefined;

		if (constraintWidth && (!constraintHeight || aspectRatio! > constraintAspectRatio!)) {
			const displayWidth = Math.min(width!, constraintWidth);
			bounds = {
				width: displayWidth,
				height: displayWidth / aspectRatio!,
			};
		} else {
			const displayHeight = Math.min(height!, constraintHeight!);
			bounds = {
				width: displayHeight * aspectRatio!,
				height: displayHeight,
			};
		}

		return bounds;
	}

	return {
		getBestFitForConstraint,
	};
}

export type IGeometryUtility = ReturnType<typeof geometryUtility>;
