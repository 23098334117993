import * as angular from 'angular';

angular.module('app').filter('assetKind', assetKind);

function assetKind(utility) {
	const formattedKinds = {
		pdf: 'PDF',
	};

	return function (aKind) {
		return aKind && (formattedKinds[aKind] || utility.capitalize(aKind));
	};
}
