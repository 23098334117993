import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('locationEditor', locationEditor);

function locationEditor($timeout, locationService, cancellationService, uriUtility, appSettings) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: require('../../views/templates/locationEditor.html'),
		scope: {
			source: '=',
			focusInput: '@',
			isReadonly: '=?',
		},
		link(scope, element) {
			const source = scope.source || {};
			const model = {
				name:
					source.name ||
					_.compact([
						source.naturalFeature,
						source.address,
						source.city,
						source.state,
						source.country,
					]).join(', '),
				width: 200,
				height: 200,
			};

			function getAddressComponent(addressComponents, name) {
				return _.find(addressComponents, c => _.includes(c.types, name));
			}

			function updateSource(loc) {
				scope.source = scope.source || {};
				scope.source.name = null;
				scope.source.naturalFeature = loc.naturalFeature;
				scope.source.address = loc.address;
				scope.source.city = loc.city;
				scope.source.state = loc.state;
				scope.source.country = loc.country;
				scope.source.point = loc.point;
			}

			function setLocation(result) {
				if (!result) {
					updateSource({
						naturalFeature: null,
						address: null,
						city: null,
						state: null,
						country: null,
						point: null,
					});
					return;
				}

				const location = result.geometry.location;

				const naturalFeature = getAddressComponent(result.address_components, 'natural_feature');
				const streetNumber = getAddressComponent(result.address_components, 'street_number');
				const street = getAddressComponent(result.address_components, 'route');
				const city = getAddressComponent(result.address_components, 'locality');
				const state = getAddressComponent(result.address_components, 'administrative_area_level_1');
				const country = getAddressComponent(result.address_components, 'country');

				const address = _.compact([
					streetNumber && streetNumber.short_name,
					street && street.short_name,
				]).join(' ');

				updateSource({
					naturalFeature: (naturalFeature && naturalFeature.short_name) || null,
					address: address || null,
					city: (city && city.short_name) || null,
					state: (state && state.short_name) || null,
					country: (country && country.long_name) || null,
					point: {
						lat: location.lat(),
						lon: location.lng(),
					},
				});
			}

			function getMapDestination() {
				const location = scope.source || {};
				let point = location.point;

				// support legacy location metadata until it is fixed
				if (
					!point &&
					typeof location.latitude !== 'undefined' &&
					typeof location.longitude !== 'undefined'
				) {
					point = { lat: location.latitude, lon: location.longitude };
				}

				// natural feature e.g. Rügen, Germany
				const address = _.compact([
					location.naturalFeature,
					location.address,
					location.city,
					location.state,
					location.country,
				]).join(', ');
				if (!point && !address) {
					return null;
				}

				const coords = point ? `${point.lat},${point.lon}` : null;

				return address || coords;
			}

			scope.getMapSrc = function () {
				const loc = getMapDestination();
				if (!loc) {
					return null;
				}

				return uriUtility.fromPattern('https://maps.googleapis.com/maps/api/staticmap', {
					center: loc,
					markers: loc,
					zoom: 11,
					size: `${model.width}x${model.height}`,
					scale: 2,
					sensor: false,
					key: appSettings.mapsApiKey,
				});
			};

			scope.getMapLinkSrc = function () {
				return uriUtility.fromPattern('https://maps.google.com', { q: getMapDestination() });
			};

			let cancellationSource;

			const querySoon = _.debounce(q => {
				if (cancellationSource) {
					cancellationSource.cancel();
				}

				cancellationSource = cancellationService.createCancellationSource();

				if (!q) {
					$timeout(() => {
						scope.source = null;
					});
					return;
				}

				locationService
					.geocodeAddressAsync(q)
					.then(response => {
						scope.source = scope.source || {};

						if (
							response.results &&
							response.results[0] &&
							(!response.results[0].partial_match || response.results.length === 1)
						) {
							setLocation(response.results[0]);
						} else {
							setLocation(null);
							scope.source.name = q;
						}
					})
					.catch(reason => {
						if (reason !== 'abort') {
							setLocation(null);
							scope.source.name = q;
						}
					});
			}, appSettings.inputDebounceTimeout);

			scope.$watch('model.name', (name, oldName) => {
				if (name === oldName) {
					return;
				}

				querySoon(name);
			});

			scope.model = model;

			if (scope.focusInput) {
				element.find('input').focus();
			}
		},
	};
}
