import * as angular from 'angular';
import { IAdapter, IDataSource } from 'angular-ui-scroll';
import * as _ from 'lodash';

angular.module('app').component('scrollingSelect', {
	templateUrl: require('../../views/templates/scrollingSelect.html'),
	controller: ScrollingSelect,
	bindings: {
		initialSelection: '<',
		filteredDataSource: '<',
		onSelect: '&',
	},
	transclude: {
		/**
		 * Displayed when dropdown is closed, to the left of the down arrow
		 *
		 * Use $parent.selectedItem to get selected object
		 */
		chosen: 'scrollingSelectChosen',
		/**
		 * Repeated for every row in open dropdown
		 *
		 * Use $parent.rowItem to get object for row
		 */
		row: 'scrollingSelectRow',
	},
});

interface IScrollingSelect<T> extends angular.IController {
	initialSelection: T;
	filteredDataSource: IFilteredDataSource<T>;
	onSelect: (args: { item: T }) => void;
	selectItem: (item: T, index: number) => void;
	selectedItem: T;
	isExpanded: boolean;
	datasource: IDataSource<T>;
	scrollAdapter: IAdapter<T>;
	expandDropdown: () => void;
	contractDropdown: () => void;
	onSearch: () => void;
	searchQuery: string;
	isLoading: { top: boolean; bottom: boolean };
	barElemId: string;
	dropdownElemId: string;
	dropdownHeight: string | number;
	highlightedIndex: number;
}

export interface IFilteredDataSource<T> {
	getSliceAsync(index: number, count: number, query?: string): Promise<T[]>;
	indexOfAsync(item: T, query?: string): Promise<number>;
}

type DataType = unknown;

function ScrollingSelect(
	this: IScrollingSelect<DataType>,
	$scope: angular.IScope,
	$timeout: angular.ITimeoutService
) {
	const listeners: (() => void)[] = [];

	const ctrl = this;
	ctrl.isExpanded = false;
	ctrl.barElemId = `bar-elem-${$scope.$id}`;
	ctrl.dropdownElemId = `dropdown-elem-${$scope.$id}`;
	ctrl.highlightedIndex = -1;
	setAutoHeight(false);

	function getDataSource(): IDataSource<DataType> {
		return {
			get: (index, count, success) =>
				ctrl.filteredDataSource.getSliceAsync(index, count, ctrl.searchQuery).then(success),
		};
	}

	function highlightedIsFirst() {
		return (
			ctrl.scrollAdapter.isBOF &&
			_.isEqual(ctrl.scrollAdapter.topVisible, ctrl.scrollAdapter.bufferFirst) &&
			ctrl.scrollAdapter.topVisibleScope.$index === ctrl.highlightedIndex
		);
	}

	function highlightedIsLast() {
		return (
			ctrl.scrollAdapter.isEOF &&
			_.isEqual(ctrl.scrollAdapter.bottomVisible, ctrl.scrollAdapter.bufferLast) &&
			ctrl.scrollAdapter.bottomVisibleScope.$index === ctrl.highlightedIndex
		);
	}

	function addArrowListener(element: JQuery) {
		element.keydown(async event => {
			if (event.key === 'ArrowDown') {
				event.preventDefault();
				if (!highlightedIsLast()) {
					ctrl.highlightedIndex++;
					$scope.$digest();
				}
			} else if (event.key === 'ArrowUp') {
				event.preventDefault();
				if (!highlightedIsFirst() && ctrl.highlightedIndex >= -1) {
					ctrl.highlightedIndex--;
					$scope.$digest();
				}
			} else if (event.key === 'Enter') {
				const [item] = await ctrl.filteredDataSource.getSliceAsync(
					ctrl.highlightedIndex,
					1,
					ctrl.searchQuery
				);
				if (item) {
					ctrl.selectItem(item, ctrl.highlightedIndex);
				}
			}
		});
		listeners.push(() => element.off('keydown'));
	}

	function setAutoHeight(auto: boolean) {
		ctrl.dropdownHeight = auto ? 'auto' : 400;
	}

	ctrl.onSearch = _.debounce(() => {
		ctrl.scrollAdapter.reload(0);
		ctrl.highlightedIndex = -1;
	}, 100);

	ctrl.selectItem = async (item, index) => {
		const newIndex = ctrl.searchQuery ? await ctrl.filteredDataSource.indexOfAsync(item) : index;
		ctrl.selectedItem = $scope.selectedItem = item;
		ctrl.contractDropdown();
		ctrl.scrollAdapter.reload(newIndex);
		ctrl.highlightedIndex = newIndex;
		ctrl.onSelect({ item });
	};

	ctrl.expandDropdown = function () {
		const barElem = angular.element(`#${ctrl.barElemId}`);
		const dropdownElem = angular.element(`#${ctrl.dropdownElemId}`);
		const inputElem = dropdownElem.find('.select2-input').eq(0);
		const offset = barElem.offset();
		const width = barElem.width();
		const top = ((offset && offset.top) || 0) + barElem.height();
		const left = (offset && offset.left) || 0;
		dropdownElem.css('top', `${top}px`);
		dropdownElem.css('left', `${left}px`);
		dropdownElem.css('width', `${width}px`);
		ctrl.isExpanded = true;
		ctrl.scrollAdapter.reload();
		setAutoHeight(true);
		$timeout(() => {
			inputElem.focus();
			addArrowListener(inputElem);
		});
		addHighlightWatcher(dropdownElem);
	};

	ctrl.contractDropdown = () => {
		ctrl.isExpanded = false;
		setAutoHeight(false);
		ctrl.searchQuery = '';
		listeners.forEach(unbind => unbind());
	};

	// if highlighted option is outside of view, scroll to it
	function addHighlightWatcher(parentElement: JQuery) {
		const scrollToHighlighted = _.debounce(() => {
			parentElement.find('.select2-highlighted').get(0).scrollIntoView();
		}, 50);

		const unwatch = $scope.$watch(
			() => ctrl.highlightedIndex,
			value => {
				if (
					value >= 0 &&
					ctrl.scrollAdapter.topVisibleScope &&
					ctrl.scrollAdapter.bottomVisibleScope
				) {
					const topIndex = ctrl.scrollAdapter.topVisibleScope.$index;
					const bottomIndex = ctrl.scrollAdapter.bottomVisibleScope.$index;
					if (value < topIndex || value > bottomIndex) {
						scrollToHighlighted();
					}
				}
			}
		);
		listeners.push(unwatch);
	}

	ctrl.$onInit = () => {
		ctrl.datasource = getDataSource();
	};

	$scope.$watch(
		() => ctrl.initialSelection,
		initialSelection => {
			if (!_.isEqual(initialSelection, ctrl.selectedItem)) {
				ctrl.selectedItem = initialSelection;
			}
		}
	);
}
