import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('facetList', facetList);

function facetList(facetQueryService, boardsModel, appSettings, embedService, $filter) {
	const facetNames = {
		'tags.text': $filter('translate')('facetList.tags'),
		readPermission: $filter('translate')('facetList.canView'),
		editPermission: $filter('translate')('facetList.canEdit'),
		uploader: $filter('translate')('assetMetadata.uploader'),
		family: $filter('translate')('facetList.family'),
		kind: $filter('translate')('assetMetadata.kind'),
		status: $filter('translate')('assetMetadata.status'),
	};

	return {
		restrict: 'AE',
		replace: true,
		scope: {
			facets: '=',
			hitsCount: '=',
		},
		templateUrl: require('../../views/templates/facetList.html'),
		controller($scope, $state) {
			let expandedFacets = [];
			$scope.assetListState = embedService.isEmbedded
				? 'assetsEmbed'
				: $state.is('boardSharedDetails')
				? 'boardSharedDetails'
				: 'assets';

			function createFacetTerm(facet, term) {
				return { facet: facet.name, term: term.value };
			}

			function createShouldShowFacetTerm(facet) {
				return function (term) {
					return !facetQueryService.containsFilter(createFacetTerm(facet, term));
				};
			}

			function shouldShowFacet(facet) {
				return _.some(facet.terms, createShouldShowFacetTerm(facet));
			}

			$scope.termLimit = appSettings.facetTermDisplayLimit;
			$scope.createFacetTerm = createFacetTerm;
			$scope.isExpanded = function (facet) {
				return _.includes(expandedFacets, facet.name);
			};
			$scope.toggleExpandedFacet = function (facet) {
				expandedFacets = _.xor(expandedFacets, [facet.name]);
			};

			$scope.getNewFilters = function (filter) {
				return facetQueryService.containsFilter(filter)
					? facetQueryService.filters
					: facetQueryService.filters.concat([filter]);
			};

			$scope.$watchCollection('facets', value => {
				$scope.shownFacets = _.filter(value, shouldShowFacet).map(f => {
					if (boardsModel.currentBoardId === 'myassets' && f.name === 'uploader') {
						// return empty object to hide uploader facet list if my assets board is selected
						return {};
					}
					const facet = angular.copy(f);
					facet.displayName = facetNames[facet.name] || facet.name;
					facet.terms = _.filter(facet.terms, createShouldShowFacetTerm(facet));
					return facet;
				});
			});

			$scope.$watch(
				() => boardsModel.currentBoardId,
				boardId => {
					$scope.currentBoardId = boardId;
				}
			);
		},
	};
}
