import { IAsset } from './assetMapper';

export function isSmartMedia(asset: IAsset | null | undefined) {
	return !!asset?.families?.some(x => x?.name === 'smartMedia');
}

export function isSmartMediaLockup(asset: IAsset | null | undefined) {
	return !!asset?.other?.some(x => x?.name === 'smartMediaLockup');
}

export function isRoomLayout(asset: IAsset | null | undefined) {
	const styles = asset?.originalMetadata?.smartMedia?.styles;
	return !!(Array.isArray(styles) && styles[0] && styles[0].kind === 'RoomLayout');
}
