import * as angular from 'angular';

angular.module('app').directive('fastInclude', fastInclude);

function fastInclude($cacheFactory, $compile, $http, $templateCache) {
	const fastIncludeCache = $cacheFactory.get('fast-include') || $cacheFactory('fast-include');

	function renderTemplate(compiledTemplate, scope, elem) {
		compiledTemplate(scope, clone => {
			elem.append(clone);
		});
	}

	return {
		restrict: 'E',
		link(scope, elem, attrs) {
			if (!attrs.src) {
				return;
			}

			let compiledTemplate = fastIncludeCache.get(attrs.src);
			if (compiledTemplate) {
				renderTemplate(compiledTemplate, scope, elem);
			} else {
				$http.get(attrs.src, { cache: $templateCache }).then(response => {
					// attempt to load the compiled template from cache again, in case
					// another request completed and populated the cache while waiting
					// for this one
					compiledTemplate = fastIncludeCache.get(attrs.src);
					if (!compiledTemplate && response && response.data) {
						compiledTemplate = $compile(response.data);
						fastIncludeCache.put(attrs.src, compiledTemplate);
					}

					if (compiledTemplate) {
						renderTemplate(compiledTemplate, scope, elem);
					}
				});
			}
		},
	};
}
