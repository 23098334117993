import * as angular from 'angular';
import { Bucket, IBucketService } from './bucketService';
import { UserModel } from './userModel';

angular.module('app').factory('pageTitleService', pageTitleService);

function pageTitleService(bucketService: IBucketService, userModel: UserModel) {
	function getBucketName(bucket: Bucket) {
		return bucket.group?.name || bucket.name;
	}

	function appendBucketToPageTitle(pageTitle: string) {
		const currentBucket = bucketService.getCurrentBucket();

		const bucketName = currentBucket && getBucketName(currentBucket);
		if (bucketName) {
			pageTitle = `${pageTitle} - ${bucketName}`;
		}

		return pageTitle;
	}

	async function setPageTitleFromCurrentState(currentStateTitle: string) {
		const user = await userModel.getCurrentUserAsync();
		if (user.isAnonymous) {
			return currentStateTitle;
		}

		const currentBucket = await bucketService.getCurrentBucketAsync();
		const bucketName = getBucketName(currentBucket);
		if (bucketName) {
			if (currentStateTitle === 'Home') {
				currentStateTitle = bucketName;
			} else {
				currentStateTitle = `${currentStateTitle} - ${bucketName}`;
			}
		}

		return currentStateTitle;
	}

	return {
		appendBucketToPageTitle,
		setPageTitleFromCurrentState,
	};
}

export type IPageTitleService = ReturnType<typeof pageTitleService>;
