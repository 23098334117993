import * as angular from 'angular';

angular.module('app').directive('modal', modal);

function modal(hotkeys) {
	return {
		restrict: 'E',
		replace: true,
		transclude: {
			title: '?modalTitle',
			subtitle: '?modalSubtitle',
			actions: '?modalActions',
			content: '?modalContent',
		},
		templateUrl: require('../../views/templates/modal.html'),
		scope: {
			closeModal: '&?',
		},
		link(scope) {
			hotkeys.bindTo(scope).add({
				combo: 'esc',
				description: 'Close upload url menu',
				callback: scope.closeModal,
				allowIn: ['INPUT'],
			});
		},
	};
}
