import * as angular from 'angular';
import { IFacetQueryService } from './facetQueryService';
import { IMimeTypeUtility } from '../helpers';

angular.module('app').service('filterUtility', filterUtility);

interface IBasicFilter {
	isNot: boolean;
	isAnd: boolean;
	isOr: boolean;
	text: string;
}

// 'tree'
interface ITermFilter extends IBasicFilter {
	type: 'term';
}

// '<heb 11>'
interface IReferenceFilter extends IBasicFilter {
	type: 'reference';
}

// 'kind:image'
interface IFieldFilter extends IBasicFilter {
	type: 'field';
	name: string;
}

type Filter = ITermFilter | IReferenceFilter | IFieldFilter;

function filterUtility(
	$log: angular.ILogService,
	facetQueryService: IFacetQueryService,
	mimeTypeUtility: IMimeTypeUtility
) {
	function matchesWithoutPrefix(str: string, prefixes: string[], matches: string[]): boolean {
		if (str.length === 0) {
			return false;
		}
		if (matches.includes(str)) {
			return true;
		}
		if (prefixes.includes(str[0])) {
			return matchesWithoutPrefix(str.substring(1), prefixes, matches);
		}
		return false;
	}

	// 'tree !kind:image NOT grass title:"mustard seed"' => ['tree', '!kind:image', '!grass', 'title:"mustard seed"']
	// can't handle regexes or parentheses in query string
	function splitTerms(str: string): string[] {
		const prefixes = {
			'!': ['NOT', '!', '-'],
			'&': ['AND', '&&'],
			'|': ['OR', '||'],
		};
		const matches = str.match(/(?:[^\s"<,]+|"[^"]*"|<[^>]*>)+/g);

		if (!matches) {
			return [];
		}

		// join combination operators with their following terms
		// ['tree', '!', 'kind:image'] => ['tree', '!kind:image']
		// ['tree', 'AND', 'NOT', 'kind:pdf'] => ['tree', '&!kind:pdf']
		return Array.from(matches).reduce((acc, cur, idx, src) => {
			/*
					Basically, just check to see if the previous array element is either:
					1. one of the array elements from the prefixes object, e.g. '!', '||', 'AND'
					2. the above, but prefixed with one or more of our shorthands, e.g. '!', '&', '|'
				 */
			const previousElement = src[idx - 1];
			const matchingPrefix =
				previousElement &&
				Object.entries(prefixes).find(([, v]) =>
					matchesWithoutPrefix(previousElement, Object.keys(prefixes), v)
				);

			if (matchingPrefix) {
				const joinedString = matchingPrefix[0] + cur;
				acc.splice(-1, 1, joinedString);
			} else {
				acc.push(cur);
			}

			return acc;
		}, [] as string[]);
	}

	function parsePrefixes(
		term: string
	): { not?: boolean; and?: boolean; or?: boolean; base: string } {
		switch (term[0]) {
			case '!':
				return {
					not: true,
					...parsePrefixes(term.substring(1)),
				};
			case '&':
				return {
					and: true,
					...parsePrefixes(term.substring(1)),
				};
			case '|':
				return {
					or: true,
					...parsePrefixes(term.substring(1)),
				};
			default:
				return {
					base: term || '',
				};
		}
	}

	function parseFilter(term: string): Filter | null {
		if (!term) {
			return null;
		}

		const { not, and, or, base } = parsePrefixes(term);

		const common = {
			isNot: not || false,
			isAnd: and || false,
			isOr: or || false,
		};

		const referenceMatch = base.match(/^<(.*)>$/);
		if (referenceMatch) {
			return {
				type: 'reference',
				text: referenceMatch[1],
				...common,
			};
		}

		if (!term.includes(':')) {
			return {
				type: 'term',
				text: base,
				...common,
			};
		}

		const fieldFilter = facetQueryService.parseFilterParam(base)[0];
		return {
			type: 'field',
			name: fieldFilter.facet.toLowerCase(),
			text: fieldFilter.term.toLowerCase(),
			...common,
		};
	}

	// only handles 'kind' and 'mediatype' at the moment
	function matchesFilter(file: File, filter: Filter): boolean {
		let initialMatch = false;
		if (filter.type === 'field') {
			if (filter.name === 'kind') {
				initialMatch = mimeTypeUtility.getFileKind(file.type) === filter.text;
			} else if (filter.name === 'mediatype') {
				initialMatch = file.type === filter.text;
			} else {
				$log.error(`Unsupported picker filter: "${filter.name}:${filter.text}"`);
			}
		} else {
			$log.error(`Unsupported picker filter: "${filter.text}"`);
		}
		return filter.isNot ? !initialMatch : initialMatch;
	}

	function parseFilters(str: string): Filter[] {
		const terms = splitTerms(str);
		const filters = terms.map(parseFilter).filter(el => el !== null) as Filter[];
		// if this is not the first filter, and there's no OR operator, then assume AND
		return filters.map((val, idx) => ({
			...val,
			isAnd: idx !== 0 && !val.isOr ? true : val.isAnd,
		}));
	}

	function matchesFilters(file: File, filters: Filter[]): boolean {
		if (filters.length === 0) {
			return true;
		}
		const matchResults = filters.map(filter => ({
			...filter,
			isMatching: matchesFilter(file, filter),
		}));
		let matches = false;
		matchResults.forEach(result => {
			if (result.isAnd) {
				matches = matches && result.isMatching;
			} else if (result.isOr) {
				matches = matches || result.isMatching;
			} else {
				matches = result.isMatching;
			}
		});
		return matches;
	}

	return {
		parseFilters,
		matchesFilters,
	};
}

export type IFilterUtility = ReturnType<typeof filterUtility>;
