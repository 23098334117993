import * as angular from 'angular';
import 'jquery-ui/ui/position';
import * as _ from 'lodash';

angular.module('app').directive('dropdownMenu', dropdownMenu);

function dropdownMenu($compile, $parse, $http, $q, $templateCache, $document) {
	const body = $document.find('body');

	return {
		restrict: 'AE',
		scope: {},
		link,
	};

	function link(scope, elem, attrs) {
		const templateFetchAborter = $q.defer();
		let menu = null;
		const getMenuItems = $parse(attrs.menuItems);

		scope.menuLists = [];

		function showMenu(e) {
			if (attrs.secretMenuDefaultAction && !(e.ctrlKey || e.metaKey)) {
				const performDefaultAction = $parse(attrs.secretMenuDefaultAction);
				performDefaultAction(scope.$parent);
				return;
			}
			scope.$apply(() => {
				scope.menuLists = getMenuLists(getMenuItems(scope.$parent));
			});

			menu.appendTo(body).position({
				my: 'left top',
				at: 'left bottom',
				of: elem,
				collision: 'flip',
			});
		}

		function onBodyClick(e) {
			if (e.target !== elem[0]) {
				menu.detach();
			}
		}

		function getMenuLists(items) {
			return _.reduce(
				items,
				(acc, value) => {
					const lastGroup = _.last(acc);
					if (lastGroup && lastGroup.name === value.group) {
						lastGroup.items.push(value);
					} else {
						acc.push({ name: value.group, items: [value] });
					}

					return acc;
				},
				[]
			);
		}

		$http
			.get('views/templates/dropdownMenu.html', {
				timeout: templateFetchAborter.promise,
				cache: $templateCache,
			})
			.then(response => {
				menu = $compile(response.data)(scope);

				elem.click(showMenu);
				body.click(onBodyClick);
			});

		scope.$on('$destroy', () => {
			templateFetchAborter.resolve();

			if (menu) {
				menu.remove();
			}

			body.off('click', onBodyClick);
		});
	}
}
