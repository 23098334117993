import * as angular from 'angular';
import * as $script from 'scriptjs';

angular.module('app').factory('lazyLoader', lazyLoader);

function lazyLoader() {
	const services = {};

	function loadServiceAsync(uri: string): Promise<void> {
		if (!services[uri]) {
			services[uri] = new Promise(resolve => {
				$script(uri, resolve);
			});
		}

		return services[uri];
	}

	return {
		loadServiceAsync,
	};
}
