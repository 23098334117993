import * as angular from 'angular';
import * as ClipboardJS from 'clipboard';

angular.module('app').component('addToClipboardButton', {
	templateUrl: require('../../views/templates/addToClipboardButton.html'),
	controller: addToClipboardButton,
	bindings: {
		textToCopy: '<',
		icon: '<',
		tooltipText: '<',
	},
});

const hideCopiedDelay = 2000;
const hideTooltipDelay = 100;

interface IAddToClipboardButton extends angular.IController {
	textToCopy?: string;
	showIcon?: boolean;
	tooltipText?: string;
}

function addToClipboardButton(
	this: IAddToClipboardButton,
	$element: JQuery,
	$scope: angular.IScope,
	$timeout: angular.ITimeoutService
) {
	const clipboard = new ClipboardJS($element.find('.clipboard-button').get(0));
	$scope.isTooltipVisible = false;
	$scope.copyButtonClicked = false;

	$scope.getTooltipOffset = () => {
		const buttonWidth = $element.find('.add-to-clipboard-button').width();
		const tooltipWidth = $element.find('.copy-button-tooltip-mask').width();
		return {
			left: `-${tooltipWidth / 2 - buttonWidth / 2}px`,
		};
	};

	function showTooltip() {
		if (!$scope.isTooltipVisible) {
			$scope.isTooltipVisible = true;
		}
	}

	function hideTooltip() {
		if ($scope.isTooltipVisible) {
			$scope.isTooltipVisible = false;
		}
	}

	function cancelHideCopiedTimer() {
		if ($scope.hideCopiedTimer) {
			$timeout.cancel($scope.hideCopiedTimer);
		}
	}

	$scope.onCopyButtonMouseOver = function () {
		if ($scope.copyButtonClicked) {
			$scope.copyButtonClicked = false;
		}

		showTooltip();
	};

	$scope.onCopyButtonClick = function () {
		if (!$scope.copyButtonClicked) {
			$scope.copyButtonClicked = true;
		}

		cancelHideCopiedTimer();
		showTooltip();

		$scope.hideCopiedTimer = $timeout(() => {
			if ($scope.copyButtonClicked) {
				hideTooltip();
			}
		}, hideCopiedDelay);
	};

	$scope.onCopyButtonMouseLeave = function () {
		cancelHideCopiedTimer();

		$scope.hideTooltipTimer = $timeout(() => {
			hideTooltip();
		}, hideTooltipDelay);
	};

	this.$onDestroy = function () {
		cancelHideCopiedTimer();

		if ($scope.hideTooltipTimer) {
			$timeout.cancel($scope.hideTooltipDelay);
		}

		clipboard.destroy();
	};
}
