import * as angular from 'angular';
import * as _ from 'lodash';
import { ILogBuffer } from '../services/logBuffer';

declare module 'angular' {
	interface ILogService {
		getLogger(name: string): ILogService;
	}
}

angular.module('app').config(configure);

function configure($provide: angular.auto.IProvideService) {
	$provide.decorator('$log', logDecorator);

	const methods = ['debug', 'log', 'info', 'warn', 'error'];

	function logDecorator($delegate: angular.ILogService, logBuffer: ILogBuffer) {
		// add getLogger method to the original service
		$delegate.getLogger = function (name: string) {
			return getLogger(name);
		};

		return getLogger();

		function getLogger(name?: string) {
			const logger = {};

			// add logger methods that inject additional info into the log message and then delegate to the original implementation
			_.forOwn($delegate, (value, key) => {
				if (_.includes(methods, key)) {
					logger[key] = function (...args) {
						// add additional info to log messages
						const message = createLogMessage(name, _.toArray(args));

						// record the message in the log buffer
						logBuffer.addLog(key, message);

						// pass the message along
						$delegate[key](...message);
					};
				} else {
					logger[key] = value;
				}
			});

			return logger as angular.ILogService;
		}

		function createLogMessage(name: string | undefined, args: any[]) {
			const message = args[0];
			if (_.isString(message)) {
				args[0] = `${`${new Date().toISOString()} ${name || ''}`.trim()} - ${message}`;
			}

			return args;
		}
	}
}
