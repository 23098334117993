import * as angular from 'angular';

angular.module('app').directive('assetFileDownloadLink', assetFileDownloadLink);

function assetFileDownloadLink($window, assetService) {
	return {
		restrict: 'AE',
		scope: {
			assetId: '=',
			file: '=',
			shareToken: '=',
		},
		transclude: true,
		replace: true,
		template:
			'<a ng-href="{{file.downloadUrl | shareToken:shareToken}}" ng-class="{ disabled: !file.id }" download="{{file.name}}" target="_self" drag-file-download drag-file-download-url="file.downloadUrl | shareToken:shareToken" drag-file-download-mime="file.mediaType" drag-file-download-name="file.name" ng-transclude></a>',
		link(scope, elem) {
			elem.click(e => {
				if (scope.file && scope.file.id) {
					assetService.reportAssetDownload(scope.assetId, scope.file.id, scope.shareToken);
					if (
						scope.file.url &&
						($window.navigator.platform.startsWith('Mac') ? e.metaKey : e.ctrlKey)
					) {
						$window.open(scope.file.url);
						e.preventDefault();
					}
				} else {
					e.preventDefault();
				}
			});
		},
	};
}
