import * as angular from 'angular';

angular.module('app').directive('secretLink', secretLink);

function secretLink() {
	return {
		restrict: 'A',
		link(scope, elem) {
			elem.click(e => {
				if (!(e.ctrlKey || e.metaKey)) {
					e.preventDefault();
				}
			});
		},
	};
}
