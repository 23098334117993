import * as angular from 'angular';
import * as _ from 'lodash';

export interface IFacetFilter {
	facet: string;
	term: string;
}

angular.module('app').factory('facetQueryService', facetQueryService);

function facetQueryService() {
	let filters: IFacetFilter[] = [];

	function parseFilterParam(param: string | undefined) {
		if (!param) {
			return [];
		}

		const filterParamsArray: IFacetFilter[] = [];

		let facetStart = 0;
		while (facetStart < param.length) {
			const facetEnd = param.indexOf(':', facetStart);
			if (facetEnd === -1) {
				break;
			}

			const valueQuoted = param[facetEnd + 1] === '"';
			const valueStart = facetEnd + (valueQuoted ? 2 : 1); // after the `:` or `:"`
			let valueEnd = valueStart;
			while (valueEnd < param.length && param[valueEnd] !== (valueQuoted ? '"' : ' ')) {
				// skip escaped characters
				if (param[valueEnd] === '\\') {
					valueEnd++;
				}

				valueEnd++;
			}

			const facet = param.substring(facetStart, facetEnd);
			let value = param.substring(valueStart, valueEnd);
			value = value.replace(/\\([\\"])/g, '$1');
			filterParamsArray.push({ facet, term: value });

			facetStart = valueEnd + 2; // after the `",`
		}

		return _.uniq(filterParamsArray);
	}

	function formatFilter(filter: IFacetFilter) {
		const term = filter.term && filter.term.replace(/([\\"])/g, '\\$1');
		return `${filter.facet}:"${term}"`;
	}

	function containsFilter(filter: IFacetFilter) {
		return filters.includes(filter);
	}

	return {
		get filters() {
			return filters;
		},
		set filters(value) {
			filters = value;
		},
		clear() {
			filters = [];
		},
		parseFilterParam,
		containsFilter,
		formatFilter,
		updateFiltersFromParam(filterParam: string | undefined) {
			filters = parseFilterParam(filterParam);
		},
	};
}

export type IFacetQueryService = ReturnType<typeof facetQueryService>;
