import * as angular from 'angular';

angular.module('app').directive('stopPropagation', stopPropagation);

function stopPropagation() {
	return {
		restrict: 'A',
		link(scope, elem, attrs) {
			const eventName = attrs.stopPropagation;
			if (eventName) {
				elem.on(eventName, e => {
					e.stopPropagation();
				});
			}
		},
	};
}
