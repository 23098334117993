import * as angular from 'angular';

angular.module('app').factory('searchResultsNavigationModel', searchResultsNavigationModel);

function searchResultsNavigationModel() {
	let assetIds: readonly string[] | null = null;
	let currentOffset: number | null = null;

	function hasPrev() {
		return !!(assetIds && assetIds.length && currentOffset !== null && currentOffset > 0);
	}

	function hasNext() {
		return !!(
			assetIds &&
			assetIds.length &&
			currentOffset !== null &&
			currentOffset < assetIds.length - 1
		);
	}

	return {
		setAssetIds(newAssetIds: readonly string[] | null) {
			assetIds = newAssetIds;
			currentOffset = null;
		},
		setCurrentAsset(newCurrentAsset) {
			const index = assetIds ? assetIds.indexOf(newCurrentAsset) : null;
			currentOffset = index !== null && index > -1 ? index : null;
		},
		hasPrev,
		getPrev() {
			return hasPrev() ? assetIds![(currentOffset ?? 0) - 1] : undefined;
		},
		hasNext,
		getNext() {
			return hasNext() ? assetIds![(currentOffset ?? 0) + 1] : undefined;
		},
		getAssetCount() {
			return assetIds ? assetIds.length : 0;
		},
		getCurrentIndex() {
			return currentOffset;
		},
		getCurrentContext() {
			return {
				assetCount: this.getAssetCount(),
				currentIndex: this.getCurrentIndex(),
				hasNext: this.hasNext(),
				next: this.getNext(),
				hasPrev: this.hasPrev(),
				prev: this.getPrev(),
			};
		},
	};
}

export type ISearchResultsNavigationModel = ReturnType<typeof searchResultsNavigationModel>;
