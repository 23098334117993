import * as angular from 'angular';

angular.module('app').directive('fileSelect', fileSelect);

function fileSelect($parse, $rootScope, constants) {
	return {
		restrict: 'E',
		transclude: true,
		templateUrl: require('../../views/templates/fileSelect.html'),
		link(scope, elem, attrs) {
			scope.hasCreatePermission = $rootScope.hasCreatePermission;

			elem.find('input').attr('multiple', attrs.multiple);

			// reading selectAction this way, because when we declare the `scope` property on this directive,
			// the new isolate scope blows away the scope of any directly attached controllers
			const callback = attrs.selectAction && $parse(attrs.selectAction);

			elem.on('change', 'input[type="file"]', function () {
				const files = this.files;

				if (!callback) {
					// if no callback is specified, broadcast the selected files
					$rootScope.$broadcast(constants.filesSelectedEventName, { files });
				} else {
					scope.$apply(() => {
						callback(scope, { files });
					});
				}
			});
		},
	};
}
