import {
	IGroup,
	IGroupDetails,
	IGroupMapperService,
	IUser,
	IUserDetails,
	IUserMapperService,
} from '.';
import * as angular from 'angular';

export interface IAgent {
	user?: IUser;
	group?: IGroup;
}

export interface IAgentDetails {
	user?: IUserDetails;
	group?: IGroupDetails;
}

angular.module('app').factory('agentMapper', agentMapper);

function agentMapper(groupMapper: IGroupMapperService, userMapper: IUserMapperService) {
	function mapAgent(source: undefined): undefined;
	function mapAgent(source: any): IAgent;
	function mapAgent(source: any[]): IAgent[];
	function mapAgent(source: any): IAgent | IAgent[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<IAgent>(mapAgent);
		}

		return {
			user: userMapper.map(source.user),
			group: groupMapper.map(source.group),
		};
	}

	function mapAgentDetails(source: undefined): undefined;
	function mapAgentDetails(source: any): IAgentDetails;
	function mapAgentDetails(source: any): IAgentDetails | undefined {
		if (!source) {
			return undefined;
		}

		return {
			user: userMapper.mapDetails(source.user),
			group: groupMapper.mapDetails(source.group),
		};
	}

	return {
		map: mapAgent,
		mapDetails: mapAgentDetails,
	};
}

export type IAgentMapperService = ReturnType<typeof agentMapper>;
