import * as angular from 'angular';
import { IUriUtility } from './uriUtility';

interface IShareCore {
	id?: string;
	token?: string;
	shareUrl?: string;
}

interface IAssetShare extends IShareCore {
	assetId: string;
}

interface IBoardShare extends IShareCore {
	boardId: string;
}

interface IFilterShare extends IShareCore {
	filterId: string;
}

export type IShare = IAssetShare | IBoardShare | IFilterShare;

angular.module('app').factory('shareMapper', shareMapper);

function shareMapper(uriUtility: IUriUtility) {
	function mapShare(source: undefined): undefined;
	function mapShare(source: any, type: 'board'): IBoardShare;
	function mapShare(source: any, type: 'asset'): IAssetShare;
	function mapShare(source: any, type: 'filter'): IFilterShare;
	function mapShare(source: any): IShare;
	function mapShare(source: any[]): IShare[];
	function mapShare(source: any, type?: string): IShare | IShare[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<IShare>(mapShare);
		}

		const isBoardShare = type === 'board' || source.boardId != null;

		return {
			id: source.id,
			token: source.token,
			assetId: source.assetId,
			boardId: source.boardId,
			filterId: source.filterId,
			shareUrl: uriUtility.fromPattern(
				isBoardShare ? '/shares/boards/{token}' : '/shares/{token}',
				{ token: source.token }
			),
		};
	}

	return {
		map: mapShare,
	};
}

export type IShareMapper = ReturnType<typeof shareMapper>;
