import * as angular from 'angular';

angular.module('app').directive('stringToNumber', stringToNumber);

function stringToNumber() {
	return {
		restrict: 'A',
		require: 'ngModel',
		link(scope, element, attrs, ngModel) {
			ngModel.$formatters.push(value => {
				const floatValue = parseFloat(value);
				return !Number.isNaN(floatValue) && Number.isFinite(floatValue) ? floatValue : null;
			});
		},
	};
}
