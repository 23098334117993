import * as angular from 'angular';

angular.module('app').factory('digestUtility', factory);

function factory($rootScope: angular.IRootScopeService) {
	return {
		enableDigestLogging() {
			const originalDigest = $rootScope.$digest;
			let count = 0;

			$rootScope.$digest = function (...args) {
				/* global console:false */
				const label = `digest ${count}`;

				console.time(label);
				const result = originalDigest.apply($rootScope, Array.prototype.slice.call(this, args));
				console.timeEnd(label);

				count++;

				return result;
			};

			return function disableDigestLogging() {
				$rootScope.$digest = originalDigest;
			};
		},
	};
}

export type IDigestUtility = ReturnType<typeof factory>;
