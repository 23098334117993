import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('boardsList', boardsList);

function boardsList() {
	return {
		restrict: 'E',
		replace: true,
		scope: true,
		templateUrl: require('../../views/templates/boardsList.html'),
		controller,
		controllerAs: 'vm',
	};

	function controller(
		$scope,
		$state,
		$filter,
		appSettings,
		assetService,
		boardService,
		boardsModel,
		bucketService,
		embedService
	) {
		const vm = this;
		let currentBoardId = boardsModel.currentBoardId;

		vm.assetCounts = {};
		vm.assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
		vm.isDeleteBoardModalVisible = false;
		vm.isRenameBoardModalVisible = false;
		vm.isAddBoardModalVisible = false;
		vm.isAddGroupBoardModalVisible = false;
		vm.isShareBoardModalVisible = false;
		vm.showSharePanel = false;
		vm.selectedBoard = null;
		vm.currentBoard = null;

		vm.boards = boardsModel.boards;
		vm.isGroupBoardsExpanded = false;

		$scope.termLimit = appSettings.facetTermDisplayLimit;

		$scope.$watch(
			() => bucketService.getCurrentBucket(),
			bucket => {
				$scope.bucket = bucket;
				loadBoardsAsync();
			}
		);

		$scope.$on('changeBoardCount', (evt, boardId) => {
			updateBoardAssetCount(boardId);
		});

		$scope.$watch(
			() => boardsModel.currentBoardId,
			boardId => {
				if (boardId && vm.boards.length > 0) {
					currentBoardId = boardId;
					vm.currentBoard = vm.boards.find(x => x.id === currentBoardId);
				} else {
					vm.currentBoard = null;
				}
			}
		);

		vm.toggleSharePanel = function () {
			vm.showSharePanel = !vm.showSharePanel;
			// if the share menu was shown a new board share was created and we want to load them again to update the menu options
			if (!vm.showSharePanel) {
				loadBoardsAsync();
			}
		};

		vm.getBoardMenuItems = function (board) {
			const boardItems = [
				{
					text: $filter('translate')('boards.shareBoard'),
					callback() {
						vm.selectedBoard = board;
						vm.isShareBoardModalVisible = true;

						vm.shareBoard = function () {
							vm.toggleSharePanel();
							vm.isShareBoardModalVisible = false;
						};
					},
				},
			];

			if (
				!board.isDefault &&
				!board.isGroupBoard &&
				$scope.bucket.group?.kind !== 'user' &&
				$scope.bucket.editPermission === 'full'
			) {
				boardItems.unshift({
					text: $filter('translate')('boards.enableGroupBoard'),
					callback() {
						toggleGroupBoardAsync(board, true).then(newBoard => {
							board.isGroupBoard = newBoard.isGroupBoard;
						});
					},
				});
			}

			if (
				board.shares?.length > 0 &&
				(!board.isGroupBoard || $scope.currentUser.id === board.creation?.agent?.user?.id)
			) {
				boardItems.unshift(
					{
						text: $filter('translate')('boards.stats'),
						callback() {
							$state.go('boardStats', { boardId: board.id });
						},
					},
					{ kind: 'separator' }
				);
			}

			if (!board.isGroupBoard || $scope.currentUser.id === board.creation?.agent?.user?.id) {
				if (board.isGroupBoard) {
					boardItems.push({
						text: $filter('translate')('boards.enablePrivateBoard'),
						callback() {
							toggleGroupBoardAsync(board, false).then(newBoard => {
								board.isGroupBoard = newBoard.isGroupBoard;
							});
						},
					});
				}
				boardItems.push(
					{ kind: 'separator' },
					{
						text: $filter('translate')('boards.renameBoard'),
						callback() {
							vm.selectedBoard = board.name;
							vm.isRenameBoardModalVisible = true;

							vm.renameBoard = function (newName) {
								if (newName) {
									board.name = newName;
									renameBoardAsync(board, newName);
									vm.isRenameBoardModalVisible = false;
								}
							};
						},
					},
					{
						text: $filter('translate')('boards.deleteBoard'),
						callback() {
							vm.selectedBoard = board.name;
							vm.isDeleteBoardModalVisible = true;

							vm.deleteBoard = function () {
								_.remove(vm.boards, board);
								deleteBoardAsync(board.id);
								vm.isDeleteBoardModalVisible = false;
							};
						},
					}
				);
			}

			return boardItems;
		};

		vm.addNewBoard = function (boardName, isGroupBoard = false) {
			if (!boardName) {
				return null;
			}

			return boardService.createBoardAsync(boardName, null, isGroupBoard).then(board => {
				vm.isAddBoardModalVisible = false;
				vm.isAddGroupBoardModalVisible = false;
				const indexBeforeDefaults = _.findIndex(vm.boards, 'isDefault');
				board.creation.agent = { user: $scope.currentUser };
				vm.boards.splice(indexBeforeDefaults, 0, board);
				$scope.$apply();
			});
		};

		function loadBoardsAsync() {
			boardService.listBoardsAsync().then(data => {
				angular.copy(data.boards, vm.boards);
				if (currentBoardId) {
					vm.currentBoard = vm.boards.find(x => x.id === currentBoardId);
				}
				vm.boards.forEach(board => {
					board.shares = board.shares?.filter(
						share => !share.expires || new Date(share.expires).getTime() > new Date().getTime()
					);
					updateBoardAssetCount(board.id);
				});
			});
		}

		async function updateBoardAssetCount(boardId) {
			let options = { q: '', limit: 0 };
			options = boardService.applyBoardToSearch(options, boardId);
			const result = await assetService.searchAssetsAsync(options);
			vm.assetCounts[boardId] = result.total;
			$scope.$apply();
		}

		function toggleGroupBoardAsync(board, isGroupBoard) {
			return boardService.editBoardAsync(board.id, {
				name: board.name,
				isGroupBoard,
			});
		}

		function renameBoardAsync(board, newName) {
			return boardService.editBoardAsync(board.id, { name: newName });
		}

		function deleteBoardAsync(boardId) {
			return boardService.deleteBoardAsync(boardId);
		}
	}
}
