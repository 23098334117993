import * as angular from 'angular';
import * as _ from 'lodash';

const app = angular.module('app');

// eslint-disable-next-line angular/module-getter
app.directive('assetVideoPlayer', assetVideoPlayer);

function assetVideoPlayer($timeout, muteService) {
	const loadComponent = new Promise(resolve => {
		require.ensure(['../components/faithlifeVideoPlayer'], () => {
			const { FaithlifeVideoPlayer } = require('../components/faithlifeVideoPlayer');

			app.compileProvider.component('faithlifeVideoPlayer', FaithlifeVideoPlayer);
			resolve(true);
		});
	});
	return {
		restrict: 'E',
		replace: true,
		scope: {
			width: '=',
			height: '=',
			posterUri: '=',
			muted: '=',
			sources: '=',
			showControls: '=',
			showMuteButton: '<',
			shareToken: '=',
			playing: '=',
			disableFullscreen: '=?',
			enableAutoplay: '=?',
			useCustomStyles: '=?',
		},
		templateUrl: require('../../views/templates/assetVideoPlayer.html'),
		link(scope) {
			loadComponent.then(loaded => {
				scope.playerComponentLoaded = loaded;
				scope.$apply();
			});

			// if mute state is unset, set it to the prop we were passed
			if (muteService.isMuted === undefined) {
				muteService.isMuted = scope.muted;
			}
			scope.muteService = muteService;

			// We're using showPlayer to remove the video-player node and reconstruct it,
			// because the inner React component does not respond properly to its attributes changing live
			scope.$watch('sources', sources => {
				const mappedSources = sources.map(source => ({
					url: source.src,
					mediaType: source.type,
				}));

				if (!_.isEqual(scope.playerMappedSources, mappedSources)) {
					scope.showPlayer = false;

					// We need the timeout in order to escape the currently in-progress digest.
					// Otherwise, the DOM won't ever reflect 'showPlayer = false'.
					$timeout(() => {
						scope.playerMappedSources = mappedSources;
						scope.showPlayer = mappedSources.length;
					}, 0);
				}
			});

			scope.registerFaithlifeVideoPlayerApiCallback = videoPlayerApi => {
				scope.$emit('asset-video-loaded', { videoPlayerApi });
			};
		},
	};
}
