import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('httpUtility', httpUtility);

function httpUtility() {
	function getErrorReason(response: any) {
		if (response && _.isNumber(response.status)) {
			if (response.status <= 0) {
				return 'abort';
			}
			if (response.status === 404) {
				return 'notfound';
			}
		}
		return 'error';
	}

	return {
		getErrorReason,
	};
}

export type IHttpUtility = ReturnType<typeof httpUtility>;
