import * as angular from 'angular';

export interface IGroup {
	id?: string;
	token?: string;
	name?: string;
	avatarUri?: string;
}

export interface IGroupDetails {
	id?: string;
	token?: string;
	email?: string;
	name?: string;
	avatarUri?: string;
}

angular.module('app').factory('groupMapper', groupMapper);

function groupMapper() {
	function mapGroup(source: undefined): undefined;
	function mapGroup(source: any): IGroup;
	function mapGroup(source: any[]): IGroup[];
	function mapGroup(source: any): IGroup | IGroup[] | undefined {
		if (!source) {
			return undefined;
		}

		if (Array.isArray(source)) {
			return source.map<IGroup>(mapGroup);
		}

		return {
			id: source.id,
			token: source.token,
			name: source.name,
			avatarUri: source.avatarUri,
		};
	}

	function mapGroupDetails(source: undefined): undefined;
	function mapGroupDetails(source: any): IGroupDetails;
	function mapGroupDetails(source: any): IGroupDetails | undefined {
		if (!source) {
			return undefined;
		}

		return {
			id: String(source.groupId),
			token: source.token,
			email: source.email,
			name: source.name,
			avatarUri: source.avatarUrl,
		};
	}

	return {
		map: mapGroup,
		mapDetails: mapGroupDetails,
	};
}

export type IGroupMapperService = ReturnType<typeof groupMapper>;
