import * as angular from 'angular';

angular.module('app').factory('layoutUtility', layoutUtility);

function layoutUtility($window) {
	function isSmallScreen() {
		return $window.innerWidth < 1024;
	}

	return {
		isSmallScreen,
	};
}
