import * as angular from 'angular';

angular.module('app').directive('promptButton', promptButton);

function promptButton(): angular.IDirective {
	return {
		restrict: 'E',
		replace: true,
		transclude: {
			promptButtonLabel: '?promptButtonLabel',
			title: '?modalTitle',
			subtitle: '?modalSubtitle',
			submitButtonLabel: '?modalSubmitButtonLabel',
		},
		scope: {
			submitButtonCallback: '&',
			showModal: '=?',
			inputType: '@',
			inputText: '@',
			promptButtonClass: '@',
		},
		templateUrl: require('../../views/templates/promptButton.html'),
		link(scope, elem, attr, ctrl, transclude) {
			scope.hasPromptButtonLabel = transclude!.isSlotFilled('promptButtonLabel');

			scope.closeModal = function () {
				scope.showModal = false;
			};

			scope.submit = function (inputText) {
				scope.submitButtonCallback({ inputText });
			};
		},
	};
}
