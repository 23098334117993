import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('selectWithCustomOption', selectWithCustomOption);

function selectWithCustomOption($timeout) {
	return {
		restrict: 'E',
		scope: {
			options: '=',
			value: '=',
			customPlaceholder: '=?',
		},
		templateUrl: require('../../views/templates/selectWithCustomOption.html'),
		replace: true,
		controller($scope) {
			$scope.selectedOption = _.some($scope.options, { key: $scope.value })
				? $scope.value
				: 'custom';

			$scope.onCustomValueChange = function () {
				$scope.value = $scope.customInput;
			};
		},
		link(scope, elem) {
			// Use timeout to wait until select2 has initialized
			$timeout(() => {
				const select2MockContainer = elem.find('.custom-dropdown-select2-mock');
				const customInput = select2MockContainer.find('input');
				const select2Container = elem.find('.select2-container').not(select2MockContainer);
				const select = elem.find('select');
				const selectedValue = select2Container.find('.select2-chosen');

				function setSelectToCustomValue() {
					const customValue = customInput.val();

					if (customValue && customValue.length) {
						selectedValue.text(customValue);
					}
				}

				select2MockContainer.on('click', '.select2-arrow', () => {
					select2MockContainer.hide();
					select2Container.show();
					select.select2('open');
				});

				select.on('select2-close', () => {
					if (scope.selectedOption === 'custom') {
						select2MockContainer.show();
						select2Container.hide();
						customInput.focus();
					}
				});

				scope.$watch('selectedOption', (value, oldValue) => {
					if (value === 'custom') {
						select.on('select2-opening', setSelectToCustomValue);
						select2MockContainer.show();
						select2Container.hide();
						if (value !== oldValue) {
							customInput.focus();
						}
					} else {
						select.off('select2-opening', setSelectToCustomValue);
						scope.value = value;
						select2MockContainer.hide();
						select2Container.show();
					}
				});

				if (scope.selectedOption === 'custom') {
					customInput.val(scope.value);
				}

				select2MockContainer.width(select2Container.width());
			});
		},
	};
}
