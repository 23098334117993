import * as angular from 'angular';

const globe = require('../../views/templates/icon-globe.html');
const group = require('../../views/templates/icon-group.html');
const lock = require('../../views/templates/icon-lock.html');
const person = require('../../views/templates/icon-person.html');

angular.module('app').filter('permissionIcon', permissionIcon);

function permissionIcon() {
	return function (membershipRequired) {
		switch (membershipRequired) {
			case 'admin':
				return lock;
			case 'moderator':
				return person;
			case 'member':
				return person;
			case 'follower':
				return group;
			case 'none':
				return globe;
			default:
				return null;
		}
	};
}
