import * as angular from 'angular';
import { Bucket } from '../services';

angular.module('app').filter('exceptPermissionsForUserBucket', exceptPermissionsForUserBucket);

function exceptPermissionsForUserBucket() {
	return function (properties: any[], bucket: Bucket) {
		const isUserBucket = bucket && bucket.group && bucket.group.kind === 'user';
		return isUserBucket ? properties.filter(prop => prop.type !== 'permission') : properties;
	};
}
