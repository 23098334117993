import * as angular from 'angular';
import * as moment from 'moment';

angular.module('app').factory('dateTimeUtility', dateTimeUtility);

function dateTimeUtility() {
	const iso8601Format = 'YYYY-MM-DDTHH:mm:ss[Z]';

	function parseIso8601(date: moment.MomentInput) {
		const parsed = moment(date, iso8601Format, true);
		return parsed.isValid() ? parsed : null;
	}

	function toIso8601(date: moment.MomentInput) {
		return moment(date).utc().format(iso8601Format);
	}

	function differenceInSeconds(date1: moment.MomentInput, date2: moment.MomentInput) {
		return moment(date1).diff(moment(date2), 'seconds');
	}

	return {
		parseIso8601,
		toIso8601,
		differenceInSeconds,
	};
}

export type IDateTimeUtility = ReturnType<typeof dateTimeUtility>;
