import * as angular from 'angular';

angular.module('app').directive('permissionInput', permissionInput);

function permissionInput($filter, assetMetadataEditorHelper) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			assets: '=',
			currentUser: '=',
			bucket: '=',
			groupPermissions: '=',
			isReadonly: '=',
			permissionKey: '=',
			showNone: '=',
			source: '=',
		},
		templateUrl: require('../../views/templates/permissionInput.html'),
		controller($scope) {
			// eslint-disable-next-line no-nested-ternary
			const assets = Array.isArray($scope.assets)
				? $scope.assets
				: $scope.assets
				? [$scope.assets]
				: [];
			const { currentUser, bucket, groupPermissions, permissionKey, source } = $scope;

			// eslint-disable-next-line eqeqeq
			if (assets.every(x => x[permissionKey] == undefined || x[permissionKey] === source)) {
				$scope.default = groupPermissions && groupPermissions[permissionKey];
			}

			if (bucket && bucket.group && bucket.group.membership && currentUser) {
				const allMyAssets = assets.every(x => x.creator && x.creator.id === currentUser.id);
				$scope.isAdminEnabled = allMyAssets || isMembershipAvailable('admin');
				$scope.isModeratorEnabled = allMyAssets || isMembershipAvailable('moderator');
				$scope.isMemberEnabled = allMyAssets || isMembershipAvailable('member');
				$scope.isFollowerEnabled = allMyAssets || isMembershipAvailable('follower');
				$scope.isUseDefaultEnabled = allMyAssets || isMembershipAvailable($scope.default);
			}

			$scope.$watch('source', newSource => {
				$scope.model = newSource || 'default';
			});

			$scope.$watch('model', model => {
				const isDefault = model === 'default';
				$scope.source = isDefault ? undefined : model;
				$scope.items = [
					{
						key: 'admin',
						label: $filter('translate')('permissionInput.admins'),
						display: true,
						disabled: !$scope.isAdminEnabled,
					},
					{
						key: 'moderator',
						label: $filter('translate')('permissionInput.moderators'),
						display: true,
						disabled: !$scope.isModeratorEnabled,
					},
					{
						key: 'member',
						label: $filter('translate')('permissionInput.members'),
						display: true,
						disabled: !$scope.isMemberEnabled,
					},
					{
						key: 'follower',
						label: $filter('translate')('permissionInput.followers'),
						display: true,
						disabled: !$scope.isFollowerEnabled,
					},
					{
						key: 'none',
						label: $filter('translate')('permissionInput.public'),
						display: $scope.showNone,
						disabled: false,
					},
					{
						key: 'default',
						label: $filter('translate')('permissionInput.useDefault'),
						display: $scope.isUseDefaultEnabled && !isDefault,
						disabled: false,
					},
				].map(item => {
					const isDefaultValue = isDefault && $scope.default === item.key;
					return {
						key: isDefaultValue ? 'default' : item.key,
						label: item.label + (isDefaultValue ? ' (Default)' : ''),
						display: item.display,
						disabled: item.disabled,
					};
				});
			});

			function isMembershipAvailable(membership) {
				return (
					assetMetadataEditorHelper.compareMembershipKinds(bucket.group.membership, membership) >= 0
				);
			}
		},
	};
}
