import * as angular from 'angular';

angular.module('app').filter('jobName', jobName);

function jobName() {
	return function (job) {
		return (
			(job.response &&
				job.response.content &&
				((job.response.content.metadata && job.response.content.metadata.title) ||
					(job.response.content.file && job.response.content.file.name))) ||
			job.id
		);
	};
}
