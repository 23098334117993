import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';

angular.module('app').service('navigationService', navigationService);

function navigationService($state: uirouter.StateService) {
	const isAnonymousState = (): boolean =>
		!!$state.current.name &&
		[
			'about',
			'sharedDetails',
			'sharedPreview',
			'shareEmbed',
			'error',
			'boardSharedDetails',
			'boardSharedAssetDetails',
			'boardSharedAssetPreview',
		].includes($state.current.name);

	return {
		isAnonymousState,
	};
}

export type INavigationService = ReturnType<typeof navigationService>;
