import * as angular from 'angular';

angular.module('app').directive('faithlifeComments', faithlifeComments);

function faithlifeComments($window, appSettings, lazyLoader) {
	return {
		restrict: 'AE',
		scope: {
			width: '@',
			targetUri: '@',
		},
		link(scope, elem) {
			let pluginElement;

			function initializePlugin(targetUri, cb) {
				lazyLoader
					.loadServiceAsync('https://faithlife.com/scripts/api/faithlifePlugins.js')
					.then(() => {
						if ($window.faithlife && $window.faithlife.plugins) {
							cb(
								$window.faithlife.plugins.createCommentsPlugin({
									width: scope.width,
									colorPrimary: '#278ED4',
									colorAccent: 'transparent',
									targetUri,
								})
							);
						}
					});
			}

			scope.$watch('targetUri', value => {
				if (pluginElement) {
					pluginElement.remove();
					pluginElement = null;
				}

				if (value) {
					const baseUri = appSettings.filesServiceUrl;
					const targetUri = baseUri + (value[0] !== '/' ? '/' : '') + value;
					initializePlugin(targetUri, e => {
						pluginElement = angular.element(e);
						elem.append(pluginElement);
					});
				}
			});
		},
	};
}
