import * as angular from 'angular';
import * as moment from 'moment';

angular.module('app').directive('dateInput', dateInput);

function dateInput(dateTimeUtility) {
	return {
		restrict: 'A',
		scope: {
			date: '=',
			isReadonly: '=?',
			requireFutureDate: '=?',
			constrainInput: '=?',
		},
		link(scope, element) {
			element.datepicker({
				showOtherMonths: true,
				dayNamesMin: moment.weekdaysShort(),
				showOn: scope.isReadonly ? 'focus' : 'both',
				buttonText: '',
				dateFormat: 'yy-mm-dd',
				monthNames: moment.months(),
				constrainInput: scope.constrainInput,
				minDate: scope.requireFutureDate ? 1 : undefined,
			});

			// The next/prev month buttons were deselecting assets in the asset list. The next/prev month buttons get removed
			// from the DOM before the selectable directive can evaluate `preserve-selection` attached to a parent. To workaround
			// this problem, we just stop event propagation.
			element.datepicker('widget').click(event => {
				event.stopPropagation();
			});

			scope.$watch('date', date => {
				if (date) {
					const dateFromScope = dateTimeUtility.parseIso8601(date);
					if (dateFromScope) {
						element.datepicker('setDate', dateFromScope.toDate());
					} else {
						element.val(date);
					}
				}
			});

			element.on('change', () => {
				scope.$apply(() => {
					const newDate = element.val();
					const newDateIso8601 = dateTimeUtility.toIso8601(newDate);
					const datePickerDate = element.datepicker('getDate');
					const datePickerDateIso8601 = dateTimeUtility.toIso8601(datePickerDate);

					// newDateIso8601 returns 'Invalid date' for non-parsable dates
					// datePickerDate8601 returns the current date for non-parsable dates
					scope.date = newDateIso8601 === datePickerDateIso8601 ? datePickerDateIso8601 : newDate;
				});
			});
		},
	};
}
