import * as angular from 'angular';
import { IThinAsset } from '../helpers';
import { IAssetService } from '../services';
import { ITooltip, ITooltipService } from '../services/tooltipService';

angular.module('app').directive('assetTooltip', assetTooltip);

function assetTooltip(
	$compile: angular.ICompileService,
	$http: angular.IHttpService,
	$q: angular.IQService,
	$templateCache: angular.ITemplateCacheService,
	$transitions,
	assetService: IAssetService,
	tooltipService: ITooltipService
): angular.IDirective {
	return {
		restrict: 'A',
		scope: false,
		link(scope, elem, attrs) {
			const templateFetchAborter = $q.defer();
			let unwatch;
			const offset = 0;
			let tooltipScope;

			function createScope() {
				const newScope = scope.$new(true);
				unwatch = scope.$watch(attrs.assetTooltip, async (asset: IThinAsset) => {
					newScope.asset = asset;
					const fullAsset = await assetService.getAssetAsync(asset.id);
					newScope.$apply(() => {
						if (newScope.asset?.id === fullAsset?.id) {
							newScope.asset = fullAsset;
						}
					});
				});

				scope.$watch(attrs.assetTooltipShowPreview, shouldShowPreview => {
					newScope.shouldShowPreview = shouldShowPreview;
				});

				return newScope;
			}

			function initializeTooltip(cancellationToken) {
				cancellationToken.then(() => {
					templateFetchAborter.resolve();
				});

				return $http
					.get<any>('views/templates/assetTooltip.html', {
						timeout: templateFetchAborter.promise,
						cache: $templateCache,
					})
					.then(response => {
						const template = response.data;
						const create = $compile(template);
						tooltipScope = createScope();
						return create(tooltipScope);
					});
			}

			function destroyTooltip() {
				if (unwatch) {
					unwatch();
				}
				if (tooltipScope) {
					tooltipScope.$destroy();
				}
			}

			const container = elem.closest('[tooltip-container]');
			let tooltip: ITooltip | null = null;

			function createTooltip() {
				tooltip = tooltipService.createTooltip(elem, initializeTooltip, {
					container,
					tooltipHideDelay: 0,
					onDestroy: destroyTooltip,
					tooltipOffset: offset,
				});
				$transitions.onStart({}, tooltip.destroy);
				container.on('scroll', tooltip.destroy);

				scope.$on('$destroy', () => {
					templateFetchAborter.resolve();

					if (tooltip) {
						container.off('scroll', tooltip.beginHide);
						tooltip.destroy();
					}
				});
			}

			elem.hover(
				() => {
					if (!tooltip) {
						createTooltip();
					}

					tooltip!.beginShow();
				},
				() => {
					if (tooltip) {
						tooltip.beginHide();
					}
				}
			);
		},
	};
}
