import * as angular from 'angular';

angular.module('app').directive('loadingSpinner', loadingSpinner);

function loadingSpinner($timeout, appSettings) {
	return {
		restrict: 'AE',
		scope: {
			delay: '<',
			isWorking: '<',
			size: '@',
		},
		template:
			'<div ng-if="isWorking" class="loading-spinner-container {{size}}"><div class="loading-spinner"></div></div>',
		link(scope) {
			scope.isWorking = !angular.isDefined(scope.isWorking) || scope.isWorking;

			let spinnerPromise;

			function showSoon() {
				if (spinnerPromise) {
					$timeout.cancel(spinnerPromise);
				}

				spinnerPromise = $timeout(
					() => {
						scope.isWorking = true;
					},
					scope.delay ? appSettings.isWorkingIndicatorDelay : 0
				);
			}

			function stop() {
				scope.isWorking = false;
				$timeout.cancel(spinnerPromise);
			}

			const isWorkingDeregister = scope.$watch('isWorking', isWorking => {
				if (isWorking) {
					showSoon();
				} else {
					stop();
				}
			});

			scope.$on('$destroy', () => {
				isWorkingDeregister();
				stop();
			});
		},
	};
}
