import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IAssetFile, IAssetFormat } from '@faithlife/amber-api';
import JsonFilter from '@faithlife/json-filter';
import * as uirouter from '@uirouter/angularjs';
import {
	AssetPermission,
	IAppSettings,
	IAssetCompat,
	IAssetFileCompat,
	IAssetFormatCompat,
	IAssetPermissionService,
	IAssetService,
	IBucketService,
	IBoardService,
	ICancellationService,
	IEmbedService,
	IFacetFilter,
	IFacetQueryService,
	IFilterService,
	IGroupService,
	IListHelper,
	ILocalSettingsService,
	IMuteService,
	ISearchResultsNavigationModel,
	ISearchTextService,
	IViewStyleService,
	SettingAccessor,
} from '../services';
import { IConstants } from '../infrastructure';
import { IAsset, IAssetMapper, ISearchFacet, IThinAsset, IUser, IUtility } from '../helpers';

angular.module('app').controller('AssetListController', assetList);

function assetList(
	$log: angular.ILogService,
	$rootScope: angular.IRootScopeService,
	$scope: angular.IScope,
	$state: uirouter.StateService,
	$timeout: angular.ITimeoutService,
	$transitions: uirouter.TransitionService,
	appSettings: IAppSettings,
	assetMapper: IAssetMapper,
	assetPermissionsService: IAssetPermissionService,
	assetService: IAssetService,
	bucketService: IBucketService,
	boardService: IBoardService,
	cancellationService: ICancellationService,
	constants: IConstants,
	currentUser: IUser,
	groupService: IGroupService,
	embedService: IEmbedService,
	facetQueryService: IFacetQueryService,
	filterService: IFilterService,
	hotkeys,
	listHelper: IListHelper,
	localSettingsService: ILocalSettingsService,
	muteService: IMuteService,
	searchResultsNavigationModel: ISearchResultsNavigationModel,
	searchTextService: ISearchTextService,
	utility: IUtility,
	viewStyleService: IViewStyleService
) {
	const assetPageSize = 30;
	let cancellationSource;
	let currentState;
	let lastObservedInputText;
	let updateSearchTime = Date.now();
	const accountToken = groupService.currentAccountToken;
	const {
		pickerMode,
		externalEditorKinds,
		disableSidebarLeft,
		sidebarRightPreview,
		sidebarRightDetails,
		multiSelect,
		presetFilters,
		forceCreateShare,
	} = embedService;
	const footerText =
		embedService.footerText ||
		"Images shared to a group will be added to the group's image gallery storage and your image gallery.";
	const isListView = () =>
		$state.is('assetsEmbed') || $state.is('assets') || $state.is('boardSharedDetails');
	const isBoardShare = $state.is('boardSharedDetails');
	const assetListState = embedService.isEmbedded
		? 'assetsEmbed'
		: isBoardShare
		? 'boardSharedDetails'
		: 'assets';

	if (!currentUser || (currentUser.isAnonymous && !isBoardShare)) {
		return;
	}
	$scope.currentUser = currentUser;

	$scope.previewDisplayOptions = {
		showPreviewModal: false,
	};

	const model = {
		accountToken,
		isListView,
		pickerMode,
		externalEditorKinds,
		presetFilters,
		footerText,
		selectedAssets: [] as (IThinAsset | IAsset)[],
		selectedAssetsPermissions: null as AssetPermission | null,
		maxThumbnailWidth: 0,
		maxThumbnailHeight: 0,
		boards: null,
		shareToken: null,
		sortOptions: listHelper.sortOptions,
		sort: null,
		facets: null as ISearchFacet[] | null,
		select: null,
		shouldShowShareUrl: false,
		disableSidebarLeft,
		sidebarRightPreview,
		sidebarRightDetails,
		multiSelect,
		shouldShowDetailsPaneSetting: localSettingsService.createSettingAccessor(
			'shouldShowSidebar',
			() => !$scope.isSmallViewport
		),
		shouldShowPreviewPaneSetting: localSettingsService.createSettingAccessor(
			'shouldShowPreviewPane',
			() => !$scope.isSmallViewport
		),
		viewStyleService: viewStyleService.createViewStyleService($scope.isSmallViewport),
		tableColumns: [
			'checkbox',
			'thumbnail',
			'title',
			'action',
			'kind',
			'authors',
			'uploaded',
			'status',
			'tags',
		],
		filters: embedService.isEmbedded
			? createTemporarySettingAccessor(false)
			: localSettingsService.createSettingAccessor('filters', () => false),
		getDate: listHelper.getDate,
		selectedBoard: undefined,
		loadedAssets: [] as IThinAsset[],
		assetTotal: null as number | null,
		thumbnailUrls: {},
		status: undefined as 'loading' | 'display' | 'error' | undefined,
		myAssetsQuery: [] as IFacetFilter[],
		bucketStats: { byteCount: 0, byteCountMax: 0 },
	};

	function getLoadedAssetsMap(): { [id: string]: IThinAsset } {
		return model.loadedAssets.reduce((acc, val) => {
			acc[val.id] = val;
			return acc;
		}, {});
	}

	function saveState(state) {
		currentState = _.cloneDeep(state);
		searchTextService.searchText = state.q;
		// if user is currently typing, don't override their text
		if (updateSearchTime > searchTextService.inputModifiedTime) {
			searchTextService.inputText = state.q;
			lastObservedInputText = searchTextService.inputText;
		}
		facetQueryService.updateFiltersFromParam(state.filter);
		filterService.selectedSavedFilter = state.savedFilter;

		model.select = state.select;
		model.sort = state.sort || listHelper.getDefaultSort(state.q);
		model.selectedBoard = state.board;
		model.shareToken = state.shareToken;
		if (presetFilters && !state.filter) {
			facetQueryService.updateFiltersFromParam(presetFilters);
		}
		if (!filterService.selectedSavedFilter) {
			filterService.clearSelectedSavedFilter();
			filterService.clearSelectedSavedFilterName();
		}
	}

	const disableAutoSelectSoon = _.debounce(() => {
		model.select = null;
	}, 3000);

	let currentRequest: Promise<boolean> | null = null;
	function loadNextPageAsync() {
		if (currentRequest) {
			return Promise.reject('in progress');
		}

		const offset = model.loadedAssets.length;

		if (model.assetTotal !== null && model.loadedAssets.length >= model.assetTotal) {
			return Promise.reject('at end');
		}

		const cancellationToken = cancellationSource.token;

		currentRequest = listHelper
			.getThinAssetsAsync(
				searchTextService.searchText ?? '',
				facetQueryService.filters,
				filterService.selectedSavedFilter,
				model.selectedBoard,
				model.sort,
				offset,
				assetPageSize,
				model.shareToken,
				cancellationToken
			)
			.then(data => {
				if (cancellationToken.isCancelled) {
					return Promise.reject('abort');
				}
				listHelper.updateAssetIdsFromModel(model);
				model.assetTotal = data.assetTotal;
				model.facets = data.facets;
				model.status = 'display';
				const largerDimension = Math.max(
					$scope.getMaxThumbnailWidth(),
					$scope.getMaxThumbnailHeight()
				);

				return listHelper.getAssetsWithFileIds(data.assets, largerDimension);
			})
			.catch(reason => {
				if (reason !== 'abort' && !(model.loadedAssets && model.loadedAssets.length)) {
					// display an error message if the failure happened before any assets were shown
					listHelper.resetModel(model, 'error');
					model.facets = null;
					return Promise.reject(reason);
				}

				return { assets: [], fileLinks: [] };
			})
			.then(results => {
				if (cancellationToken.isCancelled) {
					return false;
				}

				const loadedAssetsKeys = model.loadedAssets.map(({ id }) => id);
				const newAssets = results.assets.filter(({ id }) => !loadedAssetsKeys.includes(id));
				model.loadedAssets.push(...newAssets);

				results.fileLinks.forEach(({ fileId, uri }) => (model.thumbnailUrls[fileId] = uri));

				if (model.select === 'auto') {
					$timeout(() => {
						$scope.$broadcast('selectable.selectAll');
					}, 0);
					disableAutoSelectSoon();
				}

				return model.assetTotal === null || model.assetTotal > model.loadedAssets.length;
			})
			.finally(() => {
				currentRequest = null;
				$scope.$apply();
			});

		return currentRequest;
	}

	async function getResultsAsync() {
		if (cancellationSource) {
			cancellationSource.cancel();
		}
		try {
			await currentRequest;
		} catch (e) {
			$log.warn(e);
		}
		cancellationSource = cancellationService.createCancellationSource();
		listHelper.resetModel(model, 'loading');
		await loadNextPageAsync();
	}

	async function getFullAssetsAsync(ids: string[]): Promise<IAsset[]> {
		let loadedAssets = getLoadedAssetsMap();
		const missingAssets = ids.filter(id => !assetMapper.isFullAsset(loadedAssets[id]));
		const { assets: fullAssets } = missingAssets.length
			? await assetService.getAssetsAsync(missingAssets, cancellationSource.token)
			: { assets: [] };
		fullAssets.forEach(asset => {
			utility.findReplace(model.loadedAssets, ({ id }) => id === asset.id, asset);
		});
		loadedAssets = getLoadedAssetsMap();
		return ids.map(id => loadedAssets[id]).filter(assetMapper.isFullAsset);
	}

	let lastSearchUpdate: moment.Moment | null = null;
	const updateSearchSoon = _.debounce(
		() => {
			const urlReplaceThresholdSeconds = 2;
			const now = moment();

			const shouldReplaceUrl =
				lastSearchUpdate && now.diff(lastSearchUpdate, 'seconds') < urlReplaceThresholdSeconds;
			lastSearchUpdate = now;

			$state.go(
				assetListState,
				{ q: searchTextService.inputText || null, select: null },
				{ location: shouldReplaceUrl ? 'replace' : true }
			);
		},
		appSettings.inputDebounceTimeout,
		{ trailing: true }
	);

	function enableWatchers() {
		const unwatchers = [
			$scope.$watch(
				() => bucketService.getCurrentBucket(),
				async bucket => {
					$scope.bucket = bucket;
					$scope.groupPermissions = await bucketService.getBucketGroupPermissionsAsync(bucket);
				}
			),
			$scope.$watch(
				() => searchTextService.inputText,
				(value, old) => {
					// watchers are disengaged for 3-10ms between state transitions
					// if text was changed while watcher was disengaged, value will === old
					if (value !== old || value !== lastObservedInputText) {
						lastObservedInputText = value;
						updateSearchSoon();
					}
				}
			),
			$scope.$watch('model.sort', (value, old) => {
				if (value !== old) {
					$state.go(assetListState, {
						sort: value || null,
						q: searchTextService.inputText,
						select: null,
					});
				}
			}),
			$scope.$watch('model.selectedBoard', (value, old) => {
				if (value !== old) {
					$state.go(assetListState, {
						board: value || null,
						q: searchTextService.inputText,
						select: null,
					});
				}
			}),
		];

		return function () {
			unwatchers.forEach(unwatch => {
				unwatch();
			});
		};
	}

	function initialize() {
		saveState($state.params); // Read the active state's params instead of $transition$.params() because we need the child state's params. See also app.js.

		$rootScope.assetListInitialized = true;
		$scope.model = model;
		$scope.muteService = muteService;
		$scope.loadNextPageAsync = loadNextPageAsync;

		$scope.getMaxThumbnailWidth = function () {
			return listHelper.getMaxThumbnailWidth($scope.isSmallViewport, model);
		};

		$scope.getMaxThumbnailHeight = function () {
			return listHelper.getMaxThumbnailHeight($scope.isSmallViewport, model);
		};

		$scope.shouldDisplayAsset = function (asset) {
			// Stop displaying deleted/undeleted assets immediately instead of waiting for the server's state to reflect a recent change
			return model.selectedBoard === 'deleted' ? asset.isDeleted : !asset.isDeleted;
		};

		$scope.toggleShareMenu = function () {
			model.shouldShowShareUrl = !model.shouldShowShareUrl;
		};

		$scope.togglePreviewPane = function () {
			model.shouldShowPreviewPaneSetting.set(!model.shouldShowPreviewPaneSetting.get());

			if (model.shouldShowPreviewPaneSetting.get() && model.selectedAssets.length) {
				$scope.updateSelectedAssets(model.selectedAssets);
			}
		};

		if (typeof model.sidebarRightPreview !== 'undefined') {
			model.shouldShowPreviewPaneSetting.set(model.sidebarRightPreview, false);
		}

		if (typeof model.sidebarRightDetails !== 'undefined') {
			model.shouldShowDetailsPaneSetting.set(model.sidebarRightDetails, false);
		}

		let editPermissionsCancellationSource;
		$scope.updateSelectedAssets = function (selectedAssets: IThinAsset[]) {
			model.selectedAssets = selectedAssets;
			getFullAssetsAsync(selectedAssets.map(({ id }) => id)).then(assets => {
				model.selectedAssets = assets;
				$scope.$apply();
			});
			model.shouldShowShareUrl = false;
			if (selectedAssets.length > 1) {
				searchResultsNavigationModel.setAssetIds(selectedAssets.map(({ id }) => id));
			}

			if (editPermissionsCancellationSource) {
				editPermissionsCancellationSource.cancel();
			}
			editPermissionsCancellationSource = cancellationService.createCancellationSource();
			model.selectedAssetsPermissions = null;
			assetPermissionsService
				.getAssetsPermissionsAsync(selectedAssets, editPermissionsCancellationSource.token)
				.then(permissions => {
					if (!permissions.canEdit && currentUser.id) {
						const myAssetsFilter = { facet: 'uploaderId', term: currentUser.id };
						model.myAssetsQuery = facetQueryService.containsFilter(myAssetsFilter)
							? facetQueryService.filters
							: facetQueryService.filters.concat([myAssetsFilter]);
					}
					model.selectedAssetsPermissions = permissions;
					$scope.$apply();
				});
		};

		$scope.areFullAssets = function (assets: (IAsset | IThinAsset)[]) {
			return assets.every(assetMapper.isFullAsset);
		};

		$scope.setDeleted = function (shouldDelete) {
			if (model.assetTotal) {
				model.assetTotal -= model.selectedAssets.length;
			}
			listHelper.setDeletedAsync(model.selectedAssets, shouldDelete);
		};

		$scope.previewAssets = function (assetId) {
			if (!assetId) {
				$scope.previewAssetIndex = 0;
				$scope.previewAssetCollection =
					$scope.model.selectedAssets.length > 1
						? $scope.model.selectedAssets
						: $scope.model.loadedAssets;
			} else {
				$scope.previewAssetCollection = $scope.model.loadedAssets;
				$scope.previewAssetIndex = _.findIndex(
					$scope.model.loadedAssets,
					(asset: IAsset) => asset.id === assetId
				);
				if ($scope.model.selectedAssets.length > 1) {
					const selectedAssetIndex = _.findIndex(
						$scope.model.selectedAssets,
						(asset: IAsset) => asset.id === assetId
					);
					if (selectedAssetIndex !== -1) {
						$scope.previewAssetCollection = $scope.model.selectedAssets;
						$scope.previewAssetIndex = selectedAssetIndex;
					}
				}
			}
			$scope.assetToPreview = $scope.previewAssetCollection[$scope.previewAssetIndex];
			$scope.previewDisplayOptions.showPreviewModal = true;
		};

		$scope.previewLoadSequential = function (direction) {
			$scope.previewAssetIndex =
				((direction ? $scope.previewAssetIndex + 1 : $scope.previewAssetIndex - 1) +
					$scope.previewAssetCollection.length) %
				$scope.previewAssetCollection.length;
			$scope.assetToPreview = $scope.previewAssetCollection[$scope.previewAssetIndex];
		};

		$scope.sendAssets = async function (assets: IAsset[] | undefined, additionalData = {}) {
			if (!assets && _.includes(['filter', 'file', 'asset'], pickerMode)) {
				$log.info('Sent postMessage containing canceled: true');
				embedService.postMessage({
					type: pickerMode === 'filter' ? 'filter' : 'assets',
					canceled: true,
					...additionalData,
				});
				return;
			}

			switch (pickerMode) {
				case 'filter': {
					const filters = angular
						.copy(facetQueryService.filters)
						.concat(facetQueryService.parseFilterParam(filterService.selectedSavedFilter));
					const parsedPresetFilters = facetQueryService.parseFilterParam(presetFilters);
					const diffedFilters = filters.filter(x => !_.some(parsedPresetFilters, x));
					const searchQueries = searchTextService.inputText ? [searchTextService.inputText] : [];

					for (const filter of diffedFilters) {
						searchQueries.push(facetQueryService.formatFilter(filter));
					}

					let share;
					let filterId;
					if (embedService.pickerMode === 'filter' && embedService.createFilter) {
						const combinedQuery = listHelper.createSearchQuery(
							searchTextService.inputText,
							filters,
							filterService.selectedSavedFilter
						);

						const sort = model.sort
							? _.find(model.sortOptions, {
									key: model.sort,
							  })
							: model.sortOptions[listHelper.getDefaultSort(searchTextService.inputText)];

						const filter = await filterService.createFilterAsync({
							name: `${constants.autoGeneratedFilterPrefix} ${combinedQuery}`,
							query: combinedQuery,
							sort,
						});

						filterId = filter.id;

						if (embedService.forceCreateShare && filterId) {
							share = await filterService.createShareAsync(filterId);
						}
					}

					const filterData = {
						filters,
						share,
						filterId,
						query: searchQueries.join(' ').trim(),
						bucketId: $scope.bucket.id,
						fileCount: model.assetTotal ?? 0,
					};
					$log.info(
						`Sent postMessage containing data to filter ${filterData.fileCount} files to parent window.`
					);
					embedService.postMessage({ type: 'filter', filterData, ...additionalData });
					break;
				}
				case 'file': {
					const assetFileIds = assets
						? assets
								.filter(x => x.id && x.file?.id)
								.map(a => ({ assetId: a.id, fileId: a.file!.id }))
						: [];

					if (assetFileIds.length) {
						const fileLinks = await assetService.getFileLinksForAssetsAsync(
							assetFileIds,
							cancellationSource.token
						);
						$log.info(
							`Sent postMessage containing ${assetFileIds.length} file links to parent window.`
						);
						embedService.postMessage({ type: 'assets', assets: fileLinks, ...additionalData });
					} else {
						$log.info('Sent postMessage containing 0 file links to parent window.');
						embedService.postMessage({ type: 'assets', assets: [], ...additionalData });
					}
					break;
				}
				case 'asset': {
					if (!assets || !assets.length) {
						$log.info('Sent postMessage containing no assets.');
						embedService.postMessage({ type: 'assets', assets: [], ...additionalData });
						return;
					}

					const filter = JsonFilter.tryParse(embedService.assetPickerFields);
					if (!filter) {
						$log.warn(`Could not create filter with fields "${embedService.assetPickerFields}"`);
						return;
					}

					let result = mapAssets(await getFullAssetsAsync(assets.map(({ id }) => id)));
					const includeAssetFileLinks = filter.isPathIncluded('file.linkUri' || 'file.link.uri');
					const includeFormatFileLinks = filter.isPathIncluded('formats.file.linkUri');

					const fileLinksToRequest: { assetId: string; fileId: string }[] = [];

					if (includeAssetFileLinks) {
						result
							.filter(asset => asset.id && asset.file && asset.file.id)
							.forEach(asset => {
								fileLinksToRequest.push({
									assetId: asset.id!,
									fileId: asset.file!.id!,
								});
							});
					}

					if (includeFormatFileLinks) {
						result
							.filter(asset => asset.id && asset.formats && asset.formats.length)
							.forEach(asset => {
								asset
									.formats!.filter(format => format.file && format.file.id)
									.forEach(format => {
										fileLinksToRequest.push({
											assetId: asset.id!,
											fileId: format.file!.id!,
										});
									});
							});
					}

					await Promise.all(result.map(asset => addWebOptimizedUrlAsync(asset)));

					if (fileLinksToRequest.length === 0) {
						$log.info('Sent postMessage containing 0 file links to parent window.');
						embedService.postMessage({
							type: 'assets',
							assets: filter.filterObject(result) as IAssetCompat[],
							...additionalData,
						});
						return;
					}

					const fileLinks = await assetService.getFileLinksForAssetsAsync(
						fileLinksToRequest,
						cancellationSource.token
					);
					const fileLinkMap = new Map();
					fileLinks.forEach(fileLink => fileLinkMap.set(fileLink.fileId, fileLink.uri));

					if (includeAssetFileLinks) {
						result = result.map(asset => {
							if (!asset.file || !asset.file.id) {
								return asset;
							}

							return {
								...asset,
								file: {
									...asset.file,
									linkUri: fileLinkMap.get(asset.file.id),
								},
							};
						});
					}

					if (includeFormatFileLinks) {
						result = result.map(asset => {
							if (!asset.formats || !asset.formats.length) {
								return asset;
							}

							return {
								...asset,
								formats: asset.formats.map(format => {
									if (!format || !format.file || !format.file.id) {
										return format;
									}

									return {
										...format,
										file: {
											...format.file,
											linkUri: fileLinkMap.get(format.file.id),
										},
									};
								}),
							};
						});
					}

					await Promise.all(result.map(asset => assetService.reportAssetInsert(asset.id!)));
					$log.info(`Sent postMessage containing ${fileLinks.length} file links to parent window.`);

					embedService.postMessage({
						type: 'assets',
						assets: filter.filterObject(result) as IAssetCompat[],
						...additionalData,
					});
					break;
				}
				default:
					$log.warn(`Unsupported pickerMode '${embedService.pickerMode}'.`);
					break;
			}
		};

		model.shouldShowShareUrl = false;

		let disableWatchers: (() => void) | null = enableWatchers();

		const onBeforeDeregister = $transitions.onBefore({}, () => {
			updateSearchTime = Date.now();
		});

		const onStartDeregister = $transitions.onStart({}, trans => {
			// Detect changes to query parameters in this state and trigger a model update.
			// This is done with navigation requests to ensure the URL is updated to reflect the current state.
			const toState = trans.$to();
			const toParams = { ...trans.params('to') };
			const fromState = trans.$from();

			const assetListStates = ['assets', 'assetsEmbed', 'boardSharedDetails'];

			if (assetListStates.includes(toState.name)) {
				toParams.sort = listHelper.elideDefaultSortValue(toParams.q, toParams.sort);

				if (!angular.equals(currentState, toParams)) {
					// Ensure a new history entry is created for the next q change
					if (currentState.q === toParams.q) {
						lastSearchUpdate = null;
					}

					// disable watchers while we save state to ensure the change doesn't trigger another round of navigation
					if (disableWatchers) {
						disableWatchers();
					}

					saveState(toParams);
					disableWatchers = enableWatchers();

					getResultsAsync();
				} else if (assetListStates.includes(fromState.name)) {
					trans.abort();
				}

				if (!disableWatchers) {
					disableWatchers = enableWatchers();
				}
			} else if (fromState && assetListStates.includes(fromState.name)) {
				// We're leaving the assets view. Disable watchers while the view is inactive.
				if (disableWatchers) {
					disableWatchers();
					disableWatchers = null;
				}
			}
		});

		$scope.$on('$destroy', () => {
			onBeforeDeregister();
			onStartDeregister();
		});

		$scope.$on(constants.fileUploadComplete, () => {
			updateBucketStats();
		});

		hotkeys
			.bindTo($scope)
			.add({
				combo: 'f7',
				description: 'Preview selected assets',
				callback() {
					if (model.selectedAssets.length) {
						$state.go('details', { assetId: model.selectedAssets[0].id });
					}
				},
			})
			.add({
				combo: 'del',
				description: 'Delete selected assets',
				callback() {
					if (!pickerMode && isListView() && model.selectedAssets.length) {
						$scope.setDeleted(true);
					}
				},
			})
			.add({
				combo: 'enter',
				description: 'Open preview for first item',
				callback() {
					if (isListView() && model.selectedAssets.length) {
						$scope.previewAssets(model.selectedAssets[0].id);
					}
				},
			});

		getResultsAsync().then(() => {
			if (parent) {
				embedService.postMessage({ kind: 'status', status: 'ready' });
			}
		});
	}

	async function updateBucketStats() {
		const currentBucket = await bucketService.getCurrentBucketAsync(embedService.isEmbedded);
		const stats = await bucketService.getBucketStatsAsync(currentBucket.id);
		if (stats && stats.byteCount) {
			model.bucketStats = {
				byteCount: stats.byteCount,
				byteCountMax: appSettings.maxQuotaByteSize,
			};
			$scope.$apply();
		}
	}

	async function addWebOptimizedUrlAsync(asset: IAssetCompat) {
		if (!asset.id || (asset.kind !== 'image' && asset.kind !== 'video')) {
			return;
		}

		if (asset.id.indexOf('us_') === 0) {
			asset.webOptimizedUrl = asset.file?.url;
			return;
		} else if (
			asset.webOptimizedUrl &&
			(forceCreateShare || $scope.groupPermissions?.readRequires !== 'none')
		) {
			const webOptimizedUrl = new URL(asset.webOptimizedUrl);
			try {
				const data = await assetService.createShareAsync(asset.id);
				if (data.token) {
					webOptimizedUrl.searchParams.set('share', data.token);
					asset.webOptimizedUrl = webOptimizedUrl.toString();
				} else {
					$log.error('createShareAsync did not return a share token');
				}
			} catch (e) {
				$log.error('createShareAsync failed', e);
			}
		}
	}

	async function preinitialize() {
		$rootScope.hasCreatePermission = false;
		if (isListView()) {
			if (!currentUser.isAnonymous) {
				let currentBucket = await bucketService.getCurrentBucketAsync();
				if (!currentBucket) {
					const buckets = await bucketService.getBucketsAsync();
					if (!buckets || !buckets.length) {
						$state.go('about', { reload: null });
					}

					currentBucket = await bucketService.getCurrentBucketAsync();
				}

				if (model.accountToken) {
					updateBucketStats();
				}

				$scope.bucket = currentBucket;
				$rootScope.hasCreatePermission = currentBucket.createPermission === 'full';

				if (currentBucket.group && currentBucket.group.token !== groupService.currentAccountToken) {
					groupService.currentAccountToken = currentBucket.group.token;
				}
				$scope.groupPermissions = await bucketService.getBucketGroupPermissionsAsync(currentBucket);
			}

			if (isBoardShare) {
				const boardId = await boardService.getShareBoardIdAsync($state.params.shareToken);
				if (boardId) {
					$state.params.board = boardId;
				}
			}

			initialize();
		} else {
			const off = $transitions.onSuccess({}, () => {
				if (isListView()) {
					off();
					initialize();
				}
			});
		}
	}

	preinitialize();
}

function mapAssets(assetModels: readonly IAsset[]) {
	return assetModels.map(mapAsset);
}

function mapAsset(assetModel: IAsset): IAssetCompat {
	return {
		...assetModel.originalDto,
		file: mapAssetFile(assetModel.originalDto.file),
		formats: mapAssetFormats(assetModel.originalDto.formats),
		webOptimizedUrl: assetModel.webOptimizedUrl,
	};
}

function mapAssetFiles(files: IAssetFile[] | undefined): IAssetFileCompat[] | undefined {
	if (!files) {
		return undefined;
	}
	return files.map(x => mapAssetFile(x)!);
}

function mapAssetFile(file: IAssetFile | undefined): IAssetFileCompat | undefined {
	if (!file) {
		return undefined;
	}
	const { width, height } = file.metadata?.image ?? {};
	return {
		...file,
		linkUri: file.link?.uri,
		url: file.link?.uri,
		dimensions:
			typeof width === 'number' && typeof height === 'number' ? { width, height } : undefined,
	};
}

function mapAssetFormats(formats: IAssetFormat[] | undefined) {
	if (!formats) {
		return undefined;
	}
	return formats.map(mapAssetFormat);
}

function mapAssetFormat(format: IAssetFormat): IAssetFormatCompat {
	return {
		...format,
		file: mapAssetFile(format.file),
		files: mapAssetFiles(format.files),
		firstFile: format.files?.length ? mapAssetFile(format.files[0]) : mapAssetFile(format.file),
	};
}

function createTemporarySettingAccessor<T>(initialValue: T | null | undefined): SettingAccessor<T> {
	let currentValue = initialValue;
	return {
		get: () => currentValue,
		set: value => {
			currentValue = value;
		},
	};
}
