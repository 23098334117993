import * as angular from 'angular';
import { IAmberAppModule } from '../app';
import { IEmbedService, UserModel } from '../services';
import { IUser } from '../helpers';

import '@faithlife/brand-bar/dist/brand-bar.css';

const app: IAmberAppModule = angular.module('app');

// eslint-disable-next-line angular/module-getter
app.controller('BrandBarController', brandBar);

interface IBrandBarScope extends angular.IScope {
	brandBarLoaded: boolean;
	showBrandBar: boolean;
	user: IUser;
}

function brandBar($scope: IBrandBarScope, embedService: IEmbedService, userModel: UserModel) {
	$scope.brandBarLoaded = false;
	$scope.showBrandBar = false;

	if (!embedService.isEmbedded) {
		$scope.showBrandBar = true;
		import(/* webpackChunkName: "brandBar" */ '../components/brandBar').then(
			({ BrandBar: BrandBarComponent }) => {
				app.compileProvider!.component('brandBar', BrandBarComponent);
				$scope.brandBarLoaded = true;
				$scope.$apply();
			}
		);
	}

	userModel.getCurrentUserAsync().then(user => {
		$scope.user = user;
		$scope.$apply();
	});
}
