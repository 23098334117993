import * as angular from 'angular';

angular.module('app').factory('tagTooltipService', tagTooltipService);

function tagTooltipService() {
	const cachedAssetsById = {};

	return {
		cachedAssetsById,
	};
}
