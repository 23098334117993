import * as angular from 'angular';

angular.module('app').directive('editableText', editableText);

function editableText() {
	return {
		restrict: 'E',
		replace: true,
		require: '?ngModel',
		scope: true,
		template(tElem, tAttrs) {
			const isMultiline = tAttrs.multiline === 'true';

			return isMultiline
				? '<textarea ng-attr-placeholder="{{placeholder}}"></textarea>'
				: '<input type="text" ng-attr-placeholder="{{placeholder}}"></input>';
		},
		link(scope, elem, attrs, ngModelController) {
			if (scope.$eval(attrs.multiline)) {
				elem.autosize();
			}

			attrs.$observe('textPlaceholder', placeholder => {
				scope.placeholder = placeholder;
			});

			const unwatch = scope.$watch(
				() => ngModelController.$viewValue,
				value => {
					if (value) {
						elem.trigger('autosize.resize');
						unwatch();
					}
				}
			);

			scope.$watch('editable', editable => {
				if (typeof scope.editable !== 'undefined') {
					elem.attr('readonly', !editable);
				}

				// trigger an autosize reevaluation in case the controller updated the text value
				elem.trigger('autosize.resize');
			});
		},
	};
}
