import * as angular from 'angular';
import { IUserService, IAccountService } from '../services';

interface ICreatorInfoController extends angular.IController {
	refreshCreator: () => Promise<void>;
}

angular.module('app').directive('creatorInfo', creatorInfo);

function creatorInfo(): angular.IDirective<ICreatorInfoController> {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: require('../../views/templates/creatorInfo.html'),
		scope: {
			creatorAgent: '=',
		},
		controller(
			this: ICreatorInfoController,
			$scope: angular.IScope,
			userService: IUserService,
			accountService: IAccountService
		) {
			this.refreshCreator = async function () {
				if ($scope.creatorAgent && $scope.creatorAgent.group && $scope.creatorAgent.group.id) {
					$scope.creatorId = $scope.creatorAgent.group.id;
					const creator = await accountService.getAccountDetailsAsync($scope.creatorAgent.group.id);
					$scope.$apply(scope => {
						scope.creator = creator.group;
					});
				} else if ($scope.creatorAgent && $scope.creatorAgent.user && $scope.creatorAgent.user.id) {
					$scope.creatorId = $scope.creatorAgent.user.id;
					const creator = await userService.getUserDetailsAsync($scope.creatorAgent.user.id);
					$scope.$apply(scope => {
						scope.creator = creator;
					});
				} else {
					$scope.creator = null;
				}

				return;
			};
		},
		link(scope, elem, attrs, controller) {
			scope.creator = null;

			scope.$watch('creatorAgent', () => {
				controller.refreshCreator();
			});
		},
	};
}
