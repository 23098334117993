import * as angular from 'angular';
import * as _ from 'lodash';
import { CancellationToken } from '.';

angular.module('app').factory('promiseUtilityService', promiseUtilityService);

function promiseUtilityService($q: angular.IQService, $timeout: angular.ITimeoutService) {
	function forEachAsync<T>(
		source: ArrayLike<T>,
		callback: (value: T, index: number) => unknown,
		cancellationToken?: CancellationToken
	) {
		const collection = Array.from(source);
		const deferred = $q.defer<void>();
		let timer;
		let index = 0;

		if (cancellationToken) {
			cancellationToken.whenCanceled(() => {
				$timeout.cancel(timer);
				deferred.reject();
			});
		}

		function processItemSoon() {
			timer = $timeout(processItem, 0, false);
		}

		function processItem() {
			if (!collection.length) {
				deferred.resolve();
				return;
			}

			if (callback(collection.pop()!, index++) === false) {
				deferred.resolve();
			} else {
				processItemSoon();
			}
		}

		processItemSoon();
		return deferred.promise;
	}

	function parallelForEach<T>(
		source: ArrayLike<T>,
		parallelOptions: { cancellationToken?: CancellationToken; maxDegreeOfParallelism: number },
		callback: (x: T) => void
	) {
		return parallelMap(source, parallelOptions, callback).then(() => {});
	}

	function parallelMap<T, TResult>(
		source: ArrayLike<T>,
		parallelOptions: { cancellationToken?: CancellationToken; maxDegreeOfParallelism: number },
		callback: (x: T) => TResult | angular.IPromise<TResult>
	) {
		const deferred = $q.defer<TResult[]>();
		const promises: angular.IPromise<TResult>[] = [];

		function continueWork(count: number) {
			if (!source.length) {
				$q.all(promises)
					.then(result => {
						deferred.resolve(result);
					})
					.catch(reason => {
						deferred.reject(reason);
					});

				return;
			}

			if (parallelOptions.cancellationToken && parallelOptions.cancellationToken.isCancelled) {
				deferred.reject('cancelled');
				return;
			}

			_.take(source, count).forEach(f => {
				promises.push(
					$q.when(callback(f)).finally(() => {
						continueWork(1);
					})
				);
			});

			source = _.drop(source, count);
		}

		continueWork(parallelOptions.maxDegreeOfParallelism);

		return deferred.promise;
	}

	return {
		forEachAsync,
		parallelForEach,
		parallelMap,
	};
}

export type IPromiseUtilityService = ReturnType<typeof promiseUtilityService>;
