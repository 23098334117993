import * as angular from 'angular';
import { CancellationToken, IAppSettings } from '.';
import { IUriUtility } from '../helpers';

export interface AssetFile {
	id?: string;
	name?: string;
	mediaType?: string;
}

angular.module('app').factory('fileService', fileService);

function fileService(
	$http: angular.IHttpService,
	appSettings: IAppSettings,
	uriUtility: IUriUtility
) {
	function getFileAsync(fileId: string, cancellationToken: CancellationToken) {
		return $http
			.get<AssetFile>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}files/{id}`, {
					id: fileId,
				}),
				{ timeout: cancellationToken }
			)
			.then(response => response.data);
	}

	return {
		getFileAsync,
	};
}

export type IFileService = ReturnType<typeof fileService>;
