import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('infiniteScroll', infiniteScroll);

function infiniteScroll($timeout, $window) {
	return {
		restrict: 'A',
		link(scope, element, attributes) {
			const iScroll = scope.$eval(attributes.infiniteScroll);

			let distancePastBottom = scope.$eval(attributes.infiniteScrollDistance);
			if (!distancePastBottom && distancePastBottom !== 0) {
				distancePastBottom = 600;
			}

			let isLoading = false;
			let timer;

			function getIsEnabled() {
				return attributes.infiniteScrollEnabled
					? scope.$eval(attributes.infiniteScrollEnabled)
					: true;
			}

			function loadNow() {
				cancelLoadSoon();
				if (!getIsEnabled()) {
					return;
				}

				if (!element.is(':visible')) {
					return;
				}

				if (
					element.scrollTop() + element.height() + distancePastBottom >=
					element[0].scrollHeight
				) {
					const promise = iScroll();
					if (promise) {
						isLoading = true;
						promise
							.then(moreData => {
								if (moreData) {
									$timeout(loadNow);
								}
							})
							.finally(() => {
								isLoading = false;
							});
						return;
					}
				}
			}

			function loadSoon() {
				if (!isLoading && !timer) {
					timer = $timeout(loadNow, 100, false);
				}
			}

			function cancelLoadSoon() {
				if (timer) {
					$timeout.cancel(timer);
					timer = null;
				}
			}

			element.scroll(loadSoon);
			scope.$watch(attributes.infiniteScrollCollection, loadSoon);
			scope.$watch(() => element[0].scrollHeight, _.debounce(loadSoon, 50));
			angular.element($window).on('resize', loadSoon);

			scope.$on('$destroy', () => {
				angular.element($window).off('resize', loadSoon);
				cancelLoadSoon();
			});

			scope.$watch(getIsEnabled, loadNow);
		},
	};
}
