import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import { IEmbedService, UserModel } from '../services';

declare module '@uirouter/angularjs/lib/interface' {
	export interface Ng1StateDeclaration {
		title?: string;
	}
}

angular.module('app').config(registerRoutes);

function registerRoutes(
	$locationProvider: angular.ILocationProvider,
	$stateProvider: uirouter.StateProvider,
	$urlServiceProvider: uirouter.UrlService,
	errorServiceProvider: angular.IServiceProvider
) {
	'ngInject';

	$locationProvider.html5Mode(true);

	const resolve = {
		viewMode(embedService: IEmbedService) {
			return embedService.setViewMode();
		},
		currentUser($log: angular.ILogService, userModel: UserModel) {
			return userModel.getCurrentUserAsync().catch(error => {
				$log.warn(`User resolve fail:${error}`);
			});
		},
	};

	const mapDynamicParams = params => {
		const result = {};
		params.forEach(value => {
			result[value] = { dynamic: true };
		});
		return result;
	};

	const assetsUrlParams =
		'?returnUrl&groupId&bucket&pickerMode&assetPickerFields&disableSidebarLeft&viewStyle&q&select&filter&sort&board&savedFilter&reload&externalEditorKinds&locale';
	// Dynamic parameters can be updated without reloading the controller and template. This is necessary for features like find-as-you-type that update the q= query parameter.
	const dynamicParams = mapDynamicParams([
		'q',
		'select',
		'filter',
		'sort',
		'board',
		'savedFilter',
		'reload',
	]);

	$stateProvider
		.state('master', {
			abstract: true,
			controller: 'BrandBarController',
			templateUrl: require('../../views/templates/brandBar.html'),
		})
		.state('assetsParent', {
			abstract: true,
			parent: 'master',
			controller: 'AssetListController',
			templateUrl: require('../../views/asset/list.html'),
			params: {
				...dynamicParams,
			},
			resolve,
		})
		.state('boardSharedDetails', {
			parent: 'assetsParent',
			url: `/shares/boards/:shareToken${assetsUrlParams}`,
			title: 'Shared Board Details',
		})
		// This state allows the query string to be updated without reloading the controller and template. This is necessary for features like find-as-you-type that update the q= query parameter.
		.state('assets', {
			parent: 'assetsParent',
			url: `/:accountToken${assetsUrlParams}`,
			params: {
				accountToken: {
					value: null,
				},
			},
			title: 'Home',
		})
		.state('assetsEmbed', {
			parent: 'assetsParent',
			url: `/embed/:accountToken${assetsUrlParams}`,
			params: {
				accountToken: {
					value: null,
				},
			},
			title: 'Home',
		})
		// This state is used as a parent by several states below to allow navigation to and from the list view without losing state, such as selected assets and scroll position.
		.state('assetsChild', {
			abstract: true,
			parent: 'assetsParent',
			views: {
				child: {
					template: '<div ui-view></div>',
				},
			},
		})
		.state('details', {
			parent: 'assetsChild',
			url: '^/assets/:assetId',
			title: 'Details',
			controller: 'AssetDetailsController',
			templateUrl: require('../../views/asset/details.html'),
		})
		.state('assetStats', {
			parent: 'assetsChild',
			url: '^/assets/:assetId/stats',
			title: 'Stats',
			controller: 'AssetStatsController',
			templateUrl: require('../../views/asset/stats.html'),
		})
		.state('boardStats', {
			parent: 'assetsChild',
			url: '^/boards/:boardId/stats',
			title: 'Board Stats',
			controller: 'BoardStatsController',
			templateUrl: require('../../views/boardStats.html'),
		})
		.state('revisionDetails', {
			parent: 'assetsChild',
			url: '^/assets/:assetId/rev/:revisionId',
			title: 'Revision Details',
			controller: 'AssetDetailsController',
			templateUrl: require('../../views/asset/details.html'),
		})
		.state('preview', {
			parent: 'assetsChild',
			url: '^/assets/:assetId/preview',
			title: 'Preview',
			controller: 'AssetPreviewController',
			templateUrl: require('../../views/asset/preview.html'),
		})
		.state('shareEmbed', {
			url: '/shares/:shareToken/embed?fs&dl&autoplay&disableDetails&disableShare&bookLayout',
			title: 'Share Embed',
			controller: 'ShareEmbedController',
			templateUrl: require('../../views/embed.html'),
			data: {
				disableUploads: true,
			},
		})
		.state('sharedDetails', {
			parent: 'master',
			url: '/shares/:shareToken',
			title: 'Shared Details',
			controller: 'AssetSharesController',
			templateUrl: require('../../views/asset/shares.html'),
			resolve: { currentUser: resolve.currentUser },
		})
		.state('boardSharedAssetDetails', {
			parent: 'master',
			url: '/shares/boards/:shareToken/assets/:assetId',
			title: 'Shared Details',
			controller: 'AssetSharesController',
			templateUrl: require('../../views/asset/shares.html'),
			resolve: { currentUser: resolve.currentUser },
		})
		.state('sharedPreview', {
			parent: 'assetsChild',
			url: '^/shares/:shareToken/preview',
			title: 'Shared Preview',
			controller: 'AssetPreviewController',
			templateUrl: require('../../views/asset/preview.html'),
		})
		.state('boardSharedAssetPreview', {
			parent: 'assetsChild',
			url: '^/shares/boards/:shareToken/assets/:assetId/preview',
			title: 'Shared Preview',
			controller: 'AssetPreviewController',
			templateUrl: require('../../views/asset/preview.html'),
		})
		.state('exif', {
			parent: 'assetsChild',
			url: '^/assets/:assetId/metadata?revisionId',
			title: 'Extended Metadata',
			controller: 'AssetExifController',
			templateUrl: require('../../views/asset/exif.html'),
		})
		.state('searchHelp', {
			parent: 'assetsChild',
			url: '^/help/search',
			title: 'Search Help',
			controller: 'SearchHelpController',
			templateUrl: require('../../views/searchHelp.html'),
		})
		.state('webOptimizedHelp', {
			parent: 'simpleMaster',
			url: '/help/weboptimized',
			title: 'Web Optimized URLs',
			templateUrl: require('../../views/webOptimizedHelp.html'),
		})
		.state('simpleMaster', {
			abstract: true,
			parent: 'master',
			templateUrl: require('../../views/master-simple.html'),
		})
		.state('error', {
			parent: 'simpleMaster',
			url: '/error',
			title: 'Error',
			templateUrl: require('../../views/error/error.html'),
		})
		.state('forbidden', {
			parent: 'simpleMaster',
			url: '/forbidden',
			title: 'Forbidden',
			templateUrl: require('../../views/error/forbidden.html'),
			data: {
				disableUploads: true,
			},
		})
		.state('about', {
			parent: 'simpleMaster',
			url: '/about',
			title: 'About',
			templateUrl: require('../../views/about.html'),
		})
		.state('notFound', {
			parent: 'assetsChild',
			url: '/notfound',
			title: 'Not Found',
			controller: 'NotFoundController',
			templateUrl: require('../../views/error/notfound.html'),
		})
		.state('admin', {
			parent: 'master',
			url: '/admin',
			title: 'Admin',
			controller: 'AdminController',
			templateUrl: require('../../views/admin/admin.html'),
		});

	$urlServiceProvider.rules.when('/admin/jobs', '/admin'); // page was moved
	$urlServiceProvider.rules.otherwise(() => {
		errorServiceProvider.$get().showNotFound();
	});
}
