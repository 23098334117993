import * as angular from 'angular';

angular.module('app').component('assetDetailsThumbnailPreview', {
	templateUrl: require('../../views/templates/assetDetailsThumbnailPreview.html'),
	controller: AssetDetailsThumbnailPreview,
	bindings: {
		isEditable: '<',
		asset: '<',
		revision: '<',
		thumbnailOverrideUrl: '<',
		isWorking: '<',
		thumbnailPreviewCanvasHasData: '<',
	},
});

function AssetDetailsThumbnailPreview($element, geometryUtility) {
	const ctrl = this;
	const maxWidth = $element.parent().width();
	const maxHeight = $element.parent().height();

	ctrl.imgWidth = Math.round(maxWidth);
	ctrl.imgHeight = Math.round(maxWidth / (16 / 9));

	ctrl.previewUrl = undefined;
	ctrl.$onInit = renderThumbnail;
	ctrl.$onChanges = function (changes) {
		if (changes.revision) {
			renderThumbnail();
			ctrl.thumbnailOverrideUrl = null;
		} else if (changes.thumbnailOverrideUrl) {
			renderThumbnail(changes.thumbnailOverrideUrl.currentValue);
		}
	};

	function renderThumbnail(thumbnailOverrideUrl) {
		const thumbnail = ctrl.asset.getBestThumbnailForSize(Math.max(maxWidth, maxHeight));
		if (thumbnail) {
			const bounds = geometryUtility.getBestFitForConstraint(
				maxWidth,
				null,
				thumbnail.width,
				thumbnail.height
			);
			ctrl.imgWidth = Math.round(bounds.width);
			ctrl.imgHeight = Math.round(bounds.height);

			ctrl.previewUrl = thumbnailOverrideUrl || thumbnail.url;
		}
	}
}
