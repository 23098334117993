import * as angular from 'angular';

angular.module('app').directive('ringMeter', ringMeter);

function ringMeter($timeout) {
	return {
		restrict: 'AE',
		replace: true,
		scope: {
			value: '=',
		},
		templateUrl: require('../../views/templates/ringMeter.html'),
		link(scope, elem) {
			const ringElement = elem.find('.ring-meter-foreground');
			let maxRingLength = 0;

			scope.maxRingLength = maxRingLength;
			scope.getRingLength = function () {
				return Math.floor(maxRingLength - maxRingLength * scope.value);
			};

			// wait for the template to render so we can measure the size of the ring
			$timeout(() => {
				maxRingLength = ringElement[0].getBBox().width * Math.PI;
				scope.maxRingLength = Math.floor(maxRingLength);
			}, 0);
		},
	};
}
