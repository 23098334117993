import * as angular from 'angular';

export interface SettingAccessor<T> {
	get(): T | null | undefined;
	set(value: T | null | undefined, saveSetting?: boolean): void;
}

angular.module('app').factory('localSettingsService', localSettingsService);

function localSettingsService($log: angular.ILogService, $window: angular.IWindowService) {
	function isStorageSupported(storage: 'localStorage' | 'sessionStorage') {
		const key = 'storage_test';
		try {
			$window[storage].setItem(key, key);
			$window[storage].removeItem(key);
			return true;
		} catch (e) {
			return false;
		}
	}

	let storage;
	// We assign `storage` this way to avoid "Access Denied" errors in newer IEs.
	if (isStorageSupported('localStorage')) {
		storage = localStorage;
	} else if (isStorageSupported('sessionStorage')) {
		$log.warn(
			'Local settings will only persist through a refresh due to lack of localStorage support.'
		);
		storage = sessionStorage;
	} else {
		$log.warn('Local settings will not persist due to lack of localStorage support.');
		storage = (function () {
			const db = {};

			return {
				getItem: function getItem(key: string) {
					return db[key];
				},
				setItem: function setItem(key: string, value: string) {
					db[key] = value;
				},
			};
		})();
	}

	const cachedSettingAccessors: { [settingName: string]: SettingAccessor<unknown> } = {};
	const api = {
		get(key: string) {
			const value = storage.getItem(key);

			try {
				return JSON.parse(value);
			} catch (e) {
				return null;
			}
		},
		set(key: string, value: any) {
			storage.setItem(key, JSON.stringify(value));
		},
		createSettingAccessor<T>(settingName: string, defaultValue: T | (() => T)): SettingAccessor<T> {
			const cachedAccessor = cachedSettingAccessors[settingName];
			if (cachedAccessor) {
				return cachedAccessor as SettingAccessor<T>;
			}

			function create() {
				let hasRead = false;
				let setting: T | null | undefined;
				return {
					get() {
						if (!hasRead) {
							setting = api.get(settingName);
							if (setting === null) {
								setting =
									typeof defaultValue === 'function' ? (defaultValue as () => T)() : defaultValue;
							}

							hasRead = true;
						}
						return setting;
					},
					set(value: T | null | undefined, saveSetting?: boolean) {
						setting = value;

						if (saveSetting === undefined || saveSetting) {
							api.set(settingName, value);
						}

						hasRead = true;
					},
				};
			}
			return (cachedSettingAccessors[settingName] = create());
		},
	};

	return api;
}

export type ILocalSettingsService = ReturnType<typeof localSettingsService>;
