import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('assetFileBulkDownloadLink', assetFileBulkDownloadLink);

function assetFileBulkDownloadLink(utility, appSettings) {
	const maxUriLength = 2000; // see http://www.boutell.com/newfaq/misc/urllength.html

	return {
		restrict: 'AE',
		scope: {
			assets: '=',
			shareToken: '=?',
		},
		transclude: true,
		replace: true,
		template:
			'<a ng-href="{{vm.uri | shareToken: shareToken }}" target="_self" title="{{vm.processing ? (\'fileDownload.startingDownload\' | translate) : vm.getUiHint()}}" ng-transclude ng-click="vm.processing = true" ng-show="vm.uri"></a>',
		controller($scope, $filter) {
			const vm = this;

			vm.getUiHint = function () {
				let message;
				if (vm.fileCount === 1) {
					message = $filter('translate')('fileDownload.downloadFile');
				} else if (vm.fileCountIncluded !== vm.fileCount) {
					message = $filter('translate')('fileDownload.downloadZipPartial', {
						numFiles: vm.fileCountIncluded,
					});
				} else {
					message = $filter('translate')('fileDownload.downloadZip', {
						numFiles: vm.fileCountIncluded,
					});
				}

				message += ` ${$filter('translate')('fileDownload.fileSizeEstimation', {
					fileSize: utility.formatByteCount(vm.byteCount, 1),
				})}`;
				return message;
			};

			$scope.$watchCollection('assets', assets => {
				vm.processing = false;

				if (assets && assets.length) {
					if (assets.length === 1) {
						if (assets[0].file && assets[0].file.url) {
							vm.uri = assets[0].file.downloadUrl;
							vm.fileCountIncluded = 1;
							vm.byteCount = assets[0].file.byteCount;
						}
					} else {
						let uri = `${appSettings.assetServiceBaseUri}files/bulkdownload?assetIds=`;
						let countIncluded = 0;
						let byteCount = 0;

						_.forEach(assets, asset => {
							if (asset && asset.file && asset.file.url) {
								const idPart = `${encodeURIComponent(asset.id)},`;
								if (uri.length + idPart.length > maxUriLength) {
									return false;
								}

								uri += idPart;
								countIncluded++;
								byteCount += asset.file.byteCount;
							}
							return true;
						});

						vm.uri = uri;
						vm.fileCountIncluded = countIncluded;
						vm.byteCount = byteCount;
					}

					vm.fileCount = assets.length;
				} else {
					vm.uri = '';
				}
			});
		},
		controllerAs: 'vm',
	};
}
