import * as angular from 'angular';
import { IConstants } from '../infrastructure';
import * as uirouter from '@uirouter/angularjs';
import * as _ from 'lodash';
import {
	IBucketService,
	IEmbedService,
	IFacetQueryService,
	IFilter,
	IFilterService,
	IListHelper,
	ISearchTextService,
	UserModel,
} from '../services';

interface IMenuItem {
	text?: string;
	kind?: string;
	group?: string;
	filter?: IFilter;
	callback?(IMenuItem): void;
	action?: {
		title: string;
		text: string;
		className: string;
		callback(IMenuItem): void;
	};
}

angular.module('app').directive('filtersMenu', filtersMenu);

function filtersMenu(
	$filter: angular.IFilterService,
	$state: uirouter.StateService,
	bucketService: IBucketService,
	constants: IConstants,
	embedService: IEmbedService,
	facetQueryService: IFacetQueryService,
	filterService: IFilterService,
	listHelper: IListHelper,
	searchTextService: ISearchTextService,
	userModel: UserModel
) {
	const searchForFilter = item => {
		const sort = item.filter.sort ? item.filter.sort.split(':')[0] : null; // handles existing filters without null sort values
		const savedFilter = item.filter.query.startsWith('& ') // handles existing filters with malformed syntax (AMB-1207)
			? `(${item.filter.query})`
			: item.filter.query;
		filterService.selectedSavedFilter = savedFilter;
		filterService.selectedSavedFilterName = item.filter.name;

		const assetListState = embedService.isEmbedded
			? 'assetsEmbed'
			: $state.is('boardSharedDetails')
			? 'boardSharedDetails'
			: 'assets';

		$state.go(assetListState, {
			q: null,
			filter: null,
			board: null,
			savedFilter,
			sort,
		});
	};

	return {
		restrict: 'E',
		scope: true,
		templateUrl: require('../../views/templates/filtersMenu.html'),
		link(scope) {
			let filters = [] as IMenuItem[];
			let query;
			let sort;
			let selectedItem;
			let existingFilter;
			scope.isConfirmationModalVisible = false;
			scope.isSaveFilterModalVisible = false;

			const showSaveFilterModal = inputText => {
				scope.saveFilterInputText = inputText;
				scope.isSaveFilterModalVisible = true;
			};

			const hideSaveFilterModal = () => {
				scope.isSaveFilterModalVisible = false;
			};

			const showConfirmationModal = (modalAction, filterName, modalMessage, confirmAction) => {
				scope.modalAction = modalAction;
				scope.filterName = filterName;
				scope.modalMessage = modalMessage;
				scope.confirmAction = confirmAction;
				scope.isConfirmationModalVisible = true;
			};

			const hideConfirmationModal = () => {
				scope.isConfirmationModalVisible = false;
			};

			const onSelectSaveCurrentFilter = () => {
				query = listHelper.createSearchQuery(
					searchTextService.inputText,
					facetQueryService.filters,
					filterService.selectedSavedFilter
				);

				const sortKey = _.find(listHelper.sortOptions, { key: $state.params.sort })
					? $state.params.sort
					: listHelper.getDefaultSort(searchTextService.inputText);

				sort = _.find(listHelper.sortOptions, { key: sortKey });

				showSaveFilterModal(query);
			};

			scope.saveFilter = filterName => {
				if (!filterName || !filterName.trim()) {
					hideSaveFilterModal();
					return;
				}

				existingFilter = _.find(filters, { filter: { name: filterName } });
				if (existingFilter) {
					hideSaveFilterModal();
					showReplaceFilterModal(filterName);
					return;
				}

				filterService.createFilterAsync({ name: filterName, query, sort }).then(result => {
					const filter = createFilterMenuItem(result);
					if (filter) {
						filters.push(filter);
					}
					hideSaveFilterModal();
					updateScopeFilters();
					scope.$apply();
				});
			};

			let unWatchIsConfirmationModalVisible;
			const showReplaceFilterModal = filterName => {
				showConfirmationModal(
					'replace',
					filterName,
					$filter('translate')('filterMenu.replaceFilter', { filterName }),
					replaceFilter
				);

				unWatchIsConfirmationModalVisible = scope.$watch('isConfirmationModalVisible', value => {
					if (!value) {
						showSaveFilterModal(filterName);
						unWatchIsConfirmationModalVisible();
					}
				});
			};

			const replaceFilter = () => {
				unWatchIsConfirmationModalVisible();

				filterService
					.editFilterAsync(existingFilter.filter.id, { name: scope.filterName, query, sort })
					.then(result => {
						filters = _.without(filters, existingFilter);
						filters.push(createFilterMenuItem(result));
						hideConfirmationModal();
						updateScopeFilters();
					});
			};

			const onSelectDeleteFilter = item => {
				const modalAction = 'delete';
				const filterName = item.filter.name;
				showConfirmationModal(
					modalAction,
					filterName,
					$filter('translate')('filterMenu.confirmAction', {
						action: modalAction,
						filterName,
					}),
					deleteFilter
				);
				selectedItem = item;
			};

			const deleteFilter = () => {
				filterService.deleteFilterAsync(selectedItem.filter.id).then(() => {
					filters = _.without(filters, selectedItem);
					hideConfirmationModal();
					updateScopeFilters();
				});
			};

			const createFilterMenuItem: (IFilter) => IMenuItem = filter => ({
				group: 'filters',
				action: {
					title: $filter('translate')('generic.actions.delete'),
					text: '\xD7',
					className: 'close',
					callback: onSelectDeleteFilter,
				},
				text: filter.name,
				filter,
				callback: searchForFilter,
			});

			const updateScopeFilters = () => {
				scope.filters = (filters.length
					? filters
					: ([
							{ text: $filter('translate')('filterMenu.noSavedFilters'), kind: 'prompt' },
					  ] as IMenuItem[])
				).concat(
					{ kind: 'separator' },
					{
						text: $filter('translate')('filterMenu.saveCurrentFilter'),
						callback: onSelectSaveCurrentFilter,
					}
				);
			};

			const getFiltersAsync = async () => {
				const result = await filterService.getFiltersAsync({ limit: 100 });
				filters = result.items
					.filter(filter => filter.name.indexOf(constants.autoGeneratedFilterPrefix) !== 0)
					.map(createFilterMenuItem);
				updateScopeFilters();
			};

			userModel.getCurrentUserAsync().then(user => {
				if (!user.isAnonymous) {
					scope.$watch(() => bucketService.getCurrentBucket(), getFiltersAsync);
				}
			});

			scope.filters = [{ text: $filter('translate')('filterMenu.loading'), kind: 'prompt' }];
		},
	};
}
