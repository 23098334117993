import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('customFieldNameInput', customFieldNameInput);

function customFieldNameInput($timeout) {
	return {
		restrict: 'E',
		scope: {
			source: '=',
			assets: '=',
			model: '=',
		},
		templateUrl: require('../../views/templates/customFieldNameInput.html'),
		link(scope, elem) {
			const assets = _.isArray(scope.assets) ? scope.assets : [scope.assets];

			function updateCustomFieldName(customFieldName) {
				const customFields = _.compact(_.flatten(_.map(assets, 'other')));

				if (customFieldName) {
					customFieldName = customFieldName.trim();
				}

				if (customFields && _.find(customFields, { name: customFieldName })) {
					scope.model.splice(scope.model.indexOf(scope.source), 1);
					_.invoke(scope.source.properties, 'unwatch');

					if (scope.source.unwatch) {
						scope.source.unwatch();
					}

					return;
				}

				assets.forEach(asset => {
					asset.other = asset.other || [];

					const existingCustomField = _.find(asset.other, { name: customFieldName });
					if (!existingCustomField) {
						asset.other.push({ name: customFieldName });
					}
					asset.other = _.reject(asset.other, { name: null });
				});
			}

			scope.$watch('source.name', (newValue, oldValue) => {
				if (newValue && newValue !== oldValue) {
					updateCustomFieldName(newValue);
					if (scope.source.name) {
						$timeout(() => {
							elem
								.closest('.metadata-field')
								.find('.metadata-field-value-wrapper input')[0]
								.focus();
						}, 0);
					}
				}
			});
		},
	};
}
