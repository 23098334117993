import * as angular from 'angular';
import { Bucket, IBucketService, IEmbedService } from '.';

angular.module('app').factory('groupService', groupService);

function groupService(
	$log: angular.ILogService,
	bucketService: IBucketService,
	embedService: IEmbedService
) {
	let currentAccountToken: string | undefined;
	let nextAccountToken: string | undefined;
	let nextBucket: string;
	let currentGroupBucket: Bucket;

	async function getBucketAsync(
		accountIdOrToken: string | undefined,
		bucketIdFromParam: string | undefined
	): Promise<void> {
		const isEmbeddedModeEnabled = embedService.isEmbedded;

		if (accountIdOrToken) {
			const buckets = await bucketService.getBucketsForAccountAsync(accountIdOrToken);
			const bucket = buckets.find(
				x => x.group?.id === accountIdOrToken || x.group?.token === accountIdOrToken
			);
			if (bucket) {
				currentGroupBucket = bucket;
				await bucketService.setCurrentBucketAsync(
					currentGroupBucket,
					!isEmbeddedModeEnabled,
					false
				);
				return;
			}
		} else if (bucketIdFromParam) {
			const bucket = await bucketService.getBucketAsync(bucketIdFromParam);
			if (bucket) {
				currentAccountToken = undefined;
				await bucketService.setCurrentBucketAsync(bucket, !isEmbeddedModeEnabled);
				return;
			}
		}

		$log.warn('Bucket not found', { accountIdOrToken, bucketIdFromParam });
	}

	async function setCurrentAccountFromLocationAsync() {
		if (!nextAccountToken && nextBucket) {
			return await getBucketAsync(undefined, nextBucket);
		}

		if (nextAccountToken) {
			currentAccountToken = nextAccountToken;
		} else {
			nextAccountToken = currentAccountToken;
		}

		if (currentAccountToken) {
			return await getBucketAsync(currentAccountToken, nextBucket);
		}

		return undefined;
	}

	return {
		set currentAccountToken(value) {
			currentAccountToken = value;
		},
		get currentAccountToken() {
			return currentAccountToken;
		},
		set nextAccountToken(value) {
			nextAccountToken = value;
		},
		get nextAccountToken() {
			return nextAccountToken;
		},
		set nextBucket(value) {
			nextBucket = value;
		},
		get nextBucket() {
			return nextBucket;
		},
		set currentGroupBucket(value) {
			currentGroupBucket = value;
		},
		get currentGroupBucket() {
			return currentGroupBucket;
		},
		setCurrentAccountFromLocationAsync,
	};
}

export type IGroupService = ReturnType<typeof groupService>;
