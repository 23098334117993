import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('selectGroup', selectGroup).directive('selectable', selectable);

function selectable() {
	return {
		restrict: 'AE',
		scope: true,
		require: '^selectGroup',
		link(scope, elem, attrs, groupController) {
			scope.isSelected = false;

			function setIsSelected(isSelected) {
				scope.isSelected = isSelected;
			}

			function getItem() {
				return scope.$eval(attrs.selectableItem);
			}

			const deregister = groupController.registerSelectable({
				element: elem,
				setIsSelected,
				getItem,
			});

			scope.$on('$destroy', deregister);

			function handleSelect(e) {
				groupController.handleSelect(elem, {
					isSpan: e.shiftKey,
					isAddition:
						e.ctrlKey ||
						e.metaKey ||
						angular.element(e.target).is('[select-toggle]') ||
						scope.isSmallViewport,
				});
			}

			elem.on({
				click(e) {
					handleSelect(e);
				},
				dblclick(e) {
					return attrs.isPickerMode ? handleSelect(e) : groupController.deselectAll();
				},
			});
		},
	};
}

function selectGroup($document, $timeout, keyCodes) {
	return {
		restrict: 'AE',
		controller($scope, $element, $parse, $attrs) {
			const vm = this;
			const selectables = [];
			let lastElem;
			const onSelectionChangeCallback = $parse($attrs.selectGroupSelectionChange);
			const isMultiSelectEnabled =
				($scope.$eval($attrs.selectGroupMode) || 'multiple') === 'multiple';
			const updateSelectionSoon = _.debounce(notifySelectionChange, 10);

			vm.selectAll = function () {
				setAllSelection(true);
			};

			vm.deselectAll = function () {
				setAllSelection(false);
			};

			vm.handleSelect = function (elem, options) {
				const currentIndex = getItemIndex(elem);
				const currentItem = selectables[currentIndex];
				let previousItemIndex;
				let startIndex;
				let stopIndex;

				$scope.$apply(() => {
					if (isMultiSelectEnabled && options.isAddition) {
						currentItem.setIsSelected(!currentItem.isSelected);
					} else if (isMultiSelectEnabled && options.isSpan && lastElem) {
						previousItemIndex = getItemIndex(lastElem);
						startIndex = currentIndex;
						stopIndex = previousItemIndex;

						if (stopIndex < startIndex) {
							startIndex = previousItemIndex;
							stopIndex = currentIndex;
						}

						for (let i = startIndex; i <= stopIndex; i++) {
							selectables[i].setIsSelected(true);
						}
					} else {
						for (let i = 0; i < selectables.length; i++) {
							selectables[i].setIsSelected(selectables[i].child.element.is(elem));
						}
					}
				});

				lastElem = elem;

				updateSelectionSoon();
			};

			vm.registerSelectable = function (selectableItem) {
				selectables.push({
					isSelected: false,
					child: selectableItem,
					setIsSelected(isSelected) {
						this.isSelected = isSelected;
						this.child.setIsSelected(isSelected);
					},
				});

				return function deregister() {
					const itemIndex = getItemIndex(selectableItem.element);
					const item = selectables[itemIndex];
					selectables.splice(itemIndex, 1);

					if (item.isSelected) {
						item.setIsSelected(false);
						updateSelectionSoon();
					}
				};
			};

			vm.selectNext = function (isSpan) {
				let lastIndex = -1;
				for (let i = 0; i < selectables.length; i++) {
					if (selectables[i].isSelected) {
						lastIndex = i;
					}
				}
				const nextItem = selectables[lastIndex + 1];
				if (lastIndex !== -1 && nextItem) {
					vm.handleSelect(nextItem.child.element, { isSpan });
				}
			};

			vm.selectPrev = function (isSpan) {
				const firstItemIndex = selectables.findIndex(elem => elem.isSelected);
				if (firstItemIndex > 0) {
					vm.handleSelect(selectables[firstItemIndex - 1].child.element, { isSpan });
				}
			};

			function notifySelectionChange() {
				$timeout(() => {
					onSelectionChangeCallback($scope, { selectedItems: getSelectedItems() });
				});
			}

			function getSelectedItems() {
				return selectables
					.filter(x => x && x.isSelected)
					.map(selectableItem => selectableItem.child.getItem());
			}

			function getItemIndex(elem) {
				return _.findIndex(selectables, item => item.child.element.is(elem));
			}

			function setAllSelection(isSelected) {
				if (selectables.length) {
					$scope.$apply(() => {
						for (let i = 0; i < selectables.length; i++) {
							selectables[i].setIsSelected(isSelected);
							if (isSelected && !isMultiSelectEnabled) {
								break;
							}
						}
					});

					updateSelectionSoon();
				}
			}
		},
		link(scope, elem, attrs, groupController) {
			let mouseDownElement;

			function handleMouseDown(e) {
				mouseDownElement = e.target;
			}

			function handleDocumentClick(e) {
				// verify that the click event wasn't actually dragged from another element (AMB-1041)
				if (elem.is(':visible') && mouseDownElement === e.target) {
					const target = angular.element(e.target);

					// select2's drop mask hides itself on mouse down. A click (corresponding to the mouse up) still occurs, and
					// the e.target is document.body. Since no legitimate clicks will happen on document.body, it's safe to ignore
					// those for deselection purposes.
					if (
						target !== $document.attr('body') && // click wasn't on body
						!elem[0].contains(e.target) && // click was inside the selection region
						target.closest('body').length && // target element is in the DOM (happens when the click target is immediately removed)
						!target.closest('[preserve-selection]').length
					) {
						// target element doesn't want to clear selection when clicked
						groupController.deselectAll();
					}
				}
			}

			function handleKeyboardShortcut(e) {
				if (angular.element(e.target).is('input, select, textarea')) {
					return;
				}

				if (e.keyCode === keyCodes.escape) {
					groupController.deselectAll();
					e.preventDefault();
				} else if (e.keyCode === keyCodes.a && (e.ctrlKey || e.metaKey)) {
					groupController.selectAll();
					e.preventDefault();
				} else if (e.keyCode === keyCodes.leftArrow) {
					groupController.selectPrev(e.shiftKey);
				} else if (e.keyCode === keyCodes.rightArrow) {
					groupController.selectNext(e.shiftKey);
				}
			}

			const eventHandlers = {
				click: handleDocumentClick,
				keydown: handleKeyboardShortcut,
				mousedown: handleMouseDown,
			};

			scope.$on('selectable.selectAll', () => {
				groupController.selectAll();
			});

			$document.on(eventHandlers);

			scope.$on('$destroy', () => {
				$document.off(eventHandlers);
			});
		},
	};
}
