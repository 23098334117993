import * as angular from 'angular';

angular.module('app').directive('mediaelement', mediaelement);

function mediaelement($log) {
	return {
		restrict: 'A',
		scope: {
			muteAudio: '=?',
		},
		link,
	};

	function link(scope, element, attrs) {
		let player;
		const unregisterMuteWatch = scope.$watch('muteAudio', muted => {
			if (player) {
				player.setMuted(muted);
			}
		});

		function destroyPlayer() {
			if (!player) {
				return;
			}
			const p = player;
			player = null;
			if (p.isLoaded) {
				p.pause();
			}
			p.remove();

			element.children('source').remove();
			element.prop('src', undefined);
			element.attr('src', undefined);
			element[0].load();
		}

		function createPlayer() {
			let src;
			// eslint-disable-next-line no-undef
			return new MediaElementPlayer(element.get(0), {
				enableKeyboard: false,
				success(self) {
					src = element.attr('src');
					if (attrs.onEnded) {
						angular.element(self).on('ended', () => {
							scope.$evalAsync(attrs.onEnded);
						});
					}
				},
				error(error) {
					// don't log if player has been destroyed
					if (player) {
						$log.warn(`Video playback failed. Source: ${src}.`, error);
					}
					unregisterMuteWatch();
				},
			});
		}

		scope.$on('$destroy', destroyPlayer);

		import(/* webpackChunkName: "mediaelement" */ 'mediaelement').then(() => {
			scope.$evalAsync(() => {
				player = createPlayer();
				player.isDynamic = true;
				player.setMuted(scope.muteAudio);
			});
		});
	}
}
