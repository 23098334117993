import * as angular from 'angular';

angular.module('app').component('groupBucketQuota', {
	templateUrl: require('../../views/templates/groupBucketQuota.html'),
	controller: GroupBucketQuota,
	bindings: {
		byteCount: '<',
		byteCountMax: '<',
	},
});

function GroupBucketQuota($timeout, $filter) {
	const ctrl = this;

	ctrl.byteCountDisplay = '0 GB';
	ctrl.byteCountMaxDisplay = '0 GB';
	ctrl.percent = 0;
	ctrl.percentFull = 0;
	ctrl.initialized = false;

	// from MDN with minor modifications for readability
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
	function round(value, exp) {
		// If the exp is undefined or zero...
		if (typeof exp === 'undefined' || +exp === 0) {
			return Math.round(value);
		}

		value = +value;
		exp = +exp;

		// If the value is not a number or the exp is not an integer...
		if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
			return NaN;
		}

		// Shift
		value = value.toString().split('e');
		value = Math.round(+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);

		// Shift back
		value = value.toString().split('e');
		return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
	}

	function updateDisplayValues() {
		const byteCount = ctrl.byteCount || 0;
		const byteCountMax = ctrl.byteCountMax || 0;
		const roundedByteCountTB = round(byteCount / (1024 * 1024 * 1024 * 1024), -2);
		const roundedByteCountGB = round(byteCount / (1024 * 1024 * 1024), -2);
		const roundedByteCountMB = round(byteCount / (1024 * 1024), -2);
		if (roundedByteCountTB >= 1) {
			ctrl.byteCountDisplay = `${$filter('number')(roundedByteCountTB)} TB`;
		} else if (roundedByteCountGB >= 1) {
			ctrl.byteCountDisplay = `${$filter('number')(roundedByteCountGB)} GB`;
		} else {
			ctrl.byteCountDisplay = `${$filter('number')(roundedByteCountMB)} MB`;
		}
		ctrl.byteCountMaxDisplay = `${round(byteCountMax / (1024 * 1024 * 1024))} GB`;
		ctrl.percent = round((byteCount / byteCountMax) * 100);
		ctrl.percentFull = ctrl.percent > 100 ? 100 : ctrl.percent;
		ctrl.initialized = true;
	}

	function updateDisplayValuesSoon() {
		$timeout(updateDisplayValues, 1000);
	}

	ctrl.$onInit = function () {
		updateDisplayValuesSoon();
	};

	ctrl.$onChanges = function () {
		if (ctrl.initialized) {
			updateDisplayValuesSoon();
		}
	};
}
