import * as angular from 'angular';
import * as uirouter from '@uirouter/angularjs';
import { IAssetService, IErrorService, ICancellationService } from '../services';

angular.module('app').controller('AssetExifController', assetExif);

function assetExif(
	$scope: angular.IScope,
	$transition$: uirouter.Transition,
	assetService: IAssetService,
	errorService: IErrorService,
	cancellationService: ICancellationService
) {
	const $stateParams = $transition$.params();
	$scope.assetId = $stateParams.assetId;
	$scope.assetRevision = $stateParams.revisionId || undefined;
	$scope.status = 'loading';
	const cs = cancellationService.createCancellationSource();

	assetService
		.getAssetAsync($scope.assetId, undefined, cs.token)
		.then(data => {
			$scope.fileId = data.file && data.file.id;
			$scope.$emit('pageTitleChange', data.title);
			return assetService.getAssetExifToolAsync($scope.assetId, $scope.fileId, cs.token);
		})
		.then(data => {
			$scope.exifData = splitExifData(data);
			$scope.status = 'display';
			$scope.$apply();
		})
		.catch(error => {
			if (error.status === 'notfound') {
				errorService.showNotFound();
			} else {
				errorService.showError();
			}
		})
		.finally(cancellationService.cancelWhenDestroyed(cs, $scope));

	function splitExifData(items: object) {
		const result = {};
		const delimiter = ':';
		let splitKey;
		angular.forEach(items, (value, key) => {
			splitKey = key.split(delimiter);
			splitKey = splitKey[splitKey.length - 1];
			result[splitKey] = value;
		});
		return result;
	}
}
