import * as angular from 'angular';

angular.module('app').directive('searchForm', searchForm);

function searchForm($state, searchTextService, embedService) {
	return {
		restrict: 'AE',
		scope: true,
		require: '?^siteHeader',
		link(scope, elem, attrs, siteHeader) {
			scope.model =
				siteHeader && siteHeader.getShouldBindToSearchText()
					? searchTextService
					: { inputText: '' };

			scope.filter = function () {
				const assetListState = embedService.isEmbedded
					? 'assetsEmbed'
					: $state.is('boardSharedDetails')
					? 'boardSharedDetails'
					: 'assets';
				$state.go(assetListState, { q: scope.model.inputText || null, select: null });
			};

			scope.clear = function () {
				scope.model.inputText = null;
			};
		},
	};
}
