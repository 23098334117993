import * as angular from 'angular';

angular.module('app').filter('facetTerm', facetTerm);

function facetTerm($filter) {
	const renderers = {
		family(value) {
			return $filter('familyName')(value);
		},
		kind(value) {
			return $filter('assetKind')(value);
		},
		status(value) {
			return $filter('status')(value);
		},
		readPermission(value) {
			return $filter('permissionLabel')(value);
		},
		editPermission(value) {
			return $filter('permissionLabel')(value);
		},
	};

	return function (fTerm) {
		const render = renderers[fTerm.facet];
		return (render && render(fTerm.term)) || fTerm.term;
	};
}
