import * as angular from 'angular';

angular.module('app').service('muteService', muteService);

function muteService() {
	let muted: boolean | undefined;

	function toggleMuted() {
		muted = !muted;
	}

	return {
		set isMuted(val) {
			muted = val;
		},
		get isMuted() {
			return muted;
		},
		toggleMuted,
	};
}

export type IMuteService = ReturnType<typeof muteService>;
