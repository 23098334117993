import * as angular from 'angular';
import { AssetFile, IFileMapper, IUtility } from '.';
import { IConstants } from '../infrastructure';

export interface AssetFileFormat {
	name?: string;
	file?: AssetFile;
	files?: Array<AssetFile | undefined>;
	isSystemFormat?: boolean;
	firstFile?: AssetFile;
	isPlaceholder?: boolean;
}

angular.module('app').factory('formatMapper', formatMapper);

function formatMapper(utility: IUtility, fileMapper: IFileMapper, constants: IConstants) {
	function mapFormat(
		source: any,
		assetId: string | undefined,
		revisionId: string | undefined
	): AssetFileFormat | undefined {
		if (!source) {
			return undefined;
		}

		const format: AssetFileFormat = {
			name: source.name,
			file: source.file && fileMapper.map(source.file, assetId, revisionId),
			files:
				Array.isArray(source.files) &&
				source.files.map(x => fileMapper.map(x, assetId, revisionId)),
			isSystemFormat:
				(source.name && utility.startsWith(source.name, constants.previewFormatPrefix)) ||
				(source.name && utility.startsWith(source.name, constants.streamingFormatPrefix)) ||
				(source.name && utility.startsWith(source.name, constants.optimizedFormatPrefix)) ||
				source.name === constants.extractedTextFormatName,
		};

		utility.defineLazyProperty(format, 'firstFile', function (this: typeof format) {
			return this.file || (this.files && this.files.length && this.files[0]);
		});

		return format;
	}

	return {
		map: mapFormat,
	};
}

export type IFormatMapper = ReturnType<typeof formatMapper>;
