import * as angular from 'angular';
import * as _ from 'lodash';
import { CancellationToken, IAppSettings, IAssetService } from '.';
import { IAsset, IHttpUtility, IUriUtility } from '../helpers';
import { ICompletionSuggestion } from '@faithlife/amber-api';

angular.module('app').factory('completionService', completionService);

function completionService(
	$http: angular.IHttpService,
	$timeout: angular.ITimeoutService,
	uriUtility: IUriUtility,
	httpUtility: IHttpUtility,
	appSettings: IAppSettings,
	assetService: IAssetService
) {
	const freebaseBaseUri = 'https://www.googleapis.com/freebase/v1/';

	async function getAssetSuggestionsAsync(
		text: string,
		cancellationToken: CancellationToken
	): Promise<IAsset[]> {
		text = text && text.trim();
		if (!text) {
			return [];
		}

		const textInput = `id: (${text}) OR (${text})`;

		const searchOptions = {
			q: textInput,
			limit: 50,
		};

		try {
			const data = await assetService.searchAssetsAsync(searchOptions, null, cancellationToken);
			return data.hits;
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	function getCompletionSuggestionsAsync(
		field: string,
		text: string,
		cancellationToken: CancellationToken
	) {
		text = text && text.trim();
		if (!text) {
			return Promise.resolve([]);
		}

		return $http
			.get<ICompletionSuggestion>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/search/completion`, {
					text,
					field,
				}),
				{ timeout: cancellationToken }
			)
			.then(completionResponse => completionResponse.data.suggestions);
	}

	function getFreebaseCompletionSuggestionsAsync(text, options, cancellationToken) {
		// Freebase is shut down. Don't query it.
		return Promise.resolve([]);

		// Freebase is succeeded by Wikidata. This structure is left in place so it can be migrated to
		// use Wikidata in the future.
		// eslint-disable-next-line
		text = text && text.trim();
		if (!text) {
			return Promise.resolve([]);
		}

		options = _.extend(
			{
				limit: 10,
			},
			options
		);

		let requestUri = `${freebaseBaseUri}search`;
		requestUri = uriUtility.fromPattern(requestUri, { filter: '(not type:/common/document)' });
		requestUri = uriUtility.fromPattern(requestUri, { filter: '(any type:/common/topic)' });

		const params = {
			query: text,
			key: appSettings.freebaseApiKey,
			lang: 's/en,d/en,s/all,d/all',
			exact: false,
			prefixed: true,
			limit: options.limit,
			callback: 'JSON_CALLBACK',
		};

		return $http
			.jsonp<any>(requestUri, { params, timeout: cancellationToken })
			.then(response => {
				if (response.data.status !== '200 OK') {
					return Promise.reject('Error querying Freebase');
				}

				return response.data.result.map(item => ({
					text: item.name,
					freebase: {
						mid: item.mid,
						notable: item.notable && item.notable.name,
					},
				}));
			})
			.catch(response => Promise.reject(httpUtility.getErrorReason(response)));
	}

	function getFreebaseSuggestionDetailsAsync(mid, cancellationToken) {
		const requestUri = `${freebaseBaseUri}search`;

		const params = {
			filter: `(all mid:${mid})`,
			output: '((description:wikipedia) (type) (property./common/topic/image:wikipedia))',
			key: appSettings.freebaseApiKey,
			lang: 'en',
			callback: 'JSON_CALLBACK',
		};

		return $http
			.jsonp<any>(requestUri, { params, timeout: cancellationToken })
			.then(response => {
				if (response.data.status !== '200 OK') {
					return Promise.reject('Error querying Freebase');
				}

				const result = response.data.result[0];
				if (!result) {
					return Promise.reject('Freebase returned no results');
				}

				const output = result.output;
				let description = output && output['description:wikipedia'];
				description = description && description['/common/topic/description'];
				description = description && description[0];
				const types = output && output.type && output.type['/type/object/type'];

				let image = output['property./common/topic/image:wikipedia'];
				image = image && image['/common/topic/image'];
				image = image && image[0];

				return {
					mid: result.mid,
					name: result.name,
					notable: result.notable && result.notable.name,
					description,
					roles: types && _.uniq(_.map(types, 'name')),
					imageUrl:
						image &&
						uriUtility.fromPattern(`${freebaseBaseUri}image/{mid}`, {
							mid: image.mid,
							maxwidth: 75,
							key: appSettings.freebaseApiKey,
							errorid: '/freebase/no_image_png',
						}),
				};
			})
			.catch(response => Promise.reject(httpUtility.getErrorReason(response)));
	}

	function getUavCompletionSuggestionsAsync(
		text: string,
		options: any,
		cancellationToken: CancellationToken
	) {
		text = text?.trim();
		if (!text) {
			return Promise.resolve([]);
		}

		const timeout = $timeout(() => {}, 3000);

		return $http
			.post<any>(
				`${appSettings.libraryReportsBaseUri}uavautocomplete`,
				{
					text,
					limit: options?.limit,
				},
				{ timeout: Promise.race([cancellationToken, timeout]), withCredentials: true }
			)
			.then(response =>
				response.data.items.map(item => ({
					text: item.title,
					uav: {
						subtitle: item.subtitle,
						reference: item.reference,
					},
				}))
			)
			.catch(() =>
				// angular doesn't distinguish between aborted and failed requests when using JSONP. Assume error.
				Promise.reject('error')
			);
	}

	return {
		getAssetSuggestionsAsync,
		getCompletionSuggestionsAsync,
		getFreebaseCompletionSuggestionsAsync,
		getFreebaseSuggestionDetailsAsync,
		getUavCompletionSuggestionsAsync,
	};
}

export type ICompletionService = ReturnType<typeof completionService>;
