import * as angular from 'angular';
import 'jquery-ui/ui/position';
import * as _ from 'lodash';
import { CancellationToken } from './cancellationService';

export interface ITooltip {
	beginShow: (onShow?: (() => void) | undefined) => void;
	beginHide: () => void;
	destroy: () => void;
}

angular.module('app').factory('tooltipService', tooltipService);

function tooltipService(
	$animate,
	$document: angular.IDocumentService,
	$q: angular.IQService,
	$timeout: angular.ITimeoutService
) {
	const defaults = {
		tooltipDisplayDelay: 300,
		tooltipHideDelay: 100,
		tooltipFadeDuration: 200,
		tooltipOffset: 0,
		onDestroy() {},
	};

	const body = $document.find('body');
	function createTooltip(
		elem: JQuery,
		initializeTooltip: (cancellationToken: CancellationToken) => angular.IPromise<JQuery>,
		options
	): ITooltip {
		let mouseEnterPromise;
		let mouseLeavePromise;
		let tooltip: JQuery | null = null;
		let tooltipInitializationAborter;

		options = _.extend({}, defaults, options);

		function beginShowTooltip(onShow?: () => void) {
			$timeout.cancel(mouseLeavePromise);
			mouseEnterPromise = $timeout(
				() => {
					if (!tooltip) {
						tooltipInitializationAborter = $q.defer();

						initializeTooltip(tooltipInitializationAborter.promise).then(tooltipElem => {
							tooltipInitializationAborter = null;
							if (!tooltipElem) {
								return;
							}

							tooltip = tooltipElem;
							showTooltip();

							if (onShow) {
								onShow();
							}
						});
					} else {
						showTooltip();
					}
				},
				options.tooltipDisplayDelay,
				false
			);
		}

		function beginHideTooltip() {
			$timeout.cancel(mouseEnterPromise);
			mouseLeavePromise = $timeout(hideTooltip, options.tooltipHideDelay);
		}

		function showTooltip() {
			if (!tooltip) {
				return;
			}

			$animate.enter(tooltip, body, angular.element(body[0].lastChild!));

			reposition();
		}

		function destroyTooltip() {
			if (tooltip) {
				options.onDestroy(tooltip);
				tooltip.remove();
				tooltip = null;
			}
		}

		function hideTooltip() {
			if (tooltipInitializationAborter) {
				tooltipInitializationAborter.resolve();
			}
			if (tooltip) {
				$animate.leave(tooltip).then(() => {
					destroyTooltip();
				});
			}
		}

		function destroy() {
			$timeout.cancel(mouseEnterPromise);
			$timeout.cancel(mouseLeavePromise);
			destroyTooltip();
		}

		function reposition() {
			if (!tooltip) {
				return;
			}

			const opts = {
				my: `left+${17 + options.tooltipOffset} center`,
				at: 'right center',
				of: elem,
				within: options.container,
				collision: 'flip fit',
				using(position, feedback) {
					angular
						.element(this)
						// remember whether the tooltip is flipped so that we can show the arrow on the correct side
						.toggleClass('tooltip-arrow-right', feedback.horizontal === 'right')
						.css(position);
				},
			};

			$timeout(
				() => {
					if (tooltip) {
						tooltip.position(opts);
					}
				},
				0,
				false
			);
		}

		return {
			beginShow: beginShowTooltip,
			beginHide: beginHideTooltip,
			destroy,
		};
	}

	return {
		createTooltip,
	};
}

export type ITooltipService = ReturnType<typeof tooltipService>;
