import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').controller('AdminController', admin);

function admin($scope, $timeout, $window, jobService, adminService, cancellationService) {
	const cancellationSource = cancellationService.createCancellationSource();
	let timer;

	$scope.service = null;
	$scope.servers = [];

	$scope.startServer = function (serverId) {
		jobService.adminStartJobServerAsync(serverId).catch(() => {
			$window.alert('Error');
		});
	};

	$scope.pauseServer = function (serverId) {
		if (!$window.confirm(`Are you sure you want to pause server ${serverId}?`)) {
			return;
		}

		jobService.adminPauseJobServerAsync(serverId).catch(() => {
			$window.alert('Error');
		});
	};

	$scope.stopServer = function (serverId) {
		if (!$window.confirm(`Are you sure you want to stop server ${serverId}?`)) {
			return;
		}

		jobService.adminStopJobServerAsync(serverId).catch(() => {
			$window.alert('Error');
		});
	};

	$scope.startService = function () {
		jobService.adminStartJobServiceAsync().catch(() => {
			$window.alert('Error');
		});
	};

	$scope.pauseService = function () {
		if (!$window.confirm('Are you sure you want to pause the job service?')) {
			return;
		}

		jobService.adminPauseJobServiceAsync().catch(() => {
			$window.alert('Error');
		});
	};

	$scope.cancelJob = function (jobId, runnerId) {
		jobService.cancelJobAsync(jobId, runnerId).catch(() => {
			$window.alert('Error');
		});
	};

	$scope.restartJob = function (jobId, runnerId) {
		jobService.restartJobAsync(jobId, runnerId).catch(() => {
			$window.alert('Error');
		});
	};

	$scope.updateIndex = function (forceRecreate) {
		if ($window.confirm('Are you sure you want to submit an `updateIndex` job?')) {
			adminService.updateIndexAsync(forceRecreate);
		}
	};

	function updateJobs(jobs) {
		if (!$scope.service.pendingJobs) {
			$scope.service.pendingJobs = [];
		}

		angular.copy([], $scope.service.pendingJobs);
		$scope.servers.forEach(s => {
			if (!s.jobs) {
				s.jobs = [];
			}

			angular.copy([], s.jobs);
		});

		_.forEach(_.groupBy(jobs, 'serverId'), (groupedJobs, serverId) => {
			const displayJobs = groupedJobs.map(j => ({
				id: j.id,
				bucketId: j.bucketId,
				agent: j.agent,
				status: j.status,
				created: j.created,
				started: j.started,
				runnerId: j.runnerId,
				name: _.last(j.request.path && j.request.path.split('/')),
				ops: _.uniq(_.map(j.request.content && j.request.content.ops, 'op')),
				uri: jobService.adminGetJobUri(j.id),
			}));

			if (!serverId || serverId === 'undefined') {
				angular.copy(displayJobs, $scope.service.pendingJobs);
				return;
			}

			let server = _.find($scope.servers, { id: serverId });
			if (!server) {
				// the server is stopped. We could make another request for details, but we use a dummy server instead
				server = {
					id: serverId,
					status: 'stopped',
					description: 'stopped with abandoned jobs',
				};

				$scope.servers.push(server);
			}

			if (!server.jobs) {
				server.jobs = [];
			}

			angular.copy(displayJobs, server.jobs);
		});
	}

	function doStatusPoll() {
		Promise.all([
			jobService.adminGetJobServerStatusAsync(cancellationSource.token),
			jobService.adminGetJobsAsync('running', cancellationSource.token),
			jobService.adminGetJobsAsync('pending', cancellationSource.token),
		]).then(([status, runningJobs, pendingJobs]) => {
			if (cancellationSource.isCancelled) {
				return;
			}

			$scope.service = status.service;

			status.servers.forEach(server => {
				const oldServer = _.find($scope.servers, { id: server.id });
				if (oldServer) {
					_.assign(oldServer, server);
				} else {
					$scope.servers.push(server);
				}
			});

			_.remove($scope.servers, server => !_.find(status.servers, { id: server.id }));

			updateJobs(runningJobs.items.concat(pendingJobs.items));

			$scope.lastUpdate = Date.now();

			timer = $timeout(doStatusPoll, 1000);
		});
	}

	$scope.$on('$destroy', () => {
		cancellationSource.cancel();
		$timeout.cancel(timer);
	});

	doStatusPoll();
}
