import * as angular from 'angular';

angular.module('app').filter('mimeType', mimeType);

function mimeType(mimeTypeUtility, utility) {
	return function (mimeTypeValue, part) {
		const mType = mimeTypeValue ? mimeTypeUtility.splitMimeType(mimeTypeValue) : null;

		if (!mType) {
			return null;
		}

		if (part === 'subtype') {
			return mType.subtype.toUpperCase();
		}

		// default to showing the 'type'
		return utility.capitalize(mType.type);
	};
}
