import * as angular from 'angular';

angular.module('app').service('environmentUtility', environmentUtility);

const environments = ['local', 'internal', 'production'] as const;
// string union
export type Environment = typeof environments[number];

function environmentUtility($location: angular.ILocationService) {
	const getEnvironment = (): Environment => {
		const subdomains: Record<string, Environment> = {
			local: 'local',
			internal: 'internal',
			test: 'internal',
			localhost: 'internal',
		};

		const match = $location
			.host()
			.split('.')
			.find(part => Object.hasOwnProperty.call(subdomains, part));
		return match ? subdomains[match] : 'production';
	};

	return { allEnvironments: environments, getEnvironment };
}

export type IEnvironmentUtility = ReturnType<typeof environmentUtility>;
