import * as uirouter from '@uirouter/angularjs';
import * as angular from 'angular';
import * as _ from 'lodash';
import { IDateTimeUtility, IAsset, IAssetShare, IUtility } from '../helpers';
import {
	IAssetService,
	IErrorService,
	IUserService,
	IAssetPermissionService,
	ISearchResultsNavigationModel,
} from '../services';

interface IAssetStatsModel {
	asset: IAsset | null;
	hasPreview: boolean;
	isEditable: boolean;
	isPastRevision: boolean;
	isSubmitting: boolean;
	showRatingsBreakdown: boolean;
	liveStreamStats?: {
		uniqueViewerCount?: number;
		viewingTimeSeconds?: number;
		averageViewingTimeSeconds?: number;
	};
}

interface IAssetShareModel extends IAssetShare {
	isUrl?: boolean;
	isDeactivated?: boolean;
}

angular.module('app').controller('AssetStatsController', assetStats);

const urlRegex = /^https?:\/\//i;

function assetStats(
	$location: angular.ILocationService,
	$scope: angular.IScope,
	$state: uirouter.StateService,
	$transition$: uirouter.Transition,
	assetPermissionsService: IAssetPermissionService,
	assetService: IAssetService,
	dateTimeUtility: IDateTimeUtility,
	errorService: IErrorService,
	searchResultsNavigationModel: ISearchResultsNavigationModel,
	userService: IUserService,
	utility: IUtility
) {
	const model: IAssetStatsModel = {
		asset: null,
		hasPreview: false,
		isEditable: false,
		isPastRevision: false,
		isSubmitting: false,
		showRatingsBreakdown: false,
	};

	const $stateParams = $transition$.params();
	const assetId = $stateParams.assetId;
	const assetRevision = $stateParams.revisionId || undefined;
	let hasPermissionToEditAsset = false;
	let shareUsers;

	searchResultsNavigationModel.setCurrentAsset(assetId);

	function refreshAssetAsync() {
		return assetService
			.getAssetAsync(assetId, { revision: assetRevision })
			.then(asset =>
				assetPermissionsService.getAssetsPermissionsAsync([asset]).then(permissions => {
					hasPermissionToEditAsset = permissions.canEdit;
					reloadWithAsset(asset);
					$scope.$emit('pageTitleChange', asset.title);
				})
			)
			.catch(error => {
				if (error === 'notfound') {
					errorService.showNotFound();
				} else {
					errorService.showError();
				}
			});
	}

	function reloadWithAsset(asset: IAsset) {
		model.asset = asset;
		model.isPastRevision = assetRevision !== undefined;
		model.isEditable =
			hasPermissionToEditAsset && !model.isPastRevision && asset && !asset.isDeleted;
		model.liveStreamStats =
			model.asset.originalMetadata &&
			model.asset.originalMetadata.faithlifeTVSimple &&
			model.asset.originalMetadata.faithlifeTVSimple.liveStreamStats;

		if (
			model.liveStreamStats &&
			model.liveStreamStats.viewingTimeSeconds &&
			model.liveStreamStats.uniqueViewerCount
		) {
			model.liveStreamStats.averageViewingTimeSeconds =
				model.liveStreamStats.viewingTimeSeconds / model.liveStreamStats.uniqueViewerCount;
		}

		if (asset.shares) {
			const now = new Date().getTime();
			for (let i = 0; i < asset.shares.length; i++) {
				const share: IAssetShareModel = asset.shares[i];
				share.isDeactivated = share.expires ? new Date(share.expires).getTime() <= now : false;
				share.isUrl = share.token ? urlRegex.test(share.token) : false;
			}

			loadUserInfoForShares(asset.shares);
		}

		$scope.$apply();
	}

	function loadUserInfoForShares(shares: ReadonlyArray<IAssetShare>) {
		const ids = _.uniq(
			shares
				.map(share => share.agent && share.agent.user && share.agent.user.id)
				.filter(utility.isNotNullish)
		);
		if (ids.length > 0) {
			userService.getUserDetailsBatchAsync(ids).then(users => {
				if (users && users.length > 0) {
					shareUsers = _.keyBy(users, 'id');
				}
			});
		}
	}

	$scope.deactivateShare = function (shareId) {
		const expires = dateTimeUtility.toIso8601();
		assetService.editShareAsync(shareId, expires).then(() => {
			const share = model.asset?.shares?.find(assetShare => assetShare.id === shareId);
			if (share) {
				share.expires = expires;
				// @ts-ignore
				share.isDeactivated = true;
			}
		});
	};

	$scope.toggleShowRatingsBreakdown = function () {
		$scope.model.showRatingsBreakdown = !model.showRatingsBreakdown;
	};

	$scope.getUserName = function (id) {
		return shareUsers && shareUsers[id] ? shareUsers[id].name : '';
	};

	$scope.model = _.extend(model, {
		asset: null,
	});

	$scope.shareBaseUrl = `${$location.protocol()}://${$location.host()}/shares/`;
	$scope.getPreviewPath = function () {
		return model.hasPreview
			? $state.href('preview', {
					assetId: model.asset && model.asset.id,
					revisionId: model.asset && model.asset.revision.id,
			  })
			: undefined;
	};

	refreshAssetAsync();
}
