import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('referenceService', referenceService);

function referenceService($http, uriUtility, appSettings) {
	return {
		// referenceInput: either a string, or an object with `text` and `dataType` (optional; defaults to 'bible').
		parseReferenceAsync(referenceInput, cancellationToken) {
			let text =
				referenceInput && (_.isString(referenceInput) ? referenceInput : referenceInput.text);
			if (text) {
				const dataType = referenceInput.dataType || 'bible';

				// remove trailing '.' and ':' characters for a better parse-as-you-type experience
				text = text.trim().replace(/[.:]+$/, '');

				return $http
					.get(
						uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}references/`, {
							text,
							dataType,
						}),
						{ timeout: cancellationToken }
					)
					.then(result => result && result.data && result.data.references);
			}
			return Promise.resolve(null);
		},
	};
}
