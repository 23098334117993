import * as angular from 'angular';

angular.module('app').directive('guidebookHeadingsInput', guidebookHeadingsInput);

function guidebookHeadingsInput(autoCompleteProviderService) {
	const bibleReferenceProvider = autoCompleteProviderService.providers.bibleReference.createProvider();
	const uavProvider = autoCompleteProviderService.providers.uav.createProvider();

	function map(item) {
		if (item.reference) {
			return bibleReferenceProvider.map(item);
		} else if (item.uav) {
			return uavProvider.map(item);
		}

		return {};
	}

	function unmap(select2Item) {
		return select2Item.data;
	}

	return {
		restrict: 'AE',
		scope: {
			source: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
		},
		template:
			'<auto-complete providers="providers" restrict-selection="restrictSelection" mapping="{ map: map, unmap: unmap }" source="source" is-readonly="readonly" disable-sortable="disableSortable"></auto-complete>',
		link: {
			pre(scope) {
				scope.providers = [
					{ name: 'bibleReference', label: 'Reference' },
					{ name: 'uav', label: 'Logos' },
				];

				// only support selecting suggestions; do not allow freeform text tags
				scope.restrictSelection = true;

				scope.map = map;
				scope.unmap = unmap;
			},
		},
	};
}
