import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('multiImageThumbnail', multiImageThumbnail);

function multiImageThumbnail($timeout, geometryUtility, hotkeys) {
	return {
		restrict: 'E',
		scope: {
			images: '=',
			isPlaying: '=',
			maxWidth: '=?',
			maxHeight: '=?',
			isInteractive: '=?',
			fixedWidth: '=?',
			fixedHeight: '=?',
			shareToken: '=?',
			useCustomStyles: '=?',
			isBookLayout: '=?',
			isControlBarVisible: '=?',
			disableFullscreen: '=?',
		},
		templateUrl: require('../../views/templates/multiImageThumbnail.html'),
		replace: true,
		link(scope, elem) {
			let slider;
			let slideCount;

			scope.nextSlide = function (e) {
				e.preventDefault();
				if (!slider) {
					return;
				}

				if (e.ctrlKey || e.metaKey) {
					slider.to(slideCount - 1);
				} else {
					slider.next();
				}
			};

			scope.previousSlide = function (e) {
				e.preventDefault();
				if (!slider) {
					return;
				}

				if (e.ctrlKey || e.metaKey) {
					slider.to(0);
				} else {
					slider.prev();
				}
			};

			/* eslint-disable angular/document-service */
			function handleFullscreenChange() {
				scope.$apply(() => {
					scope.isFullscreen =
						!!document.fullscreenElement ||
						!!document.webkitFullscreenElement ||
						!!document.mozFullScreenElement ||
						!!document.msFullscreenElement;
				});
			}
			document.addEventListener('fullscreenchange', handleFullscreenChange);
			document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
			document.addEventListener('mozfullscreenchange', handleFullscreenChange);
			document.addEventListener('MSFullscreenChange', handleFullscreenChange);
			/* eslint-enable */

			scope.toggleFullscreen = function () {
				if (scope.disableFullscreen) {
					return;
				}

				if (scope.isFullscreen) {
					/* eslint-disable angular/document-service */
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						document.webkitExitFullscreen();
					}
					/* eslint-enable */
				} else {
					const thumbnail = elem.get(0);
					if (!thumbnail) {
						return;
					}

					if (thumbnail.requestFullscreen) {
						thumbnail.requestFullscreen();
					} else if (thumbnail.mozRequestFullScreen) {
						thumbnail.mozRequestFullScreen();
					} else if (thumbnail.webkitRequestFullscreen) {
						thumbnail.webkitRequestFullscreen();
					} else if (thumbnail.msRequestFullscreen) {
						thumbnail.msRequestFullscreen();
					}
				}
			};

			scope.$watch('images', images => {
				if (!scope.isBookLayout) {
					scope.imageModels = _.map(images, image => {
						const bounds =
							isNaN(scope.maxWidth) && isNaN(scope.maxHeight)
								? { width: image.width, height: image.height }
								: geometryUtility.getBestFitForConstraint(
										scope.maxWidth,
										scope.maxHeight,
										image.width,
										image.height
								  );
						return {
							src: image.src,
							width: Math.round(bounds.width),
							height: Math.round(bounds.height),
						};
					});
				} else {
					scope.imageModels = _.reduce(
						_.map(images, image => {
							const bounds =
								isNaN(scope.maxWidth) && isNaN(scope.maxHeight)
									? { width: image.width, height: image.height }
									: geometryUtility.getBestFitForConstraint(
											scope.maxWidth / 2,
											scope.maxHeight,
											image.width,
											image.height
									  );
							return {
								src: image.src,
								width: Math.round(bounds.width),
								height: Math.round(bounds.height),
							};
						}),
						(groupedImages, nextImage, nextImageIndex) => {
							if (nextImageIndex === 0 || nextImageIndex % 2 === 1) {
								groupedImages.push([nextImage]);
							} else {
								groupedImages[nextImageIndex / 2].push(nextImage);
							}

							return groupedImages;
						},
						[]
					);
				}

				$timeout(() => {
					if (scope.activeIndex) {
						slider.to(scope.activeIndex);
					}
				});
			});

			// wait for next digest cycle (after the repeat has already run)
			$timeout(() => {
				const pagingControls = elem.find('.thumbnail-paging-controls');
				const sliderElem = elem.find('.asset-thumbnail-image-slider').carousel({
					keyboard: false,
					pause: null,
					interval: scope.autoplay === true ? 2000 : false,
					wrap: scope.autoplay === true,
				});

				slider = sliderElem.data('bs.carousel');

				function updatePagingControls(fresh) {
					slideCount = scope.imageModels.length;
					const activeIndex = fresh
						? 0
						: sliderElem.find('.item').index(sliderElem.find('.active'));
					scope.activeIndex = activeIndex;
					scope.slideCount = slideCount;

					pagingControls.toggleClass('no-prev', activeIndex === 0);
					pagingControls.toggleClass('no-next', activeIndex === slideCount - 1);
				}

				sliderElem.on('slid.bs.carousel', () => scope.$apply(() => updatePagingControls(false)));
				scope.$watch('imageModels', () => updatePagingControls(true));

				let hoverTimer;
				scope.$watch('isPlaying', isPlaying => {
					if (isPlaying) {
						hoverTimer = $timeout(() => {
							slider.next();
							slider.options.interval = 2000;
							slider.cycle();
						}, 700);
					} else {
						$timeout.cancel(hoverTimer);
						slider.to(0);
						slider.pause();
					}
				});

				if (scope.isInteractive) {
					hotkeys
						.bindTo(scope)
						.add({
							combo: 'left',
							description: 'Previous slide',
							callback() {
								slider.prev();
							},
						})
						.add({
							combo: 'right',
							description: 'Next slide',
							callback() {
								slider.next();
							},
						});
				}
			});
		},
	};
}
