import * as angular from 'angular';

angular.module('app').directive('mobileSearchForm', searchForm);

function searchForm($state, searchTextService, embedService) {
	return {
		restrict: 'AE',
		scope: true,
		require: '?^mobileSiteHeader',
		link(scope, elem, attrs, mobileSiteHeader) {
			scope.model =
				mobileSiteHeader && mobileSiteHeader.getShouldBindToSearchText()
					? searchTextService
					: { inputText: '' };

			scope.filter = function () {
				const assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
				$state.go(assetListState, { q: scope.model.inputText || null, select: null });
			};

			scope.clear = function () {
				scope.model.inputText = null;
			};
		},
	};
}
