import * as angular from 'angular';

export const constants = Object.freeze({
	fileUploadComplete: 'file.upload.complete',
	dropEventName: 'dropTarget.dropped',
	filesSelectedEventName: 'fileSelect.filesSelected',
	uploadFromUrlEventName: 'assetUrlUpload.url',
	previewFormatPrefix: 'Amber Preview',
	streamingFormatPrefix: 'Amber Streaming',
	optimizedFormatPrefix: 'Amber Web Optimized',
	extractedTextFormatName: 'Extracted Text',
	autoGeneratedFilterPrefix: 'Auto-generated',
});

export type IConstants = typeof constants;

angular.module('app').constant('constants', constants);
