import * as angular from 'angular';

angular.module('app').directive('hamburgerMenu', hamburgerMenu);

function hamburgerMenu(
	$rootScope,
	$state,
	userModel,
	appSettings,
	searchTextService,
	localSettingsService,
	groupService,
	embedService
) {
	return {
		restrict: 'A',
		templateUrl: require('../../views/templates/hamburgerMenu.html'),
		scope: {
			sortOptions: '=?',
			sortValue: '=?',
			view: '=',
		},
		link(scope, elem) {
			userModel.getCurrentUserAsync().then(user => {
				if (!user.isAnonymous) {
					scope.model = {
						accountToken: groupService.currentAccountToken,
						hideGroups: embedService.isEmbedded && !embedService.pickerMode,
						user,
						signOutUri: appSettings.signOutUri,
						sortOptions: scope.sortOptions,
						sortValue: scope.sortValue,
						toggleDesktopView: $rootScope.toggleForceDesktopView,
					};
				}

				scope.$watch('model.sortValue', (value, old) => {
					if (value !== old) {
						const assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
						$state.go(
							assetListState,
							{ sort: value || null, q: searchTextService.inputText, select: null },
							{ inherit: false }
						);
					}
				});
			});

			scope.isVisible = false;

			elem.on({
				click() {
					scope.isVisible = !scope.isVisible;
					const filters = localSettingsService.createSettingAccessor('filters', () => false);
					if (filters.get()) {
						filters.set(false);
					}
					scope.$apply();
				},
			});
		},
	};
}
