import * as angular from 'angular';
import { IBrowserService, INavigationService, IUserService, UserModel } from '../services';

angular.module('app').directive('signinModal', signinModal);

function signinModal() {
	return {
		restrict: 'E',
		templateUrl: require('../../views/templates/signinModal.html'),
		controller(
			$scope: angular.IScope,
			$window: angular.IWindowService,
			$location: angular.ILocationService,
			$log: angular.ILogService,
			browserService: IBrowserService,
			navigationService: INavigationService,
			userModel: UserModel,
			userService: IUserService
		) {
			const tryIdentityTokenSignIn = async () => {
				if ($location.search().identityToken || $location.search().disableTokenSignIn) {
					return false;
				}

				try {
					const { token, generated } = await userService.getIdentityTokenAsync();
					if (token) {
						$log.info('Attempting identity token sign in');
						$window.location.replace(
							`${$window.location.href}&identityToken=${token}&callback=${encodeURIComponent(
								`${$window.location.href}&disableTokenSignIn=1`
							)}`
						);
						return true;
					}

					return generated;
				} catch (err) {
					$log.info('Identity token sign-in failed', err);
				}
				return false;
			};

			$scope.signinModalVisible = false;

			if ($window.location.pathname.endsWith('/embed')) {
				$scope.signinModalVisible = false;
			}

			if ($window.opener) {
				try {
					$log.info('$window.opener', $window.opener);
				} catch (err) {
					$log.info('$window.opener is set, but not accessible', err);
				}
			}

			$scope.$watch(
				'getCurrentUserAsync()',
				async (promise: ReturnType<typeof getCurrentUserAsync>) => {
					const user = await promise;
					if (user.isAnonymous) {
						const { isAnonymousState } = navigationService;

						$scope.thirdPartyCookiesDisabled = $location.search().signinReturn === 'true';
						const hasStorageAccess = await browserService.hasStorageAccessAsync();
						const ssiDisabled = $location.search().ssi === 'false';
						if (hasStorageAccess === false || ssiDisabled) {
							if (ssiDisabled || !(await tryIdentityTokenSignIn())) {
								$log.warn('Not attempting auto sign-in.', { hasStorageAccess, ssiDisabled });
								if (!isAnonymousState()) {
									$scope.signinModalVisible = true;
									$scope.$digest();
								}
							}
							return;
						}

						try {
							await userService.autoSignInAsync(true);
							const autoUser = await userService.getCurrentUserAsync();
							if (autoUser.isAnonymous) {
								if (!(await tryIdentityTokenSignIn())) {
									$log.info('Auto sign-in failed');
									if (!isAnonymousState()) {
										$scope.signinModalVisible = true;
										$scope.$digest();
									}
								}
							} else {
								$scope.signinModalVisible = false;

								if ($window.opener) {
									$window.opener.postMessage({ type: 'signedin' }, $window.location.origin);
								}

								$window.location.reload();
							}
						} catch (err) {
							$log.info('Auto sign-in failed', err);
							if (!isAnonymousState()) {
								$scope.signinModalVisible = true;
								$scope.$digest();
							}
						}
					} else if ($window.opener) {
						$window.opener.postMessage({ type: 'signedin' }, $window.location.origin);
					}
				}
			);

			$scope.getCurrentUserAsync = getCurrentUserAsync;
			function getCurrentUserAsync() {
				return userModel.getCurrentUserAsync();
			}

			$scope.oauthSignIn = function oauthSignIn() {
				// don't use async/await to ensure this is run immediately when called from an event handler
				const popup = !!$scope.thirdPartyCookiesDisabled;
				if (popup) {
					$log.info('Third-party cookies are disabled, opening sign-in in popup');
				}
				userService.oauthSignInAsync(popup);
			};
		},
	};
}
