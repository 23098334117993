import * as angular from 'angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
	IAsset,
	IDateTimeUtility,
	IMimeTypeUtility,
	IShare,
	IUriUtility,
	IUtility,
} from '../helpers';
import { IAssetService, IBoardService } from '../services';

interface IShareEmbedPreferences {
	allowFullscreen?: boolean;
	enableAutoplay?: boolean;
	allowDetails?: boolean;
	allowDownload?: boolean;
	allowShare?: boolean;
	bookLayout?: boolean;
	width?: number;
	height?: number;
}

interface IShareMenuScope extends angular.IScope {
	embed?: {
		preferences?: IShareEmbedPreferences;
		kind?: string;
		assetWidth?: number;
		assetHeight?: number;
		assetAspectRatio?: number;
		previewDimensions?: {
			width: number;
			height: number;
		};
		url?: string;
		code?: string;
	};
}

angular.module('app').directive('shareMenu', shareMenu);

const defaultAssetDimension = 512;

function shareMenu(
	$location: angular.ILocationService,
	assetService: IAssetService,
	boardService: IBoardService,
	dateTimeUtility: IDateTimeUtility,
	mimeTypeUtility: IMimeTypeUtility,
	uriUtility: IUriUtility,
	$timeout: angular.ITimeoutService,
	hotkeys,
	utility: IUtility
): angular.IDirective {
	return {
		restrict: 'E',
		templateUrl: require('../../views/templates/shareMenu.html'),
		scope: {
			asset: '=?',
			board: '=?',
			canExpire: '=?',
			updateShareCount: '=?',
			closeShareMenu: '&?',
		},
		controller($scope: IShareMenuScope, $filter: angular.IFilterService) {
			$scope.shareId = null;
			$scope.simpleBaseUrl = `${$location.host()}${!location.port ? '' : `:${location.port}`}`;
			$scope.shareBaseUrl = `${$location.protocol()}://${$scope.simpleBaseUrl}`;
			$scope.isCustomizing = false;
			$scope.originalToken = null;
			$scope.customToken = null;
			$scope.tokenStatus = 'available';
			$scope.tokenStatusMessage = {
				checking: $filter('translate')('shares.menu.checking'),
				unavailable: $filter('translate')('shares.menu.unavailable'),
				available: $filter('translate')('shares.menu.available'),
				invalid: $filter('translate')('shares.menu.invalid'),
			};
			$scope.mode = 'share'; // Can be 'share' or 'embed'
			_.defaults($scope, {
				updateShareCount() {},
				closeShareMenu() {},
			});

			function calculateHeightAdjustment() {
				let result = 0;

				if ($scope.embed!.preferences!.allowDetails) {
					result += 72;
				}

				if ($scope.embed!.kind === 'multi-image') {
					result += 30;
				}

				return result;
			}

			let suppressNextDimensionUpdate = false;

			function updateEmbedWidthFromHeight(height: number) {
				if (suppressNextDimensionUpdate) {
					suppressNextDimensionUpdate = false;
				} else if (height && !isNaN(height) && height > 0) {
					$scope.embed!.preferences!.width = Math.round(
						(height - calculateHeightAdjustment()) / $scope.embed!.assetAspectRatio!
					);
					suppressNextDimensionUpdate = true;
				}
			}

			function updateEmbedHeightFromWidth(width: number) {
				if (suppressNextDimensionUpdate) {
					suppressNextDimensionUpdate = false;
				} else if (width && !isNaN(width) && width > 0) {
					$scope.embed!.preferences!.height =
						Math.round(width * $scope.embed!.assetAspectRatio!) + calculateHeightAdjustment();
					suppressNextDimensionUpdate = true;
				}
			}

			function updateEmbedInfo(
				nextPreferences?: IShareEmbedPreferences,
				previousPreferences?: IShareEmbedPreferences
			) {
				if (!$scope.embed) {
					$scope.embed = {};
				}

				$scope.embed.kind = determineEmbedKind(mimeTypeUtility, $scope.asset);

				if (!$scope.embed.preferences) {
					$scope.embed.preferences = {
						allowFullscreen: true,
						enableAutoplay: false,
						allowDetails: true,
						allowDownload: true,
						allowShare: true,
						bookLayout: false,
					};
				}

				const assetThumbnailInfo =
					$scope.asset && $scope.asset.getBestThumbnailForSize(Number.MAX_SAFE_INTEGER);
				$scope.embed.assetWidth =
					(assetThumbnailInfo && assetThumbnailInfo.width) || defaultAssetDimension;
				$scope.embed.assetHeight =
					(assetThumbnailInfo && assetThumbnailInfo.height) || defaultAssetDimension;

				if ($scope.embed.kind === 'audio') {
					$scope.embed.assetWidth = 400;
					$scope.embed.assetHeight = 40;
				}

				$scope.embed.assetAspectRatio = $scope.embed.assetHeight! / $scope.embed.assetWidth!;

				if ($scope.embed.preferences.bookLayout) {
					$scope.embed.assetAspectRatio /= 2;
				}

				$scope.embed.previewDimensions = {
					width: Math.min($scope.embed!.assetWidth!, defaultAssetDimension),
					height:
						Math.round(
							Math.min($scope.embed!.assetWidth!, defaultAssetDimension) *
								$scope.embed.assetAspectRatio
						) + calculateHeightAdjustment(),
				};

				if (typeof $scope.embed.preferences.width === 'undefined') {
					$scope.embed.preferences.width = $scope.embed.previewDimensions.width;
				}

				if (
					(nextPreferences &&
						previousPreferences &&
						nextPreferences.bookLayout !== previousPreferences.bookLayout) ||
					(nextPreferences &&
						previousPreferences &&
						nextPreferences.allowDetails !== previousPreferences.allowDetails)
				) {
					updateEmbedHeightFromWidth($scope.embed.preferences.width);
				}

				const embedParameters = {
					disableDetails: $scope.embed.preferences.allowDetails ? undefined : null,
					disableShare: $scope.embed.preferences.allowShare ? undefined : null,
					fs: $scope.embed.preferences.allowFullscreen ? null : undefined, // fs -> allowFullscreen
					dl: $scope.embed.preferences.allowDownload ? null : undefined, // dl -> allowDownload
					autoplay: $scope.embed.preferences.enableAutoplay ? null : undefined,
					bookLayout: $scope.embed.preferences.bookLayout ? null : undefined,
				};

				$scope.embed.url = uriUtility.fromPattern(`${$scope.shareUrl}/embed`, embedParameters);

				$scope.embed.code = `<iframe frameborder="0" scrolling="no" ${
					$scope.embed.preferences.allowFullscreen ? 'allowfullscreen' : ''
				} src="${$scope.embed.url}" width="${$scope.embed.preferences.width}" height="${
					$scope.embed.preferences.height
				}"></iframe>`;
			}

			updateEmbedInfo();

			function updateWebOptimizedUrl() {
				if ($scope.asset?.webOptimizedUrl) {
					$scope.webOptimizedUrl = uriUtility.fromPattern($scope.asset.webOptimizedUrl, {
						share: $scope.originalToken,
					});
					$scope.webOptimizedDownloadUrl = uriUtility.fromPattern(
						$scope.asset.webOptimizedDownloadUrl,
						{
							share: $scope.originalToken,
						}
					);
					$scope.fullEmbedHtml = $scope.asset.getFullEmbedHtml($scope.originalToken);
				}
			}

			function saveChanges() {
				let editShareAsync: (
					shareId: string,
					expirationDate: string,
					token?: string
				) => Promise<IShare>;

				if ($scope.asset) {
					editShareAsync = assetService.editShareAsync;
				} else if ($scope.board) {
					editShareAsync = boardService.editShareAsync;
				} else {
					throw new Error('Share content not specified');
				}

				return editShareAsync(
					$scope.shareId,
					$scope.expires ? $scope.expirationDate : null,
					$scope.originalToken
				)
					.then(data => {
						$scope.shareUrl = $scope.shareBaseUrl + data.shareUrl;
						updateWebOptimizedUrl();
						updateEmbedInfo();
						return true;
					})
					.catch(error => {
						if (error.status === 409) {
							// Conflict
							$scope.tokenStatus = 'unavailable';
						} else {
							$scope.tokenStatus = 'invalid';
						}
						return false;
					})
					.finally(() => $scope.$apply());
			}

			$scope.saveCustomToken = function () {
				if (!$scope.customToken) {
					throw new Error('Invalid token');
				}

				$scope.originalToken = $scope.customToken;
				saveChanges().then(success => {
					if (success) {
						$scope.$apply(scope => {
							scope.isCustomizing = false;
						});
					}
				});
			};

			$scope.cancelCustomToken = function () {
				$scope.customToken = $scope.originalToken;
				$scope.isCustomizing = false;
			};

			function optionChangeListener(newValue, oldValue) {
				if (newValue !== oldValue) {
					saveChanges();
				}
			}

			function customTokenListener(newValue, oldValue) {
				if (newValue !== oldValue && newValue !== '') {
					if ($scope.delayCheck) {
						$timeout.cancel($scope.delayCheck);
					}

					$scope.tokenStatus = 'checking';

					$scope.delayCheck = $timeout(() => {
						checkIfTokenIsUnique(newValue);
					}, 500);
				}
			}

			function checkIfTokenIsUnique(token) {
				if (token === $scope.originalToken) {
					$scope.tokenStatus = 'available';
					return;
				}

				assetService
					.getShareAssetIdAsync(token)
					.then(() => {
						$scope.tokenStatus = 'unavailable';
					})
					.catch(error => {
						if (error.status === 'notfound') {
							$scope.tokenStatus = 'available';
						}
					})
					.finally(() => {
						$scope.$apply();
					});
			}

			if ($scope.canExpire === undefined) {
				$scope.canExpire = true;
			}

			$scope.expirationDate = dateTimeUtility.toIso8601(moment().add(30, 'd'));
			$scope.expires = false;

			hotkeys.bindTo($scope).add({
				combo: 'esc',
				description: 'Close share menu',
				callback: $scope.closeShareMenu,
			});

			$scope.$watch('expirationDate', optionChangeListener);
			$scope.$watch('expires', optionChangeListener);
			$scope.$watch('customToken', customTokenListener);
			$scope.$watch('embed.preferences', updateEmbedInfo, true);
			$scope.$watch('embed.preferences.width', updateEmbedHeightFromWidth);
			$scope.$watch('embed.preferences.height', updateEmbedWidthFromHeight);

			$scope.getEmbed = (file, url) => {
				if (!file || !file.mediaType?.startsWith('image/')) {
					return false;
				}
				const height = file.dimensions?.height;
				const width = file.dimensions?.width;
				return `<img src="${url || file.url}" ${width && `width="${width}"`} ${
					height && `height="${height}"`
				} alt="${utility.encodeHtmlEntities($scope.asset.title)}" />`;
			};

			if ($scope.asset) {
				$scope.customBaseUrl = `${$scope.simpleBaseUrl}/shares/`;
				assetService.createShareAsync($scope.asset.id).then(data => {
					$scope.$apply(() => {
						$scope.shareUrl = $scope.shareBaseUrl + data.shareUrl;
						$scope.shareId = data.id;
						$scope.originalToken = data.token;
						$scope.updateShareCount();
						updateWebOptimizedUrl();
						updateEmbedInfo();
					});
				});
			} else if ($scope.board) {
				$scope.customBaseUrl = `${$scope.simpleBaseUrl}/shares/boards/`;
				boardService.createShareAsync($scope.board.id).then(data => {
					$scope.$apply(() => {
						$scope.shareUrl = $scope.shareBaseUrl + data.shareUrl;
						$scope.shareId = data.id;
						$scope.originalToken = data.token;
					});
				});
			}
		},
	};
}

function determineEmbedKind(mimeTypeUtility: IMimeTypeUtility, asset: IAsset) {
	let result = 'unsupported';
	if (!asset || !asset.file) {
		return result;
	}

	const assetThumbnailInfo = asset.getBestThumbnailForSize(Number.MAX_SAFE_INTEGER);
	if (assetThumbnailInfo && assetThumbnailInfo.url) {
		if (asset.file.metadata && asset.file.metadata.duration) {
			result = 'animation';
		} else {
			result = 'image';
		}
	}

	const format = asset.getBestFormatForSize(Number.MAX_SAFE_INTEGER);
	if (format && (asset.kind === 'pdf' || (format.files && format.files.length > 1))) {
		result = 'multi-image';
	}

	const fileKind = mimeTypeUtility.getFileKind(asset.file.mediaType);
	if (fileKind === 'audio') {
		result = 'audio';
	} else if (fileKind === 'video') {
		result = 'video';
	}

	return result;
}
