import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('uriUtility', uriUtility);

function uriUtility() {
	let keyPattern = '{key}';

	/**
	 * Example inputs and outputs:
	 *
	 * input: {
	 *   uriPattern: '/proxy/accounts/v1/accounts/{id}',
	 *   parameters: {
	 *     id: 'faithlife-employees'
	 *   }
	 * }
	 * output:
	 * 		/proxy/accounts/v1/accounts/faithlife-employees
	 *
	 * input: {
	 *   uriPattern: '/proxy/files/v1/buckets',
	 *   parameters: {
	 *     limit: 1000
	 *   }
	 * }
	 * output:
	 * 		/proxy/files/v1/buckets?limit=1000
	 */
	function fromPattern(uriPattern: string, parameters: any) {
		let parameterValue;
		let key;
		let keyIndex;

		if (_.isPlainObject(parameters)) {
			Object.keys(parameters).forEach(parameterName => {
				parameterValue = parameters[parameterName];
				key = keyPattern.replace('key', parameterName);
				keyIndex = uriPattern.indexOf(key);

				if (keyIndex !== -1) {
					uriPattern = uriPattern.replace(key, encodeURIComponent(parameterValue));
				} else if (typeof parameterValue !== 'undefined') {
					uriPattern +=
						(uriPattern.indexOf('?') !== -1 ? '&' : '?') +
						encodeURIComponent(parameterName) +
						(parameterValue === null ? '' : `=${encodeURIComponent(parameterValue)}`);
				}
			});
		}
		return uriPattern;
	}

	function getFileName(uri: string) {
		if (uri.startsWith('data:')) {
			return 'data';
		}
		const lastSlash = uri.lastIndexOf('/');
		let name = lastSlash !== -1 ? uri.substr(lastSlash + 1) : uri;
		const q = name.indexOf('?');
		if (q !== -1) {
			name = name.substr(0, q);
		}
		return name;
	}

	return {
		fromPattern,
		setKeyPattern(pattern: string) {
			if (typeof pattern !== 'string' || pattern.indexOf('key') === -1) {
				throw new Error('Pattern must contain the replacement token "key". Eg, ":key".');
			}

			keyPattern = pattern;
		},
		getFileName,
	};
}

export type IUriUtility = ReturnType<typeof uriUtility>;
