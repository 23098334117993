import * as angular from 'angular';
import { IAppSettings } from '../services';
import { IUriUtility } from '../helpers';

angular.module('app').directive('serverRendered', serverRendered);

function serverRendered(uriUtility: IUriUtility, appSettings: IAppSettings) {
	return {
		restrict: 'E',
		templateUrl: require('../../views/templates/serverRendered.html'),
		scope: {
			asset: '=',
		},
		async link(scope: angular.IScope) {
			scope.showRendererLink = () =>
				scope.asset.readRequires === 'none' &&
				(scope.asset?.families ?? []).find(x => x.name === 'smartMedia');

			async function updateSmeRendererUrl() {
				if (
					scope.asset.readRequires === 'none' &&
					(scope.asset?.families ?? []).find(x => x.name === 'smartMedia')
				) {
					const loadSmartMediaHelper = new Promise(resolve => {
						require.ensure(['../helpers/smartMediaHelper'], require =>
							resolve(require('../helpers/smartMediaHelper'))
						);
					});
					const smartMediaHelper: any = await loadSmartMediaHelper;
					const data = smartMediaHelper.getDefaultLiquidContent(
						scope.asset.originalMetadata?.smartMedia?.styles[0]?.model
					);

					scope.smeRendererUrl = uriUtility.fromPattern(
						`${appSettings.mediaRendererBaseUri}/v1/assets/{assetId}`,
						{
							assetId: scope.asset.id,
							...(data && {
								d: JSON.stringify(data),
							}),
						}
					);
					scope.$apply();
				} else {
					scope.smeRendererUrl = null;
				}
			}

			await updateSmeRendererUrl();
			scope.$watch('asset.readRequires', updateSmeRendererUrl);
		},
	};
}
