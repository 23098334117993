import * as angular from 'angular';

angular.module('app').directive('assetPrevNext', assetPrevNext);

function assetPrevNext(searchResultsNavigationModel) {
	return {
		restrict: 'AE',
		replace: true,
		scope: {
			assetId: '=',
		},
		link(scope) {
			scope.pagingContext = searchResultsNavigationModel.getCurrentContext();

			scope.$watch('assetId', () => {
				scope.pagingContext = searchResultsNavigationModel.getCurrentContext();
			});
		},
		templateUrl: require('../../views/templates/assetPrevNext.html'),
	};
}
