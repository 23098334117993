import * as angular from 'angular';
import * as _ from 'lodash';
import { IAssetSearchParameters, IAssetService } from './assetService';
import { IBoardService } from './boardService';
import { CancellationToken } from './cancellationService';
import { IFacetFilter, IFacetQueryService } from './facetQueryService';
import { IThinAsset, IUtility } from '../helpers';

angular.module('app').factory('listHelper', listHelper);

type ISearchResultNavigationModel = any;

function listHelper(
	assetService: IAssetService,
	boardService: IBoardService,
	facetQueryService: IFacetQueryService,
	searchResultsNavigationModel: ISearchResultNavigationModel,
	utility: IUtility
) {
	const sortOptions = [
		{ text: 'Upload date', key: 'uploaded', sortParam: 'uploaded:desc' },
		{ text: 'Upload date (oldest)', key: 'uploadedOldestFirst', sortParam: 'uploaded:asc' },
		{ text: 'Relevance', key: 'relevance', sortParam: null },
		{ text: 'Title', key: 'title', sortParam: 'title:asc' },
		{ text: 'File date', key: 'fileDate', sortParam: 'fileDate:desc' },
		{ text: 'Modified date', key: 'lastEdited', sortParam: 'lastEdited:desc' },
		{ text: 'Rating', key: 'rating', sortParam: 'weightedRating:desc' },
	];

	function setDeletedAsync(assets: ReadonlyArray<IThinAsset>, isDeleted: boolean) {
		for (let i = 0; i < assets.length; i++) {
			assets[i].isDeleted = isDeleted;
		}

		const assetIds = assets.map(({ id }) => id);
		return isDeleted
			? assetService.deleteAssetsAsync(assetIds)
			: assetService.undeleteAssetsAsync(assetIds);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function getDefaultSort(q: string | undefined) {
		return 'uploaded';
	}

	function createSearchQuery(
		q: string | undefined,
		filters: ReadonlyArray<IFacetFilter>,
		savedFilterQuery: string | undefined
	) {
		const filtersQuery = _.compact([
			...filters.map(facetQueryService.formatFilter),
			savedFilterQuery,
		]).join(' ');
		return q && filtersQuery
			? `${q} +(& ${filtersQuery})`
			: q || (filtersQuery && `(& ${filtersQuery})`);
	}

	function createSearchOptions(
		q: string,
		filters: ReadonlyArray<IFacetFilter>,
		selectedSavedFilter: string | undefined,
		board: string | undefined,
		sort: string | undefined,
		offset: number | undefined,
		limit: number | undefined
	) {
		const sortOption = _.find(sortOptions, { key: sort });
		const searchSort = sortOption ? sortOption.sortParam : sort;

		q = createSearchQuery(q, filters, selectedSavedFilter);

		let search: IAssetSearchParameters = {
			q,
			offset,
			limit,
			sort: searchSort,
			fields: `
				hits.asset.(
					id,kind,isDeleted,creation.date,revision.id,metadata.(
						title,description,tags.text
					),source.(
						name,mediaType
					),permissions.readRequires,file.(
						id,name,link.uri,mediaType,byteCount,lastModified,metadata.(
							duration,pageCount,image.(
								width,height
							)
						)
					),formats.(
						name,files.(
							id,link.uri,mediaType,metadata.image.(
								width,height
							)
						),file.(
							id,link.uri,mediaType,metadata.image.(
								width,height
							)
						)
					)
				),hitTotal,facets`.replace(/\s/g, ''), // remove whitespace
			facets: [
				'tags.text:15',
				'kind',
				'status',
				'readPermission',
				'editPermission',
				'family',
				'uploader',
			],
			tzo: new Date().getTimezoneOffset() / 60,
			deleted: undefined,
		};

		if (board) {
			search = boardService.applyBoardToSearch(search, board);
		}

		return search;
	}

	function elideDefaultSortValue(q: string, sort: string) {
		const defaultSort = getDefaultSort(q);
		return sort === defaultSort ? null : sort;
	}

	async function getThinAssetsAsync(
		q: string,
		filters: ReadonlyArray<IFacetFilter>,
		selectedSavedFilter: string | undefined,
		board: string | null | undefined,
		sort: string | null | undefined,
		offset: number | null | undefined,
		limit: number | null | undefined,
		share: string | null | undefined,
		cancellationToken: CancellationToken
	): Promise<{ assets: IThinAsset[]; assetTotal: number; facets: any }> {
		const searchOptions = createSearchOptions(
			q,
			filters,
			selectedSavedFilter,
			board ?? undefined,
			sort ?? undefined,
			offset ?? undefined,
			limit ?? undefined
		);

		const results = await assetService.searchAssetsAsync(searchOptions, share, cancellationToken);

		return {
			assets: results.hits,
			assetTotal: results.total,
			facets: results.facets,
		};
	}

	function getDate(dateString: string) {
		return new Date(dateString).toLocaleString();
	}

	function updateAssetIdsFromModel(model) {
		searchResultsNavigationModel.setAssetIds(model.assets?.map(({ id }) => id));
	}

	function resetModel(model, status) {
		updateAssetIdsFromModel(model);
		model.assetTotal = null;
		model.loadedAssets = [];
		model.thumbnailUrls = {};
		model.status = status;
	}

	function getAssetsWithFileIds(
		assets: IThinAsset[],
		largerDimension: number
	): { assets: IThinAsset[]; fileLinks: { assetId: string; fileId: string; uri: string }[] } {
		const fileLinks = assets
			.map(a => {
				const bestThumbnail = a.getBestThumbnailForSize(largerDimension);
				return (
					bestThumbnail && { assetId: a.id, fileId: bestThumbnail.fileId, uri: bestThumbnail.url }
				);
			})
			.filter(utility.isNotNullish);

		return { assets, fileLinks };
	}

	function getMaxThumbnailWidth(isSmallViewport: boolean, model) {
		if (isSmallViewport) {
			return 130;
		}

		const viewStyle = model.viewStyleService.getStyle();
		if (viewStyle === 'grid') {
			return 356;
		} else if (viewStyle === 'table') {
			return 75;
		}
		return 178;
	}

	function getMaxThumbnailHeight(isSmallViewport: boolean, model) {
		if (isSmallViewport) {
			return 110;
		}

		const viewStyle = model.viewStyleService.getStyle();
		if (viewStyle === 'grid') {
			return 200;
		} else if (viewStyle === 'table') {
			return 50;
		}
		return 100;
	}

	return {
		createSearchQuery,
		setDeletedAsync,
		getDefaultSort,
		sortOptions,
		elideDefaultSortValue,
		getThinAssetsAsync,
		getDate,
		updateAssetIdsFromModel,
		resetModel,
		getAssetsWithFileIds,
		getMaxThumbnailWidth,
		getMaxThumbnailHeight,
	};
}

export type IListHelper = ReturnType<typeof listHelper>;
