import * as angular from 'angular';

angular.module('app').directive('assetTextPreview', assetTextPreview);

function assetTextPreview(
	$http: angular.IHttpService,
	$q: angular.IQService,
	$sanitize,
	$templateCache: angular.ITemplateCacheService,
	$timeout: angular.ITimeoutService
): angular.IDirective {
	const loadShowdown = new Promise<any>(resolve =>
		require.ensure(['showdown'], require => resolve(require('showdown')))
	);

	return {
		restrict: 'E',
		replace: true,
		scope: {
			textSrc: '=',
			textType: '=',
		},
		templateUrl: require('../../views/templates/assetTextPreview.html'),
		link(scope) {
			const templateFetchAborter = $q.defer();
			const maxTextLength = 7000; // Limit preview text to decrease render time

			const model: { html?: string; rawText?: string } = {};

			async function renderText(txt: unknown) {
				if (typeof txt !== 'string') {
					return;
				}

				const trimmedTxt = txt.length >= maxTextLength ? `${txt.slice(0, maxTextLength)}...` : txt;
				let html;

				if (scope.textType === 'text/plain') {
					model.rawText = trimmedTxt;
				} else if (scope.textType === 'text/html') {
					html = trimmedTxt;
				} else if (scope.textType === 'text/markdown') {
					html = await markdownToHtml(trimmedTxt);
				}

				if (html) {
					// Without the $timeout, "$digest already in progress" errors occur.
					// $timeout has an invokeApply argument that defaults to true which wraps the fn with an $apply call.
					// The async markdownToHtml call is what is causing a need for this.
					$timeout(() => {
						model.html = $sanitize(html);
					});
				}
			}

			async function markdownToHtml(txt) {
				const showdown = await loadShowdown;
				const converter = new showdown.Converter();
				converter.setOption('tables', 'true');
				return converter.makeHtml(txt);
			}

			function refreshTextPreview() {
				$http
					.get(scope.textSrc, {
						timeout: templateFetchAborter.promise,
						cache: $templateCache,
						withCredentials: false,
					})
					.then(res => renderText(res.data));
			}

			scope.$on('$destroy', () => {
				templateFetchAborter.resolve();
			});

			scope.model = model;

			scope.$watch('textSrc', (value, old) => {
				if (value === old) {
					return;
				}

				refreshTextPreview();
			});

			refreshTextPreview();
		},
	};
}
