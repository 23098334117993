import * as angular from 'angular';
import * as _ from 'lodash';
import { IEditableProperty, IModelProperty, IPropertySelector } from '../directives';
import { IAsset } from './assetMapper';
import { IUtility } from './utility';
import { AssetOperation } from '../services';

angular.module('app').factory('assetMetadataEditorHelper', assetMetadataEditorHelper);

function assetMetadataEditorHelper(utility: IUtility) {
	function createPropertySelector<TValue>(
		property: IEditableProperty<TValue>
	): (asset: IAsset) => IPropertySelector<TValue> {
		return function (asset) {
			if (property.customPropertySelector) {
				const s = property.customPropertySelector;
				return {
					getValue() {
						return s.getValue(asset);
					},
					canSetValue() {
						return true;
					},
					setValue(value) {
						return s.setValue(asset, value);
					},
				};
			}

			let parent;
			let name;
			if (property.custom) {
				parent = _.find(asset.other, { name: property.name });
				name = 'value';
			} else if (property.familyName) {
				const family = _.find(asset.families, { name: property.familyName });
				parent = family && family.data;
				name = property.name;
			} else {
				parent = asset;
				name = property.name;
			}

			return {
				getValue() {
					return parent && parent[name];
				},
				canSetValue() {
					return !_.isUndefined(parent);
				},
				setValue(value) {
					parent[name] = value;
				},
			};
		};
	}

	function getIntersection(property: IEditableProperty, assets: IAsset[]) {
		const selectProperty = createPropertySelector(property);
		const propertyValues = assets.map(a => selectProperty(a).getValue());

		if (property.multiple) {
			return propertyValues.length === 1
				? propertyValues[0]
				: propertyValues.reduce((left, right) =>
						(left || []).filter(elem => _.some(right, _.partial(angular.equals, elem)))
				  );
		} else if (property.customPropertySelector && property.customPropertySelector.intersectValues) {
			return _.reduce(propertyValues, property.customPropertySelector.intersectValues);
		}

		const intersection = _.uniq(propertyValues);
		return intersection.length === 1 ? intersection[0] : null;
	}

	function mapProperty(property: IEditableProperty, assets): IModelProperty {
		const sharedValue = getIntersection(property, assets);

		return {
			name: property.name,
			type: property.type,
			label: property.label,
			custom: property.custom,
			familyName: property.familyName,
			multiple: property.multiple,
			separator: property.separator,
			hidden: false,
			value: sharedValue,
			typeData: property.typeData,
			customPropertySelector: property.customPropertySelector,
			isVarious: sharedValue === null,
			focus: property.focus,
			dependantProperties: property.dependantProperties,
			updateDependantProperty: property.updateDependantProperty,
		};
	}

	function getSharedProperties<
		TProp extends keyof IAsset,
		TKey extends keyof IAsset[TProp][number]
	>(assets: IAsset[], assetProperty: TProp, keyName: TKey): string[] {
		const propertiesPerAsset = assets.map(a => a[assetProperty]?.map(p => p[keyName]) || []);

		return _.intersection(...propertiesPerAsset);
	}

	function getCustomProperties(assets: IAsset[]) {
		return getSharedProperties(assets, 'other', 'name').map(n => ({
			name: n,
			type: 'string',
			custom: true,
		}));
	}

	function getUnusedFamilies(familyTemplates, familyService) {
		const allFamilyNames = _.map(familyService.getFamilies(), 'name');
		const unusedFamilies = difference(allFamilyNames, _.map(familyTemplates, 'name'));
		return familyService.getFamilies(unusedFamilies);
	}

	function difference(xs, ys) {
		return _.filter(xs, x => !_.some(ys, _.partial(angular.equals, x)));
	}

	function isOrderDifferent(xs, ys) {
		if (xs && ys && !(utility.isEmptyValue(ys) && utility.isEmptyValue(xs))) {
			xs = angular.copy(xs);
			ys = angular.copy(ys);

			if (xs.length === ys.length && !_.isEqual(xs, ys)) {
				let sortBy: string[] = [];

				if (typeof xs[0] === 'object') {
					sortBy = ['text'];
				}

				return _.isEqual(_.sortBy(xs, ...sortBy), _.sortBy(ys, ...sortBy));
			}
		}

		return false;
	}

	function computeChanges<TValue>(
		model: IModelProperty<TValue>[],
		oldModel: IModelProperty<TValue>[]
	): AssetOperation[] {
		let ops: AssetOperation[] = [];

		const allIndexes = _.union(_.map(model, 'index'), _.map(oldModel, 'index'));

		allIndexes.forEach(index => {
			const oldProperty = _.find(oldModel, { index });
			const newProperty = _.find(model, { index });

			const property = newProperty ?? oldProperty;

			if (!property) {
				return;
			}

			if (property.family) {
				if (_.isUndefined(oldProperty)) {
					ops.push({ op: 'addToMetadataArray', path: 'families', value: property.name });
				}

				if (_.isUndefined(newProperty)) {
					ops.push({ op: 'removeFromMetadataArray', path: 'families', value: property.name });

					// additionally, remove all of the associated family metadata
					ops.push({ op: 'setMetadata', path: property.name });

					return;
				}
			}

			const old = oldProperty?.value;
			const value = newProperty?.value;

			if (property.custom) {
				if (!_.isEqual(old, value)) {
					if (!_.isUndefined(old)) {
						ops.push({
							op: 'removeFromMetadataArray',
							path: 'other',
							value: { name: property.name, value: old },
						});
					}

					if (!_.isUndefined(value)) {
						ops.push({
							op: 'addToMetadataArray',
							path: 'other',
							value: { name: property.name, value },
						});
					}
				}
			} else {
				if (property.properties) {
					ops = ops.concat(
						computeChanges(
							(newProperty && newProperty.properties) || [],
							(oldProperty && oldProperty.properties) || []
						)
					);
					return;
				}

				let path = property.name;
				if (property.familyName) {
					path = `${property.familyName}/${path}`;
				}

				if (property.multiple) {
					if (isOrderDifferent(value, old)) {
						ops.push({ op: 'setMetadata', path, value });
					} else {
						difference(value, old).forEach(addedElem => {
							ops.push({ op: 'addToMetadataArray', path, value: addedElem });
						});
						difference(old, value).forEach(removedElem => {
							ops.push({ op: 'removeFromMetadataArray', path, value: removedElem });
						});
					}
				} else if (
					!_.isEqual(old, value) &&
					!(utility.isEmptyValue(old) && utility.isEmptyValue(value))
				) {
					let op;
					if (property.customPropertySelector) {
						op = property.customPropertySelector.createEditOperation(value);
					} else {
						op = { op: 'setMetadata', path };

						if (!utility.isEmptyValue(value)) {
							op.value = value;
						}
					}

					ops.push(op);
				}
			}
		});

		return ops;
	}

	const membershipKinds = ['admin', 'moderator', 'member', 'follower', 'none'];

	function compareMembershipKinds(a, b) {
		const ai = membershipKinds.indexOf(a);
		const bi = membershipKinds.indexOf(b);
		return bi - ai;
	}

	return {
		createPropertySelector,
		getIntersection,
		mapProperty,
		getSharedProperties,
		getCustomProperties,
		getUnusedFamilies,
		difference,
		isOrderDifferent,
		computeChanges,
		compareMembershipKinds,
	};
}

export type IAssetMetadataEditorHelper = ReturnType<typeof assetMetadataEditorHelper>;
