import * as angular from 'angular';
import * as _ from 'lodash';
import { IUriUtility, IUtility } from '../helpers';
import {
	CancellationToken,
	IAppSettings,
	IEmbedService,
	IErrorService,
	ILocalSettingsService,
	UserModel,
} from '.';

export type AccountKind = 'user' | 'group';

export type MembershipKind = 'admin' | 'moderator' | 'member' | 'follower' | 'none';

export interface Account {
	id: string;
	kind?: AccountKind;
	name?: string;
	token?: string;
	avatarUri?: string;
	membership?: MembershipKind;
}

export type AssetBucketPermission = 'none' | 'myAssets' | 'full';

export interface Bucket {
	id: string;
	group?: Account;
	name?: string;
	readPermission?: AssetBucketPermission;
	editPermission?: AssetBucketPermission;
	createPermission?: AssetBucketPermission;
}

angular.module('app').factory('bucketService', bucketService);

function bucketService(
	$http: angular.IHttpService,
	$location: angular.ILocationService,
	$log: angular.ILogService,
	appSettings: IAppSettings,
	embedService: IEmbedService,
	errorService: IErrorService,
	localSettingsService: ILocalSettingsService,
	userModel: UserModel,
	utility: IUtility,
	uriUtility: IUriUtility
) {
	const bucketFetchLimit = 1000;
	let buckets: Bucket[] | undefined;
	const progressiveBucketList = {
		buckets: <Bucket[]>[],
		nextBucket: '',
		complete: false,
	};
	let currentBucket: Bucket | undefined;
	let getBucketsPromise: Promise<Bucket[]> | undefined;
	let bucketRangePromise: Promise<Bucket[]> | undefined;
	const bucketPromises = new Map<string, Promise<Bucket | undefined>>();
	const accountBucketPromises = new Map<string, Promise<Bucket[]>>();

	const getPersonalBucketAsync: () => Promise<Bucket> = _.once(async () => {
		const currentUser = await userModel.getCurrentUserAsync();
		const userId = currentUser.id;
		if (!userId || userId === '-1') {
			throw new Error('Could not load personal bucket because current user was not found');
		}
		const userBuckets = await getBucketsForAccountAsync(userId);
		// getBucketsForAccount does not return full bucket data
		return (await getBucketAsync(userBuckets[0].id))!;
	});

	const setting = localSettingsService.createSettingAccessor('bucket', () => buckets?.[0]);

	// AMB-1060 -- non-group buckets need to be able to display something under default asset permissions
	const defaultGroupPermissions = {
		readRequires: 'admin',
		editRequires: 'admin',
		createRequires: 'admin',
	};

	function getOrCreate<TKey, TValue>(
		map: Map<TKey, TValue>,
		key: TKey,
		create: () => TValue
	): TValue {
		if (map.has(key)) {
			return map.get(key)!;
		}
		const value = create();
		map.set(key, value);
		return value;
	}

	function unionBy<T>(first: T[], second: T[], prop: string): T[] {
		return _.uniqBy([...first, ...second], prop);
	}

	function mapBucket(bucket: Bucket): Bucket {
		return {
			id: bucket.id,
			group: bucket.group,
			name:
				bucket.name ||
				utility.toTitleCase(utility.breakCamelCaseWords(bucket.id).replace(/-/g, ' ')),
			readPermission: bucket.readPermission,
			editPermission: bucket.editPermission,
			createPermission: bucket.createPermission,
		};
	}

	function getCurrentBucket(ignoreSetting?: boolean): Bucket | undefined {
		if (currentBucket) {
			return currentBucket;
		}

		if (ignoreSetting === undefined) {
			ignoreSetting = embedService.isEmbedded;
		}

		const queryBucketId = $location.search().bucket;
		const queryBucket = queryBucketId && buckets && _.find(buckets, { id: queryBucketId });

		if (ignoreSetting && queryBucketId) {
			if (!queryBucket) {
				errorService.showForbidden();
				return (currentBucket = { id: queryBucketId });
			}
			return queryBucket;
		}

		const savedBucket = setting.get();
		return (
			queryBucket ||
			(savedBucket && buckets && _.find(buckets, { id: savedBucket.id })) ||
			(buckets && buckets[0])
		);
	}

	async function getCurrentBucketAsync(
		ignoreSetting?: boolean,
		cancellationToken?: CancellationToken
	): Promise<Bucket> {
		if (currentBucket) {
			return currentBucket;
		}

		if (ignoreSetting === undefined) {
			ignoreSetting = embedService.isEmbedded;
		}

		async function getBucket(id: string): Promise<Bucket | undefined> {
			return (buckets && _.find(buckets, { id })) || (await getBucketAsync(id, cancellationToken));
		}

		const queryBucketId = $location.search().bucket;
		const queryBucket = queryBucketId && (await getBucket(queryBucketId));

		if (ignoreSetting && queryBucketId) {
			if (!queryBucket) {
				errorService.showForbidden();
				return (currentBucket = { id: queryBucketId });
			}
			return (currentBucket = queryBucket);
		}

		const savedBucket = setting.get();
		return (currentBucket =
			queryBucket ||
			(savedBucket && (await getBucket(savedBucket.id))) ||
			(await getPersonalBucketAsync()));
	}

	async function setCurrentBucketAsync(
		bucket: Bucket,
		saveSetting: boolean,
		reload = true
	): Promise<Bucket | undefined> {
		if (currentBucket && currentBucket.id === bucket.id) {
			return currentBucket;
		}

		currentBucket = buckets?.find(x => x.id === bucket.id);
		if (!currentBucket) {
			currentBucket = bucket;
			getBucketsPromise = undefined;
			currentBucket = reload ? await getBucketAsync(bucket.id) : bucket;
		}
		if (saveSetting) {
			setting.set(currentBucket);
		}

		return currentBucket;
	}

	/**
	 * Get a range of buckets. Parameters are used similarly to `Array.slice`.
	 *
	 * @param start must be >= 0
	 * @param end must be >= start
	 */
	async function getBucketRangeAsync(start: number, end: number): Promise<Bucket[]> {
		if (start < 0 || end < start) {
			throw Error(`Invalid bucket range: ${start} - ${end}`);
		}
		// if we're already in the process of adding buckets to the progressiveBucketList, wait for completion (AMB-971)
		if (bucketRangePromise) {
			await bucketRangePromise;
		}
		bucketRangePromise = (async () => {
			while (end > progressiveBucketList.buckets.length && !progressiveBucketList.complete) {
				const next = progressiveBucketList.nextBucket;

				const bucketResults = await getLimitedBucketsAsync(bucketFetchLimit, next);
				// server will sometimes return overlap between chunks todo: remove unionBy as soon as bug fixed: AMB-949
				progressiveBucketList.buckets = unionBy(
					progressiveBucketList.buckets,
					bucketResults.buckets,
					'id'
				);

				// buckets should include everything in progressiveBucketList, but not necessarily vice-versa
				if (buckets) {
					buckets = unionBy(buckets, progressiveBucketList.buckets, 'id');
				} else {
					buckets = progressiveBucketList.buckets;
				}

				if (bucketResults.next) {
					progressiveBucketList.nextBucket = bucketResults.next;
				} else {
					progressiveBucketList.complete = true;
				}
			}
			return progressiveBucketList.buckets.slice(start, end);
		})();
		return bucketRangePromise;
	}

	/** Returns -1 if id not found */
	function getBucketIndex(id: string): number {
		return _.findIndex(progressiveBucketList.buckets, { id });
	}

	function searchBuckets(query: string): Bucket[] {
		return _.filter(
			progressiveBucketList.buckets,
			x => !!x.name && x.name.toLowerCase().includes(query.toLowerCase())
		);
	}

	// todo: refactor this out
	function getBucketsAsync(): Promise<Bucket[]> {
		if (getBucketsPromise) {
			return getBucketsPromise;
		}

		getBucketsPromise = getLimitedBucketsAsync(1000).then(async bucketResults => {
			buckets = bucketResults.buckets;

			const queryBucketId: string = $location.search().bucket;
			if (queryBucketId && !buckets.some(x => x.id === queryBucketId)) {
				try {
					const bucket = await getBucketAsync(queryBucketId);
					if (bucket) {
						buckets.push(bucket);
					}
				} catch (err) {
					$log.error(`Error getting requested bucket: ${queryBucketId}`, err);
				}
			}

			return buckets;
		});

		return getBucketsPromise;
	}

	async function getLimitedBucketsAsync(
		limit: number,
		next?: string,
		cancellationToken?: CancellationToken
	): Promise<{ buckets: Bucket[]; next?: string }> {
		const response = await $http.get<{ items: Bucket[]; next: string | undefined }>(
			uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}buckets`, { limit, next }),
			{ token: cancellationToken }
		);
		const allBuckets = response.data!.items.map(mapBucket);
		const nextBucket = response.data!.next;

		return { buckets: allBuckets, next: nextBucket };
	}

	async function getBucketStatsAsync(
		bucketId: string,
		cancellationToken?: CancellationToken
	): Promise<{ byteCount: number } | undefined> {
		if (!bucketId) {
			return undefined;
		}
		const response = await $http.get<any>(
			uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}buckets/{id}/stats`, {
				id: bucketId,
			}),
			{ token: cancellationToken }
		);
		return { byteCount: response.data.byteCount };
	}

	async function getBucketAsync(
		bucketId: string,
		cancellationToken?: CancellationToken
	): Promise<Bucket | undefined> {
		if (!bucketId) {
			return undefined;
		}
		// avoid making an extra network request in case there's already one in progress for this bucket
		return getOrCreate(bucketPromises, bucketId, async () => {
			try {
				const response = await $http.get<any>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}buckets/{id}`, {
						id: bucketId,
					}),
					{ token: cancellationToken }
				);
				const bucket = mapBucket(response.data);
				if (!buckets) {
					buckets = [bucket];
				} else if (!_.some(buckets, { id: bucket.id })) {
					buckets.push(bucket);
				}
				return bucket;
			} catch (err) {
				return undefined;
			}
		});
	}

	async function getBucketGroupPermissionsAsync(
		bucket?: Bucket,
		cancellationToken?: CancellationToken
	): Promise<
		| {
				readRequires: string;
				editRequires: string;
				createRequires: string;
		  }
		| undefined
	> {
		if (!bucket || !bucket.id || !bucket.group) {
			return defaultGroupPermissions;
		}
		try {
			const response = await $http.get<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}buckets/{id}/groupPermissions`, {
					id: bucket.id,
				}),
				{ token: cancellationToken }
			);
			return response.data;
		} catch (e) {
			if (e.status === 404) {
				$log.warn('Attempted to get groupPermissions on non-group bucket; returning defaults.');
				return defaultGroupPermissions;
			}
			return undefined;
		}
	}

	async function getBucketsForAccountAsync(
		accountId: string,
		cancellationToken?: CancellationToken
	): Promise<Bucket[]> {
		return getOrCreate(accountBucketPromises, accountId, async () => {
			try {
				const response = await $http.get<any>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}buckets/accounts/{accountId}`, {
						accountId,
						autoCreate: true,
					}),
					{ token: cancellationToken }
				);
				return response.data.items.map(mapBucket);
			} catch (err) {
				$log.error(err);
				return [];
			}
		});
	}

	return {
		hasBuckets() {
			return !!buckets;
		},
		getCurrentBucket,
		getCurrentBucketAsync,
		setCurrentBucketAsync,
		getBucketsAsync,
		getBucketAsync,
		getBucketRangeAsync,
		getBucketIndex,
		searchBuckets,
		getBucketStatsAsync,
		getBucketGroupPermissionsAsync,
		getBucketsForAccountAsync,
	};
}

export type IBucketService = ReturnType<typeof bucketService>;
