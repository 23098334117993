import * as angular from 'angular';
import { IAppSettings, IBucketService } from '.';
import { IShareMapper, IUriUtility } from '../helpers';

export interface IFilter {
	name: string;
	query: string;
	sort: {
		sortParam: string | null;
	};
}

angular.module('app').factory('filterService', filterService);

function filterService(
	$http: angular.IHttpService,
	appSettings: IAppSettings,
	bucketService: IBucketService,
	shareMapper: IShareMapper,
	uriUtility: IUriUtility
) {
	let selectedSavedFilter: string | undefined;
	let selectedSavedFilterName: string | undefined;

	async function createFilterAsync(filter: IFilter) {
		const currentBucket = await bucketService.getCurrentBucketAsync();
		const response = await $http.post<any>(`${appSettings.assetServiceBaseUri}filters/`, {
			bucket: currentBucket.id,
			name: filter.name,
			query: filter.query,
			sort: filter.sort.sortParam,
		});
		return response?.data;
	}

	async function createShareAsync(filterId: string, expirationDate?: string) {
		const response = await $http.post<any>(`${appSettings.assetServiceBaseUri}shares`, {
			filterId,
			expires: expirationDate,
		});
		return shareMapper.map(response.data);
	}

	async function getFiltersAsync(options?: any) {
		options = options || {};
		options.bucket = (await bucketService.getCurrentBucketAsync()).id;

		const response = await $http.get<any>(
			uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}filters/`, options)
		);
		return response?.data;
	}

	function editFilterAsync(filterId: string, filter: IFilter) {
		return $http
			.put<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}filters/{id}`, { id: filterId }),
				{ name: filter.name, query: filter.query, sort: filter.sort.sortParam }
			)
			.then(response => response && response.data);
	}

	function deleteFilterAsync(filterId: string) {
		return $http
			.delete<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}filters/{id}`, { id: filterId })
			)
			.then<void>(() => undefined);
	}

	return {
		get selectedSavedFilter() {
			return selectedSavedFilter;
		},
		set selectedSavedFilter(savedFilter) {
			selectedSavedFilter = savedFilter;
		},
		clearSelectedSavedFilter() {
			selectedSavedFilter = undefined;
		},
		get selectedSavedFilterName() {
			return selectedSavedFilterName;
		},
		set selectedSavedFilterName(name) {
			selectedSavedFilterName = name;
		},
		clearSelectedSavedFilterName() {
			selectedSavedFilterName = undefined;
		},
		createFilterAsync,
		createShareAsync,
		getFiltersAsync,
		editFilterAsync,
		deleteFilterAsync,
	};
}

export type IFilterService = ReturnType<typeof filterService>;
