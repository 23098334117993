import * as angular from 'angular';

angular.module('app').factory('adminService', adminService);

function adminService($http, appSettings) {
	function updateIndexAsync(forceRecreate) {
		return $http.post(`${appSettings.assetServiceBaseUri}admin/updateIndex`, {
			forceRecreate: !!forceRecreate,
		});
	}

	return {
		updateIndexAsync,
	};
}
