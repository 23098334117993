import * as angular from 'angular';
import { IAssetService } from '../services';

angular.module('app').directive('assetRevisions', assetRevisions);

interface IAssetRevisionsController extends angular.IController {
	refreshRevisions: () => void;
}

interface IAssetRevisionsScope extends angular.IScope {
	assetId: string;
	assetRevision: any;
	originalRevisionLimit: any;
	revertAction?({ revisionId: string }): any;
	revisions: [];
	shouldShowMoreLink: any;
	revisionLimit: any;
}

function assetRevisions(): angular.IDirective<IAssetRevisionsController, IAssetRevisionsScope> {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: require('../../views/templates/assetRevisions.html'),
		scope: {
			assetId: '=',
			assetRevision: '=',
			originalRevisionLimit: '=revisionLimit',
			revertAction: '&',
		},
		controller(
			this: IAssetRevisionsController,
			$scope: IAssetRevisionsScope,
			assetService: IAssetService
		) {
			const vm = this;

			vm.refreshRevisions = function () {
				if (!$scope.assetId) {
					$scope.revisions = [];
					return;
				}

				assetService.getRevisionsAsync($scope.assetId).then(revisions => {
					$scope.revisions = revisions;
					$scope.shouldShowMoreLink =
						$scope.originalRevisionLimit && $scope.originalRevisionLimit < revisions.length;
					$scope.revisionLimit = $scope.originalRevisionLimit;
					$scope.$apply();
				});
			};
		},
		link(scope, elem, attrs, controller) {
			scope.revisions = [];
			scope.shouldShowMoreLink = false;
			scope.revisionLimit = scope.originalRevisionLimit;
			scope.revertTo = function (revisionId) {
				if (scope.revertAction) {
					scope.revertAction({ revisionId });
				}
			};

			scope.$watch('{ id: assetId, revision: assetRevision }', controller.refreshRevisions, true);
		},
	};
}
