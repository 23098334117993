import * as angular from 'angular';
import { IUserService } from './userService';
import { IUserDetails } from '../helpers';

export class UserModel {
	private userService: IUserService;
	private promise?: Promise<IUserDetails>;

	constructor(userService: IUserService) {
		this.userService = userService;
	}

	clear() {
		this.promise = undefined;
	}

	getCurrentUserAsync() {
		if (!this.promise) {
			this.promise = this.userService.getCurrentUserAsync();
		}

		return this.promise;
	}
}

angular.module('app').service('userModel', UserModel);
