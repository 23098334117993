import * as angular from 'angular';

angular.module('app').filter('searchTerm', searchTerm);

function searchTerm() {
	return function (term, allowPrefixMatches) {
		term = (term || '').replace(/\\/g, '\\\\').replace(/"/g, '\\"');
		return allowPrefixMatches ? term : `"${term}"`;
	};
}
