import * as angular from 'angular';

angular.module('app').directive('tagList', tagList);

function tagList() {
	return {
		restrict: 'AE',
		scope: {
			tags: '=',
		},
		replace: true,
		template(tElem, tAttrs) {
			return `<ul class="item-list inline"><li ng-repeat="tag in tags || []">${
				tAttrs.hideHyperlinks !== 'true'
					? '<a ui-sref="assets({q: \'tag:\' + (tag.text | searchTerm)})">{{tag.text}}</a>'
					: '{{tag.text}}'
			}</li></ul>`;
		},
	};
}
