import * as angular from 'angular';

angular.module('app').factory('boardsModel', boardsModel);

function boardsModel($state) {
	return {
		boards: [],
		get currentBoardId() {
			return $state.params.board;
		},
	};
}
