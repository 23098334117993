import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('assetBoardsEditor', assetBoardsEditor);

function assetBoardsEditor() {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			asset: '=',
			assets: '=',
		},
		controller,
		controllerAs: 'vm',
		templateUrl: require('../../views/templates/assetBoardsEditor.html'),
	};
}

function controller(
	$filter,
	$scope,
	$rootScope,
	assetService,
	boardService,
	boardsModel,
	bucketService,
	embedService
) {
	const vm = this;
	vm.boards = boardsModel.boards;
	vm.assetBoardIds = [];
	vm.assetBoards = [];
	vm.boardMenuItems = [];
	vm.removeFromBoard = removeFromBoardAsync;
	vm.addNewBoard = addNewBoardAsync;
	vm.assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
	vm.showCreateNewBoardModal = false;
	vm.showCreateNewGroupBoardModal = false;

	let assets = [];

	$scope.groupBoardWithCreateOption = function (isGroupBoard) {
		return function (input) {
			return (
				(input.isGroupBoard === !!isGroupBoard || typeof input.isGroupBoard === 'undefined') &&
				!input.isDefault
			);
		};
	};

	function getBoardMenuItems() {
		const menuItems = vm.boards
			.filter(board => vm.assetBoardIds.indexOf(board.id) === -1)
			.map(board => ({
				text: board.name,
				id: board.id,
				isDefault: board.isDefault,
				isGroupBoard: board.isGroupBoard,
				callback() {
					addToBoardAsync(board.id);
				},
			}));

		if (menuItems.length) {
			menuItems.push({ kind: 'separator' });
		}

		// add same 'create board' menu item twice, one with group board and one regular.
		// then the template will filter out the one it doesn't need.
		menuItems.push({
			text: $filter('translate')('boards.createBoard'),
			isGroupBoard: false,
			callback() {
				vm.showCreateNewBoardModal = true;
			},
		});
		menuItems.push({
			text: $filter('translate')('boards.createBoard'),
			isGroupBoard: true,
			callback() {
				vm.showCreateNewGroupBoardModal = true;
			},
		});

		return menuItems;
	}

	function getAssetBoards() {
		return vm.assetBoardIds
			.map(boardId => _.find(vm.boards, { id: boardId }))
			.filter(board => !!board);
	}

	function addNewBoardAsync(boardName, isGroupBoard) {
		return boardService.createBoardAsync(boardName, null, isGroupBoard).then(board => {
			const defaultIndex = vm.boards.findIndex(b => b.isDefault);
			vm.boards.splice(defaultIndex < 0 ? vm.boards.length : defaultIndex, 0, board);
			vm.showCreateNewBoardModal = false;
			vm.showCreateNewGroupBoardModal = false;

			return addToBoardAsync(board.id);
		});
	}

	function addToBoardAsync(boardId) {
		assets.forEach(a => {
			a.boardIds = a.boardIds || [];
			a.boardIds.push(boardId);
		});
		updateVm();

		return assetService.addToBoardAsync(_.map(assets, 'id'), boardId).then(() => {
			$rootScope.$broadcast('changeBoardCount', boardId);
		});
	}

	function removeFromBoardAsync(boardId) {
		assets.forEach(a => {
			a.boardIds = _.without(a.boardIds, boardId);
		});
		updateVm();

		return assetService.removeFromBoardAsync(_.map(assets, 'id'), boardId).then(() => {
			$rootScope.$broadcast('changeBoardCount', boardId);
		});
	}

	function updateVm() {
		vm.assetBoardIds = _.intersection.apply(null, _.map(assets, 'boardIds'));

		vm.boardMenuItems = getBoardMenuItems();
		vm.assetBoards = getAssetBoards();
	}

	function initAsync() {
		$scope.$watch('asset', asset => {
			if (asset) {
				assets = [asset];
				updateVm();
			}
		});

		$scope.$watchCollection('assets', scopeAssets => {
			if (scopeAssets) {
				assets = scopeAssets;
				updateVm();
			}
		});

		$scope.$watch(
			() => bucketService.getCurrentBucket(),
			bucket => {
				$scope.bucket = bucket;
				updateVm();
			}
		);

		return boardService.listBoardsAsync().then(data => {
			angular.copy(data.boards, vm.boards);
			updateVm();
		});
	}

	initAsync();
}
