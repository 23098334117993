import * as angular from 'angular';
import { IAppSettings } from './appSettings';
import { CancellationToken } from './cancellationService';
import { IUriUtility, IUserDetails, IUserMapperService } from '../helpers';
import { IBrowserService } from './browserService';
import { IEmbedService } from './embedService';

angular.module('app').factory('userService', userService);

function userService(
	$http: angular.IHttpService,
	$location: angular.ILocationService,
	$log: angular.ILogService,
	$window: angular.IWindowService,
	appSettings: IAppSettings,
	browserService: IBrowserService,
	userMapper: IUserMapperService,
	uriUtility: IUriUtility,
	embedService: IEmbedService
) {
	let autoSignInPromise: angular.IPromise<boolean> | undefined;
	let generatedToken = false;

	async function getCurrentUserAsync(): Promise<IUserDetails> {
		return $http
			.get<any>(`${appSettings.accountServicesBaseUri}users/me`)
			.then(response => userMapper.mapDetails(response.data));
	}

	async function getUserDetailsAsync(id: string) {
		return $http
			.get<any>(uriUtility.fromPattern(`${appSettings.accountServicesBaseUri}users/{id}`, { id }))
			.then(response => userMapper.mapDetails(response.data));
	}

	async function getUserDetailsBatchAsync(ids: ReadonlyArray<string>) {
		return $http
			.get<any>(
				uriUtility.fromPattern(`${appSettings.accountServicesBaseUri}users?ids={ids}`, {
					ids,
				})
			)
			.then(response => {
				const users = response.data && response.data.users;
				return Array.isArray(users) ? users.map(userMapper.mapDetails) : undefined;
			});
	}

	async function getUserDetailsAsAdminAsync(id: string) {
		return $http
			.get<any>(
				uriUtility.fromPattern(`${appSettings.accountServicesBaseUri}admin/users/{id}`, {
					id,
				})
			)
			.then(response => userMapper.mapDetails(response.data));
	}

	async function searchUsersAsync(search: string, cancellationToken: CancellationToken) {
		search = search && search.trim();
		if (!search) {
			return null;
		}

		return $http
			.get<any>(
				uriUtility.fromPattern(`${appSettings.accountServicesBaseUri}users`, { q: search }),
				{ timeout: cancellationToken }
			)
			.then(response => {
				const users = response.data && response.data.users;
				return Array.isArray(users) ? users.map(userMapper.mapDetails) : undefined;
			});
	}

	async function autoSignInAsync(clearCookie: boolean) {
		if (autoSignInPromise) {
			return autoSignInPromise;
		}
		autoSignInPromise = $http
			.jsonp(
				`${appSettings.autoSignInUri}?jsonp=JSON_CALLBACK${clearCookie ? '&clearCookie=true' : ''}`
			)
			.then(credentials =>
				$http({
					method: 'post',
					url: appSettings.localAutoSignInCookieUri,
					data: credentials.data,
				}).then(response => {
					autoSignInPromise = undefined;
					return response && response.status === 200;
				})
			);

		return autoSignInPromise;
	}

	async function getIdentityTokenAsync(): Promise<{ token: string | null; generated: boolean }> {
		if (generatedToken || !embedService.createTokenUri || $location.search().identityToken) {
			return { token: null, generated: generatedToken };
		}

		$log.info(`Getting identity token from ${embedService.createTokenUri}`);

		generatedToken = true;

		const token = await $http
			.post<{ identityToken: string }>(embedService.createTokenUri, null, { withCredentials: true })
			.then(response => response?.data?.identityToken);

		return { token, generated: generatedToken };
	}

	function getOauthSignInUrl(forPopup: boolean) {
		let redirectUri = forPopup
			? '/popupauthsuccess'
			: $window.location.href
					.replace($window.location.origin, '')
					.replace(/([?&])ssi=(false|true)/, '')
					.replace(/([?&])signinReturn=(false|true)/, '');

		redirectUri += `${redirectUri.includes('?') ? '&' : '?'}signinReturn=true`;

		return `${appSettings.oauthSignInUri}?redirectUri=${encodeURIComponent(redirectUri)}`;
	}

	function oauthSignInAsync(popup: boolean) {
		// don't use async/await to ensure this is run immediately when called from an event handler
		return browserService.requestStorageAccessAsync().then(() => {
			$log.info('Starting OAuth sign-in');
			if (popup) {
				const signInUrl = getOauthSignInUrl(true);
				const popupWindow = $window.open(signInUrl);
				if (!popupWindow) {
					$log.warn('Could not open window. Enable popups.');
					return false;
				}
				browserService.addSignInEventListener(popupWindow);
			} else {
				$window.location.href = getOauthSignInUrl(false);
			}
			return true;
		});
	}

	return {
		getCurrentUserAsync,
		getUserDetailsAsync,
		getUserDetailsBatchAsync,
		getUserDetailsAsAdminAsync,
		searchUsersAsync,
		autoSignInAsync,
		getOauthSignInUrl,
		oauthSignInAsync,
		getIdentityTokenAsync,
	};
}

export type IUserService = ReturnType<typeof userService>;
