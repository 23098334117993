import * as angular from 'angular';

angular.module('app').directive('assetMetadataField', assetMetadataField);

function assetMetadataField() {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: require('../../views/templates/assetMetadataField.html'),
		scope: {
			label: '@',
		},
	};
}
