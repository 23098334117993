import * as angular from 'angular';
import * as _ from 'lodash';
import { AssetOperation, IAssetService } from '../services';
import { IAsset, IUtility } from '../helpers';
import { IAssetMetadataEditorHelper } from '../helpers/assetMetadataEditorHelper';
import { IFormattedMetadataKeyFilter } from '../filters';

export interface ICustomPropertySelector<TValue> {
	getValue(asset: IAsset): TValue;
	setValue(asset: IAsset, value: TValue): void;
	intersectValues?(x: TValue, y: TValue): TValue;
	createEditOperation(value?: TValue): AssetOperation;
}

export interface IPropertySelector<TValue> {
	getValue(): TValue;
	canSetValue(): boolean;
	setValue(value: TValue): void;
}

export interface IEditableProperty<TValue = any> {
	name: string;
	label?: string;
	type?: string;
	multiple?: boolean;
	alwaysShow?: boolean;
	typeData?: any;
	customPropertySelector?: ICustomPropertySelector<TValue>;
	dependantProperties?: string[];
	updateDependantProperty?(dependantProperty, newValue, oldValue, scope): void;
	value?: TValue;
	focus?: boolean;
	isNew?: boolean;
	unwatch?(): void;
	custom?: boolean;
	familyName?: string;
	separator?: string;
}

export interface IModelProperty<TValue = any> extends IEditableProperty<TValue> {
	index?: number;
	family?: boolean;
	sourceIndex?: number;
	properties?: IModelProperty<TValue>[];
	hidden: boolean;
	isVarious?: boolean;
}

interface IFamilyTemplate {
	name: string;
	displayName: string;
	unsupported: boolean;
	properties?: IEditableProperty[];
}

interface IMenuItem {
	name: string;
}

interface IAssetMetadataEditorScope extends angular.IScope {
	asset?: IAsset;
	assets?: IAsset[];
	model?: IModelProperty[];
	readonly: boolean;
	newFieldOptions: {
		text?: string;
		callback?(menuItem: IMenuItem): void;
		kind: string;
		name?: string;
	}[];
}

angular.module('app').directive('assetMetadataEditor', assetMetadataEditor);

function assetMetadataEditor(
	$filter: angular.IFilterService,
	$log: angular.ILogService,
	$timeout: angular.ITimeoutService,
	assetMetadataEditorHelper: IAssetMetadataEditorHelper,
	assetService: IAssetService,
	familyService,
	utility: IUtility
) {
	const editableProperties: IEditableProperty[] = [
		{
			name: 'title',
			label: $filter('translate')('assetMetadata.title'),
			type: 'string',
			alwaysShow: true,
		},
		{
			name: 'rating',
			type: 'rating',
			label: '',
			alwaysShow: true,
			customPropertySelector: {
				getValue(asset) {
					return {
						rating: asset.rating,
						average: asset.globalRating,
						count: asset.globalRatingCount,
						views: asset.views,
						downloads: asset.downloads,
						favorites: asset.globalFavoriteCount,
						shares: asset.activeShareCount,
					};
				},
				setValue(asset, value) {
					asset.rating = value.rating;
				},
				intersectValues(x, y) {
					return x.rating === y.rating ? { rating: x.rating } : { rating: null };
				},
				createEditOperation(value) {
					return { op: 'setRating', value: value?.rating || undefined };
				},
			},
		},
		{
			name: 'authors',
			label: $filter('translate')('assetMetadata.authors'),
			type: 'author',
			multiple: true,
			alwaysShow: true,
		},
		{
			name: 'description',
			label: $filter('translate')('assetMetadata.description'),
			type: 'multilinestring',
			alwaysShow: true,
		},
		{ name: 'note', label: $filter('translate')('assetMetadata.note'), type: 'multilinestring' },
		{
			name: 'tags',
			label: $filter('translate')('assetMetadata.tags'),
			type: 'tag',
			multiple: true,
			alwaysShow: true,
		},
		{
			name: 'status',
			label: $filter('translate')('assetMetadata.status'),
			type: 'radio',
			typeData: { values: [undefined, 'draft', 'published', 'retired'] },
			alwaysShow: true,
		},
		{
			name: 'canView',
			label: $filter('translate')('assetMetadata.canView'),
			type: 'permission',
			typeData: { showNone: true, permissionKey: 'readRequires' },
			customPropertySelector: {
				getValue(asset) {
					return asset.readRequires;
				},
				setValue(asset, value) {
					asset.readRequires = value;
				},
				createEditOperation(value) {
					return { op: 'setPermission', path: 'readRequires', value: value || undefined };
				},
			},
			alwaysShow: true,
			dependantProperties: ['canEdit'],
			updateDependantProperty(dependantProperty, newValue, oldValue, scope) {
				const dependantValue =
					dependantProperty.value ||
					scope.groupPermissions[dependantProperty.typeData.permissionKey];
				if (
					this.value &&
					assetMetadataEditorHelper.compareMembershipKinds(this.value, dependantValue) > 0
				) {
					dependantProperty.value = newValue;
				}
			},
		},
		{
			name: 'canEdit',
			label: $filter('translate')('assetMetadata.canEdit'),
			type: 'permission',
			typeData: { showNone: false, permissionKey: 'editRequires' },
			customPropertySelector: {
				getValue(asset) {
					return asset.editRequires;
				},
				setValue(asset, value) {
					asset.editRequires = value;
				},
				createEditOperation(value) {
					return { op: 'setPermission', path: 'editRequires', value: value || undefined };
				},
			},
			alwaysShow: true,
			dependantProperties: ['canView'],
			updateDependantProperty(dependantProperty, newValue, oldValue, scope) {
				const dependantValue =
					dependantProperty.value ||
					scope.groupPermissions[dependantProperty.typeData.permissionKey];
				if (
					this.value &&
					assetMetadataEditorHelper.compareMembershipKinds(this.value, dependantValue) < 0
				) {
					dependantProperty.value = newValue;
				}
			},
		},
		{
			name: 'rights',
			label: $filter('translate')('assetMetadata.rights'),
			type: 'rights',
			customPropertySelector: {
				getValue(asset) {
					return asset.rights;
				},
				setValue(asset, value) {
					asset.rights = value;
				},
				createEditOperation(value) {
					return { op: 'setMetadata', path: 'license/rights', value: value || undefined };
				},
			},
			alwaysShow: true,
		},
		{
			name: 'copyright',
			label: $filter('translate')('assetMetadata.copyright'),
			type: 'copyright',
			alwaysShow: true,
		},
		{
			name: 'licenseTerms',
			label: $filter('translate')('assetMetadata.licenseTerms'),
			type: 'multilinestring',
			customPropertySelector: {
				getValue(asset) {
					return asset.licenseTerms;
				},
				setValue(asset, value) {
					asset.licenseTerms = value;
				},
				createEditOperation(value) {
					return { op: 'setMetadata', path: 'license/usageTerms', value: value || undefined };
				},
			},
		},
		{
			name: 'date',
			label: $filter('translate')('assetMetadata.date'),
			type: 'editableDate',
			alwaysShow: true,
		},
		{ name: 'location', label: $filter('translate')('assetMetadata.location'), type: 'location' },
		{
			name: 'language',
			label: $filter('translate')('assetMetadata.language'),
			type: 'completion',
			typeData: { fieldName: 'language' },
			alwaysShow: true,
		},
		{
			name: 'managers',
			label: $filter('translate')('assetMetadata.managers'),
			type: 'author',
			multiple: true,
			alwaysShow: false,
		},
	];

	return {
		restrict: 'AE',
		replace: true,
		templateUrl: require('../../views/templates/assetMetadataEditor.html'),
		scope: {
			asset: '=',
			assets: '=',
			readonly: '=',
			noLinks: '=',
			currentUser: '=',
			bucket: '=',
			groupPermissions: '=',
		},
		controller($scope: IAssetMetadataEditorScope, $attrs: angular.IAttributes) {
			function unwatchProperty(property: IModelProperty) {
				_.invoke(property.properties, 'unwatch');

				if (property.unwatch) {
					property.unwatch();
				}
			}

			function initialize() {
				$scope.model = [];
				update();
			}

			function update() {
				let isUpdating = true;
				const assets = $scope.assets || ($scope.asset ? [$scope.asset] : []);
				const model = $scope.model as IModelProperty[];
				let oldModel: IModelProperty[] = [];
				let saveChangesTimer;
				let overrides;

				function cancelTimer(timer) {
					$timeout.cancel(timer);
					return null;
				}

				function saveChangesSoon() {
					cancelTimer(saveChangesTimer);
					saveChangesTimer = $timeout(savePendingChanges, 1000, false);
				}

				let saveJob: Promise<void> | null = null;
				function savePendingChanges() {
					if (saveJob) {
						return;
					}

					// compute the diff between oldModel and model; generate ops
					const ops = assetMetadataEditorHelper.computeChanges(model, oldModel);
					if (!ops.length) {
						return;
					}

					// initiate the save
					oldModel = _.cloneDeep(model);
					saveJob = assetService
						.editAssetsAsync(_.map(assets, 'id'), ops, { shouldAwaitCompletion: true })
						.then(results => {
							(results as IAsset[]).forEach(resultAsset => {
								const asset = _.find(assets, { id: resultAsset.id });
								if (asset) {
									asset.revision = resultAsset.revision;
								}
							});

							saveJob = null;
							savePendingChanges();
						});
				}

				function getUniquePropertyId(array: { index?: number }[]): number {
					return (_.maxBy(array, x => x.index)?.index ?? 0) + 1;
				}

				function addModelProperty(
					property: IModelProperty | IModelProperty[],
					insertionPosition?: number,
					replace = false
				) {
					if (Array.isArray(property)) {
						property.forEach(p => {
							addModelProperty(p, insertionPosition);
						});
						return;
					}

					const oldItem =
						replace && utility.findReplace(model, ({ name }) => name === property.name, property);
					if (oldItem) {
						property.isNew = false;
						property.index = oldItem.index;
						return;
					}

					// if being executed from a callback, e.g. when adding new metadata, isUpdating will be false
					property.isNew = !isUpdating;
					property.index = getUniquePropertyId(model);
					insertionPosition = _.isNumber(insertionPosition) ? insertionPosition : model.length;
					if (insertionPosition < 0) {
						insertionPosition = model.length;
					}

					model.splice(insertionPosition, 0, property);
				}

				function buildFamilyTemplateModel(familyTemplate: IFamilyTemplate): IModelProperty {
					const familyModel = {
						family: true,
						hidden: false,
						name: familyTemplate.name,
						displayName: familyTemplate.displayName,
						unsupported: familyTemplate.unsupported,
						properties: _.map(familyTemplate.properties, p => {
							p.familyName = familyTemplate.name;
							return assetMetadataEditorHelper.mapProperty(p, assets);
						}),
					};

					familyModel.properties.forEach(property => {
						property.index = getUniquePropertyId(familyModel.properties);
						watchProperty(property);
					});
					return familyModel;
				}

				function getUnsupportedFamilyPlaceholder(familyName) {
					return buildFamilyTemplateModel({
						name: familyName,
						displayName: utility.toTitleCase(utility.breakCamelCaseWords(familyName)),
						unsupported: true,
					});
				}

				function getFamilyProperties(assetCollection: IAsset[]) {
					return assetMetadataEditorHelper
						.getSharedProperties(assetCollection, 'families', 'name')
						.map(familyName => {
							const familyTemplate = familyService.getFamily(familyName);
							return familyTemplate
								? buildFamilyTemplateModel(familyTemplate)
								: getUnsupportedFamilyPlaceholder(familyName);
						})
						.filter(x => !!x);
				}

				function watchProperty(
					property: IEditableProperty,
					additionalWatchExpression?: string | (() => any)
				) {
					if ($scope.readonly) {
						return;
					}

					const selectProperty = assetMetadataEditorHelper.createPropertySelector(property);

					function processChange(value, old) {
						if (value === old || (utility.isEmptyValue(old) && utility.isEmptyValue(value))) {
							return;
						}

						let updateAsset;

						if (property.multiple) {
							if (property.customPropertySelector) {
								// Leaving unimplemented for now because it's hard and YAGNI. Implement if needed.
								throw new Error(
									`cannot use custom propertySelectors with multi-value properties: ${property}`
								);
							}

							const addedItems = assetMetadataEditorHelper.difference(value, old);
							const removedItems = assetMetadataEditorHelper.difference(old, value);

							updateAsset = function (p) {
								let collection = _.reject(p.getValue(), item =>
									_.some(removedItems, removed => angular.equals(item, removed))
								);

								if (assetMetadataEditorHelper.isOrderDifferent(value, old)) {
									collection = value;
								} else {
									collection = collection.concat(
										assetMetadataEditorHelper.difference(addedItems, collection)
									);
								}

								p.setValue(collection);
							};
						} else {
							updateAsset = function (p) {
								p.setValue(value);
							};
						}

						assets.forEach(a => {
							const p = selectProperty(a);
							if (!p.canSetValue()) {
								throw new Error(`parent object does not exist. asset: ${a}, property: ${p}`);
							}

							updateAsset(p);
						});
					}

					let processChangeTimer;
					let pendingNewValue;
					let pendingOldValue;
					let isChangePending;
					function processChangeSoon(value, old) {
						if (value === old) {
							return;
						}

						processChangeTimer = cancelTimer(processChangeTimer);
						saveChangesTimer = cancelTimer(saveChangesTimer);

						if (!isChangePending) {
							pendingOldValue = old;
						}

						isChangePending = true;
						pendingNewValue = value;
						processChangeTimer = $timeout(
							() => {
								processChange(pendingNewValue, pendingOldValue);
								isChangePending = false;
								processChangeTimer = pendingNewValue = pendingOldValue = null;

								saveChangesSoon();
							},
							200,
							false
						);

						if (property.dependantProperties) {
							for (const dependantPropertyName of property.dependantProperties) {
								const dependantProperty = model.find(x => x.name === dependantPropertyName);
								if (dependantProperty) {
									$log.debug('Updating dependant property', dependantPropertyName);
									property.updateDependantProperty?.(dependantProperty, value, old, $scope);
								} else {
									$log.warn('Dependant property does not exist', dependantPropertyName);
								}
							}
						}
					}

					property.unwatch = $scope.$watch(() => property.value, processChangeSoon, true);

					if (additionalWatchExpression) {
						const unwatchAdditional = $scope.$watch(
							additionalWatchExpression as any,
							(newValue, oldValue) => {
								if (newValue !== oldValue) {
									property.value = newValue;
									processChangeSoon(newValue, oldValue);
								}
							},
							true
						);
						property.unwatch = _.flowRight(property.unwatch, unwatchAdditional);
					}
				}

				function addEditableProperty(
					property: IEditableProperty,
					includeWhenUndefined: boolean,
					replace = false
				) {
					const modelProperty = assetMetadataEditorHelper.mapProperty(property, assets);
					if (_.isUndefined(modelProperty.value) && !includeWhenUndefined) {
						return;
					}

					let watchExpression: (() => any) | undefined;
					if (Object.prototype.hasOwnProperty.call(overrides, property.name)) {
						watchExpression = function () {
							return $scope.$parent.$eval(overrides[property.name]);
						};

						modelProperty.hidden = true;
					}
					watchProperty(modelProperty, watchExpression);
					modelProperty.sourceIndex = editableProperties.findIndex(
						({ name }) => name === property.name
					);
					const insertionPosition =
						modelProperty.sourceIndex < 0
							? -1
							: _.findIndex(
									model,
									p => _.isUndefined(p.sourceIndex) || p.sourceIndex > modelProperty.sourceIndex!
							  );
					addModelProperty(modelProperty, insertionPosition, replace);
				}

				function addUnusedPropertyTemplate(menuItem: IMenuItem) {
					const property = editableProperties.find(({ name }) => name === menuItem.name)!;
					property.focus = true;
					addEditableProperty(property, true);
					calculateNewFieldOptions();
				}

				function addCustomFieldTemplate() {
					const customFieldName = '';

					const customProperty = assetMetadataEditorHelper.mapProperty(
						{ name: customFieldName, type: 'string', custom: true, focus: true },
						assets
					);
					watchProperty(customProperty);

					const insertionPosition = model.findIndex(p => p.family);
					addModelProperty(customProperty, insertionPosition);
				}

				function addFamilyTemplate(familyTemplate) {
					const familyModel = buildFamilyTemplateModel(familyTemplate);
					assets.forEach(asset => {
						const existingFamily = _.find(asset.families, { name: familyTemplate.name });
						if (!existingFamily) {
							asset.families.push({ name: familyTemplate.name, data: {} });
						}
					});

					if (familyModel.properties?.length) {
						familyModel.properties[0].focus = true;
					}
					addModelProperty(familyModel);
					saveChangesSoon();

					calculateNewFieldOptions();
				}

				function calculateNewFieldOptions() {
					let newFieldOptions: IAssetMetadataEditorScope['newFieldOptions'] = [];

					const unusedProperties = editableProperties.filter(
						p => !model.some(m => !m.custom && !m.family && m.name === p.name)
					);
					if (unusedProperties.length) {
						newFieldOptions = newFieldOptions.concat(
							unusedProperties.map(f => ({
								text:
									f.label || $filter<IFormattedMetadataKeyFilter>('formattedMetadataKey')(f.name),
								callback: addUnusedPropertyTemplate,
								kind: 'unused',
								name: f.name,
							}))
						);

						newFieldOptions.push({ kind: 'separator' });
					}

					const unusedFamilies = assetMetadataEditorHelper.getUnusedFamilies(
						_.filter(model, { family: true }),
						familyService
					);
					if (unusedFamilies.length) {
						newFieldOptions = newFieldOptions.concat(
							unusedFamilies.map(f => ({
								text: f.displayName,
								callback() {
									addFamilyTemplate(f);
								},
								kind: 'family',
							}))
						);

						newFieldOptions.push({ kind: 'separator' });
					}

					newFieldOptions.push({
						text: $filter('translate')('assetMetadata.customField'),
						callback: addCustomFieldTemplate,
						kind: 'custom',
					});
					$scope.newFieldOptions = newFieldOptions;
				}

				function removeProperty(property) {
					model.splice(model.indexOf(property), 1);
					unwatchProperty(property);
					if (property.family) {
						assets.forEach(asset => {
							asset.families = _.reject(asset.families, { name: property.name });
						});
					} else if (property.custom) {
						assets.forEach(asset => {
							asset.other = _.reject(asset.other, { name: property.name });
						});
					}

					calculateNewFieldOptions();
					saveChangesSoon();
				}

				function buildModel() {
					overrides = $scope.$eval($attrs.editorFieldOverrides) || {};

					editableProperties.forEach(property => {
						addEditableProperty(property, property.alwaysShow ?? false, true);
					});

					const customProperties = assetMetadataEditorHelper.getCustomProperties(assets);
					customProperties.forEach(property => {
						const modelProperty = assetMetadataEditorHelper.mapProperty(property, assets);
						watchProperty(modelProperty);
						addModelProperty(modelProperty, undefined, true);
					});

					addModelProperty(getFamilyProperties(assets), undefined, true);
				}

				buildModel();
				oldModel = _.cloneDeep(model);
				$scope.model = model;
				$scope.removeProperty = removeProperty;
				calculateNewFieldOptions();
				isUpdating = false;
			}

			function onAssetsChange(newAssets: IAsset[], oldAssets: IAsset[]) {
				$scope.model?.forEach(unwatchProperty);

				if (
					newAssets !== oldAssets &&
					newAssets.every(({ id }, idx) => id === oldAssets?.[idx]?.id)
				) {
					update();
				} else {
					initialize();
				}
			}

			function onAssetChange(newAsset: IAsset, oldAsset: IAsset) {
				if (newAsset === oldAsset) {
					return;
				}

				onAssetsChange([newAsset], [oldAsset]);
			}

			$scope.$watch<IAsset>('asset', onAssetChange);
			$scope.$watchCollection<IAsset[]>('assets', onAssetsChange);
		},
	};
}
