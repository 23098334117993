import * as angular from 'angular';
import * as $ from 'jquery';
import { IErrorService, UserModel } from '../services';

angular.module('app').config(configure);

function configure($httpProvider: angular.IHttpProvider) {
	$.ajaxSetup({
		xhrFields: { withCredentials: true },
	});
	$httpProvider.defaults.withCredentials = true;
	$httpProvider.interceptors.push(authenticationInterceptor);
}

function authenticationInterceptor(
	$injector: angular.auto.IInjectorService
): angular.IHttpInterceptor {
	return {
		async responseError(response) {
			if (response.status === 401) {
				$injector.get<UserModel>('userModel').clear();
			}

			if (response.status === 403) {
				$injector.get<IErrorService>('errorService').showForbidden();
			}

			return Promise.reject(response);
		},
	};
}
