import * as angular from 'angular';

angular.module('app').directive('focusInput', focusInput);

function focusInput() {
	return {
		restrict: 'A',
		link(scope, element, attrs) {
			if (attrs.focusInput) {
				element.focus();
			}
		},
	};
}
