import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('autoComplete', autoComplete);

function autoComplete(
	$compile,
	$q,
	$http,
	$log,
	$state,
	$templateCache,
	$filter,
	cancellationService,
	completionService,
	tooltipService,
	autoCompleteProviderService,
	$timeout,
	appSettings,
	tagTooltipService,
	assetService,
	embedService
) {
	function readProviders(providersSpec) {
		return providersSpec
			.map(providerSpec => {
				const providerFactory = autoCompleteProviderService.providers[providerSpec.name];
				const provider =
					providerFactory && providerFactory.createProvider.apply(null, providerSpec.args);
				provider.label = providerSpec.label;
				provider.name = providerSpec.name;
				return provider;
			})
			.filter(x => !!x);
	}

	return {
		restrict: 'AE',
		scope: {
			source: '=',
			providers: '=',
			readonly: '=isReadonly',
			disableSortable: '=',
			filterCreator: '=',
			mapping: '=',
			isSingle: '=',
			restrictSelection: '=',
			focusInput: '=',
			completionType: '<',
		},
		replace: true,
		template:
			'<input type="hidden" class="tags-editor tag-input" ui-select2="select2Options" ng-model="model" ng-disabled="!hasPrefetched || !!readonly"></input>',
		link: {
			/** @param {angular.IScope} scope */
			pre(scope, elem) {
				function map(model) {
					const mapFunction = scope.mapping && scope.mapping.map;
					if (model && mapFunction) {
						return _.isArray(model) ? _.map(model, mapFunction) : mapFunction(model);
					}

					return model;
				}

				function unmap(model) {
					const unmapFunction = scope.mapping && scope.mapping.unmap;
					if (model && unmapFunction) {
						return _.isArray(model) ? _.map(model, unmapFunction) : unmapFunction(model);
					}

					return model;
				}

				function formatResult(suggestion, container, query, escapeMarkup) {
					if (!suggestion.id) {
						return suggestion.text && escapeMarkup(suggestion.text);
					}

					const newScope = scope.$new(true);
					newScope.text = suggestion.text;
					newScope.disambiguation = suggestion.disambiguation;

					if (isAssetCompletion()) {
						newScope.data = suggestion.data;
						return $compile('<asset-completion-tag ng-if="data" asset="data"/>')(newScope);
					}

					return $compile(
						'<span>{{text}} <span ng-if="disambiguation" class="result-disambiguation">{{disambiguation}}</span></span>'
					)(newScope);
				}

				function formatResultCssClass(item) {
					if (item.groupKind) {
						return `result-group-header result-group-header-${(
							item.groupKind || 'default'
						).toLowerCase()}`;
					}

					return isAssetCompletion() ? 'result-item completion-tag-wrap' : 'result-item';
				}

				function formatSelection(object, container) {
					const newScope = scope.$new(true);
					const tagId = object.text;
					newScope.tag = { text: tagId };
					newScope.filter = scope.filterCreator && scope.filterCreator(object);

					const assetListState = embedService.isEmbedded ? 'assetsEmbed' : 'assets';
					let tagHtml = newScope.filter
						? `<a ui-sref="${assetListState}({q: null, filter: ([filter] | filterParameterString)})">{{tag.text}}</a>`
						: '<span>{{tag.text}}</span>';

					if (isAssetCompletion()) {
						if (
							($state.current.name === 'assets' || $state.current.name === 'assetsEmbed') &&
							!scope.isSingle
						) {
							const tagLimit = 10;
							const taglist = scope.source;

							if (taglist.indexOf(tagId) >= tagLimit) {
								container.parent().css('display', 'none');
								return;
							}
						}

						addAssetToCompletionTag(tagId, newScope);
						container.parent().addClass('completion-tag-wrap');
						tagHtml =
							'<a ui-sref="details({ assetId: asset.id })"><asset-completion-tag ng-if="asset" asset="asset" /></a>';
					}

					container.append($compile(tagHtml)(newScope));
				}

				function addAssetToCompletionTag(assetId, newScope) {
					const cachedAssetsById = tagTooltipService.cachedAssetsById;

					if (assetId in cachedAssetsById) {
						newScope.asset = cachedAssetsById[assetId];
					} else {
						assetService
							.getAssetAsync(assetId)
							.then(asset => {
								cachedAssetsById[assetId] = asset;
								newScope.asset = asset;
							})
							.catch(error => {
								if (error === 'notfound') {
									$log.warn(`Asset for tag: ${assetId} not found`);
								} else if (error !== 'abort') {
									$log.error(error);
								}
							});
					}
				}

				let cancellationSource;

				const providers = readProviders(scope.providers);

				const querySoon = _.debounce(q => {
					if (cancellationSource) {
						cancellationSource.cancel();
					}

					cancellationSource = cancellationService.createCancellationSource();

					if (!q.term) {
						return q.callback({ results: [''] }); // Requires empty string for dropdown to stay open
					}

					function handleError() {
						return null;
					}

					const promises = providers.map(p =>
						p
							.getResults(q.term, cancellationSource.token)
							.then(results => {
								const mappedResults = p.map(results);
								if (!mappedResults || !mappedResults.length) {
									return null;
								}

								return {
									text: p.label,
									groupKind: p.name,
									children: mappedResults,
								};
							})
							.catch(handleError)
					);

					return Promise.all(promises)
						.then(results => {
							q.callback({
								results: _.compact(results),
							});
							scope.$digest();
						})
						.catch(() => {
							// ignore aborted requests
						});
				}, appSettings.inputDebounceTimeout);

				function createSearchChoice(term, groups) {
					if (scope.restrictSelection) {
						return undefined;
					}

					let foundMatch;
					term = term.trim();

					const previouslyEnteredGroup = _.find(groups, item => item.groupKind === 'completion');
					if (previouslyEnteredGroup) {
						const foundIndex = _.findIndex(previouslyEnteredGroup.children, { text: term });
						if (foundIndex !== -1) {
							const removed = previouslyEnteredGroup.children.splice(foundIndex, 1);
							foundMatch = removed[0];
						}
					}

					return isAssetCompletion()
						? foundMatch
						: foundMatch || {
								id: `free:${term}`,
								text: term,
								disambiguation: $filter('translate')('autocomplete.createNewTerm'),
						  };
				}

				scope.select2Options = {
					multiple: !scope.isSingle,
					tokenSeparators: [';'],
					createSearchChoice,
					containerCssClass: isAssetCompletion()
						? 'asset-select2-container completion-tag-container'
						: 'asset-select2-container',
					dropdownCssClass: 'asset-select2-dropdown select2-use-suggestion-tooltips',
					minimumInputLength: 1,
					formatInputTooShort: $filter('translate')('autocomplete.beginTyping'),
					formatNoMatches: $filter('translate')('autocomplete.noMatches'),
					formatSearching: $filter('translate')('autocomplete.searching'),
					query: querySoon,
					formatResult,
					formatSelection,
					formatResultCssClass,
					simple_tags: false,
					placeholder: scope.isSingle ? ' ' : null, // Placeholder only needed where allowClear is required
					allowClear: true,
					sortable: (scope.hasPrefetched || !scope.readonly) && !scope.disableSortable,
				};

				function isAssetCompletion() {
					return (
						scope.providers &&
						scope.providers.some(
							provider => provider.name === 'completion' && provider.completionType === 'asset'
						)
					);
				}

				function prefetchAssetDataIfNecessaryAsync() {
					if (isAssetCompletion()) {
						const sourceIds = Array.isArray(scope.source) ? scope.source : [scope.source];
						const cachedAssetsById = tagTooltipService.cachedAssetsById;
						const assetsToCache = sourceIds
							? sourceIds.filter(id => !(id in cachedAssetsById))
							: [];

						if (assetsToCache.length) {
							return assetService
								.getAssetsAsync(assetsToCache)
								.then(data => {
									_.forEach(data.assets, value => {
										if (value) {
											cachedAssetsById[value.id] = value;
										}
									});
								})
								.catch(error => {
									if (error !== 'abort') {
										$log.error(error);
									}
								});
						}
					}

					return Promise.resolve();
				}

				scope.model = scope.source && map(scope.source);

				prefetchAssetDataIfNecessaryAsync()
					.then(() => {
						scope.hasPrefetched = true;
					})
					.catch(error => {
						scope.hasPrefetched = true;
						$log.error(error);
					});

				scope.$watch(
					'model',
					(model, old) => {
						if (model !== old) {
							scope.source = unmap(model);
						}
					},
					true
				);

				scope.$watch(
					'source',
					(source, old) => {
						if (source || source !== old) {
							scope.model = map(source);
						}
					},
					true
				);

				const templateFetchAborter = $q.defer();

				function getCreateTooltip(element, item) {
					const freebaseId = item && item.data && item.data.freebase && item.data.freebase.mid;
					if (!freebaseId) {
						return null;
					}

					let tooltip = element.data('tooltip');

					function initializeTooltip(cancellationToken) {
						cancellationToken.then(() => {
							templateFetchAborter.resolve();
						});

						return $http
							.get('views/templates/suggestionTooltip.html', {
								timeout: templateFetchAborter.promise,
								cache: $templateCache,
							})
							.then(response => {
								const template = response.data;
								return $compile(template);
							})
							.then(createTooltip =>
								completionService.getFreebaseSuggestionDetailsAsync(freebaseId).then(details => {
									const tooltipScope = scope.$new(true);
									tooltipScope.$on('$destroy', tooltip.destroy);
									tooltipScope.freebase = details;
									return createTooltip(tooltipScope);
								})
							);
					}

					if (!tooltip) {
						tooltip = tooltipService.createTooltip(element, initializeTooltip, {
							tooltipDisplayDelay: 100,
							tooltipHideDelay: 50,
							tooltipFadeDuration: 50,
						});
						element.data('tooltip', tooltip);
					}

					return tooltip;
				}

				let currentTooltip;

				function closeCurrentTooltip() {
					if (currentTooltip) {
						currentTooltip.beginHide();
						currentTooltip = null;
					}
				}

				elem.on({
					'select2-highlight': function () {
						closeCurrentTooltip();

						const results = elem.data('select2').results;
						const highlightedResult = results
							.find('.select2-highlighted')
							.closest('.select2-result');
						const highlightedResultData = highlightedResult.data('select2-data');

						const tooltip = getCreateTooltip(highlightedResult, highlightedResultData);
						if (tooltip) {
							tooltip.beginShow();
							highlightedResult.one('mouseleave', tooltip.beginHide);
						}

						currentTooltip = tooltip;
					},
					'select2-close': closeCurrentTooltip,
				});
			},
			post(scope, elem) {
				if (scope.focusInput) {
					$timeout(() => {
						elem.parent().find('.select2-container').select2('open');
					}, 0);
				}
			},
		},
	};
}
