import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').filter('assetChanges', assetChanges);

function assetChanges($filter, familyService) {
	const renderers = {
		setMetadata(change) {
			return $filter('translate')('assetChanges.edited', { item: getNameFromPath(change.path) });
		},
		addToMetadataArray(change) {
			return $filter('translate')('assetChanges.added', { item: getNameFromPath(change.path) });
		},
		removeFromMetadataArray(change) {
			return $filter('translate')('assetChanges.removed', { item: getNameFromPath(change.path) });
		},
		createFormat() {
			return $filter('translate')('assetChanges.addedFormat');
		},
		removeFormat() {
			return $filter('translate')('assetChanges.removedFormat');
		},
		setFile() {
			return $filter('translate')('assetChanges.updateFile');
		},
		setSource(change) {
			return change.fileId
				? $filter('translate')('assetChanges.updatedNativeFile')
				: $filter('translate')('assetChanges.removedNativeFile');
		},
		deleteAsset() {
			return $filter('translate')('assetChanges.deleted');
		},
		undeleteAsset() {
			return $filter('translate')('assetChanges.undeleted');
		},
		revertAsset() {
			return $filter('translate')('assetChanges.reverted');
		},
		setPermission(change) {
			return $filter('translate')('assetChanges.editedPermission', {
				item: getPermissionNameFromPath(change.path),
			});
		},
	};

	function getNameFromPath(path) {
		const splitPath = path.split('/');

		let noun;
		if (splitPath.length === 1) {
			const family = familyService.getFamily(splitPath[0]);
			noun = family && family.displayName;
		}

		const depth = splitPath.length >= 2 ? 1 : 0;
		return noun || splitPath[depth].replace(/([a-z])([A-Z])/g, '$1 $2');
	}

	function getPermissionNameFromPath(path) {
		return path === 'read' ? 'view' : path;
	}

	return function (changes) {
		return _.uniq(
			(changes || [])
				.map(ch => {
					const renderer = renderers[ch.op];
					return renderer && renderer(ch);
				})
				.filter(x => !!x)
		);
	};
}
