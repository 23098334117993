import * as angular from 'angular';
import * as _ from 'lodash';
import { IAppSettings, IAssetSearchParameters, IBucketService } from '.';
import { IUriUtility, IShareMapper, IAgent, IUtility } from '../helpers';
import { CancellationToken } from './cancellationService';
import { IUserService } from './userService';

export interface IBoardShare {
	id?: string;
	token?: string;
	date?: string;
	expires?: string;
	agent?: IAgent;
	views?: number;
}

interface IBoardCreation {
	agent?: IAgent;
	date?: Date;
}
export interface IBoard {
	name: string;
	id: string;
	isDefault: boolean;
	creation?: IBoardCreation;
	shares?: IBoardShare[];
	isGroupBoard: boolean;
	modifySearch: (s: IAssetSearchParameters) => IAssetSearchParameters;
}

angular.module('app').factory('boardService', boardService);

function boardService(
	$http: angular.IHttpService,
	$log: angular.ILogService,
	appSettings: IAppSettings,
	bucketService: IBucketService,
	shareMapper: IShareMapper,
	uriUtility: IUriUtility,
	userService: IUserService,
	utility: IUtility
) {
	const defaultBoards: ReadonlyArray<IBoard> = [
		{
			name: 'Favorites',
			id: 'favorites',
			isDefault: true,
			isGroupBoard: false,
			modifySearch(s) {
				s.q += ' users:(userid:me favorite:true)';
				return s;
			},
		},
		{
			name: 'My Assets',
			id: 'myassets',
			isDefault: true,
			isGroupBoard: false,
			modifySearch(s) {
				s.q += ' uploader:me';
				return s;
			},
		},
		{
			name: 'Deleted',
			id: 'deleted',
			isDefault: true,
			isGroupBoard: false,
			modifySearch(s) {
				s.deleted = 'only';
				return s;
			},
		},
	];

	async function createBoardAsync(
		name: string,
		cancellationToken: CancellationToken,
		isGroupBoard: boolean = false
	) {
		const currentBucket = await bucketService.getCurrentBucketAsync();
		const response = await $http.post<any>(
			`${appSettings.assetServiceBaseUri}boards`,
			{ name, bucket: currentBucket.id, isGroupBoard },
			{ timeout: cancellationToken }
		);
		return response.data;
	}

	function getBoardAsync(
		boardId: string,
		cancellationToken: CancellationToken,
		shareToken?: string
	) {
		const defaultBoard = _.find(defaultBoards, { id: boardId });
		if (defaultBoard) {
			return Promise.resolve(defaultBoard);
		}

		return $http
			.get<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}boards/{id}`, {
					id: boardId,
					share: shareToken,
				}),
				{ timeout: cancellationToken }
			)
			.then(response => ({ ...response.data, shares: response.data.shares?.items }));
	}

	async function listBoardsAsync(options) {
		options = angular.extend(
			{
				cancellationToken: null,
				includeDefaultBoards: true,
			},
			options
		);
		const currentBucket = await bucketService.getCurrentBucketAsync();

		const response = await $http.get<any>(
			uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}boards/owned`, {
				bucket: currentBucket.id,
				limit: 1000,
				fields: 'items.(id,name,shares,isGroupBoard,creation)',
			}),
			{ timeout: options.cancellationToken }
		);
		let boards: Array<IBoard> = response.data.items.map(board => ({
			name: board.name,
			id: board.id,
			isGroupBoard: board.isGroupBoard || false,
			shares: board.shares?.items,
			creation: board.creation,
		}));

		const ids: string[] = _.uniq(
			boards.map(board => board.creation?.agent?.user?.id).filter(utility.isNotNullish)
		);
		if (ids.length > 0) {
			const users = await userService.getUserDetailsBatchAsync(ids);
			if (users && users.length > 0) {
				const usersById = _.keyBy(users, 'id');
				boards = boards.map<IBoard>(board =>
					board.creation?.agent?.user?.id
						? {
								...board,
								creation: {
									...board.creation,
									agent: {
										user: usersById[board.creation.agent.user.id],
									},
								},
						  }
						: board
				);
			}
		}

		if (options.includeDefaultBoards) {
			boards = boards.concat(defaultBoards);
		}

		return {
			boards,
		};
	}

	function editBoardAsync(boardId: string, board: IBoard, cancellationToken: CancellationToken) {
		return $http
			.put(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}boards/{id}`, { id: boardId }),
				board,
				{ timeout: cancellationToken }
			)
			.then(response => response.data);
	}

	function deleteBoardAsync(boardId: string, cancellationToken: CancellationToken) {
		return $http
			.delete(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}boards/{id}`, { id: boardId }),
				{ timeout: cancellationToken }
			)
			.then(() => null);
	}

	async function createShareAsync(boardId: string, expirationDate?: string) {
		const response = await $http.post<any>(`${appSettings.assetServiceBaseUri}shares`, {
			boardId,
			expires: expirationDate,
		});
		return shareMapper.map(response.data);
	}

	async function editShareAsync(shareId: string, expirationDate: string, token?: string) {
		try {
			return await $http
				.post<any>(`${appSettings.assetServiceBaseUri}shares/edit`, {
					id: shareId,
					expires: expirationDate,
					token,
				})
				.then(response => shareMapper.map(response.data, 'board'));
		} catch (error) {
			$log.warn(error);
			throw {
				status: error?.status ?? 'error',
			};
		}
	}

	async function getShareBoardIdAsync(shareToken: string, cancellationToken?: CancellationToken) {
		try {
			return await $http
				.get<any>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}shares`, { token: shareToken }),
					{ timeout: cancellationToken }
				)
				.then(response => {
					if (!response.data.items || response.data.items.length !== 1) {
						throw { status: 'notfound' };
					}
					return response.data.items[0].boardId as string;
				});
		} catch (error) {
			$log.warn(error);
			throw {
				status: error?.status ?? 'error',
			};
		}
	}

	function applyBoardToSearch(search: IAssetSearchParameters, boardId: string) {
		const b = _.find(defaultBoards, { id: boardId });
		const modifySearch =
			(b && b.modifySearch) ||
			function (s) {
				s.q = s.q || '';
				s.q += ` boardId:=${boardId}`;
				return s;
			};

		return modifySearch(search);
	}

	return {
		createBoardAsync,
		getBoardAsync,
		listBoardsAsync,
		editBoardAsync,
		deleteBoardAsync,
		applyBoardToSearch,
		createShareAsync,
		getShareBoardIdAsync,
		editShareAsync,
	};
}

export type IBoardService = ReturnType<typeof boardService>;
