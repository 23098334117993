import * as angular from 'angular';

angular.module('app').directive('dragFileDownload', dragFileDownload);

function dragFileDownload($location, $log) {
	return {
		restrict: 'A',
		link(scope, elem, attrs) {
			attrs.$set('draggable', true);

			elem.on('dragstart', e => {
				if (!(e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.setData)) {
					return;
				} // drag & drop download not supported

				let url = scope.$eval(attrs.dragFileDownloadUrl);
				const name = scope.$eval(attrs.dragFileDownloadName);
				const mime = scope.$eval(attrs.dragFileDownloadMime);

				if (!url || !mime || !name) {
					return;
				}

				if (!/^https?:.+/.test(url)) {
					url = `${$location.protocol()}:${url}`;
				}

				const downloadUrl = [mime, name, url].join(':');

				try {
					e.originalEvent.dataTransfer.setData('DownloadURL', downloadUrl);
					e.originalEvent.dataTransfer.effectAllowed = 'move';
				} catch (ex) {
					// browsers that don't support DnD file downloads may throw on setData
					$log.warn('Exception caught while Attempted drag & drop download operation.', ex);
				}
			});
		},
	};
}
