import * as angular from 'angular';
import * as moment from 'moment';

angular.module('app').filter('relativeDate', relativeDate);

function relativeDate() {
	function filter(date, modifier) {
		return moment(date).fromNow(modifier);
	}

	filter.$stateful = true;
	return filter;
}
