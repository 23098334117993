import * as angular from 'angular';
import { IConstants } from '../infrastructure';
import { IErrorReportingService, IEmbedService } from '../services';

angular.module('app').directive('siteHeader', siteHeader);

interface ISiteHeaderController extends angular.IController {
	getAppErrorCount: () => number;
	showUploadFromUrlModal: boolean;
	uploadFromUrl: (url: string) => void;
	getShouldBindToSearchText: () => boolean;
}

function siteHeader(embedService: IEmbedService): angular.IDirective {
	return {
		restrict: 'E',
		transclude: true,
		templateUrl: require('../../views/templates/siteHeader.html'),
		scope: {
			noLinks: '=',
			currentUser: '=',
		},
		controller,
		controllerAs: 'vm',
	};

	function controller(
		this: ISiteHeaderController,
		$rootScope: angular.IRootScopeService,
		$scope: angular.IScope,
		constants: IConstants,
		errorReportingService: IErrorReportingService
	) {
		const vm = this;

		$scope.showCreate = !embedService.externalEditorKinds?.includes('none');
		$scope.useExternalEditor = !!embedService.externalEditorKinds?.includes('image');
		$scope.showUpload = true;
		$scope.smartMediaCreateKind = embedService.smartMediaCreateKind;

		vm.getShouldBindToSearchText = function () {
			return !$scope.hideSearchText;
		};

		vm.uploadFromUrl = url => {
			$rootScope.$broadcast(constants.uploadFromUrlEventName, {
				url,
			});
			vm.showUploadFromUrlModal = false;
		};

		vm.getAppErrorCount = errorReportingService.getErrorCount;

		$scope.viewMode = $rootScope.viewMode;
		$scope.hasCreatePermission = $rootScope.hasCreatePermission;
		const unwatchers = [
			// eslint-disable-next-line angular/on-watch
			$rootScope.$watch('viewMode', newValue => {
				$scope.viewMode = newValue;
			}),
			// eslint-disable-next-line angular/on-watch
			$rootScope.$watch('hasCreatePermission', newValue => {
				$scope.hasCreatePermission = newValue;
			}),
		];

		this.$onDestroy = function () {
			unwatchers.forEach(unwatch => unwatch());
		};
	}
}
