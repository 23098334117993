import * as angular from 'angular';

declare module 'angular' {
	interface IRequestShortcutConfig {
		token?: CancellationToken;
	}
}

export interface CancellationToken extends angular.IPromise<void> {
	isCancelled: boolean;
	whenCanceled(callback: () => void): angular.IPromise<void>;
}

export interface CancellationSource {
	token: CancellationToken;
	cancel(): void;
}

angular.module('app').factory('cancellationService', cancellationService);

function cancellationService($q: angular.IQService) {
	function createCancellationSource(linkedToken?: CancellationToken): CancellationSource {
		const deferred = $q.defer<void>();
		const token: any = deferred.promise;
		token.isCancelled = false;

		token.whenCanceled = function (callback) {
			return token.then(callback);
		};

		const cs = {
			token: <CancellationToken>token,
			cancel() {
				deferred.resolve();
				token.isCancelled = true;
			},
		};

		if (linkedToken) {
			linkedToken.then(() => cs.cancel());
		}

		return cs;
	}

	/** Cancels the cancellationSource when the scope is destroyed. */
	function cancelWhenDestroyed(cancellationSource: CancellationSource, scope: angular.IScope) {
		const unwatch = scope.$on('$destroy', cancellationSource.cancel);
		return unwatch;
	}

	return {
		createCancellationSource,
		cancelWhenDestroyed,
	};
}

export type ICancellationService = ReturnType<typeof cancellationService>;
