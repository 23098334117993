import * as angular from 'angular';
import * as rg4js from 'raygun4js';

angular.module('app').config(configure);

function configure($provide, appSettingsProvider) {
	$provide.decorator(
		'$exceptionHandler',
		($delegate, $log, logBuffer, errorReportingService, buildInfo) => {
			rg4js('apiKey', appSettingsProvider.unprocessedAppSettings.raygunApiKey);
			rg4js('options', {
				ignoreAjaxAbort: true,
				ignore3rdPartyErrors: true,
			});
			rg4js('setVersion', buildInfo.version);
			rg4js('enableCrashReporting', true);

			return function (exception, cause) {
				try {
					const customData = { cause };
					try {
						customData.log = logBuffer.getRecentLogs();
					} catch (e) {
						$log.error(e);
					}
					rg4js('send', { error: exception, customData });

					errorReportingService.reportError();
				} catch (e) {
					$log.error(e);
				}

				$delegate(exception, cause);
			};
		}
	);
}
