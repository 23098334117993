import * as uirouter from '@uirouter/angularjs';
import * as angular from 'angular';
import * as _ from 'lodash';
import { IAgent, IAsset, IDateTimeUtility } from '../helpers';
import { IEmbedService } from '../services';

angular.module('app').directive('assetMetadata', assetMetadata);

interface IDimensions {
	width: number;
	height: number;
}

type IMetadataValue = any;

function isAgent(value: IMetadataValue): value is IAgent {
	return value?.user || value?.group;
}

function isDimensions(value: IMetadataValue): value is IDimensions {
	return _.isPlainObject(value) && _.isNumber(value.width) && _.isNumber(value.height);
}

interface IPropertyAccessor<T extends IMetadataValue = IMetadataValue> {
	name: string;
	fieldName?: string;
	getter?(a: IAsset): T | undefined;
}

interface IProperty<T extends IMetadataValue = IMetadataValue> {
	name: string;
	type: string;
	facetName?: string;
	fieldName?: string;
	value?: T;
	properties?: IProperty[];
}

type ITypeMapper = (name: string, value: IMetadataValue, groupName?: string) => IProperty | null;

function assetMetadata(
	$filter: angular.IFilterService,
	dateTimeUtility: IDateTimeUtility,
	embedService: IEmbedService,
	$state: uirouter.StateService
): angular.IDirective {
	const hyperlinkedFields = { camera: ['make', 'model'] };
	const explicitProperties: IPropertyAccessor[] = [
		{
			name: 'assetId',
			getter(a) {
				return a.id;
			},
		},
		{
			name: 'assetKind',
			getter(a) {
				return a.kind;
			},
		},
		{
			name: 'assetCreator',
			getter(a) {
				return a.creator;
			},
		},
		{
			name: 'uploaded',
			fieldName: 'uploaded',
			getter(a) {
				return a.dateCreated;
			},
		},
		{
			name: 'fileDate',
			fieldName: 'fileDate',
			getter(a) {
				return a.file && a.file.lastModified;
			},
		},
		{
			name: 'unsplashCredit',
			getter(a) {
				return a.unsplashCredit;
			},
		},
	];
	const simpleTypeMappers: ITypeMapper[] = [
		function (name, value, groupName) {
			if (value && groupName && _.includes(hyperlinkedFields[groupName], name)) {
				return {
					name,
					facetName: `${groupName}.${name}`,
					value,
					type: 'facetableString',
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'assetCreator' && isAgent(value)) {
				const agentName = (value.group && value.group.name) || (value.user && value.user.name);
				const agentId = (value.group && value.group.id) || (value.user && value.user.id);

				if (agentName) {
					return {
						name: 'uploader',
						label: $filter('translate')('assetMetadata.uploader'),
						facetName: 'uploader',
						value: agentName,
						type: 'facetableString',
					};
				}

				return {
					name: 'uploader',
					label: $filter('translate')('assetMetadata.uploader'),
					facetName: 'uploaderId',
					value: agentId,
					type: 'facetableString',
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'assetKind' && value) {
				return {
					name: 'kind',
					label: $filter('translate')('assetMetadata.kind'),
					value,
					type: name,
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'image' && isDimensions(value)) {
				return {
					name: 'dimensions',
					label: $filter('translate')('assetMetadata.dimensions'),
					value: { width: value.width, height: value.height },
					type: 'dimensions',
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'autoTags' && _.isArray(value)) {
				return {
					name: 'autoTags',
					label: $filter('translate')('assetMetadata.autoTags'),
					value,
					type: 'tags',
				};
			}
			return null;
		},
		function (name, value, groupName) {
			if (groupName === 'safeSearch') {
				return {
					name,
					label: $filter('translate')(`assetMetadata.safeSearchCategories.${name}`),
					value: $filter('translate')(
						`assetMetadata.safeSearchLikelihood.${value.charAt(0).toLowerCase() + value.slice(1)}`
					),
					type: 'string',
				};
			}
			return null;
		},
		function (name, value) {
			if (_.isString(value) && dateTimeUtility.parseIso8601(value)) {
				return {
					name,
					label: $filter('translate')(`assetMetadata.${name}`),
					value,
					type: 'date',
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'duration' && _.isNumber(value)) {
				return {
					name,
					label: $filter('translate')('assetMetadata.duration'),
					value,
					type: 'duration',
				};
			}
			return null;
		},
		function (name, value) {
			if (name === 'unsplashCredit' && _.isString(value)) {
				return {
					name,
					label: $filter('translate')('assetMetadata.unsplashCredit'),
					value,
					type: 'html',
				};
			}
			return null;
		},
		function (name, value) {
			if (_.isNumber(value)) {
				return {
					name,
					label: $filter('translate')(`assetMetadata.${name}`),
					value,
					type: 'number',
				};
			}
			return null;
		},
		function (name, value) {
			if (_.isString(value)) {
				return {
					name,
					label: $filter('translate')(`assetMetadata.${name}`),
					value,
					type: 'string',
				};
			}
			return null;
		},
	];

	const objectMapper = function (name: string, values: IMetadataValue[]): IProperty | null {
		const value = values[0];
		if (_.isPlainObject(value)) {
			const properties: IProperty[] = [];
			Object.keys(value).forEach(property => {
				const mapped = transformProperty(
					property,
					_.map(values, v => v[property]),
					name
				);
				if (mapped && mapped.type !== 'group') {
					properties.push(mapped);
				}
			});

			if (properties.length) {
				return {
					name,
					type: 'group',
					properties,
				};
			}
		}
		return null;
	};

	function transformProperty(
		name: string,
		values: IMetadataValue[],
		groupName?: string
	): IProperty | null {
		// find the first simple mapper which can map the property
		for (let i = 0; i < simpleTypeMappers.length; i++) {
			const mapped = simpleTypeMappers[i](name, values[0], groupName);
			if (mapped) {
				const areEqual = _.every(values.slice(1), value =>
					_.isEqual(mapped, simpleTypeMappers[i](name, value, groupName))
				);
				return areEqual ? mapped : null;
			}
		}

		// no appropriate mapper was found; try the object mapper
		return objectMapper(name, values) || null;
	}

	return {
		restrict: 'E',
		replace: true,
		templateUrl: require('../../views/templates/assetMetadata.html'),
		scope: {
			asset: '=',
			assets: '=',
			collapsible: '=',
			collapsed: '=',
			explicit: '=',
			noLinks: '=?',
		},
		link(scope) {
			scope.assetListState = embedService.isEmbedded
				? 'assetsEmbed'
				: $state.is('boardSharedDetails')
				? 'boardSharedDetails'
				: 'assets';

			function getExplicitProperties(names: string[]): IPropertyAccessor[] {
				return _.filter(explicitProperties, prop => _.includes(names, prop.name));
			}

			function mapProperties(assets: IAsset[], properties: IPropertyAccessor[]): IProperty[] {
				const mappedProperties: IProperty[] = [];
				properties.forEach(p => {
					const mapped = transformProperty(
						p.name,
						_.map(assets, p.getter || (a => a.file && a.file.primaryMetadata[p.name]))
					);
					if (mapped) {
						if (p.fieldName) {
							mapped.fieldName = p.fieldName;
						}

						mappedProperties.push(mapped);
					}
				});

				return mappedProperties;
			}

			function refreshDisplayedMetadata() {
				const assets = scope.assets || [scope.asset];

				const assetProperties = _.map(assets, a => _.keys(a.file && a.file.primaryMetadata));

				const sharedPropertyNames = _.intersection(...assetProperties);

				const explicitDefaultProperties =
					(scope.explicit &&
						scope.explicit.default &&
						getExplicitProperties(scope.explicit.default)) ||
					[];

				const defaultProperties = explicitDefaultProperties.concat(
					_.map(sharedPropertyNames, name => ({ name }))
				);

				const mappedProperties = mapProperties(assets, defaultProperties);

				const mappedDefaultProperties = _.filter(
					mappedProperties,
					descriptor => descriptor.type !== 'group'
				);

				const explicitMoreProperties =
					(scope.explicit && scope.explicit.more && getExplicitProperties(scope.explicit.more)) ||
					[];

				const mappedMoreProperties = mapProperties(assets, explicitMoreProperties).concat(
					_.filter(mappedProperties, descriptor => descriptor.type === 'group')
				);

				scope.hasMore = !!mappedMoreProperties.length;
				scope.propertyGroups = [];

				if (mappedDefaultProperties.length) {
					scope.propertyGroups.push({
						type: 'group',
						properties: mappedDefaultProperties,
					});
				}

				if (!scope.isCollapsed) {
					scope.propertyGroups = scope.propertyGroups.concat(mappedMoreProperties);
				}
			}

			scope.isCollapsed = scope.collapsed;

			scope.$watch('asset', (value, old) => {
				if (value === old) {
					return;
				}
				refreshDisplayedMetadata();
			});

			scope.$watchCollection('assets', refreshDisplayedMetadata);
			scope.$watch('isCollapsed', refreshDisplayedMetadata);
		},
	};
}
