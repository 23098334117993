import * as angular from 'angular';

angular.module('app').directive('savedIndicator', savedIndicator);

function savedIndicator($timeout, assetService) {
	return {
		restrict: 'E',
		replace: true,
		template:
			'<div ng-show="saving || saved" class="saved-indicator"><div class="loading-spinner-wrapper"><loading-spinner is-working="saving" size="medium"></loading-spinner></div><span ng-show="saving" translate>savedIndicator.saving</span><span ng-show="!saving" translate>savedIndicator.saved</span></div>',
		scope: {
			delay: '=',
		},
		link(scope) {
			scope.saved = false;
			let timeout;
			const delay = (scope.delay && +scope.delay) || 1000;
			scope.$watch(
				assetService.getLastSaveDate,
				(lastSave, previousSave) => {
					$timeout.cancel(timeout);

					if (!lastSave || !lastSave.lastSaveStartDate) {
						return;
					}

					const isSaving =
						lastSave.lastSaveStartDate !== previousSave.lastSaveStartDate &&
						lastSave.lastSaveStartDate > lastSave.lastSaveEndDate;

					scope.saving = isSaving;
					scope.saved = !isSaving && lastSave.lastSaveEndDate !== previousSave.lastSaveEndDate;

					if (scope.saved) {
						timeout = $timeout(() => {
							scope.saved = false;
						}, delay);
					}
				},
				true
			);
		},
	};
}
