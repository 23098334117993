import * as angular from 'angular';
import * as _ from 'lodash';
import { AssetFileDto, IAppSettings } from '../services';
import { IUtility } from './utility';
import { IUriUtility } from './uriUtility';

export interface AssetFile {
	id: string;
	name?: string;
	byteCount?: number;
	lastModified?: string;
	mediaType?: string;
	metadata?: any;
	readonly dimensions?: { width: number; height: number };
	readonly primaryMetadata?: any;
	readonly url?: string;
	readonly downloadUrl?: string;
}

angular.module('app').factory('fileMapper', fileMapper);

function fileMapper(utility: IUtility, uriUtility: IUriUtility, appSettings: IAppSettings) {
	function computeImageDimensions(file) {
		if (!file.metadata) {
			return null;
		}

		const image = file.metadata.image;
		const width = image && image.width;
		const height = image && image.height;
		if (!(width > 0 && height > 0)) {
			return null;
		}

		return {
			width,
			height,
		};
	}

	function mapFile(
		source: AssetFileDto,
		assetId?: string | undefined,
		revisionId?: string | undefined
	): AssetFile | undefined {
		if (!source) {
			return undefined;
		}

		const file: AssetFile = {
			id: source.id,
			name: source.name,
			byteCount: source.byteCount,
			lastModified: source.lastModified,
			mediaType: source.mediaType,
			metadata: _.omit(
				source.metadata,
				'title',
				'authors',
				'description',
				'headline',
				'copyright',
				'license',
				'workflow',
				'contact',
				'tags',
				'categories',
				'location'
			),
		};

		utility.defineLazyProperty(file, 'dimensions', function (this: AssetFile) {
			return computeImageDimensions(this);
		});

		utility.defineLazyProperty(file, 'primaryMetadata', function (this: AssetFile) {
			if (!this.metadata) {
				return null;
			}

			const filteredMetadata = _.clone(this.metadata);
			delete filteredMetadata.lastModified; // moved to file
			delete filteredMetadata.updated; // not interesting to users
			return filteredMetadata;
		});

		function getUrl(self: AssetFile, params = {}) {
			if (!self.id) {
				// expected when opening asset details in list view sidebar, before asset data is populated
				return null;
			}

			if (self.id.startsWith('vod:')) {
				return null;
			}

			if (!assetId) {
				throw new Error('File must be attached to an asset.');
			}

			return uriUtility.fromPattern(
				`${appSettings.assetServiceBaseUri}assets/{assetId}/files/{fileId}/content`,
				{ assetId, fileId: self.id, rev: revisionId, ...params }
			);
		}

		utility.defineLazyProperty(file, 'url', function (this: AssetFile) {
			if (source.link?.uri) {
				return source.link.uri;
			}

			return getUrl(this);
		});

		utility.defineLazyProperty(file, 'downloadUrl', function (this: AssetFile) {
			return getUrl(this, { download: true });
		});

		return file;
	}

	return {
		map: mapFile,
	};
}

export type IFileMapper = ReturnType<typeof fileMapper>;
