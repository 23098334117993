import * as angular from 'angular';
import * as _ from 'lodash';
import { IAssetFileLink, ISearchResponse } from '@faithlife/amber-api';
import { AssetOperation, CreateFormatAssetOperation } from './fileUploadManager';
import { IBucketService } from './bucketService';
import { Progress } from './fileUploadService';
import {
	IAsset,
	IAssetMapper,
	IDateTimeUtility,
	IFacetMapper,
	IHttpUtility,
	IRevisionMapper,
	ISearchFacet,
	IShareMapper,
	IUriUtility,
} from '../helpers';
import { IJobService, Job } from './jobService';
import { IConstants } from '../infrastructure';
import { IAppSettings } from './appSettings';
import { CancellationToken } from './cancellationService';

const standardPreviewSizes = Object.freeze([256, 512, 1024, 1920]);
export interface IAssetSearchParameters {
	q: string;
	offset?: number;
	limit?: number;
	sort?: string | null;
	fields?: string;
	facets?: string[];
	tzo?: number;
	deleted?: string;
}

export interface IFormatOptions {
	fileName?: string;
	optimizationKind?: string;
	quality?: number;
	resize?: { width?: number; height?: number };
	format: string;
	name: string;
	asset: IAsset;
	bitrate?: number;
}

interface IEdit {
	updateOriginal: (fileId: string) => void;
	updateOriginalFromSmartMedia: () => void;
	updateSource: (fileId: string | undefined) => void;
	updateMetadata: (path: string, metadata: any) => void;
	addToMetadataArray: (path: string, value: any) => void;
	createFormat: (formatOptions: IFormatOptions) => void;
	removeFormats: (fileIds: any) => void;
	replaceStandardPreviews: (options: any) => void;
	initializeAsset: () => void;
}

angular.module('app').factory('assetService', assetService);

function assetService(
	$http: angular.IHttpService,
	$log: angular.ILogService,
	appSettings: IAppSettings,
	assetMapper: IAssetMapper,
	bucketService: IBucketService,
	constants: IConstants,
	dateTimeUtility: IDateTimeUtility,
	facetMapper: IFacetMapper,
	httpUtility: IHttpUtility,
	jobService: IJobService,
	revisionMapper: IRevisionMapper,
	shareMapper: IShareMapper,
	uriUtility: IUriUtility
) {
	const revisionRequestLimit = 1024;
	const mergeWithinMinutes = 5;

	const assetFieldsToRemove =
		'(file.(metadata.exiftool,creation),formats.(files.(metadata.exiftool,creation,sha1Hash),ops),revision.changes)';
	const assetFieldFilter = `!${assetFieldsToRemove}`;
	const assetsFieldFilter = `!items.${assetFieldsToRemove}`;
	const audioVideoFormats: {
		[mediaType: string]: {
			audioCodec?: string;
			videoCodec?: string;
			audioMetadataCodec?: string;
			videoCodecTag?: string;
		};
	} = {
		'audio/mpeg': { audioCodec: 'libmp3lame' },
		'audio/ogg': { audioCodec: 'libvorbis' },
		'video/mp4; codecs="avc1, aac"': {
			audioCodec: 'libfdk_aac',
			audioMetadataCodec: 'aac',
			videoCodec: 'libx264',
			videoCodecTag: 'avc1',
		},
		'video/mp4; codecs="hvc1, aac"': {
			audioCodec: 'libfdk_aac',
			audioMetadataCodec: 'aac',
			videoCodec: 'libx265',
			videoCodecTag: 'hvc1',
		},
		'video/webm; codecs="vp8, vorbis"': {
			audioCodec: 'libvorbis',
			audioMetadataCodec: 'vorbis',
			videoCodec: 'libvpx',
			videoCodecTag: 'vp8',
		},
		'video/webm; codecs="vp9, opus"': {
			audioCodec: 'libopus',
			audioMetadataCodec: 'opus',
			videoCodec: 'libvpx-vp9',
			videoCodecTag: 'vp9',
		},
	};

	function updateAssetAsync(
		assetId: string,
		collectChanges: (edit: IEdit) => void,
		cancellationToken: CancellationToken,
		shareToken?: string
	) {
		const ops: AssetOperation[] = [];

		function updateOriginal(fileId: string) {
			// clearing original is not supported
			if (!fileId) {
				return;
			}

			ops.push({ op: 'setFile', fileId, update: true });
		}

		function updateOriginalFromSmartMedia() {
			ops.push({ op: 'setFileFromSmartMedia', update: true });
		}

		function updateSource(fileId: string | undefined) {
			const op: AssetOperation = { op: 'setSource' };
			if (fileId) {
				op.fileId = fileId;
			}

			ops.push(op);
		}

		function updateMetadata(path: string, metadata: any) {
			ops.push({ op: 'setMetadata', path, value: metadata });
		}

		function addToMetadataArray(path: string, value: any) {
			ops.push({ op: 'addToMetadataArray', path, value });
		}

		function replaceStandardPreviews(options) {
			if (!options || isNaN(options.offset)) {
				throw new Error('An offset for the new preview must be specified.');
			}
			if (options.asset.kind !== 'video') {
				throw new Error('Can only replace previews for video assets.');
			}

			const createFormats = {
				op: 'createFormats',
				ops: [] as CreateFormatAssetOperation[],
			};
			for (let index = 0; index < standardPreviewSizes.length; index++) {
				const size = standardPreviewSizes[index];
				const createFormatOperation: CreateFormatAssetOperation = {
					op: 'createFormat',
					name: `${constants.previewFormatPrefix} ${size}`,
					replace: true,
					ops: [] as any[],
				};

				createFormatOperation.ops.push({ op: 'clip', start: options.offset });
				createFormatOperation.ops.push({ op: 'createPreview', width: size, height: size });
				createFormats.ops.push(createFormatOperation);
			}
			ops.push(createFormats);
		}

		function createFormat(formatOptions: IFormatOptions) {
			const assetKind = formatOptions.asset.kind;
			let bitrate: number | null | undefined = formatOptions.bitrate;
			if (assetKind !== 'image' && assetKind !== 'audio' && assetKind !== 'video') {
				throw new Error('Can only create formats for image, audio, or video assets.');
			}

			const createFormatOperation: CreateFormatAssetOperation = {
				op: 'createFormat',
				name: formatOptions.name,
				ops: [],
			};

			if (formatOptions.format && formatOptions.format !== formatOptions.asset.file?.mediaType) {
				const avFormat = audioVideoFormats[formatOptions.format];
				const mediaType = formatOptions.format.replace(/;.*$/, '');

				if (assetKind === 'video') {
					if (avFormat.videoCodecTag !== formatOptions.asset.file?.metadata?.video?.codecTag) {
						createFormatOperation.ops.push({
							op: 'video',
							codec: avFormat.videoCodec,
							bitrate,
						});
						bitrate = null;
					}
				}

				if (assetKind !== 'image') {
					if (avFormat.audioMetadataCodec !== formatOptions.asset.file?.metadata?.audio?.codec) {
						createFormatOperation.ops.push({
							op: 'audio',
							codec: avFormat.audioCodec,
							bitrate,
						});
					}
				}

				if (mediaType !== formatOptions.asset.file?.mediaType) {
					createFormatOperation.ops.push({
						op: 'convert',
						mediaType,
					});
				}
			} else if (formatOptions.bitrate && assetKind !== 'image') {
				createFormatOperation.ops.push({
					op: assetKind,
					bitrate,
				});
			}
			if (formatOptions.resize && (formatOptions.resize.width || formatOptions.resize.height)) {
				createFormatOperation.ops.push({
					op: 'resize',
					width: formatOptions.resize.width,
					height: formatOptions.resize.height,
				});
			}
			if (formatOptions.quality) {
				createFormatOperation.ops.push({
					op: 'setQuality',
					level: formatOptions.quality,
				});
			}
			if (formatOptions.optimizationKind && formatOptions.optimizationKind !== 'none') {
				createFormatOperation.ops.push({
					op: 'optimize',
					lossy: formatOptions.optimizationKind === 'lossy',
					allowFailure: true,
				});
			}

			if (createFormatOperation.ops.length) {
				// add a name operation as the last operation; see http://git/Logos/AssetDesk/blob/master/docs/FileOperations.md
				if (formatOptions.fileName) {
					createFormatOperation.ops.push({ op: 'setName', value: formatOptions.fileName });
				}

				ops.push(createFormatOperation);
			}
		}

		function removeFormats(fileIds) {
			(fileIds || [])
				.map(f => ({ op: 'removeFormat', fileId: f }))
				.forEach(op => {
					ops.push(op);
				});
		}

		// do not use for assets that have already been initialized; this is for assets that only had source files
		function initializeAsset() {
			ops.push({ op: 'adoptFileMetadata' }, { op: 'createStandardFormats' });
		}

		const edit: IEdit = {
			updateOriginal,
			updateOriginalFromSmartMedia,
			updateSource,
			updateMetadata,
			addToMetadataArray,
			createFormat,
			removeFormats,
			replaceStandardPreviews,
			initializeAsset,
		};

		collectChanges(edit);

		return ops.length
			? editAssetsAsync(
					[assetId],
					ops,
					{ shouldAwaitCompletion: true, cancellationToken },
					shareToken
			  ).then(results => results && results[0])
			: Promise.resolve(null);
	}

	async function createShareAsync(assetId: string, expirationDate?: string) {
		const response = await $http.post<any>(`${appSettings.assetServiceBaseUri}shares`, {
			assetId,
			expires: expirationDate,
		});
		return shareMapper.map(response.data);
	}

	async function editShareAsync(shareId: string, expirationDate: string, token?: string) {
		try {
			return await $http
				.post<any>(`${appSettings.assetServiceBaseUri}shares/edit`, {
					id: shareId,
					expires: expirationDate,
					token,
				})
				.then(response => shareMapper.map(response.data, 'asset'));
		} catch (error) {
			$log.warn(error);
			throw {
				status: error?.status ?? 'error',
			};
		}
	}

	async function getShareAssetIdAsync(shareToken: string, cancellationToken?: CancellationToken) {
		try {
			return await $http
				.get<any>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}shares`, { token: shareToken }),
					{ timeout: cancellationToken }
				)
				.then(response => {
					if (!response.data.items || response.data.items.length !== 1) {
						throw { status: 'notfound' };
					}
					return response.data.items[0].assetId as string;
				});
		} catch (error) {
			$log.warn(error);
			throw {
				status: error === null ? 'error' : error.status,
			};
		}
	}

	function getAssetUri(id: string, options: { revision?: string; shareToken?: string } = {}) {
		return uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/{id}`, {
			id,
			rev: options.revision,
			share: options.shareToken,
		});
	}

	async function getAssetExifToolAsync(
		id: string,
		fileId: string,
		cancellationToken: CancellationToken
	) {
		try {
			return await $http
				.get<any>(
					uriUtility.fromPattern(
						`${appSettings.assetServiceBaseUri}assets/{id}/files/{fileId}/exiftool`,
						{ id, fileId }
					),
					{ timeout: cancellationToken }
				)
				.then(response =>
					jobService.awaitJobsCompletionAsync([response.data.id], {
						cancellationToken,
						fields: 'items.response',
					})
				)
				.then(response => response.items[0]!.response!.content);
		} catch (error) {
			$log.warn(error);
			throw {
				status: error.status === 404 ? 'notfound' : 'error',
			};
		}
	}

	function addToFavoritesAsync(assetIds: ReadonlyArray<string>) {
		return doSimpleAssetOperationAsync(assetIds, 'addToFavorites');
	}

	function removeFromFavoritesAsync(assetIds: ReadonlyArray<string>) {
		return doSimpleAssetOperationAsync(assetIds, 'removeFromFavorites');
	}

	function deleteAssetsAsync(assetIds: ReadonlyArray<string>) {
		return doSimpleAssetOperationAsync(assetIds, 'deleteAsset');
	}

	function undeleteAssetsAsync(assetIds: ReadonlyArray<string>) {
		return doSimpleAssetOperationAsync(assetIds, 'undeleteAsset');
	}

	async function doSimpleAssetOperationAsync(assetIds: ReadonlyArray<string>, op: string) {
		try {
			await editAssetsAsync(assetIds, [{ op }], { fireAndForget: true });
		} catch (error) {
			$log.warn(error);
			throw {
				status: error.status === 404 ? 'notfound' : 'error',
			};
		}
	}

	async function addToBoardAsync(
		assetIds: ReadonlyArray<string>,
		boardId: string,
		cancellationToken?: CancellationToken
	) {
		await editAssetsAsync(assetIds, [{ op: 'addToBoard', boardId }], {
			cancellationToken,
			waitForIndexing: true,
		});
	}

	async function removeFromBoardAsync(
		assetIds: ReadonlyArray<string>,
		boardId: string,
		cancellationToken?: CancellationToken
	) {
		await editAssetsAsync(assetIds, [{ op: 'removeFromBoard', boardId }], {
			cancellationToken,
			waitForIndexing: true,
		});
	}

	function getAssetRevisionsUri(id: string, options: { limit?: number; next?: string } = {}) {
		return uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/{id}/revisions`, {
			id,
			limit: options.limit,
			next: options.next,
		});
	}

	async function getAssetAsync(
		id: string,
		options: { revision?: string; shareToken?: string } = {},
		cancellationToken?: CancellationToken
	) {
		try {
			return await $http
				.get(uriUtility.fromPattern(getAssetUri(id, options), { fields: assetFieldFilter }), {
					timeout: cancellationToken,
				})
				.then(response => assetMapper.map(response.data, appSettings.assetServiceBaseUri));
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	async function getAssetsAsync(
		assetIds: ReadonlyArray<string>,
		cancellationToken?: CancellationToken
	): Promise<{ assets: IAsset[] }> {
		const request = { items: _.map(assetIds, id => ({ id })) };
		try {
			return await $http
				.post<any>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/get`, {
						fields: assetsFieldFilter,
					}),
					request,
					{ timeout: cancellationToken }
				)
				.then(response => ({
					assets: _.map(response.data.items, item =>
						assetMapper.map(item, appSettings.assetServiceBaseUri)
					),
				}));
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	async function getFileLinksForAssetsAsync(
		assetFileIds: { assetId: string; fileId: string }[],
		cancellationToken?: CancellationToken
	): Promise<IAssetFileLink[]> {
		try {
			return await $http
				.post<any>(
					`${appSettings.assetServiceBaseUri}assets/files/links/get`,
					{ items: assetFileIds },
					{ timeout: cancellationToken }
				)
				.then(response => response && response.data && response.data.items);
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	async function getRevisionsAsync(id: string, revisions: any[] = [], next?: string) {
		return await $http
			.get<any>(getAssetRevisionsUri(id, { limit: revisionRequestLimit, next }))
			.then(response => {
				let newerRevision = revisions.length === 0 ? null : revisions[revisions.length - 1];
				_.forEach(response.data.items, revision => {
					if (
						newerRevision &&
						newerRevision.agent &&
						newerRevision.agent.user &&
						revision.agent &&
						revision.agent.user &&
						newerRevision.date &&
						revision.date &&
						newerRevision.changes &&
						newerRevision.changes.ops &&
						revision.changes &&
						revision.changes.ops &&
						newerRevision.agent.user.id === revision.agent.user.id &&
						((!newerRevision.agent.group && !revision.agent.group) ||
							(newerRevision.agent.group &&
								revision.agent.group &&
								newerRevision.agent.group.id === revision.agent.group.id)) &&
						dateTimeUtility.differenceInSeconds(newerRevision.date, revision.date) <=
							mergeWithinMinutes * 60
					) {
						// merge operations of revisions of the same user near the same time
						newerRevision.changes.ops = revision.changes.ops.concat(newerRevision.changes.ops);
					} else {
						revisions.push(revision);
						newerRevision = revision;
					}
				});

				// get more revisions if necessary
				if (response.data.next) {
					return getRevisionsAsync(id, revisions, response.data.next);
				}
				return revisionMapper.map(revisions);
			});
	}

	async function getAssetsAccessAsync(
		assetIds: ReadonlyArray<string>,
		cancellationToken?: CancellationToken
	) {
		const request = { items: _.map(assetIds, id => ({ id })) };
		try {
			return await $http
				.post<any>(`${appSettings.assetServiceBaseUri}assets/access`, request, {
					timeout: cancellationToken,
				})
				.then(response => ({
					accessDecisions: response.data.items,
				}));
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	async function searchAssetsAsync(
		options: IAssetSearchParameters,
		share?: string | null | undefined,
		cancellationToken?: CancellationToken
	): Promise<{ hits: IAsset[]; total: number; facets: ISearchFacet[] }> {
		const currentBucket = share ? null : await bucketService.getCurrentBucketAsync();
		// null will be included in the network request, undefined will not
		options = _.extend({}, options, { bucket: currentBucket?.id, share: share ?? undefined });
		try {
			return await $http
				.get<ISearchResponse>(
					uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/search`, options),
					{
						timeout: cancellationToken,
					}
				)
				.then(response => ({
					hits:
						response.data.hits?.map(hit =>
							assetMapper.map(hit.asset, appSettings.assetServiceBaseUri)
						) ?? [],
					total: response.data.hitTotal ?? 0,
					facets: facetMapper.map(response.data.facets ?? []),
				}));
		} catch (response) {
			throw httpUtility.getErrorReason(response);
		}
	}

	async function createAssetAsync(
		operations: AssetOperation[],
		options?: { shouldAwaitCompletion?: boolean; cancellationToken?: CancellationToken }
	) {
		const currentBucket = await bucketService.getCurrentBucketAsync();
		const request = {
			bucket: currentBucket.id,
			ops: operations,
			waitForIndexing: true,
			forceJob: true,
		};

		const response = await $http.post<Job>(
			`${appSettings.assetServiceBaseUri}assets/create`,
			request,
			{
				timeout: options && options.cancellationToken,
			}
		);
		if (options?.shouldAwaitCompletion) {
			const { items } = await jobService.awaitJobsCompletionAsync([response.data.id], {
				cancellationToken: options.cancellationToken,
			});
			return items.length > 0
				? assetMapper.map(items[0]?.response!.content, appSettings.assetServiceBaseUri)
				: response.data;
		}
		return response.data;
	}

	function createAssetWithContentAsync(
		deferred: angular.IDeferred<IAsset>,
		updateProgressSoon: (e: Progress) => void,
		file: File
	) {
		const form = new FormData();
		form.append('file', file);

		const bucket = bucketService.getCurrentBucket()?.id;

		if (!bucket) {
			throw new Error('Current bucket not found');
		}

		const fileName =
			file.name === encodeURIComponent(file.name)
				? file.name
				: `=?UTF-8?Q?${encodeURIComponent(file.name).replace('%', '=')}?=`;

		// Angular's $http service makes it difficult to use FormData and progress events
		return $.ajax({
			url: uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/content`, { bucket }),
			type: 'POST',
			processData: false,
			contentType: false,
			headers: {
				'x-asset-file-name': fileName,
			},
			data: form,
			xhr() {
				const xhr = $.ajaxSettings.xhr();
				if (xhr.upload) {
					xhr.upload.addEventListener('progress', updateProgressSoon, false);
				}

				return xhr;
			},
		})
			.done(result => {
				deferred.resolve(assetMapper.map(result, appSettings.assetServiceBaseUri));
			})
			.fail((jqXhr, status, error) => {
				deferred.reject(error);
			});
	}

	function copyAssetFileAsync(
		assetId: string,
		fileId: string,
		revision: string | null,
		cancellationToken: CancellationToken
	) {
		return $http
			.post<any>(
				uriUtility.fromPattern(
					`${appSettings.assetServiceBaseUri}assets/{assetId}/files/{fileId}/copy`,
					{
						assetId,
						fileId,
						rev: revision,
					}
				),
				undefined,
				{ timeout: cancellationToken }
			)
			.then(response => response.data);
	}

	async function revertAssetToRevisionAsync(assetId: string, revertTo: string) {
		await editAssetsAsync([assetId], [{ op: 'revertAsset', revisionId: revertTo }], {
			shouldAwaitCompletion: true,
		});
	}

	async function reportAssetInsert(assetId: string) {
		try {
			await $http.post<never>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/{id}/inserted`, {
					id: assetId,
				}),
				undefined
			);
		} catch (err) {
			$log.warn(err);
		}
	}

	async function reportAssetView(assetId: string, share?: string | undefined) {
		try {
			await $http.post<never>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/{id}/viewed`, {
					id: assetId,
					share,
				}),
				undefined
			);
		} catch (err) {
			$log.warn(err);
		}
	}

	async function reportAssetDownload(assetId: string, fileId: string, share: string | undefined) {
		try {
			await $http.post<never>(
				uriUtility.fromPattern(
					`${appSettings.assetServiceBaseUri}assets/{id}/files/{fileId}/downloaded`,
					{ id: assetId, fileId, share }
				),
				undefined
			);
		} catch (err) {
			$log.warn(err);
		}
	}

	let lastSaveStartDate: number | null = null;
	let lastSaveEndDate: number | null = null;
	function getLastSaveDate() {
		return {
			lastSaveStartDate,
			lastSaveEndDate,
		};
	}

	async function copyAssetAsync(assetId: string, to: string, cancellationToken: CancellationToken) {
		return await $http
			.post<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/{assetId}/copy?to={to}`, {
					assetId,
					to,
				}),
				undefined,
				{ timeout: cancellationToken }
			)
			.then(response => assetMapper.map(response.data, appSettings.assetServiceBaseUri));
	}

	async function editAssetsAsync(
		assetIds: ReadonlyArray<string>,
		operations: ReadonlyArray<AssetOperation>,
		options: {
			shouldAwaitCompletion?: boolean;
			cancellationToken?: CancellationToken;
			waitForIndexing?: boolean;
			fireAndForget?: boolean;
			forceJob?: boolean;
		} = {},
		shareToken?: string
	): Promise<Job | IAsset[] | undefined> {
		lastSaveStartDate = Date.now();

		const request = {
			assetIds,
			ops: operations,
			forceJob: options.forceJob,
			waitForIndexing: options.waitForIndexing,
		};

		const params: any = {};
		if (shareToken) {
			params.share = shareToken;
		}

		if (options.fireAndForget) {
			params.fields = '!items';
		} else {
			params.fields = 'items.asset.(id,revision)';
		}

		return await $http
			.post<any>(
				uriUtility.fromPattern(`${appSettings.assetServiceBaseUri}assets/edit`, params),
				request,
				{ timeout: options.cancellationToken }
			)
			.then(async response => {
				if (options.fireAndForget) {
					return undefined;
				}

				if (response.status === 202) {
					if (options.shouldAwaitCompletion) {
						const { items } = await jobService.awaitJobsCompletionAsync([response.data.id], {
							cancellationToken: options.cancellationToken,
						});
						return items.map(job =>
							assetMapper.map(job!.response!.content, appSettings.assetServiceBaseUri)
						);
					}
					return response.data as Job;
				}
				return (response.data.items as any[]).map(x =>
					assetMapper.map(x.asset, appSettings.assetServiceBaseUri)
				);
			})
			.finally(() => {
				lastSaveEndDate = Date.now();
			});
	}

	return {
		getAssetUri,
		getAssetExifToolAsync,
		addToFavoritesAsync,
		removeFromFavoritesAsync,
		addToBoardAsync,
		removeFromBoardAsync,
		getAssetRevisionsUri,
		getAssetAsync,
		getAssetsAsync,
		getFileLinksForAssetsAsync,
		getRevisionsAsync,
		getAssetsAccessAsync,
		searchAssetsAsync,
		createAssetAsync,
		createAssetWithContentAsync,
		updateAssetAsync,
		copyAssetAsync,
		copyAssetFileAsync,
		revertAssetToRevisionAsync,
		deleteAssetsAsync,
		undeleteAssetsAsync,
		createShareAsync,
		editShareAsync,
		getShareAssetIdAsync,
		reportAssetInsert,
		reportAssetView,
		reportAssetDownload,
		editAssetsAsync,
		getLastSaveDate,
	};
}

export type IAssetService = ReturnType<typeof assetService>;
