import * as angular from 'angular';

angular.module('app').directive('allowPattern', allowPattern);

function allowPattern() {
	return {
		restrict: 'A',
		compile() {
			return function (scope, element, attrs) {
				element.bind('keypress', event => {
					const keyCode = event.which || event.keyCode;
					const keyCodeChar = String.fromCharCode(keyCode);

					if (!keyCodeChar.match(new RegExp(attrs.allowPattern, 'i'))) {
						event.preventDefault();
						return false;
					}

					return true;
				});
			};
		},
	};
}
