import * as angular from 'angular';

angular.module('app').directive('selectOnClick', selectOnClick);

function selectOnClick() {
	return {
		restrict: 'A',
		link(scope, element) {
			element.on('click', function () {
				this.select();
			});
		},
	};
}
