import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').directive('rightsInput', rightsInput);

function rightsInput() {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			source: '=',
			isReadonly: '=',
		},
		templateUrl: require('../../views/templates/rightsInput.html'),
		controller($scope, $filter) {
			const year = new Date().getFullYear();
			$scope.rightsValues = [
				{ name: 'Public Domain', helpText: undefined },
				{ name: 'Copyrighted', helpText: undefined },
				{
					name: 'Logos Free',
					helpText: $filter('translate')('copyright.logosFreeDescription', { year }),
				},
				{
					name: 'Logos User',
					helpText: $filter('translate')('copyright.logosUserDescription', { year }),
				},
				{
					name: 'Faithlife Church Use License',
					helpText: $filter('translate')('copyright.faithlifeChurchUseDescription'),
				},
				{ name: 'SoundFaith Public', helpText: undefined },
				{ name: 'Standard YouTube License', helpText: undefined },
				{
					name: 'CC Attribution',
					helpText: $filter('translate')('copyright.ccAttributionDescription'),
				},
				{
					name: 'CC Attribution-ShareAlike',
					helpText: $filter('translate')('copyright.ccAttributionShareAlikeDescription'),
				},
				{
					name: 'CC Attribution-NoDerivs',
					helpText: $filter('translate')('copyright.ccAttributionNoDerivsDescription'),
				},
				{
					name: 'CC Attribution-NonCommercial',
					helpText: $filter('translate')('copyright.ccAttributionNonCommercialDescription'),
				},
				{
					name: 'CC Attribution-NonCommercial-ShareAlike',
					helpText: $filter('translate')(
						'copyright.ccAttributionNonCommercialShareAlikeDescription'
					),
				},
				{
					name: 'CC Attribution-NonCommercial-NoDerivs',
					helpText: $filter('translate')('copyright.ccAttributionNonCommercialNoDerivsDescription'),
				},
			];
		},
		link(scope) {
			function getSelectedRightHelpText(name) {
				const rights = _.find(scope.rightsValues, { name });
				return rights && rights.helpText;
			}

			scope.$watch('source', newValue => {
				scope.selectedRightHelpText = getSelectedRightHelpText(newValue);
			});
		},
	};
}
