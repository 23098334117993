import * as angular from 'angular';
import * as $ from 'jquery';
import { IAppSettings } from './services';
import * as _ from 'lodash';
import { Environment, IEnvironmentUtility } from './helpers';

declare const appSettings: IAppSettings | undefined;
declare const buildInfo: object | undefined;

/* eslint-disable angular/module-getter,angular/window-service,no-undef */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
/* global $:false */
const app = angular.module('app');

// IAppSettings + environment-specific settings
let unprocessedAppSettings: IAppSettings & Partial<Record<Environment, IAppSettings>>;

if (typeof appSettings === 'object') {
	unprocessedAppSettings = appSettings;
} else {
	// synchronously fetch appSettings so they can remain in a JSON file, even though they're necessary for app startup
	$.ajax('appSettings.json', {
		async: false,
		dataType: 'json',
		success(appSettingsAjax) {
			unprocessedAppSettings = appSettingsAjax;
		},
	});
}

if (typeof buildInfo === 'object') {
	app.constant('buildInfo', buildInfo);
} else {
	$.ajax('buildInfo.g.json', {
		async: false,
		dataType: 'json',
		success(buildInfoAjax) {
			app.constant('buildInfo', buildInfoAjax);
		},
	});
}

app.provider('appSettings', appSettingsProvider);

function appSettingsProvider() {
	return {
		unprocessedAppSettings,
		$get(environmentUtility: IEnvironmentUtility): IAppSettings {
			const allEnvironments = environmentUtility.allEnvironments;
			const currentEnvironment = environmentUtility.getEnvironment();
			return Object.assign(
				_.omit(unprocessedAppSettings, allEnvironments),
				unprocessedAppSettings[currentEnvironment]
			);
		},
	};
}

export type IAppSettingsProvider = ReturnType<typeof appSettingsProvider>;
