import * as angular from 'angular';
import * as _ from 'lodash';
import * as uuid from 'uuid/v4';
import {
	CancellationSource,
	CancellationToken,
	IAssetService,
	ICancellationService,
	IEmbedService,
	IFileService,
	IFileUploadService,
	IJobService,
	IPromiseUtilityService,
	Job,
	JobStatus,
	Progress,
} from '.';
import { IUriUtility } from '../helpers';
import { createProgressTracker, ProgressReport, FileProgress } from './progressTracker';

export interface AssetOperation {
	op: string;
	[key: string]: any;
}

export interface CreateFormatAssetOperation extends AssetOperation {
	op: 'createFormat';
	name: string;
	ops: any[];
}

interface FileUploadRequest {
	file?: File;
	url?: string;
	fileName: string;
}

export interface FileUploadResult {
	job?: Job;
	error?: string;
}

export interface FileUploadBatchResult {
	uploadResults: FileUploadResult[];
	uploadBatchId: string;
}

export interface Upload {
	uploadBatchId?: string;
	uploadFileId: string;
	file?: File;
	url?: string;
	fileId?: string;
	fileName?: string;
	status: UploadStatus;
	errorMessage?: string;
	job?: Job;
	hasMoreJobs?: boolean;
	assetId?: string;
	progress?: number;
	cancellationSource: CancellationSource;
}

export type UploadStatus = 'uploading' | 'processing' | 'failed' | 'completed';

type ProgressCallback = (progress: ProgressReport) => void;

angular.module('app').factory('fileUploadManager', fileUploadManager);

function fileUploadManager(
	$log: angular.ILogService,
	assetService: IAssetService,
	boardsModel,
	cancellationService: ICancellationService,
	embedService: IEmbedService,
	fileService: IFileService,
	fileUploadService: IFileUploadService,
	jobService: IJobService,
	promiseUtilityService: IPromiseUtilityService,
	uriUtility: IUriUtility
) {
	const log: angular.ILogService = $log.getLogger('fileUploadManager');
	const uploads: Upload[] = [];

	jobService.addStatusChangedHandler({ limit: 25 }, handleJobStatusChanged);

	async function uploadFilesAndCreateAssetsAsync(
		fileList: ReadonlyArray<File | string>,
		onProgress?: ProgressCallback,
		cancellationToken?: CancellationToken
	): Promise<FileUploadBatchResult> {
		function abortIfCancelled() {
			if (cancellationToken && cancellationToken.isCancelled) {
				throw 'abort';
			}
		}

		const progressTracker = createProgressTracker(progress => {
			handleProgressReceived(progress.files);
			if (onProgress) {
				onProgress(progress);
			}
		});

		const uploadRequests: FileUploadRequest[] = [
			...fileList.filter(isFile).map(file => ({
				file,
				fileName: file.name,
			})),
			...fileList.filter(_.isString).map(url => ({
				url,
				fileName: uriUtility.getFileName(url),
			})),
		];

		const uploadBatchId = uuid().replace(/-/g, '').toLowerCase();

		const uploadBatch: Upload[] = [];

		// read file size to calculate totals for progress meter
		// async to avoid blocking the UI
		await promiseUtilityService.forEachAsync(
			uploadRequests,
			(uploadRequest, index) => {
				const uploadFileId = `${uploadBatchId}:${index}`;
				const cancellationSource = cancellationService.createCancellationSource(cancellationToken);

				progressTracker.addFile(uploadFileId, uploadRequest.file ? uploadRequest.file.size : 0);
				progressTracker.setFileStatus(uploadFileId, 'uploading');

				const upload: Upload = {
					...uploadRequest,
					uploadFileId,
					uploadBatchId,
					status: 'uploading',
					hasMoreJobs: true,
					cancellationSource,
				};
				uploads.push(upload);
				uploadBatch.push(upload);
				log.debug(`Created upload ${uploadFileId}`, upload);
			},
			cancellationToken
		);
		abortIfCancelled();

		// upload files and create assets in parallel
		const uploadResults = await promiseUtilityService.parallelMap(
			uploadBatch,
			{ maxDegreeOfParallelism: 2, cancellationToken },
			async upload => {
				const { uploadFileId, file, url } = upload;

				try {
					// upload file
					const operations: AssetOperation[] = [];
					let fileUpdated = false;
					if (file) {
						log.debug(`Uploading ${uploadFileId}`, upload, file);
						let uploadedContent;

						// create an asset with content for files up to 20 MiB
						// only images because that's the only type the server current extracts metadata and generates a preview for
						if (file.size <= 20 * 1024 * 1024 && file.type.startsWith('image/')) {
							uploadedContent = await fileUploadService
								.createAssetWithContentAsync(file, upload.cancellationSource.token)
								.then(
									x => x,
									undefined,
									(fileUploadProgress: Progress) => {
										progressTracker.setUploadProgress(
											uploadFileId,
											fileUploadProgress.loaded,
											fileUploadProgress.total
										);
									}
								);
							upload.assetId = uploadedContent.id;
						} else {
							uploadedContent = await fileUploadService
								.uploadFileAsync(file, upload.cancellationSource.token)
								.then(
									x => x,
									undefined,
									(fileUploadProgress: Progress) => {
										progressTracker.setUploadProgress(
											uploadFileId,
											fileUploadProgress.loaded,
											fileUploadProgress.total
										);
									}
								);
							upload.fileId = uploadedContent.id;
						}

						upload.progress = 0.9;

						abortIfCancelled();
						log.debug(`Uploaded ${uploadFileId}`, upload, uploadedContent);

						const sourceTypes = ['application/x-indesign', 'application/zip'];

						if (uploadedContent.mediaType && sourceTypes.includes(uploadedContent.mediaType)) {
							operations.push({ op: 'setSource', fileId: uploadedContent.id });
						} else if (upload.fileId) {
							operations.push({ op: 'setFile', fileId: uploadedContent.id, update: true });
							fileUpdated = true;
						}
					} else if (url) {
						operations.push({ op: 'importFile', uri: url, update: true });
						fileUpdated = true;
					}

					if (!fileUpdated) {
						operations.push({ op: 'updateFile' });
					}

					if (
						boardsModel.currentBoardId &&
						boardsModel.boards.find(
							board => board.id === boardsModel.currentBoardId && !board.isDefault
						)
					) {
						operations.push({
							op: 'addToBoard',
							boardId: boardsModel.currentBoardId,
						});
					}

					operations.push(
						{ op: 'adoptFileMetadata' },
						{ op: 'setMetadata', path: 'uploadId', value: uploadBatchId },
						{ op: 'setMetadata', path: 'uploadFileId', value: uploadFileId }
					);

					// create asset
					if (embedService.defaultPermissions) {
						for (const key of Object.keys(embedService.defaultPermissions)) {
							operations.push({
								op: 'setPermission',
								path: key,
								value: embedService.defaultPermissions[key],
							});
						}
					}

					let createdAsset;
					// createAssetWithContentAsync was used so asset already exists,
					if (upload.assetId) {
						log.debug(`Editing asset for upload ${uploadFileId}`, upload, operations);
						createdAsset = await assetService.editAssetsAsync([upload.assetId], operations, {
							waitForIndexing: true,
							shouldAwaitCompletion: true,
							cancellationToken: upload.cancellationSource.token,
						});
						log.debug(`Edit asset for upload ${uploadFileId}`, upload, createdAsset);
					} else {
						log.debug(`Creating asset for upload ${uploadFileId}`, upload, operations);
						createdAsset = await assetService.createAssetAsync(operations, {
							shouldAwaitCompletion: true,
							cancellationToken: upload.cancellationSource.token,
						});
						log.debug(`Create asset for upload ${uploadFileId}`, upload, createdAsset);
						upload.assetId = createdAsset.id;
					}

					if (!createdAsset) {
						throw new Error(
							`Error ${upload.assetId ? 'editing' : 'creating'} asset, null response.`
						);
					}

					// report file completed
					if (file) {
						progressTracker.setUploadProgress(uploadFileId, file.size, file.size);
					}
					progressTracker.setFileStatus(uploadFileId, 'complete');

					const assetId = upload.assetId;
					if (!assetId) {
						throw new Error('Create asset failed');
					}

					abortIfCancelled();

					log.debug(`Creating standard formats for upload ${uploadFileId}`, upload);
					const createStandardFormatsJob = await assetService.editAssetsAsync(
						[assetId],
						[{ op: 'createStandardFormats' }],
						{
							cancellationToken: upload.cancellationSource.token,
						}
					);
					log.debug(
						`Create standard formats job for upload ${uploadFileId}`,
						upload,
						createStandardFormatsJob
					);
					if (!createStandardFormatsJob) {
						throw new Error('Error creating standard formats, null response.');
					}

					upload.hasMoreJobs = false;
					upload.job = createStandardFormatsJob as Job;

					return {
						job: upload.job,
					} as FileUploadResult;
				} catch (err) {
					// report file error
					log.warn(`Error uploading file ${uploadFileId}`, upload, err);
					progressTracker.setFileStatus(uploadFileId, 'error');

					const error =
						typeof err === 'string' ? err : err instanceof Error ? err.message : 'unknown error';

					setUploadStatus(upload, 'failed', err === 'abort' ? 'canceled' : error);

					return {
						error,
					} as FileUploadResult;
				}
			}
		);
		abortIfCancelled();

		log.debug('Batch upload complete.', uploadBatch);

		const jobIds = _.compact(uploadResults.map(x => x.job && x.job.id));
		monitorUploadJobs(jobIds);

		return {
			uploadResults,
			uploadBatchId,
		} as FileUploadBatchResult;
	}

	function cancelUpload(upload: Upload) {
		if (upload.status === 'uploading' || upload.status === 'processing') {
			let canceled;
			const job = upload.job;

			if (job && job.id) {
				canceled = true;
				jobService.cancelJobAsync(job.id, job.runnerId).catch(error => {
					log.warn(error.data.message);
				});
			} else if (upload.cancellationSource) {
				canceled = true;
				upload.cancellationSource.cancel();
			}

			if (canceled) {
				setUploadStatus(upload, 'failed', 'canceled');
			}
		}
	}

	function handleProgressReceived(files: FileProgress[]) {
		files.forEach(file => {
			const upload = findUploadById(file.uploadFileId);

			if (
				upload &&
				file.uploadedSize &&
				file.fileSize &&
				file.uploadedSize !== file.fileSize &&
				upload.status === 'uploading'
			) {
				upload.progress = getFileProgress(file);
			}
		});
	}

	function getFileProgress(file: FileProgress) {
		const progressModifier = 0.9;
		let progress = file.uploadedSize / file.fileSize;

		if (file.status === 'uploading') {
			// reserve room in the progress meter for server processing time
			progress *= progressModifier;
		}

		return progress;
	}

	function handleJobStatusChanged(jobs: Job[]) {
		const newJobs: Job[] = [];

		for (const job of jobs) {
			const upload = findUploadByJobId(job.id);

			if (upload) {
				updateUploadWithJob(upload, job);
			} else if (job.status === 'pending' || job.status === 'running') {
				newJobs.push(job);
			}
		}

		if (newJobs.length) {
			updateFileInfoForJobs(newJobs);
		}
	}

	function updateFileInfoForJobs(jobs: Job[]) {
		const cs = cancellationService.createCancellationSource();
		const jobsToUpdate: { fileId: string; job: Job }[] = [];

		for (const job of jobs) {
			if (!job.response) {
				const fileId = getFileIdFromJobRequest(job);

				if (fileId) {
					jobsToUpdate.push({ fileId, job });
				}
			}
		}

		promiseUtilityService.parallelForEach(
			jobsToUpdate,
			{ cancellationToken: cs.token, maxDegreeOfParallelism: 5 },
			({ fileId, job }) =>
				fileService.getFileAsync(fileId, cs.token).then(file => {
					if (file) {
						const uploadFileId = getUploadFileIdFromJobRequest(job) || `job:${job.id}:${fileId}`;
						let upload = findUploadById(uploadFileId);
						if (!upload) {
							job.response = {
								content: {
									file,
								},
							};

							upload = {
								uploadFileId,
								fileId,
								fileName: file.name,
								status: mapJobStatus(job.status),
								job,
								cancellationSource: cs,
							};
							log.debug('Adding upload from websocket job', upload);
							uploads.push(upload);

							monitorUploadJobs([job.id]);
						}
					}
				})
		);
	}

	function getFileIdFromJobRequest(job: Job) {
		const op: AssetOperation | undefined =
			job.request && job.request.content && _.find(job.request.content.ops, { op: 'setFile' });

		return op && (op.fileId as string | undefined);
	}

	function getUploadFileIdFromJobRequest(job: Job) {
		const op: AssetOperation | undefined =
			job.request &&
			job.request.content &&
			_.find(job.request.content.ops, { op: 'setMetadata', path: 'uploadFileId' });

		return op && (op.value as string | undefined);
	}

	function monitorUploadJobs(jobIds: string[]) {
		jobService.awaitJobsCompletionAsync(jobIds).then(
			() => {},
			undefined,
			progress => {
				progress.updatedJobs.forEach((job: Job) => {
					const upload = findUploadByJobId(job.id);

					if (upload) {
						updateUploadWithJob(upload, job);
					}
				});
			}
		);
	}

	function updateUploadWithJob(upload: Upload, job: Job) {
		if (upload.job && upload.job.id !== job.id) {
			log.warn('Not updating upload with a different job.');
		}

		upload.job = job;

		const responseContent = job.response && job.response.content;
		if (responseContent && (responseContent.kind || responseContent.source) && responseContent.id) {
			upload.assetId = responseContent.id;
		}
		if (responseContent && responseContent.metadata && responseContent.metadata.uploadId) {
			upload.uploadBatchId = responseContent.metadata.uploadId;
		}

		const status = mapJobStatus(job.status);
		if (status !== 'completed' || !upload.hasMoreJobs) {
			// if there are more jobs to run, don't set the status to complete
			setUploadStatus(upload, status);
		}
	}

	function setUploadStatus(upload: Upload, status: UploadStatus, errorMessage?: string) {
		if (upload.status !== status) {
			upload.status = status;
			upload.errorMessage = errorMessage;
		}
	}

	function mapJobStatus(status: JobStatus): UploadStatus {
		switch (status) {
			case 'running':
			case 'pending':
				return 'processing';
			case 'failed':
			case 'canceled':
				return 'failed';
			case 'completed':
				return 'completed';
			default:
				log.warn('Invalid job status', status);
				return 'failed';
		}
	}

	function findUploadById(uploadFileId: string) {
		return uploads.find(x => x.uploadFileId === uploadFileId);
	}

	function findUploadByJobId(jobId: string) {
		return uploads.find(x => (x.job ? x.job.id === jobId : false));
	}

	function getUploadBatchIdsWithCompletedUploads() {
		return _.uniq(uploads.filter(x => x.status === 'completed').map(x => x.uploadBatchId));
	}

	return {
		uploadFilesAndCreateAssetsAsync,
		cancelUpload,
		getUploadBatchIdsWithCompletedUploads,
		uploads,
	};
}

export type IFileUploadManager = ReturnType<typeof fileUploadManager>;

function isFile(x: any): x is File {
	return x instanceof File;
}
