import * as angular from 'angular';
import * as _ from 'lodash';
import * as uirouter from '@uirouter/angularjs';
import { IConstants } from '../infrastructure';
import { IFileUploadManager, Upload, UploadStatus } from '../services';

interface UploadMenuScope extends angular.IScope {
	isUploadMenuOpen: boolean;
	isSmallViewport: boolean;
	uploads: Upload[];
	statusCount: Partial<Record<UploadStatus, number>>;
	headerStatus: string | undefined;
}

angular.module('app').directive('uploadMenu', uploadMenu);

function uploadMenu(): angular.IDirective {
	return {
		restrict: 'E',
		scope: {},
		templateUrl: require('../../views/templates/uploadMenu.html'),
		replace: true,
		controller(
			$log: angular.ILogService,
			$rootScope: angular.IRootScopeService,
			$scope: UploadMenuScope,
			$state: uirouter.StateService,
			constants: IConstants,
			fileUploadManager: IFileUploadManager
		) {
			$scope.isUploadMenuOpen = false;
			$scope.isSmallViewport = $rootScope.isSmallViewport;
			$scope.uploads = fileUploadManager.uploads;

			function updateStatusCount() {
				const counts = _.countBy(fileUploadManager.uploads, 'status');
				if (!_.isEqual($scope.statusCount, counts)) {
					$scope.statusCount = counts;
					$scope.headerStatus = setUploadHeaderStatus(counts);
				}
			}
			updateStatusCount();

			function setUploadHeaderStatus(counts: Partial<Record<UploadStatus, number>>) {
				let status: UploadStatus | undefined;
				if (counts.uploading) {
					status = 'uploading';
				} else if (counts.processing) {
					status = 'processing';
				} else if (counts.failed) {
					status = 'failed';
				} else if (counts.completed) {
					status = 'completed';
				}
				return status;
			}

			function uploadFiles(fileList: ReadonlyArray<File | string>) {
				if (!fileList.length) {
					$log.warn('No valid files.');
					return;
				}

				$scope.isUploadMenuOpen = true;

				fileUploadManager.uploadFilesAndCreateAssetsAsync(fileList).catch(reason => {
					if (reason !== 'abort') {
						$log.error('Asset creation or file upload errors', reason);
					}
				});
			}

			const handleUpload = (e: angular.IAngularEvent, data: { files: FileList; url?: string }) => {
				if ($rootScope.disableUploads) {
					return;
				}
				$log.debug('handleUpload', e);
				if (e.name === constants.uploadFromUrlEventName && data.url) {
					uploadFiles([data.url]);
				} else if (data.files) {
					uploadFiles(Array.from(data.files));
				}
			};

			$scope.cancelUpload = (upload: Upload) => fileUploadManager.cancelUpload(upload);

			$scope.cancelAllUploads = () => {
				fileUploadManager.uploads.forEach(upload => {
					fileUploadManager.cancelUpload(upload);
				});
			};

			$scope.goToAssetDetails = (assetId: string) => $state.go('details', { assetId });

			$scope.goToCompletedUploads = function () {
				const uploadBatchIds = fileUploadManager.getUploadBatchIdsWithCompletedUploads();

				if (!uploadBatchIds.length) {
					return;
				}

				$state.go('assets', {
					q: `upload:${
						uploadBatchIds.length > 1 ? `(${uploadBatchIds.join(' OR ')})` : uploadBatchIds[0]
					}`,
					filter: null,
					savedFilter: null,
					select: 'auto',
				});
			};

			$scope.toggleUploadMenu = () => {
				$scope.isUploadMenuOpen = !$scope.isUploadMenuOpen;
			};

			function enableWatchers() {
				const unwatchers = [
					$scope.$on(constants.dropEventName, handleUpload),
					$scope.$on(constants.filesSelectedEventName, handleUpload),
					$scope.$on(constants.uploadFromUrlEventName, handleUpload),
					$scope.$watch(
						() =>
							fileUploadManager.uploads.map(({ uploadFileId, status }) => ({
								uploadFileId,
								status,
							})),
						newValue => {
							$log.debug('uploads changed', newValue, fileUploadManager.uploads);
							$scope.uploads = fileUploadManager.uploads;
							updateStatusCount();
						},
						true
					),
				];

				return function () {
					unwatchers.forEach(unwatch => {
						unwatch();
					});
				};
			}

			const disableWatchers = enableWatchers();
			$scope.$on('$destroy', () => {
				disableWatchers();
			});
		},
	};
}
