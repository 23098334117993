import * as _ from 'lodash';

const statusList = ['preparing', 'uploading', 'complete', 'error'] as const;
export type FileProgressStatus = typeof statusList[number];

export interface FileProgress {
	uploadFileId: string;
	status: FileProgressStatus;
	fileSize: number;
	uploadedSize: number;
}

export interface ProgressReport {
	files: FileProgress[];
	status: FileProgressStatus | undefined;
}

export function createProgressTracker(onProgress: (progress: ProgressReport) => void) {
	const files: _.NumericDictionary<FileProgress> = {};

	function createProgressReport(): ProgressReport {
		const statusGroups = _.groupBy(files, 'status');
		const minStatus = statusList.find(
			status => statusGroups[status] && !!statusGroups[status].length
		);

		return {
			files: _.map(files, _.clone),
			status: minStatus,
		};
	}

	const updateProgressSoon = _.throttle(() => {
		onProgress(createProgressReport());
	}, 100);

	return {
		addFile(uploadFileId: string, fileSize: number) {
			files[uploadFileId] = {
				uploadFileId,
				status: statusList[0],
				fileSize,
				uploadedSize: 0.0,
			};
		},
		setFileStatus(uploadFileId: string, status: FileProgressStatus) {
			const file = files[uploadFileId];
			file.status = status;

			if (status === 'error') {
				file.uploadedSize = file.fileSize;
			}

			updateProgressSoon();
		},
		setUploadProgress(uploadFileId: string, uploadedSize: number, fileSize: number) {
			files[uploadFileId].fileSize = Math.max(fileSize, 0.01);
			files[uploadFileId].uploadedSize = Math.min(uploadedSize, files[uploadFileId].fileSize);

			updateProgressSoon();
		},
	};
}
